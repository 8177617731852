import * as ko from 'knockout';
import * as _ from 'underscore';

import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {CONFIRMATIONS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import {EventBus} from "Core/Common/EventBus/EventBus";

import {IProductConfigurationPageParams, ProductConfiguratorPage} from "../ProductConfiguratorPage";

import {NavigationStack} from '../../Stores/NavigationStack/NavigationStack';
import {NavigationItemTypes, NavigationItem} from '../../Stores/NavigationStack/NavigationItem';

import {ConfigurationPageStore} from "./Stores/ConfigurationPageStore";
import {ConfigurationPageVariablesStore} from "./Stores/ConfigurationPageVariablesStore";

import {ConfigurationPageMappings} from "./Mappings/ConfigurationPageMappings";

import {ProductInfoResponse} from "./Stores/Models/ProductInfoResponse";
import {ProductPartInfoResponse} from './Stores/Models/ProductPartInfoResponse';
import {ConfiguredProductResponse} from "./Stores/Models/ConfiguredProductResponse";

import {ViewModel} from "./Models/ViewModel";
import {BaseProductGroup} from "./Models/BaseProductGroup";
import {RootGroup} from "./Models/RootGroup";
import {ExtraGroup} from './Models/ExtraGroup';
import {ProductGroup} from "./Models/ProductGroup";
import {ProductPart} from "./Models/ProductPart";
import {BreadCrumbsItem} from "./Models/BreadCrumbsItem";

import {CreateConfigurationDto} from "./Stores/Params/CreateConfigurationDto";
import {UpdateConfigurationDto} from "./Stores/Params/UpdateConfigurationDto";
import {OrderDto} from "./Stores/Params/OrderDto";
import {ProductDto} from "./Stores/Params/ProductDto";
import {AlteredProductDto} from "./Stores/Params/AlteredProductDto";

import {
    ConfigurationPageEvents,
    GroupProductsLoadedEventArgs,
    SelectProductPartEventArgs, ConversionsDropdownArgs
} from "./Events/ConfigurationPageEvents";

import {InexConfirmationPopup} from "./Modals/InexConfirmationPopup/InexConfirmationPopup";
import {InexItem, InexProduct} from './Models/InexItem';

import Template from './Templates/Template.html';
import {NavigationStackEvents} from '../../Stores/NavigationStack/NavigationStackEvents';

import {LABELS} from "Core/Components/Translation/Locales";
import {ProductPartDto} from './Stores/Params/ProductPartDto';
import {UpdateConfigurationByLevelsDto} from './Stores/Params/UpdateConfigurationByLevelsDto';
import {ConfigurationLevelDto} from './Stores/Params/ConfigurationLevelDto';
import {ConfigurationLevel} from './Models/ConfigurationLEvel';
import {ConfiguratorLevels} from 'Core/Components/Controls/ProductConfigurator/ConfiguratorLevels';
import {CachedLevel} from './Models/CachedLevel';
import {PropertyValueDto} from "./Stores/Params/PropertyValueDto";
import {CustomFieldValueDto} from "./Stores/Params/CustomFieldValueDto";
import {PcfGroup} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/PcfGroup";
import {ProductPropertyGroup} from "./Models/Properties/ProductPropertyGroup";
import {PropertyControl} from "./Models/Properties/Controls/PropertyControl";
import {CustomFieldControl} from "./Models/CustomFields/Controls/CustomFieldControl";
import {ConversionDropdownResponse} from "./Stores/Models/ConversionDropdownResponse";
import {ConversionDropdown} from "../../Components/ConversionDropdown/ConversionDropdown";
import {SearchByConversionDto} from "../../Components/ConversionDropdown/Params/SearchByConversionDto";
import {ProductPrice} from "./Models/ProductPrice";
import {AlternativePriceModel} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/AlternativePriceModel";
import {UndefinedGroup} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/UndefinedGroup";

export interface IConfigurationPageParams extends IProductConfigurationPageParams {
    OrderEntityId: number;
    OrderId: number;
    ProductId: number;
    ConfigurationId: number;
    LockLevel1: boolean;
    HiddenPCFGROUPSIfEmpty: number[];
    ImageSizes: IConfigurationPageImageSizes;
    AlternativePriceModel: AlternativePriceModel;
}

export interface IConfigurationPageImageSizes {
    SelectedComponent: number;
    Tiles: number;
    InEx: number;
}

export interface IControlValidation {
    Name: string;
    ValidControl: boolean;
    ValidMassage: any;
}

export interface IProductOptionsForDropdown {
    ProductId: number,
    ProductGroupId: number,
    ProductGroupName: string,
    PageNumber: number,
    RecordsPerPage: number,
    Level: string,
    Conversions: Array<SearchByConversionDto>;
}

export class ConfigurationPage extends ProductConfiguratorPage<IConfigurationPageParams> {
    _labels = LABELS;
    private _newConfiguration: boolean;
    private _createLinks: boolean;
    private _handlingInex: boolean;
    private _isActiveLevelLocked: boolean;
    private _store: ConfigurationPageStore;
    private _variableStore: ConfigurationPageVariablesStore;
    private _mappings: ConfigurationPageMappings;
    private _loadedInclusiveProducts: ProductPart[];
    private _exclusiveProducts: ProductPart[];
    private configuredProductBackUp: ConfiguredProductResponse[];
    private alterationModelBackUp: UpdateConfigurationByLevelsDto;

    private _calculatingPriceSequences: number;

    private _viewModel: ViewModel;

    constructor(params: IConfigurationPageParams, navigationStack: NavigationStack, eventBus: EventBus) {
        super(params, navigationStack, eventBus);

        this._store = new ConfigurationPageStore(this.params.OrderEntityId, this.params.ProductsEntityId, this.params.ProductId);

        this._variableStore = new ConfigurationPageVariablesStore();
        this._variableStore.AssignEventBus(this.EventBus);

        this._mappings = new ConfigurationPageMappings(this.params.OrderEntityId, this.params.ProductsEntityId);

        this._loadedInclusiveProducts = [];
        this._exclusiveProducts = [];

        this._calculatingPriceSequences = 0;

        this._viewModel = new ViewModel();

        this._createLinks = false;
        this._handlingInex = false;
        this._isActiveLevelLocked = false;

        if (this.RolesImplemented) {
            this.alterationModelBackUp = new UpdateConfigurationByLevelsDto([], this.params.ConfigurationId);
        }

        this.BindEvents();
    }

    GetTemplate() {
        return Template;
    }

    ConfigureFromScratch() {
        this._newConfiguration = true;

        BlockUI.Block();

        return this._store.GetProductInfo(this.params.ProductId, this.params.OrderId, this.RolesImplemented ? this.ActiveConfigurationRole().Level : null)
            .then(productInfoDto => {
                this.FillViewModel(productInfoDto, false);
                this.navigationStack.Upsert(`${LABELS.NEW_CONFIGURATION} (${productInfoDto.NameTranslated || productInfoDto.Name})`, NavigationItemTypes.ConfigurationPage, {
                    Id: this.params.ProductId
                });
                BlockUI.Unblock();
            })
            .fail(err => {
                BlockUI.Unblock();
                new Notifier().Failed(err.message);
            });
    }

    AlterConfiguration() {
        this._newConfiguration = false;

        BlockUI.Block();

        if (this.RolesImplemented) {
            const payload = {
                OrderRecordId: this.params.OrderId,
                ProductRecordId: this.params.ProductId,
                ConfigurationId: this.params.ConfigurationId,
                ActiveLevel: this.ActiveConfigurationRole().Level
            };

            return this._store.GetConfiguredProductWithLevels(payload)
                .then(configuredProductDto => {
                    this.navigationStack.Upsert(`${LABELS.ALTERATION} (${configuredProductDto[0].Product.NameTranslated || configuredProductDto[0].Product.Name})`, NavigationItemTypes.ConfigurationPage, payload);

                    this.RestoreConfiguredProductWithLevels(configuredProductDto);
                    BlockUI.Unblock();
                })
                .fail(err => {
                    BlockUI.Unblock();
                    new Notifier().Failed(err.message);
                });
        } else {
            const payload = {
                OrderRecordId: this.params.OrderId,
                ProductRecordId: this.params.ProductId,
                ConfigurationId: this.params.ConfigurationId
            };
            return this._store.GetConfiguredProduct(payload)
                .then(configuredProductDto => {
                    this.navigationStack.Upsert(`${LABELS.ALTERATION} (${configuredProductDto.Product.NameTranslated || configuredProductDto.Product.Name})`, NavigationItemTypes.ConfigurationPage, payload);

                    this.RestoreConfiguredProduct(configuredProductDto.Product, configuredProductDto.Configuration.ProductParts);
                    BlockUI.Unblock();
                })
                .fail(err => {
                    BlockUI.Unblock();
                    new Notifier().Failed(err.message);
                });
        }
    }

    Dispose() {
        this._viewModel.Dispose();
        super.Dispose();
    }

    private BindEvents() {
        this.HandleEvent<ProductGroup>(ConfigurationPageEvents.ReloadingConversionDropdowns)
            .Using(eventArgs => this.ReloadingConversionDropdowns(eventArgs.Data))
            .Always();

        this.HandleEvent(ConfigurationPageEvents.LoadConversionDropdownsFromRemovePriceItem)
            .Using(eventArgs => this.LoadConversionDropdownsFromRemovePriceItem(eventArgs.Data as ProductPrice))
            .Always();

        this.HandleEvent<any, ConversionsDropdownArgs>(ConfigurationPageEvents.GroupConversionsDropdown)
            .Using(eventArgs => this.SelectProductOrFillGroupByDropdown(eventArgs.Data.args._this, eventArgs.Data.args._eventArgs))
            .Always();

        this.HandleEvent<any, ConversionsDropdownArgs>(ConfigurationPageEvents.RootGroupConversionsDropdown)
            .Using(eventArgs => this.SelectProductOrFillRootGroupByDropdown(eventArgs.Data.args._this, eventArgs.Data.args._eventArgs))
            .Always();

        this.HandleEvent<any, ConversionsDropdownArgs>(ConfigurationPageEvents.RootGroupConversionsDropdownSynchronization)
            .Using(eventArgs =>  this.ConversionDropdownSynchronization(eventArgs.Data.args._this, eventArgs.Data.args._eventArgs))
            .Always();

        this.HandleEvent<any, ConversionsDropdownArgs>(ConfigurationPageEvents.GroupConversionsDropdownSynchronization)
            .Using(eventArgs => this.ConversionDropdownSynchronization(eventArgs.Data.args._this, eventArgs.Data.args._eventArgs))
            .Always();

        this.HandleEvent<RootGroup>(ConfigurationPageEvents.NotInitRootGroupExpanded)
            .Using(eventArgs => {
                this.LoadRootGroupConversionDropdowns(eventArgs.Data)
            })
            .Always();

        this.HandleEvent<RootGroup>(ConfigurationPageEvents.RootGroupExapanding)
            .Using(() => this.CloseExpandedRootGroup())
            .Always();

        this.HandleEvent<ProductGroup>(ConfigurationPageEvents.NotInitGroupActivated)
            .Using(eventArgs => {
                this.LoadGroupConversionDropdowns(eventArgs.Data)
            })
            .Always();

        this.HandleEvent<GroupProductsLoadedEventArgs>(ConfigurationPageEvents.GroupProductsLoaded)
            .Using(eventArgs => this.CacheGroupProducts(eventArgs.Data.Products))
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ProductPartSelected)
            .Using(eventArgs => this.AddToPriceList(eventArgs.Data))
            .Using(eventArgs => this.AddToSelected(eventArgs.Data))
            .Using(eventArgs => {
                if (eventArgs.Source.Quantity() > 1) {
                    this.CalculateFactualPrice(eventArgs.Source);
                }
            })
            .Using(eventArgs => {
                eventArgs.Data.InitActionDefaultExpressions();
                this.SetActionDependsOnValues(eventArgs.Data);
            })
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelecting)
	        .Using(eventArgs => {
	            this.RemoveFromPriceList(eventArgs.Data);
                this.DispatchEvent<BaseProductGroup>(ConfigurationPageEvents.EvaluateCalculatedExpressionsOnUnselect, eventArgs.Data && eventArgs.Data.ParentGroup);
	        })
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ProductPartUnSelected)
            .Using(eventArgs => this.RemoveFromSelected(eventArgs.Data))
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ProductPartAddingAsExtra)
            .Using(eventArgs => this.AddAsExtra(eventArgs.Data))
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.PriceCalculationRequested)
            .When(eventArgs => eventArgs.Source.Selected())
            .Using(eventArgs => {
                this.CalculateFactualPrice(eventArgs.Source)
            })
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.QuantityChanged)
            .When(eventArgs => eventArgs.Source.IsMain())
            .Using(eventArgs => this._viewModel.UpdateAssemblyQuantity(eventArgs.Source.Quantity()))
            .Using(eventArgs => {
                this.CalculateFactualPrice(eventArgs.Source)
            })
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.QuantityChanged)
            .When(eventArgs => !eventArgs.Source.IsMain())
            .When(eventArgs => eventArgs.Source.Selected())
            .Using(eventArgs => this.AlterSelectedPart(eventArgs.Source))
            .Using(eventArgs => {
                this.CalculateFactualPrice(eventArgs.Source)
            })
            .Always()

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ProductPropertyValueChanged)
            .When(eventArgs => eventArgs.Source.Selected() && !eventArgs.Source.IsMain())
            .Using(eventArgs => this.AlterSelectedPart(eventArgs.Source))
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ProductCustomFieldValueChanged)
			.When(eventArgs => eventArgs.Source.Selected() && !eventArgs.Source.IsMain())
            .Using(eventArgs => this.AlterSelectedPart(eventArgs.Source))
            .Always();

        this.HandleEvent(ConfigurationPageEvents.SaveConfigurationRequested)
            .Using(() => this.SaveConfiguration())
            .Always();

        this.HandleEvent<ProductGroup>(ConfigurationPageEvents.ShowMore)
            .Using(eventArgs => {
                if (eventArgs.Data.ClickedAlternativeBtn) {
                    this.LoadGroupProducts(eventArgs.Data)
                } else {
                    this.LoadGroupConversionDropdowns(eventArgs.Data)
                }
            })
            .Always();

        this.HandleEvent<RootGroup>(ConfigurationPageEvents.RootShowMore)
            .Using(eventArgs => {
                if (eventArgs.Data.ClickedAlternativeBtn){
                    this.LoadRootGroupProducts(eventArgs.Data)
                } else {
                    this.LoadRootGroupConversionDropdowns(eventArgs.Data)
                }
            })
            .Always();

        this.HandleEvent<BaseProductGroup>(ConfigurationPageEvents.ShowingMore)
            .When(eventArgs => !eventArgs.Data.IsRoot)
            .Using(eventArgs => this._viewModel.FindProductGroup(eventArgs.Data.Id, eventArgs.Data.Name, eventArgs.Data.ParentProduct.Id).ShowMore())
            .Always();

        this.HandleEvent<BaseProductGroup>(ConfigurationPageEvents.ShowingMore)
            .When(eventArgs => eventArgs.Data.IsRoot)
            .When(eventArgs => !eventArgs.Source.ParentGroup.IsExtra)
            .Using(eventArgs => this._viewModel.GetRootGroupByIdAndName(eventArgs.Data.Id, eventArgs.Data.Name).ShowMore())
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.BeforeSelectingProductPartEvent)
            .Using(eventArgs => this.HandleInex(eventArgs.Data))
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.CheckAvailabilityToToggle)
            .When(() => this._calculatingPriceSequences == 0)
            .Using(eventArgs => this.DispatchEvent<ProductPart>(ConfigurationPageEvents.ToggleSelection, eventArgs.Data))
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.CheckSaveDisabilityByAction)
            .Using(() => this.CheckSaveDisability())
            .Always();

        this.HandleEvent(NavigationStackEvents.CheckNavigatability)
            .Using(() => this.CheckNavigatability())
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.PropertyContentLoading)
            .Using(eventArgs => this.BlockSaving())
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.PropertyContentLoaded)
            .Using(eventArgs => this.UnBlockSaving())
            .Always();

        this.HandleEvent<ProductPart>(ConfigurationPageEvents.ZoomButtonClicked)
            .Using(eventArgs => this.productImageViewer.ShowOriginalImage(eventArgs.Data.Id, eventArgs.Data.ImageTableType))
            .Always();
    }

    private FillViewModel(productInfoDto: ProductInfoResponse, restored: boolean, readOnly: boolean = false) {
        const product = this._mappings.MapToProduct(productInfoDto, this.params.AlternativePriceModel, this._isActiveLevelLocked);
        product.AttachBackButton(this.backButton);
        product.AssignEventBus(this.EventBus);

        const rootGroups = this._mappings.MapToRootGroups(productInfoDto.PcfPartsGroups, product,
            (productPartGroupName, productPartGroup) => {
                productPartGroup.AssignEventBus(this.EventBus);
                productPartGroup.AfterInit();

                this.CheckIfGroupIsHidden(productPartGroup);
            });

        this._viewModel.RootGroups(rootGroups);

        this._viewModel.MainGroup(new RootGroup(0, product.Name, product.NameTranslated, [], false, product));
        this._viewModel.MainGroup().AssignEventBus(this.EventBus);

        const activeLevel = this.RolesImplemented ? this.ActiveConfigurationRole().Level : null;
        this._viewModel.ExtraGroup(new ExtraGroup(product, this.params, this._variableStore, readOnly, activeLevel));
        this._viewModel.ExtraGroup().AssignEventBus(this.EventBus);

        this._viewModel.UndefinedGroup(new UndefinedGroup(product));
        this._viewModel.UndefinedGroup().AssignEventBus(this.EventBus);

        this._viewModel.Product(product);

        const mainGroupProduct = this._mappings.MapToProductPart(productInfoDto, this._viewModel.MainGroup(),
            (_, productPart) => {
                productPart.SetInitialPropertyValues();
                productPart.TileImageSize = this.params.ImageSizes.Tiles;
                productPart.SelectedImageSize = this.params.ImageSizes.SelectedComponent;
                productPart.AssignVariableStore(this._variableStore);
                productPart.MakeSelected();
                productPart.AssignEventBus(this.EventBus);
                productPart.AfterInit();

                if (this._newConfiguration) {
                    productPart.SetInitialCustomFieldValues();
                } else {
                    productPart.SetCustomFieldValues(productInfoDto.CustomFieldValues);
                }

                productPart.SetPropertyValues(productInfoDto.PropertyValues);
                productPart.Price(productInfoDto.Price);
                productPart.Quantity(productInfoDto.Quantity);

                productPart.InitActionDefaultExpressions();
                this.SetActionDependsOnValues(productPart);
            }, null, readOnly, true);

        if (restored) {
            this._viewModel.SelectedProducts.InitMainPropertyValues(this._mappings.MapToPropertyValues(productInfoDto.PropertyValues));
        } else {
            mainGroupProduct.SetInitialPropertyValues();
            this._viewModel.SelectedProducts.InitMainPropertyValues(mainGroupProduct.GetPropertyValues());
        }

        this._viewModel.Product().MainProductPart = mainGroupProduct;

        this._viewModel.MainGroup().InitMainGroup(mainGroupProduct);
        this._viewModel.MainGroup().Expand();

        let alreadyDownloadedAlternatives = [];

        productInfoDto.InclusiveProducts.forEach(inclusive => {
            const product = this._viewModel.FindProductById(inclusive);
            if (product != null) {
                product.ParentGroup.Products().forEach(p => {
                    if (alreadyDownloadedAlternatives.indexOf(p.Id) == -1) {
                        alreadyDownloadedAlternatives.push(p.Id);
                    }
                })
            }
        })

        if (this._newConfiguration && productInfoDto.InclusiveProducts.length > 0) {
            if (this.container != null) {
                BlockUI.Block({Target: this.container});
            } else {
                this._handlingInex = true;
            }
            this._store.GetInclusiveProducts({
                SelectedProductPartsIds: [product.Id],
                InclusiveProductPartsIds: productInfoDto.InclusiveProducts,
                ExclusiveProductPartsIds: productInfoDto.ExclusiveProducts,
                DefaultPartsIds: productInfoDto.Defaults,
                AlreadyDownloadedAlternatives: alreadyDownloadedAlternatives,
                GroupId: 0,
                GroupName: null,
                Level: activeLevel
            })
                .then(inclusiveProducts => {
                    if (inclusiveProducts != null) {
                        this.ShowNewConfigurationInitialInexConfirmationPopup(product.Id, inclusiveProducts);
                    }
                    this._handlingInex = false;

                    this.UnblockUi();
                })
                .fail(err => {
                    this._handlingInex = false;
                    this.UnblockUi();
                    new Notifier().Failed(err.message);
                });
        }
    }

    private RestoreConfiguredProduct(product: ProductInfoResponse, parts: ProductPartInfoResponse[]) {
        this.RemoveKSeqFromNonExtraProducts(parts);

        const undefinedProductParts = this.GetUndefinedGroupProducts(product, parts);
        const definedProductParts = _.difference(parts, undefinedProductParts);
        const isReadOnly = _.any(undefinedProductParts);

        this.FillProductsGroups(product, definedProductParts, undefinedProductParts, isReadOnly);
        this.FillBasket(definedProductParts, undefinedProductParts);
    }

    private FillProductsGroups(product: ProductInfoResponse, definedParts: ProductPartInfoResponse[], undefinedParts: ProductPartInfoResponse[], readOnly: boolean = false) {
        this.FillViewModel(product, true, readOnly);
        this.RegisterSelectedProducts(definedParts, undefinedParts);
        this.RestoreSelectedProducts(definedParts, undefinedParts, readOnly);
        this.FillBreadCrumbs(definedParts);
    }

    private RestoreConfiguredProductWithLevels(configuredProductDto: ConfiguredProductResponse[]) {
        let product, parts, definedParts, undefinedParts;

        configuredProductDto.forEach(dto => dto.Configuration && this.RemoveKSeqFromNonExtraProducts(dto.Configuration.ProductParts));

        let activeLevelDto = configuredProductDto.find(dto => dto.Product.Level == this.ActiveConfigurationRole().Level);

        this._createLinks = activeLevelDto.CreateLinks;

        if (configuredProductDto.length > 1) {
            this._viewModel.compareLevels(true);
        }

        product = activeLevelDto.Product;
        parts = activeLevelDto.Configuration.ProductParts;

        this._viewModel.Level = product.Level;

        const hasLevel2 = configuredProductDto && _.any(configuredProductDto, configuredProduct => configuredProduct && configuredProduct.Product
            && configuredProduct.Product.Level === ConfiguratorLevels.Level2);
        this._isActiveLevelLocked = hasLevel2 && this.ActiveConfigurationRole().Level === ConfiguratorLevels.Level1 && this.params.LockLevel1;

        undefinedParts = this.GetUndefinedGroupProducts(product, parts);
        definedParts = _.difference(parts, undefinedParts);
        const isReadOnly = this._isActiveLevelLocked || _.any(undefinedParts);

        this.FillProductsGroups(product, definedParts, undefinedParts, isReadOnly);
        this.FillBasketByLevels(configuredProductDto);

        if (this._isActiveLevelLocked) {
            const filteredGroups = this._viewModel.RootGroups().filter(rg => rg.HasProducts());
            this._viewModel.RootGroups(filteredGroups);
        }

        configuredProductDto.filter(dto => dto.Product.Level != this.ActiveConfigurationRole().Level).forEach(dto => {
            product = dto.Product;
            parts = dto.Configuration.ProductParts;

            let vm = new CachedLevel(dto.Product.Level);

            const rootGroups = this._mappings.MapToRootGroups(product.PcfPartsGroups, this._viewModel.Product(),
                (productPartGroupName, productPartGroup) => {
                    productPartGroup.AssignEventBus(this.EventBus);
                    productPartGroup.AfterInit();

                    this.CheckIfGroupIsHidden(productPartGroup);
                });

            vm.RootGroups(rootGroups);

            vm.MainGroup(new RootGroup(0, product.Name, product.NameTranslated, [], false, this._viewModel.Product()));
            vm.MainGroup().AssignEventBus(this.EventBus);

            const activeLevel = this.RolesImplemented ? this.ActiveConfigurationRole().Level : null;
            vm.ExtraGroup(new ExtraGroup(product, this.params, this._variableStore, true, activeLevel));
            vm.ExtraGroup().AssignEventBus(this.EventBus);

            vm.UndefinedGroup(new UndefinedGroup(product));
            vm.UndefinedGroup().AssignEventBus(this.EventBus);

            const mainGroupProduct = this._mappings.MapToProductPart(product, this._viewModel.MainGroup(), (_, productDetail) => {
                productDetail.AssignEventBus(this.EventBus);
                productDetail.readOnly(true);
                productDetail.TileImageSize = this.params.ImageSizes.Tiles;
                productDetail.SelectedImageSize = this.params.ImageSizes.SelectedComponent;
                productDetail.MakeSelected();
                productDetail.Price(product.Price);
            }, null, true, true);

            this._viewModel.Product().PriceList().ComparisonTotal(this._viewModel.Product().PriceList().ComparisonTotal() + product.Price);

            const comparisonAlternativePrice = this._viewModel.Product().PriceList().CalculateAlternativePrice(product.Price);
            this._viewModel.Product().PriceList().ComparisonAlternativeTotal(this._viewModel.Product().PriceList().ComparisonAlternativeTotal() + comparisonAlternativePrice);

            this._viewModel.Product().PriceList().ComparisonQuantity(product.Quantity);

            vm.MainGroup().InitMainGroup(mainGroupProduct);

            vm.Level = dto.Product.Level;
            this._viewModel.ViewModels.push(vm);

            this._viewModel.Product().PriceList().Levels().forEach(level => level.Active(false));
            this._viewModel.Product().PriceList().Levels().find(level => level.Level == vm.Level).Active(true);

            undefinedParts = this.GetUndefinedGroupProducts(product, parts);
            definedParts = _.difference(parts, undefinedParts);

            this.RestoreSelectedProducts(definedParts, undefinedParts, true);

            vm.RootGroups(vm.RootGroups().filter(rg => rg.HasProducts()));

            this.FillBreadCrumbs(definedParts);

            this._viewModel.Product().PriceList().Levels().forEach(level => level.Active(false));
            this._viewModel.Product().PriceList().Levels()[0].Active(true);
        });

        this._viewModel.Product().ViewModels = this._viewModel.ViewModels;
    }

    private RestoreSelectedProducts(definedProductParts: ProductPartInfoResponse[], undefinedProductParts: ProductPartInfoResponse[], readOnly?: boolean) {
        const allParts = definedProductParts.concat(undefinedProductParts);
        this.ChangeReadOnlyPrice(allParts, readOnly);

        const rootGroupsProducts = definedProductParts.filter(product => _.isEmpty(product.Path) && (product.PcfGroup.Id || product.PcfGroup.Name));

        rootGroupsProducts.forEach(productPart => {
            const group = this._viewModel.RootGroups().find(group => group.Id === productPart.PcfGroup.Id && group.Name === productPart.PcfGroup.Name);
            if (productPart.AlternativesCount == 0 || readOnly) {
                group.CanShowMore(false);
            }
            this.FillGroup(group, [productPart], true, readOnly);
        });

        const extraProductParts = definedProductParts.filter(product => _.isEmpty(product.Path) && !product.PcfGroup.Id && !product.PcfGroup.Name);
        this.FillGroup(this._viewModel.ExtraGroup(), extraProductParts, true, readOnly);

        const nestedProducts = definedProductParts.filter(product => !_.isEmpty(product.Path))
            .sort((p1, p2) => p1.Path.length > p2.Path.length ? 1 : p1.Path.length < p2.Path.length ? -1 : 0);

        nestedProducts.forEach(productPart => {
            const parentProductPath = productPart.Path.slice(0, productPart.Path.length - 1);
            const parentProductId = productPart.Path[productPart.Path.length - 1];

            let parentProduct;
            if (readOnly) {
                parentProduct = this._viewModel.FindProductById(parentProductId);
            } else {
                const parentProductFromCache = this._viewModel.CachedProducts.Find(product => _.isEqual(product.Path, parentProductPath) && _.isEqual(product.Id, parentProductId));
                parentProduct = this._viewModel.FindProduct(parentProductFromCache.Id, parentProductFromCache.RootGroupId, parentProductFromCache.RootGroupName, parentProductFromCache.GroupId, parentProductFromCache.GroupName, parentProductFromCache.Path, parentProductFromCache.KSeq, parentProductFromCache.KSeqGuid);

            }
            const group = parentProduct.Groups().find(group => group.Id === productPart.PcfGroup.Id && group.Name === productPart.PcfGroup.Name);
            this.FillGroup(group, [productPart], true, readOnly);
        });

        if (_.any(undefinedProductParts)){
            this.FillGroup(this._viewModel.UndefinedGroup(), undefinedProductParts, true, true);
        }
    }

    private FillBasket(definedProductParts: ProductPartInfoResponse[], undefinedProductParts: ProductPartInfoResponse[]) {
        const rootGroupsProducts = definedProductParts.filter(product => _.isEmpty(product.Path) && (product.PcfGroup.Id || product.PcfGroup.Name));
        const extraGroupProducts = definedProductParts.filter(product => _.isEmpty(product.Path) && !product.PcfGroup.Id && !product.PcfGroup.Name);
        const nestedProducts = definedProductParts.filter(product => !_.isEmpty(product.Path));

        const rootGroupProductPrices = this._mappings.MapRootGroupProductPartsResponseOnProductPrices(rootGroupsProducts);
        rootGroupProductPrices.forEach(product => this._viewModel.AddToPriceList(product, product.GroupId, product.GroupName, product.GroupNameTranslation));

        const extraProductPrices = this._mappings.MapRootGroupProductPartsResponseOnProductPrices(extraGroupProducts);
        extraProductPrices.forEach(product => {
            product.GroupId = this._viewModel.ExtraGroup().Id;
            product.GroupName = this._viewModel.ExtraGroup().Name;
            product.RootGroupId = this._viewModel.ExtraGroup().Id;
            product.RootGroupName = this._viewModel.ExtraGroup().Name;
            this._viewModel.AddToPriceList(product, this._viewModel.ExtraGroup().Id, this._viewModel.ExtraGroup().Name, this._viewModel.ExtraGroup().TranslatedName);
        });

        nestedProducts.forEach(product => {
            const rootGroupProductPrice = _.find(rootGroupProductPrices, rootGroupProductPrice =>
                rootGroupProductPrice.Id === product.Path[0]);
            const productPrice = this._mappings.MapProductPartInfoResponseOnProductPrice(product, rootGroupProductPrice.GroupId, rootGroupProductPrice.GroupName);
            this._viewModel.AddToPriceList(productPrice, rootGroupProductPrice.GroupId, rootGroupProductPrice.GroupName, product.PcfGroup.NameTranslation);
        });

        undefinedProductParts.forEach(product => {
            const groupId = this._viewModel.UndefinedGroup().Id;
            const groupName = this._viewModel.UndefinedGroup().Name;
            const groupNameTranslation = this._viewModel.UndefinedGroup().TranslatedName;

            const productPrice = this._mappings.MapProductPartInfoResponseOnProductPrice(product, groupId, groupName);
            productPrice.GroupId = groupId;
            productPrice.GroupName = groupName;
            productPrice.GroupNameTranslation = groupNameTranslation;
            productPrice.Path = [];

            this._viewModel.AddToPriceList(productPrice, groupId, groupName, groupNameTranslation);
        });
    }

    private FillBasketByLevels(configuredProductDto: ConfiguredProductResponse[]) {
        if (this._viewModel.compareLevels()) {
            this._viewModel.Product().PriceList().Levels(configuredProductDto.filter(dto =>
                dto.Product.Level != this.ActiveConfigurationRole().Level).map(dto =>
                new ConfigurationLevel(dto.Product.Level)));

            this._viewModel.Product().PriceList()._compare(true);
            this._viewModel.Product().PriceList().MainProductPriceGroup()._compare(true);

            const comparisonPrice = configuredProductDto.find(dto => dto.Product.Level != this.ActiveConfigurationRole().Level).Product.Price;
            const comparisonAlternativePrice = this._viewModel.Product().PriceList().CalculateAlternativePrice(comparisonPrice);

            this._viewModel.Product().PriceList().MainProductPriceGroup().ComparisonPrice(comparisonPrice);
            this._viewModel.Product().PriceList().MainProductPriceGroup().ComparisonAlternativePrice(comparisonAlternativePrice);

            this._viewModel.Product().PriceList().Levels().unshift(new ConfigurationLevel(this.ActiveConfigurationRole().Level));
            this._viewModel.Product().PriceList().Levels()[0].Active(true);
        }

        this._viewModel.Product().PriceList().MainLevel = this.ActiveConfigurationRole().Level;

        configuredProductDto.forEach(dto => {
            const mainProduct = dto.Product;
            const productParts = dto.Configuration.ProductParts;

            const undefinedProductParts = this.GetUndefinedGroupProducts(mainProduct, productParts);
            const definedProductParts = _.difference(productParts, undefinedProductParts);

            const rootGroupsProducts = definedProductParts.filter(product => _.isEmpty(product.Path) && (product.PcfGroup.Id || product.PcfGroup.Name));
            const extraGroupProducts = definedProductParts.filter(product => _.isEmpty(product.Path) && !product.PcfGroup.Id && !product.PcfGroup.Name);
            const nestedProducts = definedProductParts.filter(product => !_.isEmpty(product.Path));

            const rootGroupProductPrices = this._mappings.MapRootGroupProductPartsResponseOnProductPrices(rootGroupsProducts);
            rootGroupProductPrices.forEach(product => this._viewModel.AddToPriceListByLevel(product, product.GroupId, product.GroupName, product.GroupNameTranslation, dto.Product.Level));

            const extraProductPrices = this._mappings.MapRootGroupProductPartsResponseOnProductPrices(extraGroupProducts);
            extraProductPrices.forEach(product => {
                product.GroupId = this._viewModel.ExtraGroup().Id;
                product.GroupName = this._viewModel.ExtraGroup().Name;
                product.RootGroupId = this._viewModel.ExtraGroup().Id;
                product.RootGroupName = this._viewModel.ExtraGroup().Name;
                this._viewModel.AddToPriceListByLevel(product, this._viewModel.ExtraGroup().Id, this._viewModel.ExtraGroup().Name, this._viewModel.ExtraGroup().TranslatedName, dto.Product.Level)
            });

            nestedProducts.forEach(product => {
                const rootGroupProductPrice = _.find(rootGroupProductPrices, rootGroupProductPrice => rootGroupProductPrice.Id === product.Path[0]);
                const productPrice = this._mappings.MapProductPartInfoResponseOnProductPrice(product, rootGroupProductPrice.GroupId, rootGroupProductPrice.GroupName);
                this._viewModel.AddToPriceListByLevel(productPrice, rootGroupProductPrice.GroupId, rootGroupProductPrice.GroupName, product.PcfGroup.NameTranslation, dto.Product.Level);
            });

            undefinedProductParts.forEach(product => {
                const groupId = this._viewModel.UndefinedGroup().Id;
                const groupName = this._viewModel.UndefinedGroup().Name;
                const groupNameTranslation = this._viewModel.UndefinedGroup().TranslatedName;

                let productPrice = this._mappings.MapProductPartInfoResponseOnProductPrice(product, groupId, groupName);
                productPrice.GroupId = groupId;
                productPrice.GroupName = groupName;
                productPrice.GroupNameTranslation = groupNameTranslation;
                productPrice.Path = [];

                this._viewModel.AddToPriceList(productPrice, groupId, groupName, groupNameTranslation);
            });
        })
    }

    private FillBreadCrumbs(definedProductParts: ProductPartInfoResponse[]) {
        const rootGroupsProducts = definedProductParts.filter(product => _.isEmpty(product.Path) && (product.PcfGroup.Id || product.PcfGroup.Name));

        rootGroupsProducts.map(product => {
            const nestedProducts = definedProductParts.filter(part => product.Id === _.first(part.Path));

            const productBreadCrumb = new BreadCrumbsItem(product.Id, product.Name, product.NameTranslated, product.PcfGroup.Id, product.PcfGroup.Name, product.Path);
            const nestedProductsBreadCrumbs = nestedProducts.map(part => new BreadCrumbsItem(part.Id, part.Name, part.NameTranslated, part.PcfGroup.Id, part.PcfGroup.Name, part.Path));
            const breadCrumbsItems = [productBreadCrumb, ...nestedProductsBreadCrumbs];

            const rootGroup = this._viewModel.GetRootGroupByIdAndName(product.PcfGroup.Id, product.PcfGroup.Name);
            rootGroup.AddBreadCrumbs(breadCrumbsItems);
        });
    }

    private RegisterSelectedProducts(definedProductParts: ProductPartInfoResponse[], undefinedProductParts: ProductPartInfoResponse[],) {
        const rootGroupsProducts = definedProductParts.filter(product => _.isEmpty(product.Path) && (product.PcfGroup.Id || product.PcfGroup.Name));
        const extraGroupProducts = definedProductParts.filter(product => _.isEmpty(product.Path) && !product.PcfGroup.Id && !product.PcfGroup.Name);
        const nestedProducts = definedProductParts.filter(product => !_.isEmpty(product.Path));

        rootGroupsProducts.forEach(productPart => {
            const customFieldValues = this._mappings.MapToCustomFieldValues(productPart.CustomFieldValues);
            this._viewModel.SelectedProducts.Restore(productPart.Id, productPart.PcfGroup.Id, productPart.PcfGroup.Name, productPart.PcfGroup.Id, productPart.PcfGroup.Name, productPart.Path, productPart.KSeq, null, productPart.FactualPrice, productPart.Quantity, productPart.PropertyValues, customFieldValues);
        });

        extraGroupProducts.forEach(productPart => {
            const customFieldValues = this._mappings.MapToCustomFieldValues(productPart.CustomFieldValues);
            const groupId = this._viewModel.ExtraGroup().Id;
            const groupName = this._viewModel.ExtraGroup().Name;
            this._viewModel.SelectedProducts.Restore(productPart.Id, groupId, groupName, groupId, groupName, productPart.Path, productPart.KSeq, null, productPart.FactualPrice, productPart.Quantity, productPart.PropertyValues, customFieldValues);
        });

        nestedProducts.forEach(productPart => {
            const rootPart = _.find(definedProductParts, rootProductPart => !_.isEmpty(productPart.Path) && rootProductPart.Id === productPart.Path[0]);
            const customFieldValues = this._mappings.MapToCustomFieldValues(productPart.CustomFieldValues);
            this._viewModel.SelectedProducts.Restore(productPart.Id, productPart.PcfGroup.Id, productPart.PcfGroup.Name, rootPart.PcfGroup.Id, rootPart.PcfGroup.Name, productPart.Path, productPart.KSeq, null, productPart.FactualPrice, productPart.Quantity, productPart.PropertyValues, customFieldValues);
        });

        undefinedProductParts.forEach(productPart => {
            const customFieldValues = this._mappings.MapToCustomFieldValues(productPart.CustomFieldValues);
            const groupId = this._viewModel.UndefinedGroup().Id;
            const groupName = this._viewModel.UndefinedGroup().Name;
            this._viewModel.SelectedProducts.Restore(productPart.Id, groupId, groupName, groupId, groupName, productPart.Path, productPart.KSeq, null, productPart.FactualPrice, productPart.Quantity, productPart.PropertyValues, customFieldValues);
        });
    }

    //#region *---------==== Conversion Dropdowns ====---------*
    private CollapseGroup(product: ProductPart): void {
        if (product.HasCollapseGroup()){
            let group: RootGroup | ProductGroup = product.ParentGroup as RootGroup | ProductGroup;
            if (group instanceof ProductGroup) {
                if (group.Expanded()){
                    group.Deactivate();
                    group.ExtendedView(true);
                }
            }
        }
    }
    private LoadConversionDropdownsFromRemovePriceItem(product: ProductPrice){
        let group: RootGroup = _.find(this._viewModel.RootGroups(), (group: RootGroup)=> group.Id === product.GroupId && group.Name === product.GroupName);
        if (group != null){
            this.LoadRootGroupConversionDropdowns(group);
        }
    }
    private ResetConversionDropdownByProduct(product: ProductPart): void {
        let group: RootGroup | ProductGroup = product.ParentGroup as RootGroup | ProductGroup;

        if (group.ConversionDropdowns.IsInit) {
            group.ConversionDropdowns.Reset();
            group.ExtendedView(true);
            group.Expanded(true);
        }
    }

    private ToggleViewOrResetConversionDropdown(group: BaseProductGroup): void {
        if (group instanceof RootGroup || group instanceof ProductGroup) {

            let rootOrProductGroup: RootGroup | ProductGroup = group as RootGroup | ProductGroup;
            const conversionDropdowns = rootOrProductGroup.ConversionDropdowns;

            if (conversionDropdowns.IsInit) {
                conversionDropdowns.ResetConversions();
            } else {
                rootOrProductGroup.ToggleView();
            }

        } else {
            group.ToggleView();
        }

    }
    private ReloadingConversionDropdowns(group: RootGroup | ProductGroup) {
        if (group instanceof RootGroup) {
            if (!group.ConversionDropdowns.IsInit && group.ConversionDropdownLoaded) {
                this.LoadRootGroupConversionDropdowns(group);
            }
        }

        if (group instanceof ProductGroup) {
            if (!group.ConversionDropdowns.IsInit && group.ConversionDropdownLoaded) {
                this.LoadGroupConversionDropdowns(group);
            }
        }
    }

    private GetGroupProductByConversionDropdown(productGroup: RootGroup | ProductGroup, dto: IProductOptionsForDropdown): void {
        const rootOrProductGroup: RootGroup | ProductGroup = productGroup;
        const conversionDropdown: ConversionDropdown = rootOrProductGroup.GetConversionDropdown();
        let blockUIContainer: HTMLElement = rootOrProductGroup.Container;

        if (!rootOrProductGroup.GetActiveProduct()){

        }

        if (rootOrProductGroup instanceof ProductGroup) {
            blockUIContainer = rootOrProductGroup.ParentProduct.Container;
        }

        BlockUI.Block({Target: blockUIContainer});
        this._store.GetGroupProducts(dto)
            .then(groupProducts => {

                if(groupProducts.length == 1) {
                    conversionDropdown.ResetMessage();

                    let product: ProductPart = rootOrProductGroup.FindProductById(groupProducts[0].Id)
                    if(product){
                        product.InitActionDefaultExpressions();
                        this.SetActionDependsOnValues(product);
                        product.ToggleSelection();
                    } else {
                        this.FillGroup(rootOrProductGroup, groupProducts);
                        let product: ProductPart = rootOrProductGroup.FindProductById(groupProducts[0].Id)
                        product.ToggleSelection();
                    }

                    productGroup.CanShowMore(true);
                    if (rootOrProductGroup instanceof RootGroup){
                        rootOrProductGroup.Expanded(true);
                        rootOrProductGroup.ExtendedView(true);
                    }

                    BlockUI.Unblock(blockUIContainer);
                    return;
                }

                if (!groupProducts.length){
                    conversionDropdown.SetMessage(`${NOTIFICATIONS.RECORD_NOT_FOUND} :(`);
                    BlockUI.Unblock(blockUIContainer);
                    return;
                }

                if (groupProducts.length > 1){
                    conversionDropdown.SetMessage(LABELS.IMPOSSIBLE_TO_SELECT);
                    BlockUI.Unblock(blockUIContainer);
                    return;
                }

            })
            .fail(err => {
                new Notifier().Failed(err.message);
                BlockUI.Unblock(blockUIContainer);
            });
    }

    private SelectProductOrFillGroupByDropdown(productGroup: ProductGroup, eventArgs: any): void {
        const dto: IProductOptionsForDropdown = {
            ProductId: productGroup.ParentProduct.Id,
            ProductGroupId: productGroup.Id,
            ProductGroupName: productGroup.Name,
            PageNumber: 0,
            RecordsPerPage: 0,
            Level: this.RolesImplemented ? this.ActiveConfigurationRole().Level : null,
            Conversions: eventArgs.data.Conversions
        };
        this.GetGroupProductByConversionDropdown(productGroup, dto);
    }


    private SelectProductOrFillRootGroupByDropdown(productGroup: RootGroup, eventArgs: any): void {
        const dto: IProductOptionsForDropdown = {
            ProductId: this.params.ProductId,
            ProductGroupId: productGroup.Id,
            ProductGroupName: productGroup.Name,
            PageNumber: 0,
            RecordsPerPage: 0,
            Level: this.RolesImplemented ? this.ActiveConfigurationRole().Level : null,
            Conversions: eventArgs.data.Conversions
        };
        this.GetGroupProductByConversionDropdown(productGroup, dto);
    }

    private LoadRootGroupConversionDropdowns(rootGroup: RootGroup) {
        this.LoadConversionDropdowns(rootGroup);
    }
    
    private LoadGroupConversionDropdowns(productGroup: ProductGroup) {
        this.LoadConversionDropdowns(productGroup);
    }

    private ConversionDropdownSynchronization(productGroup: RootGroup | ProductGroup, eventArgs: any): void {
        let blockUIContainer: HTMLElement = null;
        let rootOrProductGroup: RootGroup | ProductGroup = productGroup as RootGroup | ProductGroup;
        const conversionDropdown: ConversionDropdown = rootOrProductGroup.ConversionDropdowns;
        const isRootGroup: boolean = rootOrProductGroup instanceof RootGroup;
        const isProductGroup: boolean = rootOrProductGroup instanceof ProductGroup;

        if (isRootGroup) {
            blockUIContainer = productGroup.Container;
        }
        if (isProductGroup) {
            blockUIContainer = productGroup.ParentProduct.Container;
        }

        BlockUI.Block({Target: blockUIContainer});
        const paramsDto = {
            ProductId: isProductGroup ? rootOrProductGroup.ParentProduct.Id : this.params.ProductId,
            ProductGroupId: rootOrProductGroup.Id,
            ProductGroupName: rootOrProductGroup.Name,
            Level: this.RolesImplemented ? this.ActiveConfigurationRole().Level : null,
            SelectedConversions: eventArgs.data ? eventArgs.data.SelectedConversions : null
        };

        this._store.GetConversionDropdowns(paramsDto)
            .then((conversionDropdownsResponse: ConversionDropdownResponse[])=> {
                if (conversionDropdownsResponse.length) {
                    conversionDropdown.ValueSynchronization(conversionDropdownsResponse)
                    BlockUI.Unblock(blockUIContainer);
                }
            })
            .fail(err => {
                BlockUI.Unblock(blockUIContainer);
                new Notifier().Failed(err.message);
            })
    }

    private LoadConversionDropdowns(productGroup: RootGroup | ProductGroup): void {
        let blockUIContainer: HTMLElement = null;
        let rootOrProductGroup: RootGroup | ProductGroup = productGroup as RootGroup | ProductGroup;
        const conversionDropdown: ConversionDropdown = rootOrProductGroup.ConversionDropdowns;
        const isRootGroup: boolean = rootOrProductGroup instanceof RootGroup;
        const isProductGroup: boolean = rootOrProductGroup instanceof ProductGroup;

        if (isRootGroup) {
            blockUIContainer = productGroup.Container;
        }
        if (isProductGroup) {
            blockUIContainer = productGroup.ParentProduct.Container;
        }

        if (conversionDropdown.DropDownParams.length){

            if (!rootOrProductGroup.Expanded()) {
                if (isRootGroup){
                    rootOrProductGroup.Expand();
                }
                if (rootOrProductGroup instanceof ProductGroup){
                    rootOrProductGroup.Activate();
                }
            }
            if (rootOrProductGroup.ExtendedView()) {
                rootOrProductGroup.ToggleView();
            }
            return;
        }
        BlockUI.Block({Target: blockUIContainer});

        this._store.GetConversionDropdowns({
            ProductId: isProductGroup ? rootOrProductGroup.ParentProduct.Id : this.params.ProductId,
            ProductGroupId: rootOrProductGroup.Id,
            ProductGroupName: rootOrProductGroup.Name,
            Level: this.RolesImplemented ? this.ActiveConfigurationRole().Level : null
        })
            .then((conversionDropdownsResponse: ConversionDropdownResponse[])=> {
                if (conversionDropdownsResponse.length) {
                    conversionDropdown.Init(conversionDropdown.MapConversionDropdowns(conversionDropdownsResponse));
                    rootOrProductGroup.LoadDropdown();

                    if (!rootOrProductGroup.Expanded()) {
                        if (isRootGroup){
                            rootOrProductGroup.Expand();
                        }
                        if (rootOrProductGroup instanceof ProductGroup){
                            rootOrProductGroup.Activate();
                        }
                    }
                    if (rootOrProductGroup.ExtendedView()) {
                        rootOrProductGroup.ToggleView();
                    }

                    BlockUI.Unblock(blockUIContainer);

                } else {
                    if (rootOrProductGroup instanceof RootGroup) {
                        this.LoadRootGroupProducts(rootOrProductGroup, true);
                    }
                    if (rootOrProductGroup instanceof ProductGroup){
                        this.LoadGroupProducts(rootOrProductGroup, true);
                    }
                }
            })
            .fail(err => {
                BlockUI.Unblock(blockUIContainer);
                new Notifier().Failed(err.message);
            })

    }
    //#endregion

    private LoadRootGroupProducts(productGroup: RootGroup, fromConversionDropdown?: boolean) {
        if (!fromConversionDropdown){
            BlockUI.Block({Target: productGroup.Container});
        }

        this._store.GetGroupProducts({
            ProductId: this.params.ProductId,
            ProductGroupId: productGroup.Id,
            ProductGroupName: productGroup.Name,
            PageNumber: productGroup.PageNumber,
            RecordsPerPage: productGroup.RecordsPerPage,
            Level: this.RolesImplemented ? this.ActiveConfigurationRole().Level : null
        })
            .then(groupProducts => {
                this.FillGroup(productGroup, groupProducts);
                BlockUI.Unblock(productGroup.Container);
                if (!productGroup.Expanded()) {
                    productGroup.Expand();
                }
                if (productGroup.ExtendedView()) {
                    productGroup.ToggleView();
                }
            })
            .fail(err => {
                BlockUI.Unblock(productGroup.Container);
                new Notifier().Failed(err.message);
            });
    }

    private LoadGroupProducts(productGroup: ProductGroup, fromConversionDropdown?: boolean) {
        let blockUIContainer: HTMLElement = productGroup.ParentProduct.Container;

        if (!fromConversionDropdown) {
            BlockUI.Block({Target: this.container});
        }

        this._store.GetGroupProducts({
            ProductId: productGroup.ParentProduct.Id,
            ProductGroupId: productGroup.Id,
            ProductGroupName: productGroup.Name,
            PageNumber: productGroup.PageNumber,
            RecordsPerPage: productGroup.RecordsPerPage,
            Level: this.RolesImplemented ? this.ActiveConfigurationRole().Level : null

        })
            .then(groupProducts => {

                this.FillGroup(productGroup, groupProducts);
                (fromConversionDropdown ? BlockUI.Unblock(blockUIContainer) : this.UnblockUi());
                if (!productGroup.Expanded()) {
                    productGroup.Activate();
                }
                if (productGroup.ExtendedView()) {
                    productGroup.ToggleView();
                }
            })
            .fail(err => {
                (fromConversionDropdown ? BlockUI.Unblock(blockUIContainer) : this.UnblockUi());
                new Notifier().Failed(err.message);
            });
    }

    private CacheGroupProducts(productParts: ProductPart[]) {
        if (productParts.length > 0) {
            const rootGroup = this.GetRootGroup(productParts[0]);
            const cachedProducts = this._mappings.MapToCachedProducts(productParts, rootGroup);
            this._viewModel.CachedProducts.AddRange(cachedProducts);
        }
    }

    private FillGroup(productGroup: BaseProductGroup, productsDto: ProductInfoResponse[], restored: boolean = false, readOnly?: boolean) {
        if (this._viewModel.compareLevels() && readOnly) {
            productGroup.AlternativesCount = 0;
        } else {
            productGroup.AlternativesCount = productsDto[0] ? productsDto[0].AlternativesCount : 0;
        }

        const newProductParts = this.MapProductParts(productsDto.filter(pp => productGroup.FindProductById(pp.Id) == null), productGroup, readOnly);
        productGroup.FillProducts(newProductParts, restored);
    }

    private CheckIfGroupIsHidden(productGroup: BaseProductGroup) {
        productGroup.IsHidden(!this._newConfiguration && !this._createLinks && _.contains(this.params.HiddenPCFGROUPSIfEmpty, productGroup.Id));
    }

    private CloseExpandedRootGroup() {
        const rootGroup = this._viewModel.GetRootGroup(rootGroup => rootGroup.Expanded());
        rootGroup && rootGroup.Collapse();
    }

    private MapProductParts(productsDto: ProductInfoResponse[], productGroup: BaseProductGroup, readOnly?: boolean) {
        const activeGroupProduct = productGroup ? productGroup.GetActiveProduct() : null;
        return this._mappings.MapToProductParts(productsDto, productGroup,
            (_, productPart) => {
                productPart.TileImageSize = this.params.ImageSizes.Tiles;
                productPart.SelectedImageSize = this.params.ImageSizes.SelectedComponent;

                if (!activeGroupProduct || activeGroupProduct.Id != productPart.Id) {

                    productPart.AssignEventBus(this.EventBus);
                    productPart.AssignVariableStore(this._variableStore);
                    productPart.AfterInit();

                    const rootGroup = this.GetRootGroup(productPart);

                    if (!readOnly) {
                        const isSelectedProduct = this._viewModel.SelectedProducts.IsSelectedProduct(productPart.Id, productPart.ParentGroup.Id, productPart.ParentGroup.Name, rootGroup.Id, rootGroup.Name, productPart.Path, productPart.KSeq, productPart.KSeqGuid);
                        if (isSelectedProduct) {
                            const restoredProduct = this._viewModel.SelectedProducts.GetRestoredProduct(productPart.Id, productPart.ParentGroup.Id, productPart.ParentGroup.Name, rootGroup.Id, rootGroup.Name, productPart.Path, productPart.KSeq, productPart.KSeqGuid);

                            if (restoredProduct) {
                                productPart.SetPropertyValues(restoredProduct.PropertyValues);
                                productPart.SetCustomFieldValues(restoredProduct.CustomFieldValues);
                                productPart.Price(restoredProduct.Price);
                            }

                            productPart.MakeSelected();
                        } else {
                            productPart.SetInitialPropertyValues();
                            productPart.SetInitialCustomFieldValues();
                        }

                        productPart.InitActionDefaultExpressions();
                        this.SetActionDependsOnValues(productPart);
                    }
                }
            },
            (_, newProductGroup) => {
                newProductGroup.AssignEventBus(this.EventBus);
                newProductGroup.AfterInit();

                this.CheckIfGroupIsHidden(newProductGroup);
            }, readOnly);
    }

    private AddToPriceList(product: ProductPart) {
        const rootGroup = this.GetRootGroup(product);
        if (rootGroup != null) {
            const productPrice = this._mappings.MapProductPartOnProductPrice(product, rootGroup);

            if (this._viewModel.compareLevels()) {
                productPrice._compare(true);
            }
            this._viewModel.AddToPriceList(productPrice, rootGroup.Id, rootGroup.Name, rootGroup.TranslatedName);

            product.ParentGroup.Expand();
        }
    }

    private UpdatePriceInPriceList(product: ProductPart) {
        const rootGroup = this.GetRootGroup(product);

        if (rootGroup != null) {
            const productPrice = this._mappings.MapProductPartOnProductPrice(product, rootGroup);
            productPrice._priceMiscalculated(product._priceMiscalculated());
            this._viewModel.UpdatePriceInPriceList(productPrice, rootGroup.Id, rootGroup.Name);
        }
    }

    private UpdateMainProductPrice(price: number) {
        this._viewModel.UpdateMainProductPrice(price);
    }

    private RemoveFromPriceList(product: ProductPart) {
        const rootGroup = this.GetRootGroup(product);

        if (rootGroup != null) {
            this._viewModel.RemoveFromPriceList(rootGroup.Id, rootGroup.Name, product.Name, product.KSeq, product.KSeqGuid);
            product.UnSelect();
            this.CollapseGroup(product);
        }
    }

    private AddToSelected(product: ProductPart) {
        const rootGroup = this.GetRootGroup(product);
        if (rootGroup != null) {
            this._viewModel.SelectedProducts.Add(product.Id, product.ParentGroup.Id, product.ParentGroup.Name, rootGroup.Id, rootGroup.Name, product.Path, product.KSeq, product.KSeqGuid, product.Quantity(), product.Price(), product.GetPropertyValues(), product.GetCustomFieldValues());
        }
    }

    private RemoveFromSelected(product: ProductPart) {
        const rootGroup = this.GetRootGroup(product);
        this._viewModel.SelectedProducts.Remove(product.Id, product.ParentGroup.Id, product.ParentGroup.Name, rootGroup.Id, rootGroup.Name, product.Path, product.KSeq, product.KSeqGuid);
    }

    private AddAsExtra(product: ProductPart) {
        const extraGroup = this._viewModel.ExtraGroup();
        const productIds = [product.Id];
        
        extraGroup.AddFoundProductRecords({Ids: productIds});
    }

    private HandleInex(product: ProductPart) {

        this._loadedInclusiveProducts = [];
        this._exclusiveProducts = [];

        const productGroup = product.ParentGroup;

        let selectedProductPartsIds = [product.Id];

        selectedProductPartsIds = _.union(selectedProductPartsIds,
            this._viewModel.SelectedProducts.Selected.filter(selected =>
                selected.Path.length == 0 &&
                (selected.GroupId != product.ParentGroup.Id || selected.GroupName != product.ParentGroup.Name) &&
                product.ExclusiveProducts.indexOf(selected.Id) == -1).map(function (s) {
                return s.Id;
            }));

        const inclusives = product.InclusiveProducts.filter(inclusive => selectedProductPartsIds.indexOf(inclusive) == -1);
        this._exclusiveProducts = this.FindLoadedExclusiveProducts(product.Id, product.ExclusiveProducts);

        let alreadyDownloadedAlternatives = [];

        product.InclusiveProducts.forEach(inclusive => {
            const product = this._viewModel.FindProductById(inclusive);
            if (product != null) {
                product.ParentGroup.Products().forEach(p => {
                    if (alreadyDownloadedAlternatives.indexOf(p.Id) == -1) {
                        alreadyDownloadedAlternatives.push(p.Id);
                    }
                })
            }
        })

        selectedProductPartsIds = _.union(selectedProductPartsIds, inclusives)

        if (inclusives.length > 0) {

            BlockUI.Block({Target: productGroup.Container});

            this._store.GetInclusiveProducts({
                SelectedProductPartsIds: selectedProductPartsIds,
                InclusiveProductPartsIds: inclusives,
                ExclusiveProductPartsIds: product.ExclusiveProducts,
                DefaultPartsIds: product.Defaults,
                AlreadyDownloadedAlternatives: alreadyDownloadedAlternatives,
                Level: this.RolesImplemented ? this.ActiveConfigurationRole().Level : null,
                GroupId: product.ParentGroup.Id,
                GroupName: product.ParentGroup.Name
            })
                .then(inclusiveProducts => {

                    BlockUI.Unblock(productGroup.Container);

                    this.ShowInexConfirmationPopup(product, inclusiveProducts);
                })
                .fail(err => {
                    BlockUI.Unblock(productGroup.Container);
                    new Notifier().Failed(err.message);
                });
        } else {
            this.ShowInexConfirmationPopup(product, []);
        }
    }

    private ShowNewConfigurationInitialInexConfirmationPopup(productId: number, inclusiveProducts: ProductInfoResponse[]) {

        let inexItems = [];

        const product = this._viewModel.Product().MainProductPart;

        const inexMainProduct = new InexProduct(product.Id, 0, product.Image, product.Name, product.NameTranslated, product.Price(), product.Quantity());
        inexMainProduct.ImageSize = this.params.ImageSizes.InEx;

        inexItems.unshift(new InexItem(product.Id,
            inexMainProduct,
            [],
            product.ParentGroup.Id,
            product.ParentGroup.Name,
            product.Path,
            true,
            0,
            []));

        if (inclusiveProducts != null) {
            inclusiveProducts.forEach(inclusive => {
                const inexProduct = new InexProduct(inclusive.Id, inclusive.ParentId, inclusive.Image, inclusive.Name, inclusive.NameTranslated, inclusive.Price, inclusive.Quantity);
                inexProduct.ImageSize = this.params.ImageSizes.InEx;

                const exclusiveProducts = this.FindExclusiveProductsInResponse(inclusive.Id, inclusive.PcfGroup.Id, inclusive.PcfGroup.Name, inclusive.Path, inclusive.ParentId, inclusive.ExclusiveProducts, inclusiveProducts).map(exclusive => {
                    const exclusiveProduct = new InexProduct(exclusive.Id, exclusive.ParentId, exclusive.Image, exclusive.Name, exclusive.NameTranslated, exclusive.Price, exclusive.Quantity);
                    exclusiveProduct.ImageSize = this.params.ImageSizes.InEx;
                    return exclusiveProduct;
                });
                const inexItem = new InexItem(inclusive.Id,
                    inexProduct,
                    exclusiveProducts,
                    inclusive.PcfGroup.Id,
                    inclusive.PcfGroup.Name,
                    inclusive.Path,
                    false,
                    inclusive.ParentId,
                    inclusive.InexParentIds);

                const inexParents = inclusiveProducts.filter(i => inclusive.InexParentIds.indexOf(i.Id) != -1);

                if (inexParents.find(ip => ip.Defaults.indexOf(inclusive.Id) != -1) != null ||
                    product.Defaults.indexOf(inclusive.Id) != -1) {
                    inexItem.Default(true);
                }

                inexItems.push(inexItem);
            });

            inexItems.sort(function (a, b) {
                return a.Path.length - b.Path.length || a.GroupName > b.GroupName ? 1 : -1;
            });

            const confirmationDialog = new InexConfirmationPopup({
                Text: `The following checked inclusive products will be selected and exclusive products will be deselected. Proceed?`,
                Type: ConfirmationTypes.Question,
                Inclusives: inexItems,
                ProductId: productId,
                ProductImageViewer: this.productImageViewer,
                SelectedProducts: [],
                Width: '90vw',
                Height: '90vh',
                ModalClass: 'inExConfirmationPopupContainer',
                ImageSize: this.params.ImageSizes.InEx,
                AlternativePriceModel: this.params.AlternativePriceModel
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, (eventArgs: any) => {

                const checkedItems = _.values(eventArgs.data);

                if (inclusiveProducts != null) {
                    inclusiveProducts.forEach(inclusiveProduct => {
                        const checkedItem = checkedItems.find(checked => checked.Id == inclusiveProduct.Id && checked.DependsOn == inclusiveProduct.ParentId);
                        if (checkedItem) {
                            const productGroup = this._viewModel.FindProductGroup(inclusiveProduct.PcfGroup.Id, inclusiveProduct.PcfGroup.Name, inclusiveProduct.ParentId)

                            this.FillGroup(productGroup, [inclusiveProduct], true);
                            this._loadedInclusiveProducts.push(productGroup.Products()[productGroup.Products().length - 1]);

                        }
                    });

                    this._loadedInclusiveProducts = this.GetSortedInclusiveProducts(this._loadedInclusiveProducts);
                    this._loadedInclusiveProducts.filter(inclusive => {
                        this.DispatchEvent<SelectProductPartEventArgs>(ConfigurationPageEvents.SelectProduct,
                            new SelectProductPartEventArgs(inclusive.Id, inclusive.ParentGroup.Id, inclusive.ParentGroup.Name, inclusive.Path, inclusive.KSeq, inclusive.KSeqGuid));
                    });

                    this._viewModel.MainGroup().Expand();
                }
            });

            confirmationDialog.Show();
        }

    }

    private ShowInexConfirmationPopup(product: ProductPart, inclusiveProducts: ProductInfoResponse[]) {
        let inexItems = [];

        const exchangeProduct = this._viewModel.SelectedProducts.Selected.find(s => s.Path.join() == product.Path.join());

        if (inclusiveProducts != null) {
            const currentSelectedProducts = this._viewModel.SelectedProducts.Selected;
            inclusiveProducts = inclusiveProducts.filter(inclusive =>

                !currentSelectedProducts.find(selected =>
                    (selected.Path ? selected.Path.slice(-1)[0] : this.params.ProductId) == inclusive.ParentId &&
                    selected.Id == inclusive.Id &&
                    selected.GroupId == inclusive.PcfGroup.Id &&
                    selected.GroupName == inclusive.PcfGroup.Name)
            );

            inclusiveProducts.forEach(inclusive => {
                const inclusiveItem = new InexProduct(inclusive.Id, inclusive.ParentId, inclusive.Image, inclusive.Name, inclusive.NameTranslated, inclusive.Price, inclusive.Quantity);

                inclusiveItem.ImageSize = this.params.ImageSizes.InEx;

                const loadedExclusiveItems = this.FindLoadedExclusiveProducts(inclusive.Id, inclusive.ExclusiveProducts).map(exclusive => {

                    const exclusiveProduct = new InexProduct(exclusive.Id, exclusive.ParentId, exclusive.Image, exclusive.Name, exclusive.NameTranslated, exclusive.Price(), exclusive.Quantity());
                    exclusiveProduct.ImageSize = this.params.ImageSizes.InEx;

                    if (exchangeProduct != null && exchangeProduct.Path.every(pathItem => exclusive.Path.indexOf(pathItem) != -1)) {
                        exclusiveProduct.Exchange(true);
                        exclusiveProduct.Hidden(false);
                    }

                    return exclusiveProduct;
                });

                const exclusiveItemsFromResponse = this.FindExclusiveProductsInResponse(inclusive.Id, inclusive.PcfGroup.Id, inclusive.PcfGroup.Name, inclusive.Path, inclusive.ParentId, inclusive.ExclusiveProducts, inclusiveProducts).map(exclusive => {
                    const exclusiveProduct = new InexProduct(exclusive.Id, exclusive.ParentId, exclusive.Image, exclusive.Name, exclusive.NameTranslated, exclusive.Price, exclusive.Quantity);

                    if (exchangeProduct != null && exchangeProduct.Path.every(pathItem => exclusive.Path.indexOf(pathItem) != -1)) {
                        exclusiveProduct.Exchange(true);
                        exclusiveProduct.Hidden(false);
                    }

                    exclusiveProduct.ImageSize = this.params.ImageSizes.InEx;
                    return exclusiveProduct;
                });

                const currentExclusives = _.union(loadedExclusiveItems, exclusiveItemsFromResponse);

                const inexItem = new InexItem(inclusive.Id,
                    inclusiveItem,
                    currentExclusives,
                    inclusive.PcfGroup.Id,
                    inclusive.PcfGroup.Name,
                    inclusive.Path,
                    false,
                    inclusive.ParentId,
                    inclusive.InexParentIds);

                const inexParents = inclusiveProducts.filter(i => inclusive.InexParentIds.indexOf(i.Id) != -1);

                if (inexParents.find(ip => ip.Defaults.indexOf(inclusive.Id) != -1) != null ||
                    product.Defaults.indexOf(inclusive.Id) != -1) {
                    inexItem.Default(true);
                }

                inexItems.push(inexItem);
            });
        }

        inexItems.sort(function (a, b) {
            return (a.Path.length - b.Path.length);
        });

        const productExclusives = _.union(this.FindLoadedExclusiveProducts(product.Id, product.ExclusiveProducts));

        const inclusiveProduct = new InexProduct(product.Id, product.ParentGroup.ParentProduct.Id, product.Image, product.Name, product.NameTranslated, product.Price(), product.Quantity());
        inclusiveProduct.ImageSize = this.params.ImageSizes.InEx;

        const exclusiveProducts = productExclusives.map(exclusive => {

            const exclusiveProduct = new InexProduct(exclusive.Id, exclusive.ParentGroup.ParentProduct.Id, exclusive.Image, exclusive.Name, exclusive.NameTranslated, exclusive.Price(), exclusive.Quantity());
            exclusiveProduct.ImageSize = this.params.ImageSizes.InEx;

            if (exchangeProduct != null && exchangeProduct.Path.every(pathItem => exclusive.Path.indexOf(pathItem) != -1)) {
                exclusiveProduct.Exchange(true);
                exclusiveProduct.Hidden(false);
            }

            return exclusiveProduct;
        });
        inexItems.unshift(new InexItem(
            product.Id,
            inclusiveProduct,
            exclusiveProducts,
            product.ParentGroup.Id,
            product.ParentGroup.Name,
            product.Path,
            true,
            product.ParentGroup.ParentProduct.Id,
            []));

        if (inexItems.length == 1 && productExclusives.length == 0) {
            this.DispatchEvent<SelectProductPartEventArgs>(ConfigurationPageEvents.SelectProduct,
                new SelectProductPartEventArgs(product.Id, product.ParentGroup.Id, product.ParentGroup.Name, product.Path, product.KSeq, product.KSeqGuid));

            this.ResetConversionDropdownByProduct(product);
        } else {

            let selectedProducts = [];
            this._viewModel.SelectedProducts.Selected.forEach(selected => {
                const incopatibleSelected = this._viewModel.FindProduct(selected.Id, selected.RootGroupId, selected.RootGroupName, selected.GroupId, selected.GroupName, selected.Path, selected.KSeq, selected.KSeqGuid);
                selectedProducts.push(incopatibleSelected);

                inexItems.filter(ii => selected.GroupId == ii.GroupId && selected.GroupName == ii.GroupName && selected.Path == ii.Path).forEach(ii => {
                    const exclusiveProduct = new InexProduct(incopatibleSelected.Id,
                        incopatibleSelected.ParentGroup.ParentProduct.Id,
                        incopatibleSelected.Image,
                        incopatibleSelected.Name,
                        incopatibleSelected.NameTranslated,
                        incopatibleSelected.Price(),
                        incopatibleSelected.Quantity());
                    exclusiveProduct.ImageSize = this.params.ImageSizes.InEx;

                    if (exchangeProduct != null && exchangeProduct.Path.every(pathItem => incopatibleSelected.Path.indexOf(pathItem) != -1)) {
                        exclusiveProduct.Exchange(true);
                        exclusiveProduct.Hidden(false);
                    }

                    ii.InexExclusiveItems.push(exclusiveProduct);
                });
            });

            const confirmationDialog = new InexConfirmationPopup({
                Text: `The following checked inclusive products will be selected and exclusive products will be deselected. Proceed?`,
                Type: ConfirmationTypes.Question,
                ProductId: this.params.ProductId,
                ProductImageViewer: this.productImageViewer,
                Inclusives: inexItems,
                SelectedProducts: selectedProducts,
                Width: '90vw',
                Height: '90vh',
                ModalClass: 'inExConfirmationPopupContainer',
                ImageSize: this.params.ImageSizes.InEx,
                AlternativePriceModel: this.params.AlternativePriceModel
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, (eventArgs: any) => {

                this.DispatchEvent<SelectProductPartEventArgs>(ConfigurationPageEvents.SelectProduct,
                    new SelectProductPartEventArgs(product.Id, product.ParentGroup.Id, product.ParentGroup.Name, product.Path, product.KSeq, product.KSeqGuid));

                this.ResetConversionDropdownByProduct(product);

                const checkedItems = _.values(eventArgs.data).filter(checked => !checked.IsMain);

                if (checkedItems != null) {
                    checkedItems.forEach(checkedItem => {
                        const inclusiveProduct = inclusiveProducts.find(inclusive => inclusive.Id == checkedItem.Id && inclusive.ParentId == checkedItem.DependsOn && inclusive.Path == checkedItem.Path);
                        if (inclusiveProduct) {
                            const productGroup = this._viewModel.FindProductGroup(inclusiveProduct.PcfGroup.Id, inclusiveProduct.PcfGroup.Name, inclusiveProduct.ParentId);
                            if (productGroup) {
                                this.FillGroup(productGroup, [inclusiveProduct], true);
                                this._loadedInclusiveProducts.push(productGroup.Products().find(p => p.Id == inclusiveProduct.Id));
                            }
                        }
                    });
                }

                this._loadedInclusiveProducts = this.GetSortedInclusiveProducts(this._loadedInclusiveProducts);
                this._loadedInclusiveProducts.filter(inclusive => {
                    const checkedItem = checkedItems.find(checked => checked.Id == inclusive.Id && checked.DependsOn == inclusive.ParentGroup.ParentProduct.Id);
                    if (checkedItem) {

                        const currentExclusiveProducts = this.FindLoadedExclusiveProducts(inclusive.Id, checkedItem.InexExclusiveItems().map(exclusive => exclusive.Id)).filter(exclusive =>
                            this._viewModel.SelectedProducts.Selected.find(selected => selected.GroupId == exclusive.ParentGroup.Id && selected.GroupName == exclusive.ParentGroup.Name && selected.Path == exclusive.Path));

                        this._exclusiveProducts = _.union(this._exclusiveProducts, currentExclusiveProducts);


                        this.DispatchEvent<SelectProductPartEventArgs>(ConfigurationPageEvents.SelectProduct,
                            new SelectProductPartEventArgs(inclusive.Id, inclusive.ParentGroup.Id, inclusive.ParentGroup.Name, inclusive.Path, inclusive.KSeq, inclusive.KSeqGuid));

                        this.ResetConversionDropdownByProduct(inclusive);
                    }
                });

                this._exclusiveProducts.forEach(exclusive => {
                    this.DispatchEvent<SelectProductPartEventArgs>(ConfigurationPageEvents.UnselectProduct,
                        new SelectProductPartEventArgs(exclusive.Id, exclusive.ParentGroup.Id, exclusive.ParentGroup.Name, exclusive.Path, exclusive.KSeq, exclusive.KSeqGuid));

                    if (exclusive.ParentGroup instanceof RootGroup || exclusive.ParentGroup instanceof ProductGroup) {
                        this.ReloadingConversionDropdowns(exclusive.ParentGroup);
                    }
                });

            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, (eventArgs: any) => {
                product.ParentGroup.Products(product.ParentGroup.Products().sort(function (a, b) {
                    return (a.Id - b.Id);
                }));

                this.ToggleViewOrResetConversionDropdown(product.ParentGroup);

            });

            confirmationDialog.Show();
        }
    }

    private FindInclusiveProducts(product: ProductPart) {
        let inclusives = [];

        product.InclusiveProducts.filter(id => this._loadedInclusiveProducts.map(p => p.Id).indexOf(id) == -1).forEach(id => {
            const loadedProduct = this._viewModel.FindProductById(id);
            const selected = this._viewModel.SelectedProducts.Selected.find(s => s.Id == id);

            if (loadedProduct) {
                if (selected) {
                    if (!loadedProduct.ParentGroup.IsRoot) {
                        const rootGroup = this.GetRootGroup(loadedProduct);
                        loadedProduct.ParentGroup.ParentProduct.ParentGroup.Products().forEach(p => {
                            const nestedProduct = p.FindNestedProduct(loadedProduct.Id, rootGroup.Id, rootGroup.Name, loadedProduct.ParentGroup.Id, loadedProduct.ParentGroup.Name, p.Path.concat([p.Id]), loadedProduct.KSeq, loadedProduct.KSeqGuid);
                            if (nestedProduct == null) {
                                if (inclusives.indexOf(id) == -1) {
                                    inclusives.push(id);
                                }
                            } else if (nestedProduct.Path != selected.Path) {
                                this._loadedInclusiveProducts.push(nestedProduct);
                                inclusives.push(nestedProduct.Id);
                                if (product.InclusiveProducts.length > 0) {
                                    const currentInclusives = this.FindInclusiveProducts(loadedProduct);

                                    currentInclusives.forEach(id => {
                                        if (inclusives.indexOf(id) == -1) {
                                            inclusives.push(id)
                                        }
                                    });
                                }
                            }
                        });
                    }
                } else {

                    this._loadedInclusiveProducts.push(loadedProduct);
                    inclusives.push(id);

                    if (product.InclusiveProducts.length > 0) {
                        const currentInclusives = this.FindInclusiveProducts(loadedProduct);

                        currentInclusives.forEach(id => {
                            if (inclusives.indexOf(id) == -1) {
                                inclusives.push(id)
                            }
                        });
                    }
                }
            } else {
                inclusives.push(id);
            }
        });

        return inclusives;
    }

    private GetSortedInclusiveProducts(inclusiveProducts: ProductPart[]){
        return _.sortBy(inclusiveProducts, inclusive => {
            const rootGroup = this.GetRootGroup(inclusive);
            return rootGroup && (rootGroup.TranslatedName || rootGroup.Name);
        });
    }

    private FindExclusiveProductsInResponse(Id: number, GroupId: number, GroupName: string, Path: number[], ParentId: number, exclusives: number[], inclusiveProducts: ProductInfoResponse[]) {
        return inclusiveProducts.filter(p => {
            const sameGroupItem = p.Id != Id && p.PcfGroup.Id == GroupId && p.PcfGroup.Name == GroupName && p.Path == Path && p.ParentId == ParentId;
            return exclusives.indexOf(p.Id) != -1 || p.ExclusiveProducts.indexOf(Id) != -1 || sameGroupItem;
        });
    }

    private FindLoadedExclusiveProducts(id: number, exclusives: number[]) {

        const productIds = _.union(exclusives, this._viewModel.FindSelectedProductsIncopatibleWith(id));
        let products = [];

        if (productIds.length > 0) {
            productIds.forEach(id => {
                const selected = this._viewModel.SelectedProducts.Selected.find(s => s.Id == id);
                if (selected) {
                    products.push(this._viewModel.FindProduct(selected.Id, selected.RootGroupId, selected.RootGroupName, selected.GroupId, selected.GroupName, selected.Path, selected.KSeq, selected.KSeqGuid));
                } else {
                    const inclusive = this._loadedInclusiveProducts.find(i => i.Id == id);
                    if (inclusive) {
                        products.push(inclusive);
                    }
                }
            });
        }
        return products;
    }

    private CalculateFactualPrice(product: ProductPart) {
        let selected = null;
        this.BlockSaving();

        const propertyValuesDto = this._mappings.MapToPropertyValuesDto(product.GetPropertyValuesForPrice());
        const customFieldValuesDto = this._mappings.MapToCustomFieldValuesDto(product.GetCustomFieldValuesForPrice())

        this._calculatingPriceSequences++;

        this._store.GetFactualPrice(product.IsMain() ? 0 : product.Id, product.Quantity(), propertyValuesDto, customFieldValuesDto)
            .then(factualPrice => {
                if (factualPrice != null) {
                    product._priceMiscalculated(false);
                    product.Price(factualPrice);
                    if (product.IsMain()) {
                        this.UpdateMainProductPrice(factualPrice);
                    } else {
                        this.UpdatePriceInPriceList(product);
                    }
                } else {
                    product.Price(0);
                    product._priceMiscalculated(true);
                    if (product.IsMain()) {
                        this.UpdateMainProductPrice(null);
                    } else {
                        this.UpdatePriceInPriceList(product);
                    }
                }
            })
            .fail(err => {
                new Notifier().Failed(err.message)
            })
            .always(err => {
                this._calculatingPriceSequences--;
                if (selected != null) {
                    selected.Price = product.Price();
                }
                this.UnBlockSaving();
            });
    }

    private SetActionDependsOnValues(product: ProductPart) {
        const actionDependsOnDto = product.GetActionDependsOnDto();
        if (!actionDependsOnDto) {
            return;
        }

        this._store.GetActionDependsOnValues(actionDependsOnDto)
            .then(actionDependsOnValues => {
                product.InitActionDependsOnExpressions(actionDependsOnValues)
            })
            .fail(err => {
                new Notifier().Failed(err.message)
            });
    }

    private AlterSelectedPart(product: ProductPart) {
        const priceListSelectedLevel = this._viewModel.Product().PriceList().ActiveLevel;
        const isPartFromSelectedLevel = !priceListSelectedLevel || priceListSelectedLevel === product.Level;

        if (!isPartFromSelectedLevel)
        {
            return;
        }

        const rootGroup = this.GetRootGroup(product);

        const selected = this._viewModel.SelectedProducts.GetSelectedProduct(product.Id, product.ParentGroup.Id, product.ParentGroup.Name, rootGroup.Id, rootGroup.Name, product.Path, product.KSeq, product.KSeqGuid);
        selected.Price = +product.Price();
        selected.Quantity = +product.Quantity();
        selected.PropertyValues = product.GetPropertyValues();
        selected.CustomFieldValues = product.GetCustomFieldValues();
    }

    private GetRootGroup(product: ProductPart) {
        if (product.ParentGroup.IsExtra || product.ParentGroup.IsUndefined) {
            return product.ParentGroup;
        }

        return _.find(this._viewModel.RootGroups(), rootGroup => product.RootGroupIs(rootGroup));
    }

    private GetUndefinedGroupProducts(product: ProductInfoResponse, parts: ProductPartInfoResponse[]) {
        let undefinedGroupProducts = [];

        const rootGroupsProducts = parts.filter(product => _.isEmpty(product.Path) && (product.PcfGroup.Id || product.PcfGroup.Name));

        rootGroupsProducts.forEach(rootProduct => {
            const groupExists = _.any(product.PcfPartsGroups, group => rootProduct.PcfGroup.Id === group.Id && rootProduct.PcfGroup.Name === group.Name);
            if (!groupExists) {
                undefinedGroupProducts.push(rootProduct);
            }
        });

        const nestedProducts = parts.filter(product => !_.isEmpty(product.Path))
            .sort((p1, p2) => p1.Path.length > p2.Path.length ? 1 : p1.Path.length < p2.Path.length ? -1 : 0);

        nestedProducts.forEach(productPart => {
            const parentProductPath = productPart.Path.slice(0, productPart.Path.length - 1);
            const parentProductId = productPart.Path[productPart.Path.length - 1];

            const parentProduct = _.find(parts, part => part.Id === parentProductId && _.isEqual(part.Path, parentProductPath));
            if (!parentProduct || _.contains(undefinedGroupProducts, parentProduct)) {
                undefinedGroupProducts.push(productPart);
                return;
            }

            const groupExists = _.any(parentProduct.PcfPartsGroups, group => group.Id === productPart.PcfGroup.Id && group.Name === productPart.PcfGroup.Name);
            if (!groupExists) {
                undefinedGroupProducts.push(productPart);
            }
        });

        return undefinedGroupProducts;
    }

    private ChangeReadOnlyPrice(productParts: ProductPartInfoResponse[], readOnly: boolean) {
        if (readOnly) {
            productParts.forEach(part => part.Price = part.FactualPrice);
        }
    }

    private RemoveKSeqFromNonExtraProducts(parts: ProductPartInfoResponse[]) {
        const extraGroupParts = parts.filter(product => _.isEmpty(product.Path) && !product.PcfGroup.Id && !product.PcfGroup.Name);
        const nonExtraGroupParts = _.difference(parts, extraGroupParts);

        nonExtraGroupParts.forEach(part => part.KSeq = 0);
    }

    private SaveConfiguration() {

        if (!this.CheckModelValidation()){
            new Notifier().Warning(NOTIFICATIONS.PLEASE_FILL_ALL_DATA);
            return;
        }

        const orderDto = this.GetOrderDto();

        if (this.params.ConfigurationId) {
            if (this.RolesImplemented) {
                this.UpdateConfigurationByLevels(orderDto);
            } else {
                this.UpdateConfiguration(orderDto);
            }
        } else {
            this.CreateConfiguration(orderDto);
        }
    }

    private CreateConfiguration(orderDto: OrderDto) {
        BlockUI.Block({Target: this.container});

        const partsDto = this._viewModel.SelectedProducts.New.map(selectedProduct => {
            const productPart = this._viewModel.FindProduct(selectedProduct.Id, selectedProduct.RootGroupId, selectedProduct.RootGroupName, selectedProduct.GroupId, selectedProduct.GroupName, selectedProduct.Path, selectedProduct.KSeq, selectedProduct.KSeqGuid);
            const productPartDto = this._mappings.MapToProductPartDto(selectedProduct);

            productPartDto.Price = productPart.Price();
            productPartDto.Quantity = +productPart.Quantity();
            productPartDto.PropertyValues = this._mappings.MapToPropertyValuesDto(productPart.GetPropertyValues());
            productPartDto.CustomFieldValues = this._mappings.MapToCustomFieldValuesDto(productPart.GetCustomFieldValues());

            if (productPart.ParentGroup === this._viewModel.ExtraGroup()) {
                productPartDto.PcfGroup = new PcfGroup(0, null, null, []);
            } else {
                productPartDto.PcfGroup.NameTranslations = productPart.ParentGroup && productPart.ParentGroup.Translations || [];
            }

            return productPartDto;
        });

        const customFieldValues = this._mappings.MapToCustomFieldValuesDto(this._viewModel.Product().MainProductPart.GetCustomFieldValues());
        const productDto = new ProductDto(this.params.ProductId, this.params.ProductsEntityId, partsDto, customFieldValues);

        const configurationDto = new CreateConfigurationDto(orderDto, productDto);

        this._store.CreateProductConfiguration(configurationDto)
            .then(() => {
                this.DispatchEvent(ConfigurationPageEvents.ConfigurationSaved);
                this.UnblockUi();
            })
            .fail(err => {
                this.UnblockUi();
                new Notifier().Failed(err.message);
            });
    }

    private UpdateConfiguration(orderDto: OrderDto) {
        BlockUI.Block({Target: this.container});

        const alteredProductDto = this.GetAlteredProductDto();

        const configurationDto = new UpdateConfigurationDto(orderDto, alteredProductDto, this.params.ConfigurationId);
        this._store.UpdateProductConfiguration(configurationDto)
            .then(() => {
                this.DispatchEvent(ConfigurationPageEvents.ConfigurationSaved);
                this.UnblockUi();
            })
            .fail(err => {
                this.UnblockUi();
                new Notifier().Failed(err.message);
            });
    }

    CheckControlsValidation(products: ProductPart[]): IControlValidation[] {
        let controlsValidation: IControlValidation[] = [];
        _.each(products, (product: ProductPart) => {

            if (product.Selected()) {
                _.each(product.CustomFieldsGroup.CustomFieldsControls, (customFieldsControl: CustomFieldControl) => {
                    customFieldsControl.CheckValidControl();
                    let controlValidation: IControlValidation = {
                        Name: customFieldsControl.Name,
                        ValidControl: customFieldsControl.IsValidControl || customFieldsControl.SkipValidationOnSave,
                        ValidMassage: customFieldsControl.ValidMessage
                    }
                    controlsValidation.push(controlValidation);
                });
                _.each(product.PropertyGroups, (propertyGroup:ProductPropertyGroup ) => {
                    _.each(propertyGroup.PropertyControls, (propertyControl: PropertyControl) =>{
                        propertyControl.CheckValidControl();
                        let controlValidation: IControlValidation = {
                            Name: propertyControl.Name,
                            ValidControl: propertyControl.IsValidControl || propertyControl.SkipValidationOnSave,
                            ValidMassage: propertyControl.ValidMessage
                        }
                        controlsValidation.push(controlValidation);
                    })
                });

                if (product.Groups().length){
                    _.each(product.Groups(), (productGroup: ProductGroup)=> {
                        _.each(this.CheckControlsValidation(productGroup.Products()), (controlValidation: IControlValidation) => controlsValidation.push(controlValidation));
                    });
                }
            }

        })
        return controlsValidation;
    }
    private CheckModelValidation(): boolean {
        const mainGroup = this._viewModel.MainGroup(),
            rootGroups = this._viewModel.RootGroups(),
            extraGroup = this._viewModel.ExtraGroup();

        let controls = [];

        controls.push( this.CheckControlsValidation(mainGroup.Products()) );

        _.each(rootGroups, (rootGroup: RootGroup)=> {
            controls.push( this.CheckControlsValidation(rootGroup.Products()) );
        })

        controls.push( this.CheckControlsValidation(extraGroup.Products()) );

        return _.every(_.flatten(controls), (control: IControlValidation) => control.ValidControl);
    }

    private UpdateConfigurationByLevels(orderDto: OrderDto) {
        BlockUI.Block({Target: this.container});

        if (this._viewModel.compareLevels()) {
            this._viewModel.Product().PriceList().Levels().forEach(level => level.Active(false));
            this._viewModel.Product().PriceList().Levels()[0].Active(true);
        }

        orderDto.Level = this.GetActiveConfigurationRole().Level;

        const alteredProductDto = this.GetAlteredProductDto();

        const configurationLevel = new ConfigurationLevelDto(orderDto, alteredProductDto, this.GetActiveConfigurationRole().Level, this._createLinks);

        this.alterationModelBackUp.Levels.push(configurationLevel);
        this._store.UpdateProductConfigurationByLevels(this.alterationModelBackUp)
            .then(() => {
                this.DispatchEvent(ConfigurationPageEvents.ConfigurationSaved);
                this.UnblockUi();
            })
            .fail(err => {
                this.UnblockUi();
                new Notifier().Failed(err.message);
            });
    }

    private CheckNavigatability() {

        const selectedProducts = this._viewModel.SelectedProducts;

        const mainProductProperties = this._viewModel.Product().MainProductPart.GetPropertyValues().sort((a, b) => a.Id - b.Id);
        const initialMainProperties = selectedProducts.InitialMainPropertyValues.sort((a, b) => a.Id - b.Id);

        const allPropertiesContentLoaded = this._viewModel.Product().MainProductPart.PropertyGroups.every(pg => pg.AllContentLoaded());

        if (selectedProducts.Altered.length > 0 ||
            selectedProducts.New.length > 0 ||
            selectedProducts.Removed.length > 0 ||
            (allPropertiesContentLoaded && !_.isEqual(mainProductProperties, initialMainProperties))) {
            this.backButton.NavigateConfirmation = CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST;
        }
        this.DispatchEvent(NavigationStackEvents.Checked);
    }

    private CheckSaveDisability() {
        const priceListSelectedLevel = this._viewModel.Product().PriceList().ActiveLevel;
        const activeLevel = this.RolesImplemented ? this.ActiveConfigurationRole().Level : null;
        const isActiveLevelSelected = !priceListSelectedLevel || priceListSelectedLevel === activeLevel;

        if (!isActiveLevelSelected)
        {
            return;
        }

        const allSelectedProducts = this._viewModel.FindAllSelectedProducts();
        const disableSaving = _.any(allSelectedProducts, (product: ProductPart) => product && product.IsDisabledByAction());
        this._viewModel.DisableSaveByAction(disableSaving);
    }

    private BlockSaving() {
        BlockUI.Block({Target: this.totalContinueBtnContainer});
    }

    private UnBlockSaving() {
        if (this._calculatingPriceSequences == 0 && this._viewModel.Product().MainProductPart &&
            this._viewModel.Product().MainProductPart.PropertyGroups.every(pg => pg.AllContentLoaded())) {
            BlockUI.Unblock(this.totalContinueBtnContainer);
        }
    }

    AfterRender(el: HTMLElement): void {
        this.container = el;
        if (this._handlingInex) {
            BlockUI.Block({Target: this.container});
        }
        this.totalContinueBtnContainer = el[0].getElementsByClassName('save-button-container');
        if ((this._calculatingPriceSequences > 0 || !this._viewModel.Product() || !this._viewModel.Product().MainProductPart ||
            !this._viewModel.Product().MainProductPart.PropertyGroups.every(pg => pg.AllContentLoaded()))
            && !this._isActiveLevelLocked && !this._viewModel.UndefinedGroup().HasProducts) {
            this.BlockSaving();
        }
    }

    GetNewParts(): ProductPartDto[] {
        return this._viewModel.SelectedProducts.New.map(selectedProduct => {
            const productPartDto = this._mappings.MapToProductPartDto(selectedProduct);
            const productPart = this._viewModel.FindProduct(selectedProduct.Id, selectedProduct.RootGroupId, selectedProduct.RootGroupName, selectedProduct.GroupId, selectedProduct.GroupName, selectedProduct.Path, selectedProduct.KSeq, selectedProduct.KSeqGuid);

            productPartDto.Price = productPart.Price();
            productPartDto.Quantity = +productPart.Quantity();
            productPartDto.PropertyValues = this._mappings.MapToPropertyValuesDto(productPart.GetPropertyValues());
            productPartDto.CustomFieldValues = this._mappings.MapToCustomFieldValuesDto(productPart.GetCustomFieldValues());

            if (this._viewModel.ExtraGroup() && productPartDto.PcfGroup.Id === this._viewModel.ExtraGroup().Id && productPartDto.PcfGroup.Name === this._viewModel.ExtraGroup().Name) {
                productPartDto.PcfGroup = new PcfGroup(0, null, null, []);
            } else {
                productPartDto.PcfGroup.NameTranslations = productPart.ParentGroup && productPart.ParentGroup.Translations || [];
            }

            return productPartDto;
        });
    }

    GetRemovedParts(): ProductPartDto[] {
        const removedPartsDto = this._mappings.MapToProductPartsDto(this._viewModel.SelectedProducts.Removed)
            .map(removedProduct => {
                if (this._viewModel.ExtraGroup() && removedProduct.PcfGroup.Id === this._viewModel.ExtraGroup().Id && removedProduct.PcfGroup.Name === this._viewModel.ExtraGroup().Name) {
                    removedProduct.PcfGroup = new PcfGroup(0, null, null, []);
                }

                return removedProduct;
            });

        return removedPartsDto;
    }

    GetAlteredParts(): ProductPartDto[] {
        return this._viewModel.SelectedProducts.Altered.map(alteredProduct => {
            const productPartDto = this._mappings.MapToProductPartDto(alteredProduct);
            const productPart = this._viewModel.FindProduct(alteredProduct.Id, alteredProduct.RootGroupId, alteredProduct.RootGroupName, alteredProduct.GroupId, alteredProduct.GroupName, alteredProduct.Path, alteredProduct.KSeq, alteredProduct.KSeqGuid);

            if (productPart) {
                productPartDto.PropertyValues = this._mappings.MapToPropertyValuesDto(productPart.GetPropertyValues());
                productPartDto.CustomFieldValues = this._mappings.MapToCustomFieldValuesDto(productPart.GetCustomFieldValues());
                productPartDto.Price = productPart.Price();
                productPartDto.Quantity = +productPart.Quantity();

                if (this._viewModel.ExtraGroup() && productPartDto.PcfGroup.Id === this._viewModel.ExtraGroup().Id && productPartDto.PcfGroup.Name === this._viewModel.ExtraGroup().Name) {
                    productPartDto.PcfGroup = new PcfGroup(0, null, null, []);
                }
                return productPartDto;
            }

            return null;
        }).filter(productPartDto => productPartDto !== null);
    }

    GetSelectedParts(): ProductPartDto[] {
        return this._viewModel.SelectedProducts.Selected.map(selectedProduct => {
            const productPartDto = this._mappings.MapToProductPartDto(selectedProduct);
            const productPart = this._viewModel.FindProduct(selectedProduct.Id, selectedProduct.RootGroupId, selectedProduct.RootGroupName, selectedProduct.GroupId, selectedProduct.GroupName, selectedProduct.Path, selectedProduct.KSeq, selectedProduct.KSeqGuid);

            productPartDto.Price = productPart.Price();
            productPartDto.Quantity = +productPart.Quantity();
            productPartDto.PropertyValues = this._mappings.MapToPropertyValuesDto(productPart.GetPropertyValues());
            productPartDto.CustomFieldValues = this._mappings.MapToCustomFieldValuesDto(productPart.GetCustomFieldValues());

            if (this._viewModel.ExtraGroup() && productPartDto.PcfGroup.Id === this._viewModel.ExtraGroup().Id && productPartDto.PcfGroup.Name === this._viewModel.ExtraGroup().Name) {
                productPartDto.PcfGroup = new PcfGroup(0, null, null, []);
            } else {
                productPartDto.PcfGroup.NameTranslations = productPart.ParentGroup && productPart.ParentGroup.Translations || [];
            }

            return productPartDto;
        });
    }

    GetOrderDto(): OrderDto {
        const product = this._viewModel.Product().MainProductPart;

        const propertyValues = this._mappings.MapToPropertyValuesDto(product.GetPropertyValues())
            .filter(p => {
                const initialProperty = this._viewModel.SelectedProducts.InitialMainPropertyValues.find(ip => p.Id == ip.Id)
                return this._createLinks || !initialProperty || initialProperty.Value != p.Value;
            });

        const price = product.Price();
        const quantity = product.Quantity();

        return new OrderDto(this.params.OrderEntityId, this.params.OrderId, propertyValues, price, quantity);
    }

    GetAlteredProductDto(): AlteredProductDto {
        if (this.RolesImplemented) {
            let newParts = [], removedParts = [], alteredParts = [];

            if (this._createLinks) {
                newParts = this.GetSelectedParts();
                newParts.forEach(p => p.Level = this.GetActiveConfigurationRole().Level);

                const customFieldValues = this._mappings.MapToCustomFieldValuesDto(this._viewModel.Product().MainProductPart.GetCustomFieldValues());
                return new AlteredProductDto(this.params.ProductId, this.params.ProductsEntityId, newParts, removedParts, alteredParts, customFieldValues);
            } else {
                newParts = this.GetNewParts();
                removedParts = this.GetRemovedParts();
                alteredParts = this.GetAlteredParts();

                newParts.forEach(p => p.Level = this.GetActiveConfigurationRole().Level);
                removedParts.forEach(p => p.Level = this.GetActiveConfigurationRole().Level);
                alteredParts.forEach(p => p.Level = this.GetActiveConfigurationRole().Level);

                const customFieldValues = this._mappings.MapToCustomFieldValuesDto(this._viewModel.Product().MainProductPart.GetCustomFieldValues());
                return new AlteredProductDto(this.params.ProductId, this.params.ProductsEntityId, newParts, removedParts, alteredParts, customFieldValues);
            }
        }

        const newParts = this.GetNewParts();

        const removedParts = this._mappings.MapToProductPartsDto(this._viewModel.SelectedProducts.Removed);

        const alteredParts = this.GetAlteredParts();
        const customFieldValues = this._mappings.MapToCustomFieldValuesDto(this._viewModel.Product().MainProductPart.GetCustomFieldValues());

        return new AlteredProductDto(this.params.ProductId, this.params.ProductsEntityId, newParts, removedParts, alteredParts, customFieldValues);
    }

    BlockUi() {
        BlockUI.Block();
    }

    UnblockUi() {
        if (!this._handlingInex) {
            BlockUI.Unblock(this.container);
        }
    }
}