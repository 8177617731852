import {deserialize, deserializeAs} from 'libs/cerialize';

import Exception from "Core/Results/Exceptions/Base/Exception";

interface IOperationResult {
	IsSuccessful: boolean;
	ErrorMessage: string;
}

export default class OperationResult {
	@deserialize
    Id: number;

    @deserialize
    FollowUpRecordId: number;

	@deserialize
	Sequence: number;

	@deserialize
	IsSuccessfull: boolean;

	@deserialize
	ErrorMessage: string;

	@deserialize
	OperationMessage: string;

	@deserializeAs(Exception)
	Exceptions: Array<Exception>;

	@deserialize
	Warnings: string[];

	get Exception() {
		return this.ErrorMessage;
	}

	constructor(options?: IOperationResult) {
		if (options) {
			this.ErrorMessage = options.ErrorMessage;
			this.IsSuccessfull = options.IsSuccessful;
		}
	}
}	