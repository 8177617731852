import {URL} from 'Core/Common/Url';
import {IGetEntityRequestModel} from "Core/Common/Interfaces/IGetEntityRequestModel";
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GridDataModel} from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {GridCellValueModel, RecordKey} from 'Core/Controls/Grid/Models/GridDataModel/GridCellValueModel';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {
	FieldDataModel,
	GridRowDataModel,
	RecordKeyModel
} from 'Core/Controls/Grid/Models/UpdateDataModel/UpdateDataModel';
import {UpdateLifestatusModel} from 'Core/Controls/Grid/Models/UpdateDataModel/UpdateLifestatusModel';
import {OperationResultModel} from 'Core/Models/OperationResultModel';
import {ViewModes} from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import { FastFilterRequestModel } from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilterModels';
import { SortModel } from 'Core/Controls/Grid/Models/SortModel';
import {IGetFastFilterRequestModel} from 'Core/Controls/Grid/BaseGrid/FastFilter/FastFilterModels';
import { DataModes } from "Core/Enums/DataModes";
import {QueryColumnModel} from "../Models/GridDataModel/QueryExpression/QueryColumnModel";
import {GridRowModel} from "../Models/GridDataModel/GridRowModel";
import {PivotDetailModel} from "../Models/PivotDetailModel";
import {GroupDetailModel} from "../Models/GroupDetailModel";
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";
import { IDynamicFieldData } from 'Core/Common/Stores/RecordStore';
import { IConditionValueDto } from 'Core/Common/Interfaces/IConditionValueDto';

export interface IGetGridDataRequestModel {
	ControlId: number;
	TableViewId: number;
	SubjectEntityId: number;
	SubjectRecordId: number;
	SubjectTypeId: number,
	SubjectKindId: number,
	SubjectStatusId: number,
	GridSubjectEntityId?: number;
	GridSubjectRecordIds?: number[];
	ViewMode: ViewModes;
	PageNumber: number;
	RecordsPerPage: number;
	Sort: Array<SortModel>;
	SearchPhrase: string;
	FilterByOwners: boolean;
	RecordOwners: number[];
	ShowPlanned: boolean;
	SelectedTags?: any;
	ScreenData?: Array<IDynamicFieldData>;
	FastFilters?: FastFilterRequestModel[];
	ShowRetired?: boolean;
    Query?: QueryExpressionModel;
    DataMode?: DataModes;
	SubQueryGuid?: string;
	ParentRowId?: string;
	RowId?: string;
	ReferenceLookupFieldId?: number;
	ScopedTableId?: number;
	ScopedRecordId?: number;

	ConditionValues?: Array<IConditionValueDto>;
}

export interface IGetDefaultTableView {
	ControlId: number;
	ViewMode: number;
	SubjectEntityId: number;
}

export interface IUnlinkRecordRequestModel {
	MainEntityId: number;
	MainRecordId: number;
	RelatedEntityId: number;
	RelatedRecordId: number;
	KSeq: number;
	RelationshipType?: number;
}

export interface IUnlinkMultipleRecordsRequestModel {
	Rows: Array<IUnlinkRecordRequestModel>
}

export interface IExportDataRequestModel {
	Query: QueryExpressionModel;
	Destination: string;
	ViewMode: ViewModes;
	FilterByOwners: boolean;
	RecordOwners: number[];
	ShowPlanned: boolean;
	RecordId?: number;
	ShowRetired: boolean;
}

export interface IUpdateRowsRuestModel {
	Rows: Array<GridRowDataModel>
}

export interface IUpdateSortRequestModel {
	UpCell: FieldDataModel,
	DownCell: FieldDataModel
}

export interface IGetMemoValueRequestModel {
	EntityId: number;
	FieldId: number;
	RecordKeys: Array<RecordKeyModel>;
}

export interface IGetImageValueRequestModel {
	EntityId: number;
	FieldId: number;
	RecordKeys: Array<RecordKeyModel>;
}

export interface IGetDataByClusteredFieldRequestModel {
	SubjectEntityId: number;
	FieldId: number;
	RecordKeys: Array<Array<RecordKeyModel>>;
	DisplayFields: Array<number>;
}

export interface INewRecordRequestModel {
	EntityId: number;
	LinkTableId?: number;
	TypeId: number;
	ParentRecordId: number;
	Fields: Array<QueryColumnModel>;
}

export interface IGetCrossTableDetailRequestModel {
	SubjectEntityId: number;
	SubjectRecordId: number;
	RelatedEntityId: number;
	RelatedRecordId: number;
	KSeqs: Array<number>;
	DisplayFields: Array<number>;
}

interface IGetGroupDetailRequestModel {
	EntityId: number;
	RecordIds: Array<number>;
}

export interface IsRecordValidDto {
	TableId: number;
	RecordId: number;
}

interface ISearchByBarcodeRequestModel {
	EntityId: number;
	Barcode: string;
}

export class GridStore {

	static  NewRecord(params: INewRecordRequestModel): P.Promise<GridRowModel>{
		let deferredResult = P.defer<GridRowModel>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.NEW_RECORD,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({message: data.ErrorMessage});
				} else {
					deferredResult.resolve(<GridRowModel>Deserialize(data.ResultObject, GridRowModel));
				}
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static GetData(params: IGetGridDataRequestModel): P.Promise<GridDataModel> {
		var deferredResult = P.defer<GridDataModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_GRID_DATA,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					const message = "<span class=\"notification-errors-list\">" + data.ErrorMessage + "</span>";

					deferredResult.reject({ message: message});
				} else {
					deferredResult.resolve(GenericDeserialize(data.ResultObject, GridDataModel));
				}
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static GetDataByClusteredField(params: IGetDataByClusteredFieldRequestModel): P.Promise<PivotDetailModel> {
		let deferredResult = P.defer<PivotDetailModel>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/GridApi/GetDataByClusteredField',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({message: data.ErrorMessage});
				} else {
					deferredResult.resolve(GenericDeserialize(data.ResultObject, PivotDetailModel));
				}
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static GetCrossTableDetail(params: IGetCrossTableDetailRequestModel): P.Promise<PivotDetailModel> {
		let deferredResult = P.defer<PivotDetailModel>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/GridApi/GetCrossTableDetail',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({message: data.ErrorMessage});
				} else {
					deferredResult.resolve(GenericDeserialize(data.ResultObject, PivotDetailModel));
				}
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static GetGroupDetail(params: IGetGroupDetailRequestModel): P.Promise<GroupDetailModel> {
		const deferredResult = P.defer<GroupDetailModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/GridApi/GetGroupDetail',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({message: data.ErrorMessage});
				} else {
					deferredResult.resolve(GenericDeserialize(data.ResultObject, GroupDetailModel));
				}
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static UpdateRow(params: GridRowDataModel): P.Promise<OperationResultModel> {
		var deferredResult = P.defer<OperationResultModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.UPDATE_GRID_ROW_DATA,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<OperationResultModel>Deserialize(data, OperationResultModel));
			});

		return deferredResult.promise();
	}

	static UpdateRows(params: IUpdateRowsRuestModel): P.Promise<OperationResultModel> {
		var deferredResult = P.defer<OperationResultModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.UPDATE_GRID_ROWS_DATA,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<OperationResultModel>Deserialize(data, OperationResultModel));
			});

		return deferredResult.promise();
	}

	static UpdateRowLifestatus(params: UpdateLifestatusModel): P.Promise<OperationResultModel> {
		var deferredResult = P.defer<OperationResultModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.UPDATE_GRID_ROW_LIFESTATUS,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<OperationResultModel>Deserialize(data, OperationResultModel));
			});

		return deferredResult.promise();
	}

	static DeleteRow(params: GridRowDataModel): P.Promise<OperationResultModel> {
		var deferredResult = P.defer<OperationResultModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.DELETE_GRID_ROW_DATA,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<OperationResultModel>Deserialize(data, OperationResultModel));
			});

		return deferredResult.promise();
	}

	static UnlinkMultipleRecords(params: IUnlinkMultipleRecordsRequestModel): P.Promise<OperationResultModel> {
		var deferredResult = P.defer<OperationResultModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.UNLINK_GRID_ROWS,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<OperationResultModel>Deserialize(data, OperationResultModel));
			});

		return deferredResult.promise();
	}

	static UnlinkRecord(params: IUnlinkRecordRequestModel): P.Promise<OperationResultModel> {
		var deferredResult = P.defer<OperationResultModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.UNLINK_GRID_ROW,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(<OperationResultModel>Deserialize(data, OperationResultModel));
			});

		return deferredResult.promise();
	}

	static GetDefaultTableView(params: IGetDefaultTableView): P.Promise<QueryExpressionModel> {
		var deferredResult = P.defer<QueryExpressionModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_DEFAULT_TABLE_VIEW,
				dataType: 'json',
				type: 'GET'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(<QueryExpressionModel>Deserialize(data.ResultObject, QueryExpressionModel));
				}
			});

		return deferredResult.promise();
	}

	static GetDefaultTableViewMetaData(screenType: ScreenTypes): P.Promise<IGetEntityRequestModel> {
		var deferredResult = P.defer<IGetEntityRequestModel>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_DEFAULT_TABLE_VIEW_METADATA,
				dataType: 'json',
				type: 'GET'
			}, params: {screenType: screenType}
		};

		Request.Send(requestParams)
			.then(data => {
				if (!data.IsSuccessfull) {
					deferredResult.reject({message: data.ErrorMessage});
				} else {
					deferredResult.resolve(data.ResultObject);
				}
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static ExportData(params: IExportDataRequestModel) {
		const deferred = P.defer();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.EXPORT_DATA,
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then((result) => {
				if (result.IsSuccessfull) {
					deferred.resolve(result.ResultObject);
				} else {
					deferred.reject({message: result.ErrorMessage});
				}
			})
			.fail(error => deferred.reject(error));

		return deferred.promise();
	}

	static LinkWithNextRelationType(params: RecordKey[]): any {
		const deferred = P.defer();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.LINK_WITH_NEXT_RELATION,
				dataType: 'json',
				type: 'POST'
			}, params: {RecordKeys: params}
		};

		Request.Send(requestParams)
			.then((result) => {
				if (result.IsSuccessfull) {
					deferred.resolve(result.ResultObject);
				} else {
					deferred.reject({message: result.ErrorMessage});
				}
			})
			.fail(error => {
				deferred.reject(error)
			});

		return deferred.promise();
	}

	static GetFastFilterData(params: IGetFastFilterRequestModel): any {
		const deferred = P.defer();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_FAST_FILTER_DATA,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then((result) => {
				if (result.IsSuccessfull) {
					deferred.resolve(result.ResultObject);
				} else {
					deferred.reject({message: result.ErrorMessage});
				}
			})
			.fail(error => {
				deferred.reject(error)
			});

		return deferred.promise();
	}

	static GetFastFilterTimeData(params: IGetFastFilterRequestModel): any {
		const deferred = P.defer();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_FAST_FILTER_TIME_DATA,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then((result) => {
				if (result.IsSuccessfull) {
					deferred.resolve(result.ResultObject);
				} else {
					deferred.reject({ message: result.ErrorMessage });
				}
			})
			.fail(error => {
				deferred.reject(error)
			});

		return deferred.promise();
	}

	static GetFastFilterDateData(params: IGetFastFilterRequestModel): any {
		const deferred = P.defer();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_FAST_FILTER_DATE_DATA,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then((result) => {
				if (result.IsSuccessfull) {
					deferred.resolve(result.ResultObject);
				} else {
					deferred.reject({message: result.ErrorMessage});
				}
			})
			.fail(error => {
				deferred.reject(error)
			});

		return deferred.promise();
	}

	static GetFastFilterDateTimeData(params: IGetFastFilterRequestModel): any {
		const deferred = P.defer();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.GET_FAST_FILTER_DATETIME_DATA,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then((result) => {
				if (result.IsSuccessfull) {
					deferred.resolve(result.ResultObject);
				} else {
					deferred.reject({ message: result.ErrorMessage });
				}
			})
			.fail(error => {
				deferred.reject(error)
			});

		return deferred.promise();
	}

	static UpdateSort(params: IUpdateSortRequestModel): P.Promise<any>{
		const deferred = P.defer();

		const requestParams: IRequestOptions = {
			proxy: {
				url: URL.CONTROLS.UPDATE_SORT,
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then((result) => {
				if (result.IsSuccessfull) {
					deferred.resolve(result);
				} else {
					deferred.reject({ message: result.ErrorMessage });
				}
			})
			.fail(error => {
				deferred.reject(error)
			});

		return deferred.promise();
	}

	static GetMemoValue(params: IGetMemoValueRequestModel): P.Promise<GridCellValueModel>{
		const deferred = P.defer<GridCellValueModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/GridApi/GetMemoValue',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then((result) => {
				if (result.IsSuccessfull) {
					deferred.resolve(<GridCellValueModel>Deserialize(result.ResultObject, GridCellValueModel));
				} else {
					deferred.reject({ message: result.ErrorMessage });
				}
			})
			.fail(error => {
				deferred.reject(error)
			});

		return deferred.promise();
	}

	static GetImageValue(params: IGetImageValueRequestModel): P.Promise<GridCellValueModel>{
		const defered = P.defer<GridCellValueModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/GridApi/GetImageValue',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};

		Request.Send(requestParams)
			.then((result) => {
                defered.resolve(Deserialize(result, GridCellValueModel));
            }).fail(data => { defered.reject({ message: JSON.parse(data.message).Message }) });


		return defered.promise();
	}

	static IsRecordValid(params: IsRecordValidDto): P.Promise<boolean>{
		const defered = P.defer<boolean>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/GridApi/IsRecordValid',
				type: 'GET'
			},
			params: params
		};

		Request.Send(requestParams)
			.then((result) => {
                defered.resolve(Deserialize(result, Boolean));
            }).fail(data => { defered.reject({ message: JSON.parse(data.message).Message }) });


		return defered.promise();
	}

	static SearchByBarcode(params: ISearchByBarcodeRequestModel) {
		const deferred = P.defer<number>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/GridApi/SearchByBarcode',
				dataType: 'json',
				type: 'POST'
			},
			params
		};

		Request.Send(requestParams)
			.then((result: number) => {
				deferred.resolve(result);
			})
			.fail(error => {
				deferred.reject({
					message: error.status === 404 ? NOTIFICATIONS.RECORD_NOT_FOUND : JSON.parse(error.message).Message
				});
			});

		return deferred.promise();
	}
}