import * as ko from 'knockout';
import * as _ from 'underscore';

import clone from 'clone';

import {Serialize} from 'libs/cerialize';

import {BlockUI} from 'Core/Common/BlockUi';
import {P} from 'Core/Common/Promise';

import {ToolBar} from 'Core/Controls/FormDesigner/ToolBar/ToolBar';
import {IControlParam} from 'Core/Screens/IScreen';
import {BaseControl} from 'Core/Controls/BaseControl/BaseControl';
import {CONTROL_TYPES, RenderModes, CARD_SCREEN_PROPERTY_TYPE} from 'Core/Constant';
import {DesignScreen} from 'Core/Screens/DesignScreen/DesignScreen';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {getWidthValue} from 'Core/Screens/CardScreen/CardScreenUtils';
import {
    TypedScreenModel, TableType, FormTypeModel,
    FieldCollectionModel
} from 'Core/Models/Screens/ScreenModel';
import {Width} from 'Core/Screens/DesignScreen/Models/FormProperties';
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';
import {Modal} from 'Core/Common/Modal';
import {FormDesignerStore} from 'Core/Controls/FormDesigner/Stores/FormDesignerStore';
import {FormDesignerMetadataModel} from 'Core/Controls/FormDesigner/Models/FormDesignerMetadataModel';
import {Notifier} from 'Core/Common/Notifier';
import {SelectDialog, ISelectorOption} from 'Core/Components/Dialogs/SelectDialog/SelectDialog';
import {CreateNewFormRequestModel} from 'Core/Controls/FormDesigner/Models/CreateNewFormRequestModel';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {
    ConfirmationDialog,
    EVENTS as ConfirmationDialogEvents,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {ISettingsModal, SettingsModal} from 'Core/Controls/FormDesigner/SettingsModal/SettingsModal';
import {ResetFormRequestModel} from "Core/Controls/FormDesigner/Models/ResetFormRequestModel";
import {NotTypedScreenTypes} from "Core/Constant";
import {NOTIFICATIONS, LABELS, CONFIRMATIONS} from "Core/Components/Translation/Locales";

import 'pubsub';
import {JBoxDropDown} from "Core/Components/JBoxDropdown/DropDown";
import {ZIndexManager} from 'Core/Common/ZIndexManager';

import ViewTemplate from 'Core/Controls/FormDesigner/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/FormDesigner/Templates/ToolBar.html';
import FieldCollectionDropdownTemplate from 'Core/Controls/FormDesigner/Templates/FieldCollectionDropdownTemplate.html';
import TypedScreenDropDownTemplate from 'Core/Controls/FormDesigner/Templates/TypedScreenDropDownTemplate.html';
import * as $ from "jquery";

ko.templates['Core/Controls/FormDesigner/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/FormDesigner/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/FormDesigner/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/FormDesigner/Templates/FieldCollectionDropdownTemplate'] = FieldCollectionDropdownTemplate;
ko.templates['Core/Controls/FormDesigner/Templates/TypedScreenDropDownTemplate'] = TypedScreenDropDownTemplate;

export class FormDesigner extends BaseControl {
    private _metadata: FormDesignerMetadataModel;
    private _isReady: KnockoutObservable<boolean>;
    private _toolBar: KnockoutObservable<ToolBar>;
    private _recordId: number;
    private _screen: DesignScreen;
    private _hasData: KnockoutObservable<boolean>;
    private _isSaveEnable: KnockoutObservable<boolean>;
    private _isDashScreen: KnockoutObservable<boolean>;
    private _screenName: KnockoutObservable<string>;
    private _screenType: KnockoutObservable<string>;
    private _title: KnockoutComputed<string>;
    private _searchTerm: KnockoutObservable<string>;
    private _fieldCollections: KnockoutObservableArray<FieldCollectionModel>;
    private _typedScreens: KnockoutObservableArray<TypedScreenModel>;
    private _entityTypes: KnockoutObservableArray<TableType>;
    private _enableEntityTypeButtons: KnockoutObservable<boolean>;
    private _isEditable: KnockoutObservable<boolean>;
    private _currentFieldCollection: FieldCollectionModel;
    private _currentType: TableType;
    private _screenTypes: KnockoutObservableArray<FormTypeModel>;
    private _selectedType: KnockoutObservable<FormTypeModel>;
    private _disableDelete: KnockoutObservable<boolean>;
    private _previousScreenName: string;
    private _designScreen: DesignScreen;

    private _hasCustomColor: KnockoutObservable<boolean>;
    private _hasCustomWeight: KnockoutObservable<boolean>;
    private _screenWidth: KnockoutObservable<Width>;
    private _screenBgColor: KnockoutObservable<string>;
    private _fontColor: KnockoutObservable<string>;
    private _fontFamily: KnockoutObservable<string>;
    private _fontWeight: KnockoutObservable<string>;
    private _fontStyle: KnockoutObservable<string>;

    private _activeFieldCollectionName: KnockoutObservable<string>;
    private _fieldCollectionDropDown: JBoxDropDown;
    private _typedScreenDropDown: JBoxDropDown;
    private _activeTypeName: KnockoutObservable<string>;
    private _defaultDropDownOtherOptions: any;
    private _dafaultDropDownClass: string;
    private _isOpenFieldCollectionDropdown: KnockoutObservable<boolean>;
    private _isOpenTypedScreenDropDown: KnockoutObservable<boolean>;
    private _isOpenFromDatabaseDesigner: KnockoutObservable<boolean>;
    private _isEnablePathRunnerToggler: KnockoutObservable<boolean>;
    private _pathRunnerTogglerColor : KnockoutObservable<string>;

    constructor(params: IControlParam) {
        super(params);
        this._searchTerm = ko.observable('');
        this._isReady = ko.observable(true);
        this._enableEntityTypeButtons = ko.observable(true);
        this._isEditable = ko.observable(false);
        this._fieldCollections = ko.observableArray([]);
        this._typedScreens = ko.observableArray([]);
        this._entityTypes = ko.observableArray([]);
        this._disableDelete = ko.observable(true);
        this._isEnablePathRunnerToggler = ko.observable(false);

        if (this._renderMode() !== RenderModes.ToolBar) {
            this._toolBar = ko.observable(null);
        }
        this._previousScreenName = null;
        this._screen = null;
        this._screenName = ko.observable(LABELS.FORM_DESIGNER);
        this._screenType = ko.observable('');
        this._title = ko.computed(() => `${this._screenType()} ${this._screenName()}`);
        this._hasData = ko.observable(false);
        this._isSaveEnable = ko.observable(false);
        this._isDashScreen = ko.observable(true);

        this._screenTypes = ko.observableArray([]);
        this._selectedType = ko.observable(null);
        this._designScreen = null;
        this._hasCustomColor = ko.observable(null);
        this._hasCustomWeight = ko.observable(null);
        this._screenWidth = ko.observable(null);
        this._screenBgColor = ko.observable(null);
        this._fontColor = ko.observable(null);
        this._fontFamily = ko.observable(null);
        this._fontWeight = ko.observable(null);
        this._fontStyle = ko.observable(null);
        this._pathRunnerTogglerColor = ko.observable('rgb(0, 255, 0)');

        this._activeFieldCollectionName = ko.observable(null);
        this._activeTypeName = ko.observable(null);
        this._defaultDropDownOtherOptions = {
            closeOnClick: 'body',
            attach: undefined,
            height: 'auto',
            maxHeight: 300,
            isolateScroll: true,
            pointer: "right",
            maxWidth: 250,
            position: {
                x: "right",
                y: "bottom"
            }
        }
        this._dafaultDropDownClass = 'jBox-padding-10px jBox-fix-pointer-position-right-15px';
        this._isOpenFieldCollectionDropdown = ko.observable(null);
        this._isOpenTypedScreenDropDown = ko.observable(null);
        this._isOpenFromDatabaseDesigner = ko.observable(false);

        this.AddEvent('SCREEN_CREATED');
        this.Init();

        this._hasCustomColor.subscribe(newValue => {
            (this._screen as DesignScreen).HasCustomColor = newValue;
        });

        this._hasCustomWeight.subscribe(newValue => {
            (this._screen as DesignScreen).HasCustomWeight = newValue;
        });

        this._screenWidth.subscribe(width => {
            (this._screen as DesignScreen).ScreenWidth = getWidthValue(width);
        });

        this._screenBgColor.subscribe(newValue => {
            (this._screen as DesignScreen).ScreenBgColor = newValue;
        })

        this._fontColor.subscribe(newValue => {
            (this._screen as DesignScreen).Color = newValue;
        })

        this._fontFamily.subscribe(newValue => {
            (this._screen as DesignScreen).FontFamily = newValue;
        })
        this._fontWeight.subscribe(newValue => {
            (this._screen as DesignScreen).FontWeight = newValue;
        })
        this._fontStyle.subscribe(newValue => {
            (this._screen as DesignScreen).FontStyle = newValue;
        })
    }

    ApplyProperties(){}

    private Init(): void {
        if (this._renderMode() != RenderModes.Design && this._renderMode() != RenderModes.ToolBar) {
            BlockUI.Block();
            FormDesignerStore.GetFormDesignerMetaData()
                .always(() => {
                    BlockUI.Unblock();
                })
                .then((result) => {
                    if (!result.IsSuccessfull) {
                        new Notifier(this._el).Failed(result.ErrorMessage);
                        return;
                    }

                    this._metadata = result.Data;
                });
        }
    }

    get Title(): KnockoutComputed<string> {
        return this._title;
    }

    get ScreenType(): KnockoutObservable<string> {
        return this._screenType;
    }

    get EnableEntityTypeButtons(): KnockoutObservable<boolean> {
        return this._enableEntityTypeButtons;
    }

    get IsEditable(): KnockoutObservable<boolean> {
        return this._isEditable;
    }

    get ToolBar(): KnockoutObservable<ToolBar> {
        return this._toolBar;
    }

    get EnableToolBar(): boolean {
        return this._toolBar() && this._toolBar().EnableToolBar();
    }

    get Screen(): any {
        return this._screen;
    }

    get HasData() {
        return this._hasData;
    }

    get IsSaveEnable() {
        return this._isSaveEnable;
    }

    get IsDashScreen() {
        return this._isDashScreen;
    }

    get TypedScreens() {
        return this._typedScreens;
    }

    get FieldCollections() {
        return this._fieldCollections;
    }

    get EntityTypes() {
        return this._entityTypes;
    }

    get ScreenTypes() {
        return this._screenTypes;
    }

    get CurrentFieldCollection() {
        return this._currentFieldCollection;
    }

    get CurrentType() {
        return this._currentType;
    }

    get ShouldShowButtonsOrEntityTypeButtons(): KnockoutObservable<boolean> {
        return ko.observable( this.ScreenType() !== 'Portlet' && this.ScreenType() !== 'QueryScreen' ) || this.EnableEntityTypeButtons;
    }

    get ShouldShowButtons(): boolean {
        return this.ScreenType() !== 'Portlet' && this.ScreenType() !== 'QueryScreen';
    }

    SetScreen(screen: DesignScreen) {
        this._screen = screen;
    }

    SetValue(value: any) {
        this._isOpenFromDatabaseDesigner(true);
        return this.LoadScreen(value);
    }

    ApplyScreen(screen: DesignScreen) {
        this._screenName(screen.GetScreenName());
        this._screenType(`${screen.GetType()}`);
        this._toolBar(new ToolBar(screen.ControlGroups));
        this._toolBar().IsEnabled = true;
        this._toolBar().EntityToolBar.Init(screen.SubjectEntity, screen, this._isOpenFromDatabaseDesigner());
        screen.SetRenderMode(RenderModes.Design);

        this._screen = screen;
        const canvas = $(this._el).find('#designScreenCanvas');
        canvas.html('<!-- ko template: { name: $data.GetTemplateName.bind($data), afterRender: $data.AfterRender.bind($data) } --><!-- /ko -->');

        var designScreen = screen as DesignScreen;

        this._toolBar().CollapseAll();

        let selectedType = _.find(designScreen.DesignModel.ScreenTypes, type => type.IsMain);

        if (selectedType) {
            let oldTypeId = selectedType.TypeId;
            this._selectedType(selectedType);
            this.SelectedTypeInit(oldTypeId);
        } else {
            if (_.any(designScreen.DesignModel.ScreenTypes)) {
                this._selectedType(new FormTypeModel());
                this.SelectedTypeInit(0);
            }
        }

        designScreen.DesignModel.FieldCollections.unshift(new FieldCollectionModel(0, 'User'));

        this._currentFieldCollection = _.find(designScreen.DesignModel.FieldCollections, item => item.Id === designScreen.GetFieldCollectionId());
        this._currentType = _.find(designScreen.DesignModel.EntityTypes, (item) => {
            return item.Id === designScreen.GetTableTypeId();
        });

        this._fieldCollections(designScreen.DesignModel.FieldCollections);
        this._typedScreens(designScreen.DesignModel.TypedScreens);
        this._entityTypes(designScreen.DesignModel.EntityTypes);
        this._screenTypes(designScreen.DesignModel.ScreenTypes);

        this._screenWidth(this.FindElementByType(designScreen.FormProperties.Options, CARD_SCREEN_PROPERTY_TYPE.SCREEN_WIDTH)?.Value);
        this._screenBgColor(this.FindElementByType(designScreen.FormProperties.Options, CARD_SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR)?.Value);
        this._fontColor(this.FindElementByType(designScreen.FormProperties.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_COLOR)?.Value);

        this._hasCustomColor(!!this._fontColor());
        this._hasCustomWeight(!!this.FindElementByType(designScreen.FormProperties.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_WEIGHT)?.Value.Value);

        designScreen.RenderByTarget(canvas[0]);

        this._hasData(true);
        this._isSaveEnable(true);
        this._isDashScreen(!Boolean(this._currentType.Id));

        this._enableEntityTypeButtons(NotTypedScreenTypes.indexOf(ScreenTypes[designScreen.ScreenType]) < 0);

        this.CheckIfExist();

        this._designScreen = designScreen;
        this._isEnablePathRunnerToggler(this._designScreen.IsConsultScreen);
        this.InitPathRunnerTogglerColor();             
    }

    InitPathRunnerTogglerColor(){
        if(this._designScreen.IsEnablePathRunner){
            this._pathRunnerTogglerColor('rgb(0, 200, 0)');
        }else{
            this._pathRunnerTogglerColor('rgb(200, 0, 0)');
        }  
    }

    TogglePathRunner(){
        this._designScreen.TogglePathRunner();
        this.InitPathRunnerTogglerColor();
    }

    
    FindElementByType(array: ISettingsModal[], type: string): ISettingsModal | undefined {
        return _.find(array, { Type: type });
    }

    async LoadScreen(screenId: number) {
        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        screenManager.GetDesignScreen(screenId)
            .then((screen: DesignScreen) => {
                this.ApplyScreen(screen);
            })
            .fail(error => new Notifier($(this._el)).Failed(error.message));
    }

    private SelectedTypeInit(oldTypeId: number): void {
        this._selectedType.subscribe((newValue) => {
            if (newValue) {
                var currentMain = _.find(this._screenTypes(), (screenType) => {
                    return screenType.IsMain
                });
                if (currentMain) {
                    currentMain.IsMain = false;
                }

                if (oldTypeId != newValue.TypeId) {
                    oldTypeId = newValue.TypeId;
                    this._screen.HasChanges = true;
                }

                newValue.IsMain = true;
            }
        });
    }

    PickType(formId, $data) {
        $data.IsMain = true;
        this._selectedType($data);
    }

    private async Preview(): Promise<void> {
        const previewModal = new Modal();

        const factory = (await import('Core/Screens/ScreenFactory')).ScreenFactory;

        const model = clone(this._screen.DesignModel.ExistingScreenViewModel);

        if (model.ActionBar) {
            model.ActionBar.Controls = this._screen.ActionBar.Controls().map(c => c.GetModel());
        }

        if (model.BottomBar) {
            model.BottomBar.Controls = this._screen.BottomBar.Controls().map(c => c.GetModel());
        }

        for (let index = 0; index < model.SubForms.length; index++) {
            model.SubForms[index].Controls = this._screen.SubForms[index].Controls().map(c => c.GetModel());
        }

        model.Properties = JSON.stringify(Serialize(this._screen.FormProperties));

        const screen = factory.GetScreenInstance(model);

        screen.RenderByTarget(previewModal.Wrapper);

        const buttonSave =  screen.GetControl(CONTROL_TYPES.ButtonSave);
        if(buttonSave){
            BlockUI.Unblock(buttonSave.GetWrapper());
        }

        previewModal.Show();

        BlockUI.TransparentBlock(previewModal.Wrapper);
    }

    GetFieldCollectionButtonColor(fieldCollection: FieldCollectionModel) {
        let buttonStyle = " btn-default";

        if (!this.ScreenExists(fieldCollection.Id, 0)) {
            buttonStyle = " grey-mint ";
        }

        if (fieldCollection.Id === this.CurrentFieldCollection.Id) {
            buttonStyle += " active";
            this._activeFieldCollectionName(fieldCollection.Name);
        }

        return buttonStyle;
    }

    GetTypeButtonColor(entityType): string {
        let buttonStyle = " btn-default";

        if (!this.ScreenExists(this.CurrentFieldCollection.Id, entityType.Id)) {
            buttonStyle = " grey-mint ";
        }

        if (entityType.Id === this.CurrentType.Id) {
            buttonStyle += " active";
            this._activeTypeName(entityType.Name);
        }
        return buttonStyle;
    }

    GetTypeNameById(typeId) {
        const entityType = _.find(this._entityTypes(), (item) => {
            return item.Id === typeId
        });
        return entityType ? entityType.Name : "";
    }

    CheckIfExist() {
        if (this.CurrentType.Name == '-' && this._entityTypes().length != 1) {
            if (!(this._entityTypes().slice(1).some(el => this.ScreenExists(this._currentFieldCollection.Id, el.Id)))) {
                this._disableDelete(false);
            } else {
                this._disableDelete(true);
            }
        } else {
            this._disableDelete(false);
        }
    }

    SelectFieldCollection(fieldCollection: FieldCollectionModel) {
        this._disableDelete(true);

        const tableType = new TableType(0, "-");
        this.SwitchScreenType(fieldCollection, tableType);
    }

    SelectTypedScreen(tableType: TableType) {
        const isDashType: boolean = tableType.Name == '-';
        this._disableDelete(isDashType);

        this.SwitchScreenType(this.CurrentFieldCollection, tableType);
    }

    SwitchTypedScreen(fieldCollection: FieldCollectionModel, tableType: TableType) {
        const tableTypeId = tableType.Id;
        const screen = this.GetTypedScreen(fieldCollection.Id, tableType.Id);

        if (screen) {
            this.LoadScreen(screen.FormId);
            this.AllDropDownClose();
            return;
        }

        const entityId = this._screen.GetEntityId();
        const screenTypeId = this._screen.GetTypeId();

        this.CreateNewForm(entityId, screenTypeId, tableTypeId, 0, fieldCollection.Id);
        this.AllDropDownClose();
    }

    GetActiveFieldCollectionName(): string {
        const activeFieldCollection: FieldCollectionModel = _.find(this.FieldCollections(), (fieldCollection: FieldCollectionModel) => fieldCollection.Id === this.CurrentFieldCollection.Id);
        this._activeFieldCollectionName(activeFieldCollection ? activeFieldCollection.Name : '');
        return this._activeFieldCollectionName();
    }
    ShowFieldCollectionDropdown(data, event): void {
        let target: HTMLElement = event.currentTarget;
        if (!this._fieldCollectionDropDown) {
            this.InitFieldCollectionDropdown(target);
        }
        this._fieldCollectionDropDown?.Toggle();
    }
    InitFieldCollectionDropdown(el: HTMLElement): void {
        this._fieldCollectionDropDown = new JBoxDropDown({
            target: el,
            bindTarget: el,
            bindComponent: this,
            otherOptions: {
                ...this._defaultDropDownOtherOptions,
                addClass: `${this._dafaultDropDownClass} js-fieldCollection-dropdown`,
                zIndex: ZIndexManager.Instance.NextValue,
                onCloseComplete: () => {
                    this._isOpenFieldCollectionDropdown(false);
                }
            },
            onCreated: () => {
                this._fieldCollectionDropDown.SetContent({ content: FieldCollectionDropdownTemplate as any });
            },
            onOpen: () => {
                this._isOpenFieldCollectionDropdown(true);
            }
        });
    }
    FieldCollectionDropdownOpen(): void {
        this._fieldCollectionDropDown?.Open()
    }
    FieldCollectionDropdownClose(): void {
        this._fieldCollectionDropDown?.Close()
    }

    GetActiveTypeName(): string {
        const activeType: TableType = _.find(this.EntityTypes(), (type: TableType) => type.Id === this.CurrentType.Id);
        this._activeTypeName(activeType ? activeType.Name : '');
        return this._activeTypeName();
    }
    ShowTypedScreenDropDown(data, event):void {
        let target: HTMLElement = event.currentTarget;
        if (!this._typedScreenDropDown) {
            this.InitTypedScreenDropDown(target);
        }
        this._typedScreenDropDown?.Toggle();
    }
    InitTypedScreenDropDown(el: HTMLElement): void {
        this._typedScreenDropDown = new JBoxDropDown({
            target: el,
            bindTarget: el,
            bindComponent: this,
            otherOptions: {
                ...this._defaultDropDownOtherOptions,
                addClass: `${this._dafaultDropDownClass} js-typed-screen-dropdown`,
                zIndex: ZIndexManager.Instance.NextValue,
                onCloseComplete: () => {
                    this._isOpenTypedScreenDropDown(false);
                }
            },
            onCreated: () => {
                this._typedScreenDropDown.SetContent({ content: TypedScreenDropDownTemplate as any });
            },
            onOpen: () => {
                this._isOpenTypedScreenDropDown(true);
            }
        });
    }
    TypedScreenDropDownOpen(): void {
        this._typedScreenDropDown?.Open();
    }
    TypedScreenDropDownClose(): void {
        this._typedScreenDropDown?.Close();
    }

    AllDropDownClose(): void {
        this.FieldCollectionDropdownClose();
        this.TypedScreenDropDownClose();
    }

    Refresh(): void {
        this.LoadScreen(this._screen.GetScreenId())
            .then(result => new Notifier($(this._el)).Success(NOTIFICATIONS.SCREEN_REFRESHED));
    }

    Save(data?: FormDesigner, evt?: JQuery.ClickEvent<HTMLElement>): void {
        if (this._screen.HasChanges) {
            this._isSaveEnable(false);

            const target: HTMLElement = evt && evt.target.closest('.form-designer');
            BlockUI.Block(BlockUI.GetTargetObjectFromTarget(target));

            this._screen.Save()
                .always(()=> BlockUI.Unblock(target ? target : null))
                .then(() => {
                    new Notifier().Success(NOTIFICATIONS.SCREEN_UPDATED);
                    this._isSaveEnable(true);
                })
                .fail(err => {
                    new Notifier().Failed(err.message);
                    this._isSaveEnable(true)
                });
        } else {
            new Notifier().Warning(NOTIFICATIONS.NO_PENDING_CHANGES);
        }
    }

    async DeleteScreen() {

        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;

        let confirmationDialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.DELETE_SCREEN,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, eventArgs => {
            screenManager.DeleteScreen(this._screen.GetScreenId())
                .then(() => {
                    PubSub.publish('GO_TO_FORM_DESIGNER', null);
                }).fail(error => new Notifier().Failed(error.message));
        })

        confirmationDialog.Show();
    }

    async UpdateScreenName() {
        if (this._screenName() === '') {
            new Notifier().Warning(NOTIFICATIONS.PLEASE_PROVIDE_NAME);
            return;
        }

        const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        let formId = this._screen.GetScreenId();
        screenManager.UpdateScreenName(formId, this._screenName());
        this._isEditable(false);
        this._previousScreenName = null;
    }

    Reset(): void {
        if (this._isDashScreen()) {
            return;
        }

        let confirmationDialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.RESET_SCREEN,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, eventArgs => {
            let screen = this._screen;

            let resetFormModel = new ResetFormRequestModel(screen.GetScreenId(),
                screen.GetEntityId(),
                screen.GetTableTypeId(),
                screen.GetFieldCollectionId(),
                screen.GetTypeId());
            BlockUI.Block();
            FormDesignerStore.ResetForm(resetFormModel)
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(result => {
                    if (!result.IsSuccessfull) {
                        new Notifier($(this._el)).Failed(result.ErrorMessage);
                        return;
                    }

                    this.Refresh();
                });
        });

        confirmationDialog.Show();
    }

    Search(): void {
        const searchScreen = new SearchScreen({
            EntityId: this._metadata.EntityId,
            SearchTerm: this._searchTerm(),
            IsRootForFormDesigner: true
        });
        searchScreen.On('RECORD_SELECTED', this, (eventArgs) => {
            var recordId = eventArgs.data.RecordId;
            this.LoadScreen(recordId);
        });

        searchScreen.On('NEW_RECORD', this, (eventArgs) => {
            var typeScreen = new TypeScreen(this._metadata.EntityId, 0, true);
            typeScreen.On("TYPES_NOT_FOUND", this, (eventArgs) => {
                new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
            });

            typeScreen.On("TYPE_SELECTED", this, (eventArgs) => {
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;
                const exampleRecordId = eventArgs.data.ExampleRecordId;
                this.ShowEntitySelector(typeId, kindId, exampleRecordId);
            });

            this.On('SCREEN_CREATED', this, () => {
                searchScreen.Cancel();
            });

            typeScreen.Show();
        });
        searchScreen.Show();
    }

    OpenSettingsModal(isCardScreen: boolean) {
        const params: Array<ISettingsModal> = this._screen.FormProperties?.Options;
        const settingsModal = new SettingsModal(params,this._screenType());

        settingsModal.On('SUBMIT', null, (eventArgs: any) => {
            let options: Array<ISettingsModal> = eventArgs?.data.Options;
            let hasChanges: boolean = eventArgs?.data.HasChanges;

            this._screen.FormProperties.Options = options.length ? options : null;
            this._screen.HasChanges = hasChanges;

            if (isCardScreen) {
                this._screenWidth(this.FindElementByType(options, CARD_SCREEN_PROPERTY_TYPE.SCREEN_WIDTH)?.Value);
                this._screenBgColor(this.FindElementByType(options, CARD_SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR)?.Value);
                this._fontColor(this.FindElementByType(options, CARD_SCREEN_PROPERTY_TYPE.FONT_COLOR)?.Value);

                this._fontFamily(this.FindElementByType(options, CARD_SCREEN_PROPERTY_TYPE.FONT_FAMILY)?.Value.Value);
                this._fontWeight(this.FindElementByType(options, CARD_SCREEN_PROPERTY_TYPE.FONT_WEIGHT)?.Value.Value);
                this._fontStyle(this.FindElementByType(options, CARD_SCREEN_PROPERTY_TYPE.FONT_STYLE)?.Value.Value);

                (this._screen as DesignScreen).FontFamily = this._fontFamily();
                (this._screen as DesignScreen).FontWeight = this._fontWeight();
                (this._screen as DesignScreen).FontStyle = this._fontStyle();

                // (this._screen as DesignScreen).HasCustomWeight = this._fontStyle();
                this._hasCustomColor(!!this._fontColor());
                this._hasCustomWeight(!!this._fontWeight());
            } else {

            }
        });

        settingsModal.Show();
    }

    StartLayoutConfiguration() {
        this._designScreen.StartLayoutConfiguration();
    }

    AfterRender(el: Array<HTMLElement>) {
        super.AfterRender(el);
    }

    private SwitchScreenType(fieldCollection: FieldCollectionModel, tableType: TableType) {
        if (this._screen.HasChanges) {
            let confirmationDialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.SAVE_CHANGES,
                Type: ConfirmationTypes.Question
            });

            confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED,
                this,
                eventArgs => {
                    this._screen.Save()
                        .then(() => {
                            new Notifier().Success(NOTIFICATIONS.SCREEN_UPDATED);
                            this.SwitchTypedScreen(fieldCollection, tableType);
                        }).fail(err => new Notifier().Failed(err.message));
                });

            confirmationDialog.On(ConfirmationDialogEvents.DISCARD_SELECTED,
                this,
                eventArgs => {
                    this.SwitchTypedScreen(fieldCollection, tableType);
                });
            confirmationDialog.Show();
        } else {
            this.SwitchTypedScreen(fieldCollection, tableType);
        }
    }

    private ShowEntitySelector(typeId: number, kindId: number, exampleRecordId: number) {
        FormDesignerStore.GetEntities().then(data => {
            const options: Array<ISelectorOption<number>> = data.Entities.map((entity): ISelectorOption<number> => {
                return {Text: entity.EntityName, Value: entity.EntityId};
            });

            const selectEntityDialog = new SelectDialog({
                Label: 'Entity:',
                Options: options,
                SelectedOption: options[1]
            });

            selectEntityDialog.On('Save',
                this,
                (eventArgs) => {
                    const selectedOption: ISelectorOption<number> = eventArgs.data;
                    const selectedEntityId = selectedOption.Value;
                    const tableTypeId = 0;
                    this.CreateNewForm(selectedEntityId, typeId, tableTypeId, exampleRecordId, null);
                });

            selectEntityDialog.Show();
        });
    }

    private CreateNewForm(entityId: number, typeId: number, tableTypeId: number, exampleRecordId: number, fieldCollectionId: number) {
        BlockUI.Block();
        FormDesignerStore.CreateNewForm(new CreateNewFormRequestModel(entityId, typeId, tableTypeId, fieldCollectionId))
            .then(async screenModel => {
                this.Trigger('SCREEN_CREATED');
                this.LoadScreen(screenModel.FormId);
            })
            .fail((err) => new Notifier($(this._el)).Failed(err.message))
            .always(() => {
                BlockUI.Unblock();
            });
    }

    private GetTypedScreen(fieldCollectionId: number, tableTypeId: number) {
        return _.find(this._typedScreens(), (item) => {
            return item.FieldCollectionId === fieldCollectionId && item.TableTypeId === tableTypeId;
        });
    }

    private ScreenExists(fieldCollectionId: number, tableTypeId: number) {
        return _.find(this._typedScreens(), (item) => {
            return item.FieldCollectionId === fieldCollectionId && item.TableTypeId === tableTypeId
        }) !== undefined;
    }

    OnEditClick(): void {
        this._isEditable(true);
        this._previousScreenName = this._screenName();
    }

    Cancel(): void {
        this._isEditable(false);
        this._screenName(this._previousScreenName);
    }

    EasterEgg() {
        $('.remove-control').toggleClass('easterEggRotate');
    }
}