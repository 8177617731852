import * as ko from 'knockout';
import * as $ from 'jquery';
import * as _ from 'underscore';

import * as GridStack from 'gridstack';

import {BlockUI} from "Core/Common/BlockUi";
import {BaseScreen} from 'Core/Screens/BaseScreen';

import {Notifier} from "Core/Common/Notifier";
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {DataModes} from "Core/Enums/DataModes";

import {CONTROL_TYPES, RenderModes} from 'Core/Constant';
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControl} from 'Core/Controls/IControl';
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel';
import {RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel';
import {TypeScreen} from "Core/Screens/TypeScreen/TypeScreen";
import {ProfileSelectorScreen} from "Core/Screens/ProfileSelectorScreen/ProfileSelectorScreen";
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {ScreenManager} from "Core/ScreenManager/ScreenManager";
import {EditScreen} from "Core/Screens/EditScreen/EditScreen";

import { defaultGridStackOptions } from 'Core/Screens/Dashboard/DashboardUtils';

import DashboardTemplate
    from 'Core/Screens/Templates/AdditionalConsultScreen/Dashboard/DashboardTemplate.html';

ko.templates["Core/Screens/Templates/AdditionalConsultScreen/Dashboard/Dashboard"] = DashboardTemplate;

export class Dashboard extends BaseScreen {
    constructor(screenModel: ScreenModel) {
        super(screenModel, RenderModes.View);
        this.Init();

        let body = $('body');
        if (!body.hasClass('Dashboard') && this.IsDashboard){
            body.addClass('Dashboard');
        }
    }

    private Init() {
        if (!this._model.Data) {
            return;
        }

        this._dataModel = this._model.Data;

        var controlData = new ControlDataModel();
        controlData.Rights = this._model.Data.Rights;

        var controlValue: IControlValue = {
            Data: controlData,
            SubjectEntityId: this._model.EntityId,
            SubjectRecordId: -1,
            RecordSpecsModel: null
        };

        _.each(this._controls, (control: IControl) => {
            control.SetValue(controlValue);
        });

        this.On('ADD_RECORD', this, (eventArgs: any) => {
            this.CreateRecord();
        });
    }

    AfterRender(el) {
        super.AfterRender(el);

        setTimeout(() => {
            GridStack.addGrid(
                this._el.querySelector('.dashboard-container'),
                {
                    ...defaultGridStackOptions,
                    children: this.SubForms.map(subForm => ({
                        ...subForm.GetModel().Properties.DashboardBlock,
                        content: `<div class="dashboard-RuntimeContentBox" id="${subForm.GetFormGuid()}"></div>`
                    }))
                }
            );

            this.SubForms.forEach(subForm => {
                subForm.RenderDynamically(subForm.GetFormGuid(), this);
            });
        });
    }

    CreateRecord() {
        const showTypeSelector = (allowChildrenTypes: boolean) => {
            const SearchControl = this.GetTextSearchTermControl();
            const searchTerm = SearchControl ? SearchControl.GetValue() : '';

            const parentTypeId = allowChildrenTypes ? this.GetTableTypeId() : 0;
            const typeScreen = new TypeScreen(this.GetEntityId(), parentTypeId, true);

            typeScreen
                .On('TYPES_NOT_FOUND', this, (eventArgs) => {
                    new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
                })
                .On('TYPE_SELECTED', this, (eventArgs) => {
                    const typeId = eventArgs.data.TypeId;
                    const kindId = eventArgs.data.KindId;
                    const exampleRecordId = eventArgs.data.ExampleRecordId;

                    this.NewRecord(typeId, kindId, exampleRecordId, searchTerm);
                });

            typeScreen.Show();
        };

        if (this.IsInModal()) {
            showTypeSelector(false);
            return;
        }

        const profileSelectorScreen = new ProfileSelectorScreen(this.GetEntityId(), this.GetEntityName());

        profileSelectorScreen
            .On('PROFILES_NOT_FOUND', this, () => {
                new Notifier().Warning('Profiles with create possibilities not found');
            })
            .On('PROFILE_SELECTION_CANCELLED', this, () => {
                profileSelectorScreen.Close();
            })
            .On('PROFILE_SELECTED', this, () => {
                profileSelectorScreen.Close();
                showTypeSelector(false);
            })
            .On('USED_CURRENT_PROFILE', this, () => {
                profileSelectorScreen.Close();
                showTypeSelector(false);
            });

        profileSelectorScreen.ShowIfNeeded();
    }

    NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number, searchTerm?: string,
              dataMode: DataModes = DataModes.Default, subjectLifestatusId: number = null) {
        BlockUI.Block();

        ScreenManager.GetEditScreen({
            EntityId: this.GetEntityId(),
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0,
            DataMode: dataMode,
            SubjectLifestatusId: subjectLifestatusId,
            ParentRecordId: this.GetRecordId()
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                const editScreen = screen;

                editScreen.IsDataFromExample = exampleRecordId > 0;

                if (searchTerm) {
                    this.NameControlSetValue(editScreen, searchTerm);
                }

                screen
                    .On('COPY', this, (eventArgs) => {
                        if (this.IsInModal()) {
                            this.Close();
                        }

                        this.NewRecord(this._model.TableTypeId, null, eventArgs.data.recordId, null, eventArgs.data.dataMode);
                    });

                screen.ShowInModal();
            })
            .fail(error => {
                new Notifier($(this._el)).Warning(error.message);
            });
    }

    private NameControlSetValue(screen: EditScreen, value: string) {
        const control = screen.GetControlByFieldName('NAME', CONTROL_TYPES.Text);

        if (control) {
            const controlData = new ControlDataModel();

            controlData.FieldId = control.FieldModel.Id;
            controlData.Value = value;
            controlData.DisplayValue = value;

            const controlValue: IControlValue = {
                Data: controlData,
                SubjectEntityId: this._model.EntityId,
                SubjectRecordId: 0,
                RecordSpecsModel: new RecordSpecsModel()
            };

            control.SetDefaultValue(controlValue);
        }
    }

    GetTextSearchTermControl(): IControl {
        return _.first(_.filter(this.GetAllControls(), (control: IControl) => control.GetType() === CONTROL_TYPES.Search));
    }

    GetTemplateName(): string {
        return 'Core/Screens/Templates/AdditionalConsultScreen/Dashboard/Dashboard';
    }
}