import {P} from 'Core/Common/Promise';
import {Request} from 'Core/Common/Request';

import {BlockUI} from "../BlockUi";

import {GetServerRequestError} from "../Enums/ServerRequestErrors";

export interface IGetRecordParams {
	TableId?: Number;
	TableName?: String;
    RecordId: Number;
}

export interface IGetDependsOnValues {
    FieldIds: Array<number>;
    RecordId: number;
}

export interface IDependsOnValues {
    Id: number;
    Name: string;
    Value: string
}

export interface IGetDependsOnResponse {
    ErrorMessage: string;
    ErrorOccured: string;
    Exceptions: Array<string>;
    HasData: boolean;
    IsServerError: boolean;
    IsSuccessfull: boolean;
    OperationMessage: string;
    ResultObject: Array<IDependsOnValues>;
}

export interface IDynamicFieldData {
    FieldId: number;
    Value: string;
}

export interface IFieldDataModel {
    Name: string;
    Value: string;
}

export interface ICreateRecordRequest {
    TableId: number;
    RequiredValidation: boolean;
    ExampleRecordId?: number;
    Fields?: Array<IFieldDataModel>;
}

export interface IGetDynamicDependsOnValue {
    SubjectEntityId: number;
    SubjectRecordId: number;
    RelatedEntityId: number;
    RelatedRecordId: number;
    KSeq: number;
    ScreenData: Array<IDynamicFieldData>;
    FieldId: number;
}

export interface IGetLookupDataRequest {
    TableName: string;
    Value?: string;
    DisplayValue: string;
}

export interface IGetLookupDataResponse {
	Count: number;
    Data: ILookupRecord[];
    Type: string;
    Format: string;
}

export interface ILookupRecord {
    Name: string;
    Value: string;
    Type: string;
    Format: string;
}

export class RecordStore {

    static GetRecordSpecs(params: IGetRecordParams): P.Promise<any>  {
        const deferredResult = P.defer<any>();

        Request.Send({
            proxy: {
                url: "/api/RecordsApi/GetSpecs",
                type: "GET",
                dataType: "json"
            },
            params: params
        })
            .then(response => deferredResult.resolve(response))
            .fail((error) => this.RejectResponse(deferredResult, error));

        return deferredResult.promise();
    }

    static GetRecord(params: IGetRecordParams): P.Promise<any>  {
        const deferredResult = P.defer<any>();

        Request.Send({
            proxy: {
                url: "/api/RecordsApi/Get",
                type: "GET",
                dataType: "json"
            },
            params: params
        })
            .then(response => deferredResult.resolve(response))
            .fail((error) => this.RejectResponse(deferredResult, error));

        return deferredResult.promise();
    }

    static CreateRecord(params: ICreateRecordRequest): P.Promise<number> {
        const deferredResult = P.defer<number>();

        BlockUI.Block();

        Request.Send({
            proxy: {
                url: "/api/RecordsApi/CreateRecord",
                type: "POST",
                dataType: "json"
            },
            params: params
        }).then(response => {
            deferredResult.resolve(response)
        }).fail((error) => {
            this.RejectResponse(deferredResult, error);
        })
        .always(() => BlockUI.Unblock());

        return deferredResult.promise();
    }

    static GetLookupData(params: IGetLookupDataRequest) : P.Promise<IGetLookupDataResponse> {
        const deferred = P.defer<IGetLookupDataResponse>();

        Request.Send({
            proxy: {
                url: '/api/RecordsApi/GetLookupData',
                type: 'GET'
            },
            params
        }).then((result) => {
            return deferred.resolve(result);
        }).fail(error => {
            if (error.status === 404) {
                return deferred.resolve(null);
            }

            return deferred.reject(this.GetResponseError(error, 'ERROR_READING_LOOKUP_DATA'));
        });

        return deferred.promise();
    }

    static GetCount(tableId: number) : P.Promise<number> {
        const deferred = P.defer<number>();

        Request.Send({
            proxy: {
                url: '/api/RecordsApi/GetRecordsCount',
                type: 'GET'
            },
            params: {tableId: tableId}
        }).then((result) => {
            return deferred.resolve(result);
        }).fail(error => {
            if (error.status === 404) {
                return deferred.resolve(null);
            }

            return deferred.reject(this.GetResponseError(error, 'Error getting records count'));
        });

        return deferred.promise();
    }

    static GetDependsOnValues(params: IGetDependsOnValues): P.Promise<IGetDependsOnResponse> {
        const deferredResult = P.defer<any>();

        BlockUI.Block();

        Request.Send({
            proxy: {
                url: "/api/ScreenFormApi/GetDependsOnValues",
                type: "POST",
                dataType: "json"
            },
            params: params
        }).then(response => deferredResult.resolve(response))
            .fail((error) => this.RejectResponse(deferredResult, error))
            .always(() => BlockUI.Unblock());

        return deferredResult.promise();
    }

    static GetDynamicDependsOnValues(params: IGetDynamicDependsOnValue): P.Promise<any> {
        const deferredResult = P.defer<any>();

        Request.Send({
            proxy: {
                url: "/api/ScreenFormApi/GetDynamicDependsOnValues",
                type: "POST",
                dataType: "json"
            },
            params: params
        }).then(response => {
            if(response.IsSuccessfull){
                deferredResult.resolve(response);
            }else{
                deferredResult.reject({message: response.ErrorMessage });
            }
        });

        return deferredResult.promise();
    }

    private static RejectResponse<T>(deferredResult: P.Deferred<T>, error: P.Rejection) {
        const requestError = GetServerRequestError(error.status);
        deferredResult.reject({message: error.message, status: error.status, requestError: requestError});
    }

    private static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}