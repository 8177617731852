import {HubConnectionBuilder, HubConnection, HttpTransportType} from '@microsoft/signalr';

import {CookieManager} from 'Core/Common/CookieManager';
import {Notifier, MessageTypes} from 'Core/Common/Notifier';
import {P} from 'Core/Common/Promise';
import * as $ from 'jquery';
import {NotificationTypes} from '../../Common/Enums/NotificationTypes';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

import 'ace-builds/src-noconflict/mode-json';
import { FunctionBuilder } from 'Core/Components/CustomFunctions/FunctionBuilder';
import { BlockUI } from "Core/Common/BlockUi";
import { Guid } from '../../Common/Guid';
import {Event} from 'Core/Common/Event'

export class SignalRNotificationManager extends Event {
    private _signalRHub: HubConnection;

    private static instance: SignalRNotificationManager;
    private static allowInstantiation: boolean;
    private _debugEditor: any;
    private _scriptDebugEditor: any;
    public ImageCache: any = {};

    constructor() {
        super();
        if (!SignalRNotificationManager.allowInstantiation) {
            throw new Error('Use SignalRNotificationManager.Instance instead');
        }
        this.Init();
    }

    public SetScriptDebugEditor(debugEditor) {
        this._scriptDebugEditor = debugEditor;
        if (this._scriptDebugEditor) {
            this._scriptDebugEditor.session.setMode('ace/mode/json');
            this._scriptDebugEditor.session.setUseWorker(false);
        }
    }

    public SetDebugEditor(debugEditor) {
        this._debugEditor = debugEditor;
        if (this._debugEditor) {
            this._debugEditor.session.setMode('ace/mode/json');
            this._debugEditor.session.setUseWorker(false);
        }
    }

    private Init() {
        this.AddEvent('PASTE_IMAGE');
        this._signalRHub = new HubConnectionBuilder()
            .withUrl('/api/signalRHub', {accessTokenFactory: () => CookieManager.GetAuthToken(), skipNegotiation: true, transport: HttpTransportType.WebSockets})
            .withAutomaticReconnect()
            .build();

        this._signalRHub.on('ShowDebugLog', (message) => {
            if (this._debugEditor) {
                this._debugEditor.session.insert({
                    row: this._debugEditor.session.getLength(),
                    column: 0
                }, "\n" + message);

                this._debugEditor.scrollToLine(this._debugEditor.session.getLength(), true, true, function () {
                });
                this._debugEditor.gotoLine(this._debugEditor.session.getLength(), 0, true);
            }
        });

        this._signalRHub.on('ConsoleLog', (message) => {
            if (this._scriptDebugEditor) {
                this._scriptDebugEditor.session.insert({
                    row: this._scriptDebugEditor.session.getLength(),
                    column: 0
                }, "\n" + message);

                this._scriptDebugEditor.scrollToLine(this._scriptDebugEditor.session.getLength(), true, true, function () {});
                this._scriptDebugEditor.gotoLine(this._scriptDebugEditor.session.getLength(), 0, true);
            }
        });

        this._signalRHub.on('ConsoleImage', (title, image) => this.Trigger('PASTE_IMAGE', { title: title, image: image }));

        this._signalRHub.on('ShowProgress', message => {
            $("#dw-progress").html(`<h2>${message}</h2>`);
        });

        this._signalRHub.on('ShowNotification', (message, notificationType: NotificationTypes) => {
            if (notificationType === NotificationTypes.Warning) {
                new Notifier(null).Warning(message);
            } else if (notificationType === NotificationTypes.Failed) {
                new Notifier(null).Failed(message);
            } else {
                new Notifier(null).Success(message);
            }
        });

        this._signalRHub.on('EvaluateFunction', (code, params, objectId) => {
            if (objectId) {
                PubSub.publishSync(PUB_SUB_EVENTS.EXECUTE_SCRIPT, { Code: code, Params: params, ObjectId: objectId });
                return;
            }
            this.EvalFunction(code, params);
        });

        this._signalRHub.on('ShowMessage', message => {
            new Notifier(null).Show({ Message: message, Type: new MessageTypes().Success, ZIndex: 1000000 });
        });
    }

    private SkipRows(skipValue: number){
        for(var indx = 0; indx<skipValue;indx++){
            this._scriptDebugEditor.session.insert({
                row: this._scriptDebugEditor.session.getLength(),
                column: 0
            }, "\n");

            this._scriptDebugEditor.scrollToLine(this._scriptDebugEditor.session.getLength(), true, true, function () {
            });
            this._scriptDebugEditor.gotoLine(this._scriptDebugEditor.session.getLength(), 0, true);
        }
    }

	private EvalFunction(code, param) {
		(async ()=> { await FunctionBuilder.Execute(code, param) })();
	}

    static get Instance(): SignalRNotificationManager {
        if (SignalRNotificationManager.instance == null) {
            SignalRNotificationManager.allowInstantiation = true;
            SignalRNotificationManager.instance = new SignalRNotificationManager();
            SignalRNotificationManager.allowInstantiation = false;
        }

        return SignalRNotificationManager.instance;
    }

    SetToken() {
        const token = CookieManager.GetAuthToken();
        $.ajaxSetup({headers: {"Authorization": `Bearer ${token}`}});
    }

    Start(): P.Promise<any> {
        let deferredResult = P.defer<any>();
        this.Stop().finally(()=>{
            BlockUI.Block();

            Promise
                .all([
                    this._signalRHub.start()
                ])
                .then(() => {
                    BlockUI.Unblock();
                    deferredResult.resolve(null);
                });
        });
        return deferredResult.promise();
    }

    Stop() {
        return this._signalRHub.stop();
    }
}