import * as ko from 'knockout';
import 'jquery';
import 'tinymceTheme';
import 'tinymceAdvList';
import 'tinymceAutoLink';
import 'tinymceLists';
import 'tinymceLink';
import 'tinymceImage';
import 'tinymcePreview';
import 'tinymceAnchor';
import 'tinymceCode';
import 'tinymcePrint';
import 'tinymceSearchreplace';
import 'tinymceFullscreen';
import 'tinymceVisualblocks';
import 'tinymceCharmap';
import 'tinymceAutoresize';
import 'tinymceTable';
import 'tinymcePaste';
import 'tinymceMedia';
import 'tinymceInsertdatetime';
import 'tinymceNoneditable';
import 'tinymceIcon';
import 'tinymceImageTools';
import 'tinymceHelp';
import 'tinymcePageBreak';
import 'tinymceVisualChars';

import {UserManager} from 'User/UserManager';
import {edit} from "ace-builds";
import * as moment from "moment";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {Notifier} from "Core/Common/Notifier";
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";

export class TinymceExtention {
	static AddProtocol(url: string){
		if (!/^(https?:)?\/\//i.test(url)) {
			return 'http://' + url;
		}
		return url;
	}

	static ChangeTimestampFormat(format: string | null): string {
		const defaultFormatValue = 'DD/MM/YY';
		const formatType = format || defaultFormatValue;

		const isValidMomentFormat: boolean = formatType === defaultFormatValue || moment(moment().format(formatType)).isValid();
		const date: string = moment(new Date()).format(isValidMomentFormat ? formatType : defaultFormatValue);

		if (!isValidMomentFormat) {
			let message = NOTIFICATIONS.WRONG_FORMAT_IN_MEMO_TIMESTAMP_FORMAT_GLOBAL
				.replace('{formatValue}', `<strong class="format-value">${formatType}</strong>`)
				.replace('{defaultFormatValue}', `<strong class="default-format-value">${defaultFormatValue}</strong>`)
			new Notifier().Warning(message);
		}

		return date;
	}

	static Init() {
		ko.bindingHandlers.tinymce = {
			init: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
				const options = valueAccessor();
				const scroll = $(window).scrollTop();
				const autofocusTarget = options.autofocus ? `${options.guid}` : null;

				(tinymce as any).baseURL = `${__webpack_public_path__}tinymce`;

				const tinymceStyles =
					`.mce-content-body {
						 padding-bottom: 4px !important;
						 font-size: 13px !important;
					 }
					 
					 .reply-signature-body {
					 	color: #666 !important;
					 	font-style: italic !important;
					 }
					 `;

				const tinymceOptions: any = {
					extended_valid_elements : 'a[next-status|command-type|href]',
					selector: `#${options.guid}`,
					min_height: +options.min_height || 200,
					height: +options.height || '100%',
					max_height: !!+options.max_height ? +options.max_height : '',
					resize: false,
					link_assume_external_targets: true,
					document_base_url: '/',
					content_css: [`${__webpack_public_path__}codepen.css`],
					content_style: tinymceStyles,
					plugins: ['link image imagetools table'],
					contextmenu: "link image imagetools table",
					quickEdit: options.quickEdit,
					isMailControl: options.isMailControl,
					autoresize: options.autoresize,
					fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
					removed_menuitems: 'spellchecker',
					paste_data_images: true,
					convert_urls: false,
					remove_script_host: false,
                    table_responsive_width: false,
                    table_default_styles: {
                        collapse: 'collapse',
                    },
                    table_default_attributes: {
                        border: '1',
                    },
					mobile: {
						theme: 'silver',
					}
				};

				// enable 'autoresize' plugin but only on grid
				if (tinymceOptions.autoresize && (tinymceOptions.quickEdit || tinymceOptions.isMailControl)) {
					tinymceOptions.plugins.push('autoresize');
				} else if (tinymceOptions.autoresize){
					tinymceOptions.plugins.push('autoresize');
				}

				if (options.readOnly) {
					if (!options.quickEdit){
						tinymceOptions.min_height = !!+options.height || 100;
					}
					tinymceOptions.toolbar = false;
					tinymceOptions.menubar = false;
					tinymceOptions.statusbar = false;
					tinymceOptions.readonly = 1;

					tinymceOptions.plugins.push(
						'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'pagebreak', 'searchreplace', 'visualchars'
					);


					tinymceOptions.init_instance_callback = (editor) => {

						editor.on('OpenNotification', function (notification) {
                            if(notification.close && notification.settings && notification.settings.text.includes('blob:outlook-mobile-compose')){
                                notification.close();
                            }                           
                          });

						editor.show();
						$(window).scrollTop(scroll);
						options.afterInit(editor);

						$(editor.getBody()).find('a').click(function (e) {
							var url = e.target.getAttribute('href');
							window.open(TinymceExtention.AddProtocol(url));
							e.stopPropagation();
							e.preventDefault();
						});

					};
				} else if (tinymceOptions.quickEdit) {
					tinymceOptions.toolbar = options.languages ? 'translations' : false;
					tinymceOptions.menubar = false;
					tinymceOptions.statusbar = false;
					tinymceOptions.readonly = 0;
					tinymceOptions.nowrap = false;

					tinymceOptions.height = '200';
					tinymceOptions.min_height = '200';
					tinymceOptions.max_height = '200';

					tinymceOptions.plugins.push(
						'advlist', 'autolink', 'lists', 'link', 'charmap', 'print', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'pagebreak', 'searchreplace', 'noneditable', 'visualchars'
					);

					tinymceOptions.auto_focus = autofocusTarget;

					tinymceOptions.init_instance_callback = (editor) => {

						editor.on('OpenNotification', function (notification) {
                            if(notification.close && notification.settings && notification.settings.text.includes('blob:outlook-mobile-compose')){
                                notification.close();
                            }                           
                          });

						editor.show();
						$(window).scrollTop(scroll);
						options.afterInit(editor);

						editor.on('Change', (e) => {
							if (options.afterChange) {
								options.afterChange(editor.getContent());
							}
						});

						editor.on('click', function (e) {
							if (e.target.nodeName === 'A') {
								var url = e.target.getAttribute('href');
								window.open(TinymceExtention.AddProtocol(url));
							}
						});

					};

					tinymceOptions.setup = (editor) => {
						editor.ui.registry.addButton('usertime',
							{
								title: 'Add timestamp and user name',
								tooltip: 'Add timestamp and user name',
								icon: 'user',

								onAction: () => {
									const content = editor.getContent({format: 'html'});
									const userManager = UserManager.Instance;

									const userNameAndDate =
										'<p><span style="text-decoration: underline;" data-mce-style="text-decoration: underline;"><strong>' + '<<< ' +
										userManager.CurrentUser.Name +
										', at ' +
										new Date().toLocaleString('en-US', options) + ' >>>' +
										'</strong></span></p>';

									editor.setContent(userNameAndDate + '<br />' + '<span id="_cursor" />' + content);
									editor.selection.select(editor.dom.select('#_cursor')[0]);
									editor.selection.collapse(0);
									editor.dom.remove('_cursor');

									if (options.afterChange) {
										options.afterChange(editor.getContent());
									}
								}
							});

					};
				} else {
					tinymceOptions.nowrap = false;
					tinymceOptions.readonly = 0;

					tinymceOptions.plugins.push(
						'advlist', 'autolink', 'lists', 'link', 'charmap', 'print', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'pagebreak' ,'searchreplace', 'noneditable', 'visualchars'
					);

					tinymceOptions.toolbar = 'insertfile undo redo | styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | usertime | forecolor backcolor | fontsizeselect | translations';

					tinymceOptions.auto_focus = autofocusTarget;

					tinymceOptions.init_instance_callback = (editor) => {
						
						editor.on('OpenNotification', function (notification) {
							if(notification.close && notification.settings && notification.settings.text.includes('blob:outlook-mobile-compose')){
								notification.close();
							}							
						  });

						editor.show();
						$(window).scrollTop(scroll);
						options.afterInit(editor);

						editor.on('Change', (e) => {
							if (options.afterChange) {
								options.afterChange(editor.getContent());
							}
						});

						editor.on('click', function (e) {
							if (e.target.nodeName === 'A') {
								var url = e.target.getAttribute('href');
								window.open(TinymceExtention.AddProtocol(url));
							}
						});
					};

					tinymceOptions.setup = (editor) => {
						editor.ui.registry.addButton('usertime',
							{
								title: 'Add timestamp and user name',
								tooltip: 'Add timestamp and user name',
								icon: 'user',

								onAction: () => {
									const content = editor.getContent({format: 'html'});
									const userManager = UserManager.Instance;
									const userName = userManager.CurrentUser.Name;

									const userNameAndDate = `
														<p><span style="text-decoration: underline;" data-mce-style="text-decoration: underline;">
															<strong>
																<<< ${userName}, at ${TinymceExtention.ChangeTimestampFormat(GlobalManager.Instance.GetGlobal(GLOBALS.MEMO_TIMESTAMP_FORMAT))} >>>
															</strong>
														</span></p>
													`;

									editor.setContent(`${userNameAndDate}<br /><span id="_cursor" />${content}`);
									editor.selection.select(editor.dom.select('#_cursor')[0]);
									editor.selection.collapse(0);
									editor.dom.remove('_cursor');

									if (options.afterChange) {
										options.afterChange(editor.getContent());
									}
								}
							});

						if (options.languages) {
							const container = editor.container || '.tox-editor-container';
							const languages = options.languages.List.map(language => {
								return {
									type: 'menuitem',
									text: language.Name,
									onAction: function () {
										if (options.afterChange) {
											options.afterChange(editor.getContent());
										}
										options.onChangeLanguage(language.Id);

										$(container)
											.find('.tox-toolbar')
											.find('.tox-toolbar__group')
											.last()
											.find('.tox-tbtn__select-label')
											.text(`Translations ${language.ShortName}`);
									},
								};
							});

							let languageActive = options.languages.Active.ShortName;
							editor.ui.registry.addMenuButton('translations', {
								text: `Translations ${languageActive}`,
								fetch: function (callback) {
									let items = languages;
									callback(items);
								},
							});
						}
					};
				}

				tinymce.init(tinymceOptions);

				ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
					const instance = tinymce.get(options.guid);

					if (instance) {
						instance.remove();
					}
				});
			}
		};
	}
}