import * as ko from 'knockout';
import * as _ from 'underscore';

import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { ENTITY_COLORS } from 'QueryBuilder/Constants';
import { EVENTS } from 'QueryBuilder/Events';
import { Event } from 'Core/Common/Event';
import { FieldListMenu } from 'QueryBuilder/FieldListMenu/FieldListMenu';
import { FieldListItem } from 'QueryBuilder/FieldListMenu/FieldListItem';
import { EntityListMenu } from 'QueryBuilder/EntityListMenu/EntityListMenu';
import { EntityRelationshipsMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/Metadata/EntityRelationshipsMetadataModel';
import { EntityTypes } from 'Core/Controls/Grid/BaseGrid/Enums/EntityTypes';
import { EntityListItem } from 'QueryBuilder/EntityListMenu/EntityListItem';
import { ElementZIndexManager } from 'QueryBuilder/ElementZIndexManager';
import { QueryLinkEntity } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/QueryLinkEntity';
import { LABELS } from 'Core/Components/Translation/Locales';
import { IQueryEntityParams } from 'QueryBuilder/QueryEntity/IQueryEntityParams';
import { JoinTypeImages } from 'QueryBuilder/QueryEntityJoin/QueryLinkEntity/JoinTypesImages';
import { JoinOptionsMenu } from 'QueryBuilder/QueryEntityJoin/JoinOptionsMenu/JoinOptionsMenu';
import { JoinTypes } from 'QueryBuilder/Enums';
import { EVENTS as OPTION_MENU_EVENTS } from 'QueryBuilder/QueryEntityJoin/JoinOptionsMenu/Events';
import { IObjectIndex } from 'QueryBuilder/IObjectIndex';

import QueryEntityTemplate from 'QueryBuilder/QueryEntity/Templates/QueryEntity.html';
import QuerySubEntityTemplate from 'QueryBuilder/QueryEntity/Templates/QuerySubEntity.html';
import { EntityMetadataModel } from "Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel";
import { FIELD_TYPES } from "Core/Constant";

const DELETE_LIFESTATUS = 'Delete';

export interface  ISelectedEntity {
	Id: number;
	LookupFieldId?: number;
	ReferenceFieldId?: number;
	ReferenceFieldName?: number;
	ReferenceLookupField?: number;
}

export class QueryEntity extends Event {
	private _isEnableDrag: KnockoutObservable<boolean>;
	private _el: HTMLElement;
	private _selectedEntitiesCount: KnockoutObservable<number>;
	private _fieldListMenu: FieldListMenu;
	private _entityListMenu: EntityListMenu;
	private _referenceEntityListMenus: KnockoutObservable<Array<EntityListMenu>>;

	private _linkEntity: KnockoutObservable<QueryLinkEntity>;
	private _labels = LABELS;

	private _model: QueryEntityModel;
	private _jsPlumb: KnockoutObservable<jsPlumbInstance>;
	private _entitiesRelationships: Array<EntityRelationshipsMetadataModel>;
	private _isSubject: boolean;
	private _gridSubjectEntityId: number;
	private _isEnableSubQuery: boolean;
	private _isInSubQuery: boolean;
	private _isEnableSelectSubEntity: boolean;
	private _joinTypeImage: KnockoutObservable<string>;
	private _joinType: JoinTypes;
	private _joinOptionsMenu: JoinOptionsMenu;
	private _objectIndexes: Array<IObjectIndex>;

	constructor(params: IQueryEntityParams)
	{
		super();
		this._model = params.Model;
		this._jsPlumb = params.JsPlumb;
		this._joinType = params.JoinType;

		if (this._joinType === null || this._joinType === undefined) {
			this._joinType = JoinTypes.LeftJoin;
		}

		this._entitiesRelationships = params.EntitiesRelationships;
		this._isSubject = params.IsSubject;
		this._gridSubjectEntityId = params.GridSubjectEntityId;
		this._isEnableSubQuery = params.IsEnableSubQuery;
		this._isInSubQuery = params.IsInSubQuery;
		this._isEnableSelectSubEntity = params.IsEnableSelectSubEntity || false;

		this._linkEntity = ko.observable(null);
		this._selectedEntitiesCount = ko.observable(0);
		this._isEnableDrag = ko.observable(!this._isSubject);
		this._objectIndexes = params.ObjectIndexes;
		this._referenceEntityListMenus = ko.observableArray();

		this.AddEvent(EVENTS.JOIN_TYPE_CHANGED);
		this.AddEvent(EVENTS.AFTER_RENDER);
		this.AddEvent(EVENTS.ENTITY_SELECTED);
		this.AddEvent(EVENTS.ENTITY_UNSELECTED);
		this.AddEvent(EVENTS.LOOKUP_ENTITY_SELECTED);
		this.AddEvent(EVENTS.LOOKUP_ENTITY_UNSELECTED);
		this.AddEvent(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED);
		this.AddEvent(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED);
		this.AddEvent(EVENTS.REFERENCE_ENTITY_SELECTED);
		this.AddEvent(EVENTS.REFERENCE_ENTITY_UNSELECTED);
		this.AddEvent(EVENTS.CREATE_SUB_QUERY);
		this.AddEvent(EVENTS.REMOVE_SUB_QUERY);
		this.AddEvent(EVENTS.DRAG);
		this.AddEvent(EVENTS.DRAG_STOP);
		this.AddEvent(EVENTS.MOUSE_OVER);
		this.AddEvent(EVENTS.CLICK);

		this.Init();
		this.InitFieldListMenu(params.EnabledColumnTypes);

		if (this._model.Metadata.Type === EntityTypes[EntityTypes.Sub] || params.IsLookupJoin || params.IsReferenceJoin) {
			this._joinTypeImage = ko.observable(JoinTypeImages[this._joinType]);
			this._joinOptionsMenu = new JoinOptionsMenu(this._joinType);
			this._joinOptionsMenu.On(OPTION_MENU_EVENTS.JOIN_TYPE_CHANGED, this, (eventArgs: any) => {
				this._joinTypeImage(JoinTypeImages[eventArgs.data.JoinType]);
				this.Trigger(EVENTS.JOIN_TYPE_CHANGED, { JoinType: eventArgs.data.JoinType });
			});
		}
		this.InitReferenceMenu();
		this.InitEntityListMenu();
	}

	private Init() {

		var objIndex = _.find(this._objectIndexes, (indx) => { return indx.Id === this._model.Metadata.Id });

		if (objIndex) {
			if (this._model.Index === 0 || this._model.Index === null) {
				objIndex.Index = objIndex.Index + 1;
				this._model.Index = objIndex.Index;
			} else {
				objIndex.Index = this._model.Index;
			}
		}
		else {
			let currentIndex = (this._model.Index === 0 || this._model.Index === null) ? 1 : this._model.Index;
			var objIndex: IObjectIndex = {
				Id: this._model.Metadata.Id,
				Index: currentIndex
			};
			this._objectIndexes.push(objIndex);
			this._model.Index = objIndex.Index;
		}

		var metadata = _.find(this._entitiesRelationships, item => item.EntityMetadata.Id === this._model.Metadata.Id);
		if (metadata) {
			this._model.Metadata = metadata.EntityMetadata;
		}else{
			_.each(this._entitiesRelationships, (item)=>{
				var metadata = _.find(item.TablesRelatedToLookup, referenceLookup => referenceLookup.Id === this._model.Metadata.Id);
				if (metadata) {
					this._model.Metadata = metadata;
				}
			});
		}

		_.each(this._model.Metadata.Fields, (fieldMetadata) => {
			var selectedQueryColumnModel = _.find(this._model.Columns, queryColumn => { return queryColumn.Metadata.Id === fieldMetadata.Id });
			if (selectedQueryColumnModel) {
				selectedQueryColumnModel.Metadata = fieldMetadata;
			}
		});
	}

	private CreateSubQuery(){
		this._isInSubQuery = true;
		this._fieldListMenu.IsInSubQuery = this._isInSubQuery;
		this._isEnableDrag(false);
		this.Trigger(EVENTS.CREATE_SUB_QUERY)
	}

	public RemoveSubQuery(){
		this._isInSubQuery = false;
		this._fieldListMenu.IsInSubQuery = this._isInSubQuery;
	}

	private InitReferenceMenu() {
		let relationship = _.find(this._entitiesRelationships, item => item.EntityMetadata.Id === this._model.Metadata.Id);
		let makerFields = _.filter(this._model.Metadata.Fields, (item) => {
			return item.Type === FIELD_TYPES.Reference});
		_.each((makerFields), (referenceField)=>{
			let referenceMenu = new EntityListMenu(false, referenceField);
			let items = [];

			referenceMenu.On(EVENTS.REFERENCE_ENTITY_SELECTED, this, (eventArgs: any) => {
				this.Trigger(EVENTS.REFERENCE_ENTITY_SELECTED, eventArgs.data);
			});

			referenceMenu.On(EVENTS.REFERENCE_ENTITY_UNSELECTED, this, (eventArgs: any) => {
				this.Trigger(EVENTS.REFERENCE_ENTITY_UNSELECTED, eventArgs.data);
				this.RemovedDeletedReferenceMenu(referenceMenu);
			});

			_.each(relationship.ReferenceEntities, (item)=>{
				let entityListItem = new EntityListItem(item, false, true, null, referenceField);
				items.push(entityListItem);
			});
			referenceMenu.AddItems(items);
			this._referenceEntityListMenus.push(referenceMenu);
		});
	}

	private RemovedDeletedReferenceMenu(referenceMenu: EntityListMenu) {
		if(!!referenceMenu && referenceMenu.ReferenceField.LifestatusName === 'Delete' && referenceMenu.SelectedItemsCount === 0){
			this._referenceEntityListMenus.splice(this._referenceEntityListMenus().indexOf(referenceMenu), 1);
		}
	}

	private RemovedDeletedReferencesMenus() {
		let makerFields = _.filter(this._model.Metadata.Fields, (item) => {
			return item.Type === FIELD_TYPES.Reference});
		_.each((makerFields), (referenceField)=>{
			let referenceMenu = _.find(this._referenceEntityListMenus(), item => item.ReferenceFieldId === referenceField.Id);

			if(!!referenceMenu && referenceField.LifestatusName === 'Delete' && referenceMenu.SelectedItemsCount === 0){
				this._referenceEntityListMenus.splice(this._referenceEntityListMenus().indexOf(referenceMenu), 1);
			}
		});
	}

	private InitFieldListMenu(enabledColumnTypes: Array<string>) {
		this._fieldListMenu = new FieldListMenu(this._model, this._isEnableSubQuery, this._isInSubQuery);
		_.each(this._model.Metadata.Fields, field => {
			var column = _.find(this._model.Columns, queryColumn => { return queryColumn.Metadata.Id === field.Id });
			if (enabledColumnTypes && enabledColumnTypes.length > 0) {
				if (enabledColumnTypes.indexOf(field.Type) >= 0) {
					this._fieldListMenu.AddItem(new FieldListItem(field, column != null));
				}
			} else {
				this._fieldListMenu.AddItem(new FieldListItem(field, column != null));
			}
		});

		this._fieldListMenu.On(EVENTS.CREATE_SUB_QUERY, this, () => {
			this._isEnableDrag(false);
			this.Trigger(EVENTS.CREATE_SUB_QUERY);
		});

		this._fieldListMenu.On(EVENTS.REMOVE_SUB_QUERY, this, () => {
			this._isEnableDrag(true);
			this.Trigger(EVENTS.REMOVE_SUB_QUERY);
		});
	}

	private InitEntityListMenu() {
		var relationship = _.find(this._entitiesRelationships, item => item.EntityMetadata.Id === this._model.Metadata.Id);
		this._entityListMenu = new EntityListMenu(this._model.IsIterator, null);

		this._entityListMenu.On(EVENTS.ENTITY_SELECTED, this, (eventArgs: any) => {
			this._selectedEntitiesCount(this._selectedEntitiesCount() + 1);
			this.Trigger(EVENTS.ENTITY_SELECTED, eventArgs.data);
		});
		this._entityListMenu.On(EVENTS.ENTITY_UNSELECTED, this, (eventArgs: any) => {
			if(eventArgs.data.Lifestatus != DELETE_LIFESTATUS){
				 this._selectedEntitiesCount(this._selectedEntitiesCount() -1);
			}
			this.Trigger(EVENTS.ENTITY_UNSELECTED, eventArgs.data);
		});

		this._entityListMenu.On(EVENTS.LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
			this._selectedEntitiesCount(this._selectedEntitiesCount() + 1);
			this.Trigger(EVENTS.LOOKUP_ENTITY_SELECTED, eventArgs.data);
		});
		this._entityListMenu.On(EVENTS.LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			if(eventArgs.data.Lifestatus != DELETE_LIFESTATUS){
				this._selectedEntitiesCount(this._selectedEntitiesCount() -1);
			}			
			this.Trigger(EVENTS.LOOKUP_ENTITY_UNSELECTED, eventArgs.data);
		});


		this._entityListMenu.On(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
			this._selectedEntitiesCount(this._selectedEntitiesCount() + 1);
			this.Trigger(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED, eventArgs.data);
		});

		this._entityListMenu.On(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			if(eventArgs.data.Lifestatus != DELETE_LIFESTATUS){
				this._selectedEntitiesCount(this._selectedEntitiesCount() -1);
			}
			this.Trigger(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED, eventArgs.data);
		});

		this._entityListMenu.On(EVENTS.IS_ITERATOR_CHANGED, this, (eventArgs: any) => {
			if (eventArgs.data) {
				this._model.IsIterator = eventArgs.data.IsIterator;
			}
		});

		let items = [];
		if (relationship) {
			_.each(relationship.RelatedEntitiesMetadata, item => {
				var entityListItem = new EntityListItem(item.EntityMetadata, false, item.EntityMetadata.Id !== this._gridSubjectEntityId, null);
				items.push(entityListItem);
			});

			_.each(relationship.RelatedSubEntitiesMetadata,
				item => {
					var entityListItem = new EntityListItem(item.EntityMetadata, false, true, null);
					items.push(entityListItem);
			});

			_.each(relationship.TablesRelatedToLookup,
				tableRelatedToLookup => {
					_.each(tableRelatedToLookup.Fields, (field) => {
						if(field.LookupTable && field.LookupTable.Id === this._model.Metadata.Id){
							var entityListItem = new EntityListItem(tableRelatedToLookup, false, true, null, null, field);
							items.push(entityListItem);
						}
					});
				});
		}

		var lookupFields =  _.filter(this._model.Metadata.Fields, (item)=> { return item.Type === FIELD_TYPES.Lookup});

		_.each(lookupFields, (lookupField) => {
			if(lookupField.LookupTable){
				var entityListItem = new EntityListItem(lookupField.LookupTable, false, true, lookupField);
				items.push(entityListItem);
			}
		});

		this._entityListMenu.AddItems(items);
	}

	AddDeleteEntityToMenu(metadata: EntityMetadataModel){
		var entityListItem = new EntityListItem(metadata, false, true, null);
		this._entityListMenu.AddItems([entityListItem]);
		this._entityListMenu.SelectEntityItemById(metadata.Id);
	}

	SetSelectedEntities(selectedEntities: Array<ISelectedEntity>) {
		let entities = _.filter(selectedEntities, (item) => !item.LookupFieldId && !item.ReferenceFieldId && !item.ReferenceLookupField);
		_.each(entities, selectedEntity => {
			this._entityListMenu.SelectEntityItemById(selectedEntity.Id);
		});

		let lookupEntities = _.filter(selectedEntities, (item) => !!item.LookupFieldId && !item.ReferenceFieldId && !item.ReferenceLookupField);
		_.each(lookupEntities, selectedEntity => {
			this._entityListMenu.SelectLookupItemById(selectedEntity.Id, selectedEntity.LookupFieldId);
		});

		let referenceEntities = _.filter(selectedEntities, (item) => !item.LookupFieldId && !item.ReferenceLookupField && !!item.ReferenceFieldId);
		_.each(this._referenceEntityListMenus(), (referenceMenu) => {
			_.each(referenceEntities, selectedEntity => {
				referenceMenu.SelectReferenceItemById(selectedEntity.Id, selectedEntity.ReferenceFieldId);
			});
		});

		let referenceLookupEntities = _.filter(selectedEntities, (item) => !item.LookupFieldId && !item.ReferenceFieldId && !!item.ReferenceLookupField);
		_.each(referenceLookupEntities, selectedEntity => {
			this._entityListMenu.SelectReferenceLookupItemById(selectedEntity.Id, selectedEntity.ReferenceLookupField);
		});

		this.RemovedDeletedReferencesMenus();

		this._selectedEntitiesCount(this._entityListMenu.SelectedItemsCount);
	}

	GetTemplateHtml() {
		if (this._model.Metadata.Type === EntityTypes[EntityTypes.Sub]) {
			return QuerySubEntityTemplate;
		}
		return QueryEntityTemplate;
	}

	get SelectedEntitiesCount(): KnockoutObservable<number>{
		return this._selectedEntitiesCount;
	}

	get Name() :string {
		return this._model.Metadata.Name;
	}

	get TranslatedName(): string {
		var name = this._model.Metadata.NameTranslation == null || this._model.Metadata.NameTranslation == '' ? this.Name : this._model.Metadata.NameTranslation;
		if (this._model.Index > 1) {
			name = `${name}${this._model.Index}`;
		}
		return name;
	}

	get IsEnableDrag(): KnockoutObservable<boolean> {
		return this._isEnableDrag;
	}

	get FieldListMenu(): FieldListMenu{
		return this._fieldListMenu;
	}

	get EntityListMenu(): EntityListMenu{
		return this._entityListMenu;
	}

	get ReferenceEntityListMenus(): KnockoutObservable<Array<EntityListMenu>>{
		return this._referenceEntityListMenus;
	}

	AfterRender(el) {
		this._el = el[0];
		var parent = $(this._el).parent();
		var isRoot = $(parent).hasClass('querybuilder-root-canvas');
		$(this._el).draggable({
			containment: isRoot ? false : 'parent',
			grid: [20, 20],
			drag: () => {
				this._jsPlumb().repaintEverything();
			},
			stop: (evt, ui) => {
				this._jsPlumb().repaintEverything();
				this._model.CanvasPosition.Top = ui.position.top;
				this._model.CanvasPosition.Left = ui.position.left;
			}
		});

		this.Trigger(EVENTS.AFTER_RENDER);
	}

	get Wrapper(){
		return this._el;
	}

	Drag() {
		this.Trigger(EVENTS.DRAG);
	}

	Drop() {
		return false;
	}


	DragStop(params) {
		this.Trigger(EVENTS.DRAG_STOP);
	}

	GetLeftPosition(): string {
		return `${this._model.CanvasPosition.Left}px`;
	}

	GetTopPosition(): string {
		return `${this._model.CanvasPosition.Top}px`;
	}

	get Model(): QueryEntityModel {
		return this._model;
	}

	get BackgroundColor(): string {
		return this._isSubject ? ENTITY_COLORS.MAIN_ENTITY : ENTITY_COLORS.RELATED_ENTITY;
	}

	Click(element: HTMLElement) {
		ElementZIndexManager.Instance.ToTop(this._el);
		this.Trigger(EVENTS.CLICK);
	}

	MouseOver(element: HTMLElement) {
		ElementZIndexManager.Instance.ToTop(this._el);
		this.Trigger(EVENTS.MOUSE_OVER);
	}

	ToTop() {
		ElementZIndexManager.Instance.ToTop(this._el);
	}

	get LinkEntity(): KnockoutObservable<QueryLinkEntity> {
		return this._linkEntity;
	}

	get JoinOptionsMenu(): JoinOptionsMenu {
		return this._joinOptionsMenu;
	}
}