import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize} from 'libs/cerialize';

import {DesignedPortletSpace} from "Core/Portlets/Models/Store/DesignedPortletSpace"
import {PortletsCollection} from "Core/Portlets/Models/Design/Explorer/PortletsCollection"
import {IEditPortletSpaceParams} from "Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModal";

export class PortletSpaceStore {
	static GetPortletSpaces(controlId: number) {
		return Request.Send({
			proxy: {
				url: URL.PORTLETS.GET_PORTLET_SPACES,
				dataType: 'json',
				type: 'GET'
			},
			params: {controlId: controlId || 0}
		});
	}

	static GetMainPortletSpace(): P.Promise<DesignedPortletSpace> {
		let deferredResult = P.defer<DesignedPortletSpace>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.PORTLETS.GET_PORTLET_SPACE,
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(portletSpace => deferredResult.resolve(<DesignedPortletSpace>Deserialize(portletSpace, DesignedPortletSpace)))
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static GetPortletSpace(id: number): P.Promise<DesignedPortletSpace> {
		let deferredResult = P.defer<DesignedPortletSpace>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.PORTLETS.GET_PORTLET_SPACE_BY_ID,
				dataType: 'json',
				type: 'GET'
			}, params: {id: id}
		};

		Request.Send(requestParams)
			.then(portletSpace => deferredResult.resolve(<DesignedPortletSpace>Deserialize(portletSpace, DesignedPortletSpace)))
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}

	static GetPortletsExplorer(): P.Promise<PortletsCollection> {
		let deferredResult = P.defer<PortletsCollection>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: URL.PORTLETS.GET_AVAILABLE_PORTLETS,
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(portletEntities => deferredResult.resolve(<PortletsCollection>Deserialize(portletEntities, PortletsCollection)));

		return deferredResult.promise();
	}

	static UpdatePortletSpace(changedPortletSpace: DesignedPortletSpace): P.Promise<any> {
		return Request.Send({
			proxy: {
				url: URL.PORTLETS.UPDATE_PORTLET_SPACE,
				type: 'POST'
			},
			params: changedPortletSpace
		});
	}

	static CreatePortletSpace(portletSpace: IEditPortletSpaceParams) {
		return Request.Send({
			proxy: {
				url: URL.PORTLETS.CREATE_PORTLET_SPACE,
				type: 'POST',
				dataType: 'json'
			},
			params: {Name: portletSpace.Name}
		});
	}

	static UpdatePortletSpaceInfo(portletSpaceId: number, name: string, isMain?: boolean) {
		return Request.Send({
			proxy: {
				url: URL.PORTLETS.UPDATE_PORTLET_SPACE_INFO,
				type: 'POST'
			},
			params: {Id: portletSpaceId, Name: name, IsMain: isMain}
		});
	}

	static BindToControl(portletSpaceId: number, controlId: number) {
		return Request.Send({
			proxy: {
				url: URL.PORTLETS.BIND_TO_CONTROL,
				type: 'POST'
			},
			params: {PortletSpaceId: portletSpaceId, controlId: controlId}
		});
	}

	static DeletePortletSpace(id: number) {
		return Request.Send({
			proxy: {
				url: URL.PORTLETS.DELETE_PORTLET_SPACE,
				type: 'GET'
			},
			params: {id: id}
		});
	}
}