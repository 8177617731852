import * as ko from 'knockout'
import * as _ from 'underscore';

import { BaseControl, IControlValue } from 'Core/Controls/BaseControl/BaseControl'
import { IControlParam } from 'Core/Screens/IScreen'
import {RenderModes} from "Core/Constant";
import {GeneralProperties} from "Core/GeneralProperties/GeneralProperties";

import CopyButtonConfig from "Core/Controls/ButtonCopy/Configs/copy-button-config.json";

import ViewTemplate from 'Core/Controls/ButtonCopy/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/ButtonCopy/Templates/HelpView.html'
import DesignTemplate from 'Core/Controls/ButtonCopy/Templates/Design.html'
import ToolBarTemplate from 'Core/Controls/ButtonCopy/Templates/ToolBar.html'
import {MenuItemModel} from "Core/Controls/ButtonCopy/Models/MenuItemModel";

ko.templates['Core/Controls/ButtonCopy/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/ButtonCopy/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/ButtonCopy/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/ButtonCopy/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/ButtonCopy/Templates/Edit'] = ViewTemplate;

export class ButtonCopy extends BaseControl {
	private _menuItems: KnockoutObservableArray<MenuItemModel>;
    private _config: any;
	private _mainRecordId: number;
	constructor(params: IControlParam) {
		super(params, CopyButtonConfig);
        this._style = ko.computed(() => {
            return {
                backgroundColor: this._backgroundColor(),
                color: this._color(),
                borderColor: this._borderColor(),
                borderWidth: this._border() ? '1px' : '0',
                padding: this._border() ? '6px 14px' : '7px 14px'
            };
        });
		this.Init();
	}

	private Init(): void {
        this.ApplyProperties();
        
		let copyMenuItem = new MenuItemModel(0, 'Copy', this._labels.COPY);
		let copyWithRelationMenuItem = new MenuItemModel(1, 'Copy with relation', this._labels.COPY_WITH_RELATIONS);

		this._menuItems = ko.observableArray([copyMenuItem, copyWithRelationMenuItem]);
	}

	get MenuItems(): KnockoutObservableArray<MenuItemModel> {
		return this._menuItems;
	}

	Click(menuItem: MenuItemModel) {
		
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			var screen = this._form.GetScreen();
			if (screen) {
				menuItem.Id === 0 ? screen.Trigger('COPY_RECORD') : screen.Trigger('COPY_RECORD_WITH_RELATIONS');
			}
		}
	}

	private ClickRootCopyBtn(data?, event?): void {
		if (this._help.IsHelpButtonPressed()) {
			event.stopPropagation();
			this._help.ShowControlHelp(this);
		}
	}

	SetValue(value: IControlValue): void {
		if (value.Data && value.Data.Rights && !value.RecordSpecsModel.IsNewRecord) {
			this._enabled(value.Data.Rights.IsAddingRecordAllowed && value.Data.Rights.IsEditingAllowed && value.Data.Rights.IsRecordSecurityOk);
		} else if (value.RecordSpecsModel.IsNewRecord) {
			this._enabled(true);
		}
	}

    ApplyProperties() {
        if (this.Properties) {
            this.AssignProperty('Styling', 'BackgroundColor', this._backgroundColor);
            this.AssignProperty('Styling', 'TextColor', this._color);
            this.AssignProperty('Styling', 'Border', this._border);
            this.AssignProperty('Styling', 'BorderColor', this._borderColor);
        }
    }

    private AssignProperty(groupedBy: string, propertyName: string, propertyHolder: KnockoutObservable<any> | any) {
        if (this.Properties[groupedBy]) {
            _.each(this.Properties[groupedBy].Properties,
                (property: any) => {
                    if (property.hasOwnProperty(propertyName)) {
                        propertyHolder(property[propertyName]);
                    }
                });
        }
    }

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}
} 