///<reference path="../libs/typings/knockout.d.ts" />
///<reference path="../libs/typings/x-editable.d.ts" />
///<reference path="../libs/typings/bootstrap-switch.d.ts" />

import * as ko from "knockout";
import * as moment from 'moment';
import { RecordDataModel } from "Core/Screens/Models/RecordDataModel";

import {GenericDeserialize} from 'libs/cerialize'

import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_EVENTS,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import {TreeModel} from "LookupEditor/Models/TreeModel";
import {TreeNodeModel} from "LookupEditor/Models/TreeNodeModel";
import {TableModel} from "LookupEditor/Store/Models/TableModel";
import {LookupGridModel} from "LookupEditor/Models/LookupGridModel";
import {LookupModel} from "LookupEditor/Store/Models/LookupModel";
import {LookupEditorStore} from "LookupEditor/Store/LookupEditorStore";
import {TablesStore} from "LookupEditor/Store/TablesStore";
import {IRetrieveRecordsModel} from "LookupEditor/Store/Interfaces/IRetrieveRecordsModel";
import {RetrievedRecordsModel} from "LookupEditor/Store/Models/RetrievedRecordsModel";
import {IDeleteRecordsRequestModel} from "LookupEditor/Store/Interfaces/IDeleteRecordsRequestModel";
import {ISaveLookupRequestModel} from "LookupEditor/Store/Interfaces/ISaveLookupRequestModel";
import {EditableColumnModel} from "LookupEditor/Models/EditableColumnModel";
import {EditableRowModel} from "LookupEditor/Models/EditableRowModel";
import {Notifier} from "Core/Common/Notifier";
import {Event} from "Core/Common/Event";
import {EVENTS, TABLE_TYPES} from "Core/Constant";
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen'
import {BlockUI} from 'Core/Common/BlockUi';
import {NOTIFICATIONS, LABELS, CONFIRMATIONS} from "Core/Components/Translation/Locales";
import {NewRecordScreen} from "LookupEditor/NewRecord/NewRecordScreen";
import {NewRecordModel} from "LookupEditor/NewRecord/Models/NewRecordModel";
import {FormatConverter} from "FormatEditor/FormatConverter";
import {DATE_FORMATS} from "Core/Constants/DateTimeFormats";
import {FIELD_TYPES} from "Core/Constant";
import { FieldDataModel } from "Core/Screens/Models/RecordDataModel";

import EditorTemplate from 'LookupEditor/Templates/Editor.html';
import TreeTemplate from 'LookupEditor/Templates/Tree.html';
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {util} from "../libs/rappid/build/rappid";
import number = util.interpolate.number;
import {ZIndexManager} from "../Core/Common/ZIndexManager";

ko.templates["LookupEditor/Templates/Editor"] = EditorTemplate;
ko.templates["LookupEditor/Templates/Tree"] = TreeTemplate;

export class LookupEditor extends Event {
    private _isReady: KnockoutObservable<boolean>;
    private _container: HTMLElement;
    private _cdTablesId: number;
    private _isAllRecordsSelected: KnockoutObservable<boolean>;
    private _treeModel: KnockoutObservable<TreeModel>;
    private _lookupTableModel: LookupModel;
    private _gridModel: KnockoutObservable<LookupGridModel>;
    private _isTreeVisible: KnockoutObservable<boolean>;
    private _isGridVisible: KnockoutObservable<boolean>;
    private _isAnyRecordSelected: KnockoutComputed<boolean>;
    private _searchScreen: SearchScreen;
    private _searchTerm: KnockoutObservable<string>;
    private _searchValue: KnockoutObservable<string>;
    private _newRecordScreen: NewRecordScreen;
    private _labels = LABELS;

    IsAnyRecordChanged: KnockoutComputed<boolean>;

    constructor() {
        super();

        this._isReady = ko.observable(false);
        this._isAllRecordsSelected = ko.observable(false);
        this._treeModel = ko.observable(null);
        this._gridModel = ko.observable(new LookupGridModel());
        this._isTreeVisible = ko.observable(false);
        this._isGridVisible = ko.observable(false);
        this._isAnyRecordSelected = ko.computed(() => {
            return this._gridModel().IsAnyRecordSelected();
        }, this);

        this.IsAnyRecordChanged = ko.computed(() => {
            return this._gridModel().IsAnyRecordChanged();
        });

        this._searchScreen = null;
        this._newRecordScreen = null;
    }

    Render(container: string) {
        BlockUI.Block();
        LookupEditorStore.GetMetadata()
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (!result.IsSuccessfull) {
                    var notifier = new Notifier(null);
                    notifier.Warning(result.ErrorMessage);
                } else {
                    this._cdTablesId = result.ResultObject.CdTablesEntityId;

                    var rootNodes = result.ResultObject.Entities.map(entity => {
                        var treeModel = new TreeNodeModel();
                        treeModel.Id = entity.Id;
                        treeModel.Name = entity.TranslatedName || entity.Name;
                        treeModel.Type = entity.Type;
                        treeModel.Icon = entity.Icon;
                        treeModel.EntityColor = entity.EntityColor;
                        treeModel.HasLookups = entity.HasLookups;
                        treeModel.CanExpand = entity.HasLookups;
                        return treeModel;
                    });

                    var treeModel = new TreeModel(rootNodes);
                    this.RenderTree(treeModel);

                    this.OnReady(container);
                }
            });
    }

    ShowSearchForm(): void {
        if (!this._cdTablesId) {
            var notifier = new Notifier();
            notifier.Failed(NOTIFICATIONS.CD_TABLE_NOT_FOUND);
        } else {
            this._searchScreen = new SearchScreen({
                EntityId: this._cdTablesId,
                SearchTerm: this._searchTerm(),
                ButtonAdd: false,
                CloseAfterSelectingRecord: false
            });
            this._searchScreen.On("RECORD_SELECTED", this, (eventArgs) => {
                var recordTypeName = eventArgs.data.TypeName;

                var recordId = eventArgs.data.RecordId;

                this._searchScreen.Cancel();
                if (recordTypeName.toUpperCase() === TABLE_TYPES.Lookup.toUpperCase()) {
                    this.LoadRelatedTables(recordId, TABLE_TYPES.Lookup);
                    this.GetLookup(recordId);
                } else {
                    this.LoadEntity(recordId);
                }
            });
            this._searchScreen.Show();
        }
    }

    SelectOrDiselectRecords() {
        this._gridModel().AllRecordsSelected = this._isAllRecordsSelected();
        return true;
    }

    NewRecord() {
        NewRecordScreen.GenerateScreen(this._lookupTableModel)
            .then(newRecordScreen => {

                newRecordScreen.ShowInModal('newLookupRecord');

                newRecordScreen.On('SAVE_DATA', this, eventArgs => this.AddRecord(eventArgs.data));

                this._newRecordScreen = newRecordScreen;
            });
    }

    SaveChanges() {
        var tableId = this._gridModel().TableId;
        var columns = this._gridModel().Columns;
        var changedRecords: EditableRowModel[] = this._gridModel().ChangedRecords;
        var changedRecordsData = this.GetRecordsData(columns, changedRecords);

        var requestData: ISaveLookupRequestModel = {
            TableId: tableId,
            ChangedRecords: changedRecordsData
        };
        BlockUI.Block();
        LookupEditorStore.SaveChanges(requestData)
            .always(() => BlockUI.Unblock())
            .then(result => {
                this.ShowWarning(result.Warning);
                this.ShowSaveResult()
            })
            .fail(error => this.ShowError(error));
    }

    CancelChanges() {
        this.ShowGridForcibly(this._lookupTableModel);
    }

    DeleteRecords() {
        var selectedRecordsId = this._gridModel().SelectedRecordsId;
        if (selectedRecordsId === null) {
            return;
        }
        if (selectedRecordsId.length === 0) {
            var notifier = new Notifier(null);
            notifier.Warning(NOTIFICATIONS.NO_SELECTED_RECORDS);
            return;
        }

        let dialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.ARE_YOUR_SURE_TO_DELETE_RECORDS,
            Type: ConfirmationTypes.Question,
            Width: 500,
            MinHeight: 160
        });

        dialog.On(CONFIRMATION_EVENTS.CONFIRM_SELECTED, this, () => {
            var lookupTableId = this._lookupTableModel.Id;

            this.DeleteRecordsFromDb(lookupTableId, selectedRecordsId);
        });

        dialog.Show();
    }

    private RenderTree(tree: TreeModel) {
        this._treeModel(tree);
        this._treeModel().On(EVENTS.ON_TREE_NODE_SELECTED, self, args => {
            var nodeModel = args.data.NodeModel;
            if (nodeModel.Type === 'Lookup') {
                this.GetLookup(nodeModel.Id);
            }
        });
    }

    private LoadEntity(entityId: number) {
        BlockUI.Block();
        TablesStore.GetEntity(entityId)
            .then(result => {
                if (!result.IsSuccessfull) {
                    var notifier = new Notifier(null);
                    notifier.Failed(result.ErrorMessage);
                } else {
                    this._searchScreen.Close();
                    this.UpdateTree(result.ResultList);
                }
            }).fail(() => new Notifier().Failed(NOTIFICATIONS.SOMETHING_WENT_WRONG))
            .always(() => BlockUI.Unblock());
    }

    private LoadRelatedTables(tableId: number, tableType: string) {
        BlockUI.Block();
        TablesStore.GetRelatedTables(tableId, tableType, false)
            .then(nodes => {
                this.UpdateTree(nodes);
            }).fail(() => new Notifier().Failed(NOTIFICATIONS.SOMETHING_WENT_WRONG))
            .always(() => BlockUI.Unblock());
    }

    private UpdateTree(tables: Array<TableModel>) {
        var rootNodes = tables.map(entity => {
            var treeNodeModel = new TreeNodeModel();
            treeNodeModel.Id = entity.Id;
            treeNodeModel.Name = entity.Name;
            treeNodeModel.Type = entity.Type;
            treeNodeModel.HasLookups = entity.HasLookups;
            treeNodeModel.CanExpand = entity.HasLookups;
            return treeNodeModel;
        });

        var treeModel = new TreeModel(rootNodes);
        this.RenderTree(treeModel);
    }

    private GetLookup(lookupId: number) {
        BlockUI.Block();
        TablesStore.GetLookup(lookupId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (!result.IsSuccessfull) {
                    var notifier = new Notifier(null);
                    notifier.Warning(result.ErrorMessage);
                } else {
                    this.ShowGrid(GenericDeserialize(result.ResultObject, LookupModel));
                }
            });
    }

    private ShowGrid(lookup: LookupModel) {
        if (this._gridModel().IsAnyRecordChanged()) {
            var notifier = new Notifier(null);
            notifier.Warning(NOTIFICATIONS.SAVE_OR_DISCARD_CHANGES);
            return;
        }

        this.ShowGridForcibly(lookup);
    }

    private ShowGridForcibly(lookup: LookupModel) {
        this.UpdateGrid(lookup, this._gridModel().CurrentPage(), this._gridModel().SelectedRecordsPerPage());
        this.GetDataForLookup();
    }

    private GetRecordsData(columns: EditableColumnModel[], changedRecords: EditableRowModel[]): Array<any> {
        var primaryKeyField = _.findWhere(columns, {IsPrimaryKey: true});

        if (!primaryKeyField) {
            return null;
        }

        var primaryKeyFieldName = primaryKeyField.Name;

        var records = [];
        changedRecords.forEach(record => {
            var recordData = {
                RecordId: 0,
                Data: []
            };
            recordData.RecordId = parseInt(record.GetColumnData(primaryKeyFieldName).FieldValue);
            columns
                .filter(field => field.TypeName !== "Image")
                .filter(field => field.Name === primaryKeyFieldName || record.GetColumnData(field.Name) && record.GetColumnData(field.Name).Changed)
                .forEach(field => {
                    const columnData = record.GetColumnData(field.Name);

                    let value = columnData.FieldValue;
                    if (columnData.FieldTypeName === 'Decimal' && columnData.FieldTypeFormat === 'Percentage') {
                        value = String(parseFloat(columnData.FieldValue.replace(',', '.')).toFixed(columnData.FieldSize < 2 ? 0 : columnData.FieldSize));
                        if (Number.isNaN(Number(value))) {
                            value = null;
                        }
                    }

                    const fieldData = {
                        FieldName: field.Name,
                        FieldValue: value,
                        Translations: []
                    };

                    if (columnData.FieldValueTranslations && columnData.FieldValueTranslations.length > 0) {
                        columnData.FieldValueTranslations = columnData.FieldValueTranslations.filter(translation => translation.LanguageId != 0);
                        fieldData.Translations = columnData.FieldValueTranslations.map(translation => {
                            return {
                                LanguageShortName: TranslationManager.Instance.GetLanguageById(translation.LanguageId).ShortName,
                                Value: translation.Value
                            };
                        })
                    }

                    recordData.Data.push(fieldData);
                });
            records.push(recordData);
        });

        return records;
    }

    private GetDataForLookup() {
        var pageNumber = this._gridModel().CurrentPage(),
            recordsPerPage = this._gridModel().SelectedRecordsPerPage();

        const requestData: IRetrieveRecordsModel = {
            TableId: this._lookupTableModel.Id,
            PageNumber: pageNumber,
            RecordsPerPage: recordsPerPage,
            SearchValue: this._searchValue()
        };

        BlockUI.Block();
        LookupEditorStore.GetLookupData(requestData)
            .then(retrievedRecordsModel => {
                if (retrievedRecordsModel.Records.length > 0 || pageNumber == 1) {
                    this.ShowGetDataForLookupResult(retrievedRecordsModel)
                } else {
                    this._gridModel().CurrentPage(pageNumber - 1);
                }
            })
            .fail(err => {
                let notifier = new Notifier();
                notifier.Failed(err.message);
            })
            .always(() => BlockUI.Unblock());
    }

    private ShowGetDataForLookupResult(model: RetrievedRecordsModel) {
        if (this._searchValue()) {
            let columns = this._gridModel().Columns;
            this._gridModel().Columns = _.without(columns, _.findWhere(columns, {_name: 'SORT'}));
        } else {
            if (this._gridModel().CurrentPage() == 0) {
                return false;
            } else {
                this.UpdateGrid(this._lookupTableModel, this._gridModel().CurrentPage(), this._gridModel().SelectedRecordsPerPage());
            }
        }
        const activeLanguesId = model.Languages.map(l => l.Id);
        TranslationManager.Instance.UpdateLanguages(activeLanguesId)
            .then(() => this.RenderGrid(model));
    }

    private RenderGrid(retrievedRecordsModel: RetrievedRecordsModel): void {
        _.each(retrievedRecordsModel.Records, (record) => {
            _.each(record.Fields, (field) => {
                if (field.FieldTypeName == FIELD_TYPES.Memo && field.FieldValueTranslations.length > 0) {

                    const defaultLanguageToAdd = {
                        LanguageId: retrievedRecordsModel.Languages[0].Id,
                        LanguageShortName: retrievedRecordsModel.Languages[0].ShortName,
                        Value: field.FieldValue

                    };
                    field.FieldValueTranslations.unshift(defaultLanguageToAdd);
                }
                field.FieldValue = this.GetConvertedValue(field);
            });
        });
        this._gridModel().AllRecordsCount(retrievedRecordsModel.AllRecordsCount);
        this._gridModel().Data = retrievedRecordsModel.Records;
        this._isGridVisible(true);
    }

    private GetConvertedValue(field: FieldDataModel): string {
        const value = field.FieldValue;
        const fieldType = field.FieldTypeName;
        if (!value) {
            return value;
        } else if (fieldType === FIELD_TYPES.DateTime) {
            return moment(FormatConverter.CorrectTimezone(value)).format();
        } else if (fieldType === FIELD_TYPES.Date) {
            return moment(value).format();
        } else if (fieldType === FIELD_TYPES.Time) {
            return moment(FormatConverter.CorrectTimezone(value)).format(DATE_FORMATS.TIME.Format);
        } else if (fieldType === FIELD_TYPES.TimeSpan) {
            return moment(value).format(DATE_FORMATS.TIME_SPAN.Format);
        }
        return value;
    }

    private DeleteRecordsFromDb(lookupId: number, selectedRecordsId: Array<number>): void {
        var requestData: IDeleteRecordsRequestModel = {
            TableId: lookupId,
            RecordsId: selectedRecordsId
        };
        BlockUI.Block();
        LookupEditorStore.DeleteRecords(requestData)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(deleteResult => this.ShowDeleteResult(deleteResult));
    }

    private ShowDeleteResult(deleteResult: any) {
        if (!deleteResult.IsSuccessfull) {
            var notifier = new Notifier(null);
            notifier.Warning(deleteResult.ErrorMessage);
        }
        this.GetDataForLookup();
    }

    private UpdateGrid(model: LookupModel, currentPage?: number, recordsPerPage?: number): LookupEditor {
        this._lookupTableModel = model;
        this._isAllRecordsSelected(false);
        this._gridModel(new LookupGridModel({
            Model: model,
            CurrentPage: currentPage,
            RecordsPerPage: recordsPerPage
        }));
        this._gridModel().SelectedRecordsPerPage(recordsPerPage);

        this._gridModel().One(EVENTS.ON_SELECT_CHANGED, this, (eventArgs: any) => {
            this.GetDataForLookup();
        }).One(EVENTS.ON_PAGE_CHANGED, this, (eventArgs: any) => {
            this.GetDataForLookup();
        });
        return this;
    }

    private AddRecord(newRecordModel: NewRecordModel) {
        BlockUI.Block();
        LookupEditorStore.AddRecord(newRecordModel)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(() => {
                this._newRecordScreen.Close();
                this._newRecordScreen = null;
                new Notifier().Success(NOTIFICATIONS.RECORD_SAVED);
                this.ShowGridForcibly(this._lookupTableModel);
            }).fail(error => this.ShowError(error));
    }

    private ShowSaveResult() {
        const notifier = new Notifier(null);
        notifier.Success(NOTIFICATIONS.ALL_RECORDS_SAVED);
        this.ShowGridForcibly(this._lookupTableModel);
    }

    private ShowError(error) {
        new Notifier().Failed(JSON.parse(error.message).Message);
    }

    private ShowWarning(warning) {
        if(warning) {
            new Notifier().Warning(warning);
        }
    }

    GetTemplateName(): string {
        return 'LookupEditor/Templates/Editor';
    }

    OnReady(target: string) {
        this._container = document.getElementById(target);
        this._searchTerm = ko.observable("");
        this._searchValue = ko.observable("");

        ko.cleanNode(this._container);
        ko.applyBindings(this, this._container);
    }

    AfterRender() {
        this._isReady(true);
    }
}