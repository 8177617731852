import * as ko from 'knockout';
import * as _ from 'underscore';

import { BaseGrid } from 'Core/Controls/Grid/BaseGrid/BaseGrid';
import { Paginator } from 'Core/Components/Paginator/Paginator';
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {GridStore, IGetGridDataRequestModel} from 'Core/Controls/Grid/Stores/GridStore';
import { BlockUI } from 'Core/Common/BlockUi';
import { EVENTS as PAGINATOR_EVENTS } from 'Core/Components/Paginator/Constants';
import { EVENTS as BASE_GRID_EVENTS } from 'Core/Controls/Grid/BaseGrid/Events';
import { ViewModes } from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import { GridDataModel } from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import { Serialize } from 'libs/cerialize';
import { SortModel } from 'Core/Controls/Grid/Models/SortModel';
import { Notifier } from 'Core/Common/Notifier';
import { LABELS } from 'Core/Components/Translation/Locales';
import {Toolbar} from "Core/Controls/Grid/Toolbar/Toolbar";
import {MobileChecker} from "Core/Common/MobileChecker";
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {EVENTS as TOOLBAR_EVENTS} from "Core/Controls/Grid/Toolbar/Constants";
import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';
import {PUB_SUB_EVENTS} from "MenuManager/PubSubEvents";

export class QueryResultGrid {
	
	private _baseGrid: KnockoutObservable<BaseGrid>;
	private _paginator: Paginator;
	private _sorting: Array<SortModel>;

	private _toolbar: Toolbar;
	private _userVarsManager: UserVarsManager;
	private _showTogglePrioritiesButton: boolean;
	private _isPrioritiesDisabled: boolean;
	private _screenTypes: string;

	constructor(
		private _model: QueryExpressionModel,
		private _recordId: number,
		private _recordPerPage: number,
		private _viewMode: ViewModes,
		private _isDesktop: boolean,
		private _filterByOwners: boolean,
		private _subjectEntityId: number,
		private _gridSubjectEntityId: number
	) {
		this._userVarsManager = UserVarsManager.Instance;
		this._screenTypes = ScreenTypes[ScreenTypes.None];
		this._showTogglePrioritiesButton = this._screenTypes !== ScreenTypes[ScreenTypes.Portlet];
		this._isPrioritiesDisabled = !this._showTogglePrioritiesButton;

		this._baseGrid = ko.observable(
			new BaseGrid({
				isEditable: ko.observable(true),
				isQueryResultGrid: true,
				screenType: this._screenTypes,
				isPrioritiesDisabled: this.GetGridPriorityToggle(),
				ToggleAllShownColumns: this.ToggleAllShownColums.bind(this)
			}));
		this._baseGrid().IsFastFilterEnabled = true;
		this._baseGrid().IsReady = false;

		this._toolbar = new Toolbar(
			false,
			false,
			false,
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			this._screenTypes,
			this.GetGridPriorityToggle(),
			this._showTogglePrioritiesButton,
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			false,
			false
		);
		this._toolbar.On(TOOLBAR_EVENTS.TOGGLE_PRIORITIES, this, (eventArgs: any) => {
			this.TogglePriorities(eventArgs.data.state)
		});

		this._paginator = this._toolbar.Paginator();
		this._paginator.RecordsPerPage = this._recordPerPage;

		this._paginator.On(PAGINATOR_EVENTS.CHANGE, this, () => { this.Refresh(); });
		this._baseGrid().On(BASE_GRID_EVENTS.SORT, this, (eventArgs: any) => {
			this._sorting = eventArgs.data.SortColumns;
			this.Refresh(true);
		});

		this._baseGrid().On(BASE_GRID_EVENTS.UPDATE_GRID, this, eventArgs => {
			if(eventArgs.data.updateFromFastFilters){
				if (this._paginator.PageNumber != 1) {
					this._paginator.Reset();
				} else {
					this.Refresh(false);
				}
			}
		 });
		 
		this._baseGrid().On(BASE_GRID_EVENTS.LOAD_SUB_GRID, this, (eventArgs)=>{
			this.Refresh(false, eventArgs.data.SubGrid, eventArgs.data.ParentRowId);
		});

		this._baseGrid().On(BASE_GRID_EVENTS.OPEN_HYPERLINK, this, eventArgs => this.OnHyperLink(eventArgs.data));
	}

	TogglePriorities(state) {
		!MobileChecker.IsMobile() && this._userVarsManager.SetGridPriority(this._subjectEntityId, this._gridSubjectEntityId, state);
		this._isPrioritiesDisabled = state;
		this.Refresh();
	}

	ToggleAllShownColums(canHide: boolean, canShow: boolean) {
		this._toolbar && this._toolbar.ToggleAllShownColums.call(this._toolbar, canHide, canShow);
	}

	GetGridPriorityToggle(): boolean {
		if (MobileChecker.IsMobile()) {
			return this._isPrioritiesDisabled;
		}
		return this._userVarsManager && this._userVarsManager.GetGridPriority(this._subjectEntityId, this._gridSubjectEntityId, this._isPrioritiesDisabled);
	}

	set FilterByOwners(value: boolean){
		this._filterByOwners = value;
	}

	private BlockUI() {
		BlockUI.Block();
	}

	private UnblockUI() {
		BlockUI.Unblock();
	}

	Refresh(restoreState: boolean = false,  subGrid?: BaseGrid, parentRowId?: string) {

		let pageNumber = subGrid ? subGrid.Paginator.PageNumber : this._toolbar.Paginator().PageNumber;

		let requestModel: IGetGridDataRequestModel = {
			RecordsPerPage: this._paginator.RecordsPerPage,
			PageNumber: pageNumber,
			Query: this._model,
			ControlId: 0,
			TableViewId: 0,
			ParentRowId: parentRowId,
			ShowPlanned: false,
			SearchPhrase: null,
			SubjectEntityId: 0,
			RecordOwners: [],
			Sort: this._sorting,
			ViewMode: this._viewMode,
			SubjectRecordId: this._recordId,
			SubjectTypeId: 0,
			SubjectKindId: 0,
			SubjectStatusId: 0,
			FilterByOwners: this._filterByOwners,
			SubQueryGuid: subGrid ? subGrid.Model.SubQueryGuid : null,
			FastFilters: this._baseGrid() ? this._baseGrid().FilterSaveModels.filter((filter) => filter.Values.length)
				.map((filter) => {
								filter.Values.forEach(value => {
									if (value.DisplayValue === LABELS.EMPTY_VALUE) {
										value.DisplayValue = null;
									}
								});
								return {
									FieldAlias: filter.FieldAlias,
									Values: filter.Values,
									TimeZone: (new Date().getTimezoneOffset()) / 60
								};
			}) : []
		};
		this.BlockUI();
		GridStore.GetData(requestModel)
			.always(() => {
				this.UnblockUI();
			})
			.then(data => {
				if(subGrid){
					subGrid.SetData(data, restoreState);
					subGrid.SetRecordsPerPage(this._toolbar.Paginator().RecordsPerPage);
				}else{
					this._baseGrid().SetGridDataModel(requestModel);
					this.SetGridData(data, restoreState);	
				};
			})
			.fail((error) => {
				const notifier = new Notifier(null);
				notifier.Failed(error.message);
			});
	}

	private SetGridData(gridData: GridDataModel, restoreState: boolean) {
		if (this._isDesktop) {
			_.each(gridData.Columns, (column) => {
				column.DisplayPriority = 0;
			});
			_.each(gridData.Rows, (row) => {
				_.each(row.Data, (dataCell) => {
					dataCell.DisplayPriority = 0;
				});
			});
		}

		this._paginator.TotalRecords = gridData.TotalRecords;
		this._baseGrid().SetData(gridData, restoreState, this.GetGridPriorityToggle());
	}

	set RecordId(value: number) {
		this._recordId = value;
	}

	private GoToScreen(linkInfo) {
        UserVarsManager.Instance.AddRecent(linkInfo.EntityId, linkInfo.RecordId, linkInfo.RecordTypeId);
        Lockr.set(LOCAL_STORAGE.HYPERLINK_SOURCE, linkInfo.EntityId);

        linkInfo.IsOpenInModal = true;
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, linkInfo);
    }

    private OnHyperLink(linkInfo) {
        this.GoToScreen(linkInfo);
    }
}