import * as ko from 'knockout';

import {ViewGridModel} from 'Core/Controls/Grid/Models/ViewGridModel';
import {JBoxDropDown} from 'Core/Components/JBoxDropdown/DropDown';
import {UserManager, UserRoles, UserTypes} from 'User/UserManager';
import {LABELS} from 'Core/Components/Translation/Locales';

import Template from 'Core/Components/QueryDropdowns/Templates/QueryDropdowns.html';
ko.templates['Core/Components/QueryDropdowns/Templates/QueryDropdowns'] = Template;

export class QueryDropdowns {
    _showElement: KnockoutObservable<boolean>;
    _showViewLookup: boolean;
    _showFunctionLookup: boolean;
    _view: KnockoutObservable<ViewGridModel>;
    _isEnabled: KnockoutObservable<boolean>;
    _viewList: KnockoutObservableArray<ViewGridModel>;
    _allowViewListUnset: boolean;
    _labels = LABELS;
    _isAddingRecordAllowed: KnockoutObservable<boolean>;
    _enableEditView: KnockoutObservable<boolean>;
    _enableDeleteView: KnockoutObservable<boolean>;
    _enableLockItem: KnockoutObservable<boolean>;
    _queryLocked: KnockoutObservable<boolean>;
    _withExportFeatures: boolean;
    _showLockItem: boolean;
    _exportDataAllowed: KnockoutObservable<boolean>;
    _id: string;

    private _newView: () => void;
    private _editView: () => void;
    private _copyView: () => void;
    private _deleteView: () => void;
    private _toggleLock: () => void;
    private _exportToCSV: () => void;
    private _exportToExcel: () => void;

    private _dropDown: JBoxDropDown;

    constructor(params: {
        showElement: KnockoutObservable<boolean>;
        showViewLookup: boolean;
        showFunctionLookup: boolean;
        view: KnockoutObservable<ViewGridModel>;
        isEnabled: KnockoutObservable<boolean>;
        viewList: KnockoutObservableArray<ViewGridModel>;
        allowViewListUnset: boolean;
        isAddingRecordAllowed: KnockoutObservable<boolean>;
        enableEditView: KnockoutObservable<boolean>;
        enableDeleteView: KnockoutObservable<boolean>;
        enableLockItem: KnockoutObservable<boolean>;
        withExportFeatures: boolean;
        showLockItem: boolean;
        queryLocked: KnockoutObservable<boolean>;
        exportDataAllowed: KnockoutObservable<boolean>;
        newView: () => void;
        editView: () => void;
        copyView: () => void;
        deleteView: () => void;
        toggleLock: () => void;
        exportToCSV: () => void;
        exportToExcel: () => void;
    }) {
        const {
            showElement = ko.observable(true),
            showViewLookup = true,
            showFunctionLookup = true,
            view,
            isEnabled = ko.observable(true),
            viewList,
            allowViewListUnset = false,
            isAddingRecordAllowed,
            enableEditView,
            enableDeleteView,
            enableLockItem,
            exportDataAllowed,
            withExportFeatures = true,
            showLockItem,
            queryLocked = ko.observable(false),
            newView,
            editView,
            copyView,
            deleteView,
            toggleLock,
            exportToCSV,
            exportToExcel
        } = params;

        this._showElement = showElement;
        this._showViewLookup = showViewLookup && UserManager.Instance.CurrentUser.UserType !== UserTypes.Gdpr;
        this._showFunctionLookup = showFunctionLookup && UserManager.Instance.CurrentUser.UserType !== UserTypes.Gdpr;
        this._view = view;
        this._isEnabled = isEnabled;
        this._viewList = viewList;
        this._allowViewListUnset = allowViewListUnset;
        this._isAddingRecordAllowed = isAddingRecordAllowed;
        this._enableEditView = enableEditView;
        this._enableDeleteView = enableDeleteView;
        this._enableLockItem = enableLockItem;
        this._withExportFeatures = withExportFeatures;
        this._showLockItem = showLockItem;
        this._queryLocked = queryLocked;
        this._exportDataAllowed = exportDataAllowed;
        this._newView = newView;
        this._editView = editView;
        this._copyView = copyView;
        this._deleteView = deleteView;
        this._toggleLock = toggleLock;
        this._exportToCSV = exportToCSV;
        this._exportToExcel = exportToExcel;

        this._id = JBoxDropDown.GetDropDownId();
    }

    get ShowViewLookup() {
        return this._showViewLookup && (!this._queryLocked() || UserManager.Instance.IsUserInRole(UserRoles.SuperUser));
    }

    get LockItemText() {
        if (this._queryLocked()) {
            return this._labels.UNLOCK_TABLEVIEW;
        }

        return this._labels.LOCK_TABLEVIEW;
    }

    get IsAddingRecordAllowed() {
        return this._isAddingRecordAllowed() && (!this._queryLocked() || UserManager.Instance.IsUserInRole(UserRoles.SuperUser));
    }

    get EnableEditView() {
        return this._enableEditView() && (!this._queryLocked() || UserManager.Instance.IsUserInRole(UserRoles.SuperUser));
    }

    get EnableDeleteView() {
        return this._enableDeleteView() && (!this._queryLocked() || UserManager.Instance.IsUserInRole(UserRoles.SuperUser));
    }

    koDescendantsComplete(el: HTMLElement) {
        this.Init(el);
    }

    Init(el: HTMLElement){
        if (!this._showFunctionLookup) return;
        const target = '.' + this._id;
        let contentAdded = false;
        this._dropDown = new JBoxDropDown({
            bindTarget: el,
            bindComponent: this,
            target: target,
            onOpen: () => {
                if (contentAdded) return;
                contentAdded = true;
                this._dropDown.SetContent();
            },
            otherOptions: {
                addClass: 'lookup-dropdown',
                closeOnClick: 'body',
                position: {
                    x: 'left',
                    y: 'bottom'
                },
                offset: {
                    x: 15
                }
            }
        });
    }

    NewView() {
        if (this.IsAddingRecordAllowed) {
            this._dropDown.Close();
            this._newView();
        }
    }

    EditView() {
        if (this.EnableEditView) {
            this._dropDown.Close();
            this._editView();
        }
    }

    CopyView() {
        if (this.EnableEditView) {
            this._dropDown.Close();
            this._copyView();
        }
    }

    DeleteView() {
        if (this.EnableDeleteView) {
            this._dropDown.Close();
            this._deleteView();
        }
    }

    ToggleLock() {
        this._toggleLock();
    }

    ExportToCSV() {
        if (this._exportDataAllowed()) {
            this._dropDown.Close();
            this._exportToCSV();
        }
    }

    ExportToExcel() {
        if (this._exportDataAllowed()) {
            this._dropDown.Close();
            this._exportToExcel();
        }
    }

    GetTemplateName(){
        return 'Core/Components/QueryDropdowns/Templates/QueryDropdowns'
    }

    AfterRender(el: Array<HTMLElement>){
        this.Init(el[0]);
    }
}

ko.components.register('query-dropdowns', {
    viewModel: QueryDropdowns,
    template: Template
});