import * as ko from "knockout";

import {JBoxDropDown} from 'Core/Components/JBoxDropdown/DropDown';
import {BaseGrid} from "Core/Controls/Grid/BaseGrid/BaseGrid";
import {EVENTS as GRID_EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import {Icon} from "Core/Icon/Icon";
import {Event} from "Core/Common/Event";
import {EVENTS} from "Core/Controls/Grid/BaseGrid/QueryResultPage/Events";
import {PivotDetailModel} from "Core/Controls/Grid/Models/PivotDetailModel";
import {FONT_NAME} from "Core/Constant";
import {LABELS} from "Core/Components/Translation/Locales";
import {ZIndexManager} from 'Core/Common/ZIndexManager';

import Template from 'Core/Controls/Grid/BaseGrid/PivotDetaillsDropdown/Templates/PivotDetailsDropdown.html';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';
import { ScreenTypes } from "Core/Common/Enums/ScreenTypes";
import { GridRow } from "Core/Controls/Grid/BaseGrid/GridRow/GridRow";
ko.templates['Core/Controls/Grid/BaseGrid/PivotDetaillsDropdown/Templates/PivotDetailsDropdown'] = Template;

export class PivotDetailsDropdown extends Event {
    private readonly _id: string;
    private readonly _grid: KnockoutObservable<BaseGrid>;
    private _dropDown: JBoxDropDown;
    private _leftRecordName: KnockoutObservable<string>;
    private _rightRecordName: KnockoutObservable<string>;
    private _leftEntityIcon: KnockoutObservable<Icon>;
    private _rightEntityIcon: KnockoutObservable<Icon>;
    private _relationType: KnockoutObservable<string>;
    private _labels = LABELS;

    constructor(){
        super();
        this._id = JBoxDropDown.GetDropDownId();
        this._grid = ko.observable(null);
        this._leftRecordName = ko.observable(null);
        this._rightRecordName = ko.observable(null);
        this._leftEntityIcon = ko.observable(null);
        this._rightEntityIcon = ko.observable(null);
        this._relationType = ko.observable(null);
        this.AddEvent(EVENTS.RECORD_SELECTED);
        this.AddEvent(GRID_EVENTS.REFRESH);
        this.AddEvent(GRID_EVENTS.EDIT_CLUSTERED_LINK);
    }

    Close(){
        if(this._dropDown){
            this._dropDown.Close();
        }
    }

    SetData(pivotDetails: PivotDetailModel, editLink: boolean = false){
        this._leftRecordName(pivotDetails.LeftRecordName);
        this._rightRecordName(pivotDetails.RightRecordName);
        this._relationType(pivotDetails.RelationType);

        if(pivotDetails.LeftEntityIcon){
            this._leftEntityIcon(new Icon(pivotDetails.LeftEntityIcon));
        }

        if(pivotDetails.RightEntityIcon){
            this._rightEntityIcon(new Icon(pivotDetails.RightEntityIcon));
        }

        const grid = new BaseGrid({
            isPrioritiesDisabled: true,
            isQueryResultGrid: true,
            isEditable: ko.observable(true),
            screenType: ScreenTypes[ScreenTypes.ConsultScreen],
            enabledFavorite: false,
            isEnableSelectRecord: false,
            enableSelectRowCell: true,
        });

        grid.On(GRID_EVENTS.SELECT_RECORD, this,(eventArgs: any) => {
            this.Trigger(EVENTS.RECORD_SELECTED, { SelectedRecord: eventArgs.data.Row });
        });

        grid.On(GRID_EVENTS.REFRESH, this, () => {
            this.Trigger(GRID_EVENTS.REFRESH);
        });

        grid.On(GRID_EVENTS.EDIT_LINK, this, (eventArgs) =>{
            this.Trigger(GRID_EVENTS.EDIT_CLUSTERED_LINK, { gridRow: eventArgs.data.gridRow, grid: grid });
        });

        this._grid(grid);
        this._grid().SetData(pivotDetails.Data, undefined, true);

        if(grid.Rows.length == 1 && editLink){
            this.Trigger(GRID_EVENTS.EDIT_CLUSTERED_LINK, { gridRow: grid.Rows[0], grid: grid });
        }
    }

    get Rows(): Array<GridRow>{
        return this._grid().Rows;
    }

    Show(target: any){
        this._dropDown = new JBoxDropDown({
            target: target || "." + this._id,
            otherOptions: {
                attach: undefined,
                closeOnClick: 'body',
                position: {
                    x: "left",
                    y: "bottom"
                },
                onCloseComplete: () => this._dropDown.Destroy(),
                zIndex: ZIndexManager.Instance.NextValue,
                blockScroll: true,
                addClass: 'pivotDetailsJBox',
                maxWidth: "calc(100%)"
            },
            onOpen: () => {
                this._dropDown.SetContent({ content: Template as any });
            },
            bindComponent: this,
        });

        this._dropDown.Open();
    }

    private GetIconClasses(fontName, name):string {
        fontName = fontName.replace('fa-stack', '');
        let classes: string = '';
        if (fontName === FONT_NAME.FONT_AWESOME) {
            classes = `${classes} fa`;
        } else if (fontName === FONT_NAME.SOCIAL_ICONS) {
            classes = `${classes} socicon-btn`;
        } else {
            classes = fontName;
        }
        classes = `${classes} ${name} image-icon-xs`;

        return classes;
    }

    @enumerable get Id(){
        return this._id;
    }
}