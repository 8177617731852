import * as ko from 'knockout';
import * as _ from 'underscore';
import * as moment from 'moment';
import * as $ from "jquery";

import {FlagResolver} from "Core/Common/FlagResolver";
import {FieldFlags} from "Core/Common/Enums/FieldFlags";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";

import {
    ConvertedCurrencyModel,
    CurrencyValueModel,
    GridCellValueModel,
    RelationTypeModel, SpimValueModel
} from 'Core/Controls/Grid/Models/GridDataModel/GridCellValueModel';
import {
    FieldMetadataModel,
    extractLookupValFieldMetadata
} from 'Core/Controls/Grid/Models/GridDataModel/FieldMetadataModel';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {Event} from 'Core/Common/Event';
import {IControl} from 'Core/Controls/IControl';
import {IControlParam} from 'Core/Screens/IScreen';
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {
    FIELD_TYPE_TO_CONTROL,
    FIELD_TYPES,
    LABEL_POSITIONS,
    CONTROL_TYPES,
    FONT_NAME
} from 'Core/Constant';
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel';
import {ReferenceModel} from 'Core/ScreenManager/Models/ReferenceModel';
import {RenderModes, SYSTEM_FIELD_NAMES} from 'Core/Constant';
import {DISPLAY_PRIORITY_TO_CSS} from 'Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn';
import {DocumentManager} from 'Core/Components/Controls/DocumentManager/DocumentManager';
import {DocumentDataModel} from 'Core/Controls/Document/DocumentDataModel';
import {EVENTS as DOCUMENT_MANAGER_EVENTS} from 'Core/Components/Controls/DocumentManager/Events';
import {FormatConverter} from 'FormatEditor/FormatConverter';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {NOTIFICATIONS, LABELS, CONFIRMATIONS} from "Core/Components/Translation/Locales";
import {FormatManager, FormatsEnum} from 'Core/Components/FormatManager/FormatManager';
import {IForm} from "Core/Screens/IScreen";
import {BlockUI} from "Core/Common/BlockUi";
import {Icon} from "Core/Icon/Icon";
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {Dropdown} from "Core/Controls/Dropdown/Dropdown";
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {EntityTypes} from "../Enums/EntityTypes";
import {SortOrder} from 'Core/Controls/Grid/BaseGrid/GridColumn/BaseColumn';
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {IntlTelInputExtention} from "Core/KnockoutExtentions/IntlTelInputExtention";
import {MultiSelect} from 'Core/Controls/MultiSelect/MultiSelect';
import {EVENTS as QUERY_RESULT_GRID_EVENTS} from "Core/Controls/Grid/BaseGrid/QueryResultPage/Events";
import {GridStore} from "Core/Controls/Grid/Stores/GridStore";
import {MemoValueModal} from "Core/Controls/Grid/BaseGrid/GridCell/MemoValueModal";
import {Util} from "QueryBuilder/Util";
import {PivotDetailsDropdown} from "Core/Controls/Grid/BaseGrid/PivotDetaillsDropdown/PivotDetailsDropdown";
import {GridRowModel} from "Core/Controls/Grid/Models/GridDataModel/GridRowModel";
import {ITooltipConfig} from "Core/KnockoutExtentions/TooltipExtention";
import {FieldDataModel} from "Core/Screens/Common/LinkEditor/Models/DataModel";
import {CONTROL_TYPES_LINK} from 'Core/Controls/Text/Constants';
import {ColorConverter} from "Core/Components/ColorSelector/ColorConverter";
import {FieldFormat, FORMATS} from "Core/Common/FieldFormat";
import {Notifier} from 'Core/Common/Notifier';
import 'Core/Controls/Grid/BaseGrid/GridCell/DataCellTemplates';
import { MailStore } from 'Core/Controls/Mail/Stores/MailStore'
import { MailTabModel } from 'Core/Controls/Mail/Models/MailTabModel'
import {
    FormatManagerStore,
    ICreateEmlMessageRequestModel
} from 'Core/Components/FormatManager/Stores/FormatManagerStore';
import { ComposeMail } from 'Core/Controls/Mail/ComposeMail';
import { JBoxDropDown } from 'Core/Components/JBoxDropdown/DropDown';
import { QueryColumnModel } from '../../Models/GridDataModel/QueryExpression/QueryColumnModel';

(moment as any).createFromInputFallback = config => {
    config._d = new Date(config._i);
};

export interface IDataCellParams {
    Model: GridCellValueModel,
    ScreenType: string,
    MaxRowHeight: string,
    IsNewRecord: boolean,
    Form: IForm,
    QueryExpression: QueryExpressionModel
}

export class DataCell extends Event {
    private _model: GridCellValueModel;
    private _isEnableEdit: KnockoutObservable<boolean>;
    private _isHyperLink: boolean;
    private _valueChanged: KnockoutObservable<boolean>;
    private _editValue: KnockoutObservable<string>;
    private _isEditMode: KnockoutObservable<boolean>;
    private _editor: KnockoutObservable<IControl>;
    private _size: KnockoutObservable<any>;
    private _height: KnockoutObservable<number>;
    private _documentManager: DocumentManager;
    private _screenType: string;
    private _maxRowHeight: string;
    private _isNewRecord: boolean;
    private _formatManager: FormatManager;
    private _notifications: NOTIFICATIONS;
    private _valueExists: KnockoutObservable<boolean>;
    private _form: IForm;
    private _reference: KnockoutObservable<ReferenceModel>;
    private _referenceIcon: KnockoutObservable<Icon>;
    private _referenceEntityName: KnockoutObservable<string>;
    private _viewValue: KnockoutObservable<string>;
    private _showClusteredArrow: boolean;
    private _queryExpression: QueryExpressionModel;
    private _globalCountry: string;
    private GetTemplateName: KnockoutComputed<string>;
    private _icon: KnockoutObservable<Icon>;
    private _pivotDetailsDropDown: PivotDetailsDropdown;
    private _currencyValue: CurrencyValueModel;
    private _showOriginalMemoButton: KnockoutObservable<boolean>;
    private _recordNotFound: KnockoutObservable<boolean>;
    private Tooltip: KnockoutObservable<ITooltipConfig>;
    private _el: HTMLElement;
    private _mails: KnockoutObservableArray<MailTabModel>;
    private _hasMails: KnockoutComputed<boolean>;
    private _showMails: KnockoutComputed<boolean>;
    private _dropDown: JBoxDropDown;
    private _id: string;
    private _labels: LABELS;
    private _hasDescription: boolean;

    constructor(params: IDataCellParams) {
        super();
        this._showClusteredArrow = params.QueryExpression.IsCrossTable;
        this._queryExpression = params.QueryExpression;
        this._screenType = params.ScreenType;
        this._isNewRecord = params.IsNewRecord;
        this._isHyperLink = params.Model.IsHyperLink;
        this._model = params.Model;
        this._isEditMode = ko.observable(false).extend({ rateLimit: 1 });
        this._valueChanged = ko.observable(false);
        this._editValue = ko.observable('');
        this._editor = ko.observable(null);
        this._size = ko.observable({width: '500px', height: '0px'});
        this._height = ko.observable(0);
        this.InitEvents();
        this._model.MaxRowHeight = params.MaxRowHeight;
        this._maxRowHeight = params.MaxRowHeight;
        this._formatManager = null;
        this._notifications = NOTIFICATIONS;
        this._valueExists = ko.observable(Boolean(this._model.TranslatedValue || this._model.DisplayValue));
        this._form = params.Form;
        this._referenceIcon = ko.observable(null);
        this._referenceEntityName = ko.observable(null);
        this._viewValue = ko.observable(null);
        this._icon = ko.observable(null);
        this._pivotDetailsDropDown = new PivotDetailsDropdown();
        this._showOriginalMemoButton = ko.observable(!this._isNewRecord);
        this._recordNotFound = ko.observable(false);
        this._id = JBoxDropDown.GetDropDownId();
        this._labels = LABELS;
        this._hasDescription = params.Model.HasDescription;

        if(this.Model.FieldMetadata){
            this.Tooltip = ko.observable<ITooltipConfig>({
                content: this.TooltipContent(_.escape(this.TranslateValue), this.Model.FieldMetadata.Type.toString(), this.Model.FieldMetadata.FormatName.toString()),
                position: { x: 'right', y: 'center' },
                outside: 'x',
                addClass: 'ellipsis-tooltip'
            });
        }

        if (this._model.FieldMetadata && this._model.FieldMetadata.FormatName === FieldFormat.MAIL) {
            this.ApplyMailProperties();
        }

        this._pivotDetailsDropDown.On(EVENTS.REFRESH, this, () => {
            this.Trigger(EVENTS.REFRESH);
        });

        this._pivotDetailsDropDown.On(EVENTS.EDIT_CLUSTERED_LINK, this, (eventArgs) => {
            this.Trigger(EVENTS.EDIT_CLUSTERED_LINK, { gridRow: eventArgs.data.gridRow, grid: eventArgs.data.grid });
        });

        this._pivotDetailsDropDown.On(QUERY_RESULT_GRID_EVENTS.RECORD_SELECTED, this, (eventArgs) => {
            if (eventArgs.data.SelectedRecord.Model) {
                let row = eventArgs.data.SelectedRecord.Model as GridRowModel;
                let clusteredCell = _.last(row.Data);

                this._pivotDetailsDropDown.Close();
                const confirmationDialog = new ConfirmationDialog({
                    Text: CONFIRMATIONS.LINK_RECORD_WITH_NEXT_RELATION_TYPE,
                    Type: ConfirmationTypes.Question
                });

                confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                    this.Trigger(EVENTS.LINK_NEXT_RELATION, { field: clusteredCell});
                });

                confirmationDialog.Show();
            }
        });

        if (this._model.Type === FIELD_TYPES.Reference) {
            this.SetReferenceData(this._model.Reference);
        }

        if (this._model.Type === FIELD_TYPES.Spim && (!this._model.Spim || !this._model.Spim.FeatureTypeIsSet)) {
            this._model.IsEnableEdit = false;
        }

        this._isEditMode.subscribe(async (editMode: boolean) => {
            if (editMode) {
                let editor = await this.GetDataEditor();
                this._editor(editor);

                if (editor && editor.GetType() === CONTROL_TYPES.Memo) {

                    if (this._isNewRecord) {
                        this._editValue(this._model.Value);
                        const controlDataModel = new ControlDataModel();
                        controlDataModel.Value = this._model.Value;
                        controlDataModel.DisplayValue = this._model.OriginalDisplayValue;
                        controlDataModel.Translations = this._model.Translations;
                        editor.SetValue({
                            SubjectEntityId: null,
                            SubjectRecordId: -1,
                            Data: controlDataModel,
                            RecordSpecsModel: null
                        });
                    } else {
                        GridStore.GetMemoValue(
                            {
                                EntityId: this.Model.EntityMetadata.Id,
                                FieldId: this.Model.FieldMetadata.Id,
                                RecordKeys: this.Model.RecordKeys
                            }).then((data) => {
                            this._editValue(data.Value);
                            const controlDataModel = new ControlDataModel();
                            controlDataModel.Value = data.Value;
                            controlDataModel.DisplayValue = data.DisplayValue;
                            controlDataModel.Translations = data.Translations;
                            editor.SetValue({
                                SubjectEntityId: null,
                                SubjectRecordId: -1,
                                Data: controlDataModel,
                                RecordSpecsModel: null
                            });
                        });
                    }

                } else {
                    this._editValue(this._model.Value);
                    const controlDataModel = new ControlDataModel();
                    controlDataModel.Value = this._model.Currency || this._model.Spim || this._model.Value;
                    controlDataModel.DisplayValue = this._model.OriginalDisplayValue;
                    controlDataModel.Translations = this._model.Translations;
                    editor.SetValue({
                        SubjectEntityId: null,
                        SubjectRecordId: -1,
                        Data: controlDataModel,
                        RecordSpecsModel: null
                    });
                }

                if (this._editor() && this._editor().GetType() === CONTROL_TYPES.Memo) {
                    this._editor().On('AFTER_RENDER',
                        this,
                        (eventArgs: any) => {
                            this._height($(this._editor().GetWrapper()).height());
                        });
                }

                if (this._editor() && this._editor().GetType() === CONTROL_TYPES.Dropdown) {
                    this._editor().On(EVENTS.DROPDOWN_VALUE_SELECTED,
                        this,
                        (eventArgs: any) => {
                            const editor = this._editor() as Dropdown;
                            const fieldId = editor.GetFieldModel().Id;
                            this.Trigger(EVENTS.DROPDOWN_VALUE_SELECTED, {
                                recordId: eventArgs.data.recordId,
                                fieldId: fieldId
                            });
                        });
                }

            }
        });

        _.each(this._model.Details, (detail) => {
            detail.FieldMetadata = this._model.FieldMetadata;
            detail.EntityMetadata = this._model.EntityMetadata;
        });

        this.InitPhoneCountry();
        this.Init();
    }

    private InitEvents(){
        this.AddEvent(EVENTS.REFRESH);
        this.AddEvent(EVENTS.LINK_NEXT_RELATION);
        this.AddEvent(EVENTS.DROPDOWN_VALUE_SELECTED);
        this.AddEvent(EVENTS.MOVE_ROW_DOWN);
        this.AddEvent(EVENTS.MOVE_ROW_UP);
        this.AddEvent(EVENTS.HOVER_SORT);
        this.AddEvent(EVENTS.UN_HOVER_SORT);
        this.AddEvent(EVENTS.SHOW_ORIGINAL_IMAGE);
        this.AddEvent(EVENTS.EDIT_CLUSTERED_LINK);
        this.AddEvent(EVENTS.PRINT_LABEL);
        this.AddEvent(EVENTS.SAVE_RECORD);
        this.AddEvent(EVENTS.CREATE_OR_DELETE_SORT_DESCRIPTION);
    }

    private InitPhoneCountry() {
        const global = GlobalManager.Instance.GetGlobal(GLOBALS.DEFAULT_COUNTRY_CODE).toLowerCase();
        if (IntlTelInputExtention.IsCountryValid(global)) {
            this._globalCountry = global;
        } else {
            this._globalCountry = 'nl';
        }
    }

    private SetReferenceData(reference) {
        this._reference = ko.observable(reference);
        if (this._reference()) {
            const entityRecord = this._reference().SelectedEntityRecord;
            const referenceTable = this._reference().ReferenceTable;

            if (entityRecord && !entityRecord.SelectedEntity) {
                this.SetInvalidIcon();
                this.ViewValue = NOTIFICATIONS.TABLE_NOT_FOUND;
                return;
            } else if (entityRecord && !entityRecord.SelectedRecord) {
                this.SetInvalidIcon();
                this.ViewValue = NOTIFICATIONS.RECORD_NOT_FOUND;
                this._recordNotFound = ko.observable(true);
                return;
            } else {
                this.ViewValue = reference.TranslatedName || reference.Name;

                if (referenceTable && referenceTable.IsValid) {
                    this.SetInvalidIcon();
                    this.ViewValue = LABELS.NO_RECORD;
                    return;
                }

                if (referenceTable && !referenceTable.IsValid) {
                    this.SetInvalidIcon();
                    this.ViewValue = NOTIFICATIONS.INVALID_REFERENCE_TABLE;
                    return;
                }

                if (entityRecord && entityRecord.IsValid) {
                    const icon = entityRecord.SelectedEntity.Icon;
                    if (icon) {
                        this._referenceIcon(new Icon(icon));
                    }

                    const memo = entityRecord.SelectedRecord.MemoTranslation || entityRecord.SelectedRecord.Memo;
                    const entityName = entityRecord.SelectedEntity.Translation || entityRecord.SelectedEntity.Name;
                    this.ViewValue = entityName;
                    this._referenceEntityName(entityName);

                    if (entityRecord.SelectedRecord.Name) {
                        this.ViewValue = entityRecord.SelectedRecord.Name;
                    } else {
                        this._referenceIcon(null);
                        this.ViewValue = null;
                    }
                }
            }
        } else {
            this.ViewValue = NOTIFICATIONS.REFERENCE_TABLE_NOT_SET;
            this.SetInvalidIcon();
        }
    }

    private get IsAbleSort(): boolean {
        return this.Model.SortDirection !== SortOrder.Both && (this._screenType === ScreenTypes[ScreenTypes.ConsultScreen] || this._screenType === ScreenTypes[ScreenTypes.EditScreen]);
    }

    private get IsNotAbleSortMessage(): string {
        if (this._screenType !== ScreenTypes[ScreenTypes.ConsultScreen] && this._screenType !== ScreenTypes[ScreenTypes.EditScreen]) {
            return NOTIFICATIONS.NOT_APPLICABLE_FOR_LIST_SCREEN;
        }

        if (this._screenType === ScreenTypes[ScreenTypes.ConsultScreen] || this._screenType === ScreenTypes[ScreenTypes.EditScreen]) {
            if (this.Model.SortDirection === SortOrder.Both) {
                return NOTIFICATIONS.SORT_BY_COLUMN_TO_ENABLE_SORTING;
            }
        }

        return null;
    }

    private SetInvalidIcon() {
        let iconModel = new IconModel();
        iconModel.IsImage = false;
        iconModel.IsIcon = true;
        iconModel.FontName = FONT_NAME.FONT_AWESOME;
        iconModel.Name = 'fa-times';

        this._referenceIcon(new Icon(iconModel));
    }

    private SetLinkIcon() {
        let iconModel = new IconModel();
        iconModel.IsImage = false;
        iconModel.IsIcon = true;
        iconModel.FontName = FONT_NAME.FONT_AWESOME;
        iconModel.Name = 'fa-external-link';

        this._referenceIcon(new Icon(iconModel));
    }

    async GetDataEditor(): Promise<IControl> {
        const attachedFieldModel = new AttachedFieldModel();
        attachedFieldModel.FormatName = this._model.FieldMetadata.FormatName;
        attachedFieldModel.Id = this._model.FieldMetadata.Id;
        attachedFieldModel.Name = this._model.FieldMetadata.Name;
        attachedFieldModel.AllowCustomValue = this._model.FieldMetadata.AllowCustomValue;
        attachedFieldModel.AllowCreatingRecords = this._model.FieldMetadata.AllowCreatingRecords;
        attachedFieldModel.EntityId = this._model.EntityMetadata.Id;
        attachedFieldModel.ValTableId = this._model.FieldMetadata.ValTableId;
        attachedFieldModel.ValTableName = this._model.FieldMetadata.ValTableName;
        attachedFieldModel.IsReadOnly = this._model.FieldMetadata.IsReadOnly;
        attachedFieldModel.IsRequired = this._model.FieldMetadata.IsRequired;
		attachedFieldModel.IsSystem = this.IsFieldSystem();
        attachedFieldModel.FieldTypeName = this._model.FieldMetadata.Type;
        attachedFieldModel.Size = this._model.FieldMetadata.Size;
        attachedFieldModel.AllowInsert = this.IsInsertAllowed();
        attachedFieldModel.ValTableType = this._model.FieldMetadata.ValTableType;
        attachedFieldModel.ValFieldId = this._model.FieldMetadata.ValFieldId;

        const validationFieldMetadata = extractLookupValFieldMetadata(this._model.FieldMetadata);
        if (validationFieldMetadata) {
            attachedFieldModel.ValFieldTypeName = validationFieldMetadata.Type;
            attachedFieldModel.ValFieldFormatName = validationFieldMetadata.FormatName;
            attachedFieldModel.ValFieldSize = validationFieldMetadata.Size;
        }

        const controlModel = new ControlModel();
		controlModel.IsReadOnly = this.IsControlReadOnly();
        controlModel.IsRequired = this._model.FieldMetadata.IsRequired;
        controlModel.LabelPosition = LABEL_POSITIONS.NoLabel;
        controlModel.TypeName = FIELD_TYPE_TO_CONTROL[this._model.FieldMetadata.Type];
        controlModel.Fields.push(attachedFieldModel);
        controlModel.FieldFlag = this._model.Flags;

        let renderMode = RenderModes.Edit;
        if( attachedFieldModel.Name === SYSTEM_FIELD_NAMES.F_TYPE && this._model.Value !== '0' &&  this._model.Value){
            renderMode = RenderModes.View;
        }
        const params: IControlParam = {
            Model: controlModel,
            Form: this._form,
            RenderMode: renderMode
        };

        const controlFactory = (await import('Core/Controls/ControlFactory')).ControlFactory;
        const control = controlFactory.CreateControl(params);


        if(controlModel.TypeName === CONTROL_TYPES.Dropdown && this._model.RecordKeys.length === 0){
            (control as Dropdown).AutoSelectIfOneRecord = true;
        }

        return control as IControl;
    }

    IsDataChanged() {
        if (this._isEnableEdit()) {
            if (this._editor && this._editor().IsModified()) {
                return true;
            }
        }
        return false;
    }

    IsDataValid() {
        if (this._isEnableEdit()) {
            if (this._editor()) {
                if (!this._editor().IsValid()) {
                    return false;
                }
            }
        }
        return true;
    }

    GetContrast() {
        return ColorConverter.GetContrast(this.ViewValue);
    }

    IsEnableEdit(): boolean {
        return this._model.IsEnableEdit;
    }

    IsReadOnly() {
        if (this._isEnableEdit()) {
            if (this._editor()) {
                return this._editor().GetIsReadOnly();
            }
        }
        return true;
    }

    private IsControlReadOnly() {
		if ((this._model.FieldMetadata.Name === SYSTEM_FIELD_NAMES.F_TYPE || this._model.FieldMetadata.Name === SYSTEM_FIELD_NAMES.F_KIND)
			&& (this._isNewRecord || this.Value === "0")) {
		    return false;
		}

		return this._model.FieldMetadata.IsReadOnly || this._model.FieldMetadata.IsSystem;
    }

    private IsFieldSystem() {
		if ((this._model.FieldMetadata.Name === SYSTEM_FIELD_NAMES.F_TYPE || this._model.FieldMetadata.Name === SYSTEM_FIELD_NAMES.F_KIND)
			&& (this._isNewRecord || this.Value === "0")) {
		    return false;
		}

	    return this._model.FieldMetadata.IsSystem;
    }

	private IsInsertAllowed() {
		if (this._model.FieldMetadata.Name === SYSTEM_FIELD_NAMES.F_TYPE ||
			this._model.FieldMetadata.Name === SYSTEM_FIELD_NAMES.F_KIND) {
			return false;
		}

		return this._model.FieldMetadata.AllowInsert;
	}

    private ApplyMailProperties() {
        this._mails = ko.observableArray<MailTabModel>();
        this._hasMails = ko.computed(() => {
            return this._mails().length > 0;
        }, this);
        this._showMails = ko.computed(() => {
            return this._mails().length > 1;
        }, this);
    }

    private LoadTabs() {
        let TryOpenComposer = () => {
            if (!this._showMails()) {
                this.MailClick(this._mails()[0]);
            } else {
                this.OpenDropDown()
            }
        };

        if (this._hasMails()) {
            TryOpenComposer();
            return;
        }

        BlockUI.Block();
        MailStore.GetMailTabs()
            .then((tabs: any) => {
                //not issue something wrong with model
                _.map(tabs, (tab: any) => {
                    let mail = new MailTabModel();
                    mail.Login = tab.Login;
                    mail.MailConnectionId = tab.MailConnectionId;
                    mail.Client = tab.Client;
                    this._mails.push(mail);
                });

                if (!this._hasMails()) {
                    new Notifier().Failed(NOTIFICATIONS.PLEASE_ADD_YOUR_EMAIL);
                } else {
                    TryOpenComposer();
                }
            })
            .always(() => BlockUI.Unblock())
            .fail((err) => new Notifier().Failed(err.message));
    }

    private OpenDropDown() {
        this._dropDown = new JBoxDropDown({
            target: "." + this._id,
            otherOptions: {
                attach: undefined,
                closeOnClick: true,
                addClass: "mails-dropdown",
                onCloseComplete: () => this._dropDown.Destroy()
            },
            onOpen: () => {
                this._dropDown.SetContent({ content: $("." + this._id).next().data("jbox-content") });
            },
            bindComponent: this,
        });

        this._dropDown.Open();
    }

    private MailClick(mail: MailTabModel) {
        if (!!this._model.Value) {
            if (mail.Client === 'Desktop') {
                this.OpenDesktopClient(mail);
            } else {
                this.OpenInternalClient(mail);
            }

        } else {
            new Notifier().Failed(NOTIFICATIONS.PLEASE_ADD_EMAIL);
        }
    }

    private OpenDesktopClient(mail: MailTabModel) {
        var requestModel: ICreateEmlMessageRequestModel = {
            FromAddress: mail.Login,
            ToAddresses: [this._model.Value]
        }
        BlockUI.Block();
        FormatManagerStore.CreateEmlMessage(requestModel).fail((err) => {
            new Notifier().Failed(err.message);
        }).always(() => {
            BlockUI.Unblock();
        });
    }

    private OpenInternalClient(mail: MailTabModel) {
        let composeMail = new ComposeMail();
        composeMail.AddMailWithRecord(this._model.Value, this._model.FieldMetadata.ValTableId, this._model.RecordKeys[0].RecordId);
        composeMail.ShowInModal(mail.MailConnectionId);
    }


    private Init() {

        if (this._model.Icon) {
            this._icon(new Icon(this._model.Icon));
        }

        this._isEnableEdit = ko.observable(this._model.IsEnableEdit);
        this.AddEvent(EVENTS.OPEN_HYPERLINK);

        if(this._model.FieldMetadata){
            const notEnabledMail = FormatsEnum[this._model.FieldMetadata.FormatName] === FormatsEnum.Mail && !this._model.IsEnableMail;

            if (FormatsEnum.GetFormat(this._model.FieldMetadata.FormatName) && !notEnabledMail) {
                this._formatManager = new FormatManager({
                    Screen: this._form?.GetScreen(),
                    Icon: this.GetIconName(),
                    Value: this._model.Value,
                    Format: this._model.FieldMetadata.FormatName,
                    TableId: 0,
                    RecordId: -1,
                    IsGrid: true
                });
            }
        }

        this.TranslateData();
        this.InitTemplate();
    }

    set IsEditMode(value: boolean) {
        if (this._isEnableEdit()) {
            this._isEditMode(value);
        }
    }

    get IsEditMode(): boolean{
        return this._isEditMode();
    }

    get ViewValue(): string {
        return this._model.TranslatedValue || this._model.DisplayValue;
    }

    set ViewValue(value: string) {
        this._model.DisplayValue = value;
        this._viewValue(value);
    }

    get RecordNotFound(): boolean {
        return this._recordNotFound();
    }

    GetViewValue(): KnockoutObservable<string> {
        this._viewValue(this.ViewValue);
        let fieldModelTypeName = this._model.FieldMetadata.Type;
        if (
            !!this.ViewValue &&
            _.contains([FIELD_TYPES.Integer, FIELD_TYPES.Decimal, FIELD_TYPES.Currency], fieldModelTypeName)
        ) {
            this._viewValue(FormatConverter.LocalizeDecimalOrInteger(this.ViewValue));
        } else if (this.Value == null && fieldModelTypeName === FIELD_TYPES.Radio) {
            this._viewValue(LABELS.EMPTY_VALUE);
        }
        return this._viewValue;
    }

    get FormatName(){
        return !!this._viewValue() && this._model.FieldMetadata.FormatName;
    }

    GetPhoneViewValue() {

        if (this._model.Value) {
            let phone = this._model.Value;

            if (phone.indexOf('+') > -1) {
                phone = phone.replace('+', '');
            }

            if (phone.indexOf('(') > -1) {
                //only for old ua phones (can be removed in future)
                return '+' + phone.replace(/\D/g, '');
            } else if (phone.indexOf('-') > -1) {
                return '+' + phone.replace('-', '');
            } else {
                return '+' + phone.replace(/\D/g, '');
            }
        }

        return this._model.Value;
    }

    get EditValue(): any {
        return this._editor().GetValue();
    }

    get EntityId(): number {
        return this._model.EntityMetadata.Id;
    }

    get EntityTypeName(): string {
        return this._model.EntityMetadata.Type;
    }

    get FieldId(): number {
        return this._model.FieldMetadata.Id;
    }

    get QueryColumnGuid(): string {
        return this._model.QueryColumnGuid;
    }

    get Model(): GridCellValueModel {
        return this._model;
    }

    get Value(): string {
        return this._model.Value;
    }

    set Value(value: string) {
        this._model.Value = value;
    }

    get DisplayValue() {
        return this._model.DisplayValue;
    }

    get Translations() {
        return this._model.Translations;
    }

    get IsLocked(): boolean {
        return false;
    }

    get IsWrapped(): boolean {
        return this._model.IsWrapped;
    }

    get IsSortDescription(): boolean {
        return this._model.IsSortDescription;
    }

    get IsOrdinary(){
        return !this.IsWrapped && !this.IsSortDescription;
    }

    get IsGroup(): boolean {
        return this._model.IsGroup;
    }

    get RelationType(): RelationTypeModel {
        return this._model.RelationType;
    }

    get Title(): string {
        if (this._model.Details && this._model.Details.length > 0) {
            let details = [];
            _.each(this._model.Details, (item) => {
                details.push(item.DisplayValue);
            });
            return details.join(", ");
        }

        if(this._model.HyperlinkRecordId > 0){
            return NOTIFICATIONS.GRID_CELL_HYPERLINK_TOOLTIP;
        }
        
        return null;        
    }

    get TranslateValue(): string {
        if (this.IsTranslatable()) {
            const translation = TranslationManager.Instance.GetTranslation(this._model.DisplayValue, this._model.Translations);
            return translation.TranslatedValue || translation.Value;
        } else {
            return this._model.Value;
        }
    }

    ShowNextRelationTypeScreen(editLink: boolean = false) {
        let allEntities = Util.GetAllQueryEntities(this._queryExpression), target = event.target;
        let subjectEntityId = 0;
        _.each(allEntities, (item) => {
            let column = _.find(item.Columns, (column) => {
                return column.Guid === this._model.QueryColumnGuid;
            });
            if (column) {
                let parentEntity = Util.GetParentEntity(item, this._queryExpression);
                if (parentEntity) {
                    subjectEntityId = parentEntity.Metadata.Id;
                }
            }
        });

        let recordKeys = [];
        _.each(this._model.Details, (item) => {
            recordKeys.push(item.RecordKeys);
        });

        BlockUI.Block();
        GridStore.GetDataByClusteredField({
            SubjectEntityId: subjectEntityId,
            FieldId: this._model.FieldMetadata.Id,
            RecordKeys: recordKeys,
            DisplayFields: this.QueryColumn.DisplayFields            
        }).always(() => {
            BlockUI.Unblock();
        }).then((result) => {

            if(editLink && result.Data.Rows.length == 1){
                this._pivotDetailsDropDown.SetData(result, editLink);
                return;
            }

            this._pivotDetailsDropDown.Show(target);
            this._pivotDetailsDropDown.SetData(result, editLink);
        });
    }

    get QueryColumn(): QueryColumnModel{
        return _.find(Util.GetAllQueryColumns(this._queryExpression), (col) => col.Guid === this._model.QueryColumnGuid);
    }

    Click(_, evt) {
        if (this._isHyperLink) {
            var recordkey = this._model.RecordKeys[0];

            this.Trigger(EVENTS.OPEN_HYPERLINK, {
                EntityId: recordkey.EntityId,
                IsOpenAgenda: this._model.EntityMetadata.Name === 'AGENDA',
				RecordId: recordkey.RecordId,
				RecordTypeId: this._model.RecordTypeId,
                IsHyperlink: true,
                ViewValue: this._viewValue(),
                ShowInModal: evt.ctrlKey
            });     
            
        }

        if (this._model.HyperlinkRecordId > 0 && this._model.FieldMetadata.HyperlinkEntityId > 0) {
            this.NavigateByHyperlink(this._model.FieldMetadata.HyperlinkEntityId, this._model.HyperlinkRecordId, evt.ctrlKey);
        }
    }

    NavigateByHyperlink(entityId: number, recordId: number, showInModal: boolean){
        BlockUI.Block();
        GridStore.IsRecordValid({ TableId: entityId, RecordId : recordId })
        .always(()=>{
            BlockUI.Unblock();
        })
        .then((result)=>{
            if(result){
                this.Trigger(EVENTS.OPEN_HYPERLINK, {
                    EntityId: this._model.FieldMetadata.HyperlinkEntityId,
                    IsOpenAgenda: this._model.EntityMetadata.Name === 'AGENDA',
                    RecordId: this._model.HyperlinkRecordId,
                    IsHyperlink: true,
                    ViewValue: this._viewValue(),
                    ShowInModal: showInModal
                });        
            }else{
                new Notifier().Failed(NOTIFICATIONS.INVALID_RECORD_ID);
            }
        }).fail(err=>{
            new Notifier().Failed(err.message);
        });
    }

    IsNoAccessCell() {
        var isNoAccess = (_.filter(this._model.RecordKeys, (key) => {
            return key.RecordId !== 0;
        }).length === 0 && (this._model.Value === null || this._model.Value === undefined)) && !this._isNewRecord;

        if (isNoAccess && (this._model.IsCrossValue || this._model.IsCrossRow)) {
            isNoAccess = false;
        }

        return isNoAccess;
    }

    InitTemplate() {
        this.GetTemplateName = ko.computed(()=>{
            if(!this._model.Type){
                return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Empty';
            }

            if (this.IsNoAccessCell() && (this._model.Type !== FIELD_TYPES.Document)) {
                return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/NoAccess';
            }

            if (this._model.Details.length > 0 && this._model.FieldMetadata.IsClustered) {
                return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Clustered';
            }

            switch (this._model.Type) {
                case FIELD_TYPES.Currency:
                    this.PrepareCurrency();
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Currency';
                case FIELD_TYPES.Spim:
                    this.PrepareSpim();
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Spim';
                case FIELD_TYPES.Sort:
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Sort';
                case FIELD_TYPES.Reference:
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Reference';
                case FIELD_TYPES.Image:
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Image';
                case FIELD_TYPES.YesNo:
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/YesNo';
                case FIELD_TYPES.Memo:
                    this.PrepareMemo();

                    if(this._model.IsSortDescription){
                        return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/SortDescription';
                    }else{
                        return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Memo';
                    }
                
                case FIELD_TYPES.Color:
                    this.PrepareColor();
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Color';
                case FIELD_TYPES.Password:
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Password';
                case FIELD_TYPES.Document:
                    this.PrepareDocument();
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Document';
                case FIELD_TYPES.Date:
                case FIELD_TYPES.DateTime:
                case FIELD_TYPES.Time:
                    this.PrepareTime();
                    break;
                case FIELD_TYPES.TimeSpan:
                    this.PrepareTimeSpan();
                    break;
                case FIELD_TYPES.MultiSelect:
                    this.PrepareMultiSelect();
                    return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/MultiSelect';
                case FIELD_TYPES.Decimal:
                case FIELD_TYPES.Integer:
                    this.PrepareDecimalOrInteger({ value: this._model.Value, fieldMetadata: this._model.FieldMetadata });
                    break;
            }

            const validationFieldMetadata = extractLookupValFieldMetadata(this._model.FieldMetadata);

            if (
                validationFieldMetadata &&
                _.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], validationFieldMetadata.Type)
            ) {
                this.PrepareDecimalOrInteger({
                    value: this._model.OriginalDisplayValue,
                    fieldMetadata: validationFieldMetadata,
                    localize: true
                });
            }

            if (this._model.FieldMetadata.FormatName === FORMATS.PHONE_NUMBER) {
                return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/PhoneNumber';
            }

            if (this._formatManager) {
                return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/Format';
            }

            if (this._isHyperLink) {
                return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/HyperLink';
            }

            return this._isEditMode() ? 'Core/Controls/Grid/BaseGrid/GridCell/Templates/GeneralEdit' : 'Core/Controls/Grid/BaseGrid/GridCell/Templates/General';
        })
    }

    private PrepareDecimalOrInteger({
        value,
        fieldMetadata,
        localize
    }: {
        value: string;
        fieldMetadata: FieldMetadataModel;
        localize?: boolean;
    }) {
        if (value) {
            const valueForDisplay =
                fieldMetadata.FormatName === 'Percentage'
                    ? (Number(value) * 100).toFixed(fieldMetadata.Size < 2 ? 0 : fieldMetadata.Size - 2)
                    : value;

            this._model.DisplayValue = localize
                ? FormatConverter.LocalizeDecimalOrInteger(valueForDisplay)
                : valueForDisplay;
        }
    }

    private PrepareMultiSelect() {
        let result = '';

        if (this._model.DisplayValue) {
            const validationFieldMetadata = extractLookupValFieldMetadata(this._model.FieldMetadata);

            if (
                validationFieldMetadata &&
                _.contains([FIELD_TYPES.Decimal, FIELD_TYPES.Integer], validationFieldMetadata.Type)
            ) {
                const splitValues = this._model.DisplayValue.split(',\\n ');

                const formattedValues = splitValues.map(singleValue => FormatConverter.LocalizeDecimalOrInteger(
                    validationFieldMetadata.FormatName === FieldFormat.PERCENTAGE
                        ? (Number(singleValue) * 100).toFixed(validationFieldMetadata.Size < 2 ? 0 : validationFieldMetadata.Size - 2)
                        : singleValue
                ));

                result = formattedValues.join(', ');
            } else {
                result = this._model.DisplayValue.replace(/\\n/g, '');
            }
        }

        this._model.DisplayValue = result;
    }

    private PrepareTimeSpan() {
        let datetimeFormat = FormatConverter.GetDateFormatFromFieldModel(this._model.FieldMetadata, true);
        this._model.DisplayValue = FormatConverter.ConvertFromDefaultFormat(this._model.DisplayValue, datetimeFormat);
    }

    private PrepareTime() {
        this._model.DisplayValue = this._model.Value;

        if(this._model.Type !== FIELD_TYPES.Date){
            this._model.DisplayValue = FormatConverter.CorrectTimezone(this._model.DisplayValue);
        }        

        let datetimeFormat = FormatConverter.GetDateFormatFromFieldModel(this._model.FieldMetadata, true);

        this._model.DisplayValue = FormatConverter.ConvertFromDefaultFormat(this._model.DisplayValue, datetimeFormat);
    }

    private PrepareMemo() {
        this._model.DisplayValue = _.isEmpty(this._model.DisplayValue) ? null : this._model.DisplayValue.replace(/\n/g, '');
        this._model.TranslatedValue = _.isEmpty(this._model.TranslatedValue) ? null : this._model.TranslatedValue.replace(/\n/g, '');
    }

    private PrepareColor() {
        this._model.DisplayValue = ColorConverter.ToHex(this._model.DisplayValue);
    }

    private PrepareCurrency() {
        if (this._model.Currency) {
            this._currencyValue = this._model.Currency.Converted ? this._model.Currency.Converted : this._model.Currency.Origin;
            this._model.DisplayValue = this._currencyValue.Value.toFixed(this._model.FieldMetadata.Size);

            if (this._currencyValue.Currency && this._currencyValue.Currency.Icon) {
                this._icon(new Icon(this._currencyValue.Currency.Icon));
            } else {
                this._model.DisplayValue = LABELS.INVALID_FIELD_DATA;
            }
        }
    }

    private PrepareSpim() {
        if (!this._model.Spim || !this._model.Spim.FeatureTypeIsSet) {
            this._model.DisplayValue = LABELS.FEATURE_TYPE_IS_NOT_SET;
            return;
        }

        if (this._model.Spim.IsAlphaNumeric) {
            this._model.DisplayValue = this._model.Spim.AlphaNumericDisplayValue;
            return;
        }

        if (this._model.Spim.IsNumeric) {
            this._model.DisplayValue = FormatConverter.LocalizeDecimalOrInteger(
                this._model.Spim.NumericValue && this._model.Spim.NumericValue.toString()
            );
            return;
        }

        if (this._model.Spim.IsLogic) {
            this._model.DisplayValue = this._model.Spim.LogicalValue ? '1' : '0';
            return;
        }

        if (this._model.Spim.IsRange) {
            const rangeLow =
                this._model.Spim.RangeLow === null
                    ? `(${LABELS.EMPTY})`
                    : FormatConverter.LocalizeDecimalOrInteger(this._model.Spim.RangeLow.toString());
            const rangeHigh =
                this._model.Spim.RangeHigh === null
                    ? `(${LABELS.EMPTY})`
                    : FormatConverter.LocalizeDecimalOrInteger(this._model.Spim.RangeHigh.toString());
            this._model.DisplayValue = `${rangeLow} - ${rangeHigh}`;

            return;
        }
    }

    GetRateTitle() {
        if (this._model.Currency) {
            let rateDate = this._model.Currency.Origin ? this._model.Currency.Origin.RateDate : this._currencyValue.RateDate;
            const date = moment(rateDate).format('L');
            if (this._model.Currency.Origin.Currency) {
                const icon = new Icon(this._model.Currency.Origin.Currency.Icon);
                const template = `
                    <div class="origin-tooltip">${icon.GetTemplate()}${this._model.Currency.Origin.Value ? FormatConverter.LocalizeDecimalOrInteger(
                    Number(this._model.Currency.Origin.Value.toString().replace(/[.,]/g, '.')).toFixed(2)) : ''} - ${date} </div>`;
                return (ko as any).renderTemplateXHtml(template, icon);
            }
            return '';
        }
        return '';
    }

    private PrepareDocument() {
        if (this._model.Values) {
            var documentDataModel = new DocumentDataModel();
            documentDataModel.Data = this._model.Values;
            documentDataModel.RecordKeys = this._model.RecordKeys;
            this._documentManager = new DocumentManager(
                this._screenType,
                this._form ? this._form.GetScreen() : null,
                documentDataModel,
                this.EntityId,
                this.EntityTypeName,
                this._model.FieldMetadata.Id,
                this._model.FieldMetadata.Name,
                this._model.IsEnableMail,
                this._model.Document.IsEnableSwitchVersion
            );

            this._documentManager.On(DOCUMENT_MANAGER_EVENTS.UPDATED, this, (eventArgs) => {
                this.Trigger(EVENTS.REFRESH);
            });
        }
    }

    get DocumentManager() {
        return this._documentManager;
    }

    AfterRender(elements: Array<HTMLElement>) {
        this._el = _.find(elements, (element: HTMLElement)=> element.nodeType !== 8 && element.nodeType !== 3);
    }

    get Wrapper(): HTMLElement
    {
        return this._el;
    }

    get DisplayPriorityClass(): string {
        return this._model.DisplayPriority ? DISPLAY_PRIORITY_TO_CSS[this._model.DisplayPriority] : '';
    }

    SaveControlValue(linkEditorFieldData: FieldDataModel = null) {
        if (this._editor()) {

	        this._isNewRecord = true;
            this._valueExists(this._editor().GetValue() || false);

            if (this.IsTranslatable()) {
                const defaultTranslation = this._editor().GetDefaultTranslation();
                const translations = this._editor().GetTranslations();

                this._model.Value = defaultTranslation.Value;
                this._model.DisplayValue = defaultTranslation.Value;
                this._model.Translations = translations;
                this._model.OriginalDisplayValue = defaultTranslation.Value;

                this.TranslateData();

            } else {
                this._model.Value = this._editor().GetValue();
				this._model.DisplayValue = this._editor().GetValue();
				this._model.OriginalDisplayValue = this._editor().GetValue();

                const controlType = this._editor().GetType();
                const controlsWithComplexValues = [CONTROL_TYPES.Currency, CONTROL_TYPES.Spim];

                if (_.some(controlsWithComplexValues, controlWithComplexValue => controlWithComplexValue === controlType)) {
                    this._model.Value = JSON.stringify(this._editor().GetValue());
                }

                if (linkEditorFieldData && linkEditorFieldData.FieldValue[1]) {
                    linkEditorFieldData.FieldValue[1] = this._model.Value;
                }

                if (this._editor().GetType() === CONTROL_TYPES.Dropdown || this._editor().GetType() === CONTROL_TYPES.DateTime || this._editor().GetType() === CONTROL_TYPES.RadioButton) {
                    this._model.DisplayValue = this._model.OriginalDisplayValue = (this._editor() as any).GetDisplayValue();

                } else if (this._editor().GetType() === CONTROL_TYPES.MultiSelect) {
                    this._model.DisplayValue = (this._editor() as MultiSelect).SelectedItems().map(item => item.Label).join(", ");
                } else if (this._editor().GetType() === CONTROL_TYPES.ColorSelector) {
                    this._model.DisplayValue = this._editor().GetValue();
                    this.PrepareColor();
                } else {
                    if (this._editor().GetType() !== CONTROL_TYPES.Password) {
                        this._model.DisplayValue = this._editor().GetValue();
                    }
                }
            }

            if (this._editor().GetFieldModel().FormatName === "Percentage" && this._editor().GetType() === CONTROL_TYPES.Text) {
                if (this._model.Value) {
                    this._model.Value = (Number(this._model.Value.replace(/[.,]/g, '.')) / 100).toString();
                }
            }

            if (this._editor().GetFieldModel().FormatName === "Phone number" && this._editor().GetType() === CONTROL_TYPES.Text) {
                const phoneCode = (this._editor().GetType() as any).GetPhoneCode();
                if (phoneCode && this._editor().IsModified() && this._model.Value) {
                    this._model.Value = phoneCode + '-' + this._model.Value.replace(/\D/g, '').replace('-', '');
                }
            }

            if (this._editor().GetType() === CONTROL_TYPES.Currency) {
                if (!this._model.Currency) {
                    this._model.Currency = new ConvertedCurrencyModel();
                    this._model.Currency.Origin = new CurrencyValueModel();
                }
                const shownCurrency = this._model.Currency.Converted || this._model.Currency.Origin;
                shownCurrency.Currency = this._editor().GetValue().Currency;
                shownCurrency.Value = Number(this._editor().GetValue().Value.replace(',', '.'));
                shownCurrency.RateDate = this._editor().GetValue().RateDate;
                this.PrepareCurrency();
            }

            if (this._editor().GetType() === CONTROL_TYPES.Spim) {
                if (!this._model.Spim) {
                    this._model.Spim = new SpimValueModel();
                }

                this._model.Spim = this._model.Spim.FeatureType && this._editor().GetValue();
                this.PrepareSpim();
			}

			this.Tooltip({
				content: this.TooltipContent(_.escape(this._model.Value), this._editor().GetType(), this._editor().GetFieldModel().FormatName),
				position: { x: 'right', y: 'center' },
				outside: 'x',
				addClass: 'ellipsis-tooltip'
			});
        }
    }

    SaveLinkEditorValue(control: IControl) {
        if (control && this.IsEnableEdit) {
            if (this.IsTranslatable()) {
                const defaultTranslation = control.GetDefaultTranslation();
                const translations = control.GetTranslations();

                this._model.Value = defaultTranslation.Value;
                this._model.DisplayValue = defaultTranslation.Value;
                this._model.Translations = translations;

                this.TranslateData();

            } else {
                this._model.Value = control.GetType() === CONTROL_TYPES.Currency ? JSON.stringify(control.GetValue()) : control.GetValue();
                if (control.GetType() === CONTROL_TYPES.Dropdown || control.GetType() === CONTROL_TYPES.DateTime) {
                    this._model.DisplayValue = (control as any).GetDisplayValue();
                } else if (control.GetType() === CONTROL_TYPES.MultiSelect) {
                    this._model.DisplayValue = (control as MultiSelect).SelectedItems().map(item => item.Label).join(", ");
                } else {
                    this._model.DisplayValue = control.GetValue();
                }
            }

            if (control.GetFieldModel().FormatName === "Percentage" && control.GetType() === CONTROL_TYPES.Text) {
                this._model.Value = (Number(this._model.Value) / 100).toString();
            }

            if (control.GetFieldModel().FormatName === "Phone number" && control.GetType() === CONTROL_TYPES.Text) {
                const phoneCode = (control as any).GetPhoneCode();
                if (phoneCode && control.IsModified() && this._model.Value) {
                    this._model.Value = phoneCode + '-' + this._model.Value.replace(/\D/g, '').replace('-', '');
                }
            }
            this._valueExists(control.GetValue() || false);
            this.GetViewValue();
        }
    }

    private IsTranslatable() {
        return FlagResolver.ContainsFlag(FieldFlags.Translate, this._model.Flags);
    }

    private TranslateData() {
        if (this.IsTranslatable()) {
            const translation = TranslationManager.Instance.GetTranslation(this._model.DisplayValue, this._model.Translations);
            this._model.TranslatedValue = translation.TranslatedValue || translation.Value;
        }
    }

    private SelectNextRelationType() {
        const confirmationDialog = new ConfirmationDialog({
            Text: CONFIRMATIONS.LINK_RECORD_WITH_NEXT_RELATION_TYPE,
            Type: ConfirmationTypes.Question
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
            this.Trigger(EVENTS.LINK_NEXT_RELATION, {field: this._model});
        });

        confirmationDialog.Show();
    }

    private OpenMarkedEditScreen(cell, evt) {
        const entityRecord = this._reference() && this._reference().SelectedEntityRecord;

        if (!this._reference()) {
            return;
        }

        this.Trigger(EVENTS.OPEN_HYPERLINK, {
            EntityId: entityRecord.SelectedEntity.Id,
            RecordId: entityRecord.SelectedRecord.Id,
            IsHyperlink: true,
            ViewValue: this._viewValue(),
            ShowInModal: evt.ctrlKey
        });
    }

    private GetIconClasses(fontName, name): string {
        let classes: string = '';
        if (fontName === FONT_NAME.FONT_AWESOME) {
            classes = `${classes} fa`;
        } else if (fontName === FONT_NAME.SOCIAL_ICONS) {
            classes = `${classes} socicon-btn`;
        } else if (!name) { // Set default icon to reference field
            classes = 'fa fa-3x fa-stack fa-bookmark';
        } else {
            classes = fontName;
        }
        classes = `${classes} ${name} image-icon-xs`;

        return classes;
    }

    private MoveRowUp() {
        if (this.IsAbleSort) {
            this.Trigger(EVENTS.MOVE_ROW_UP);
        }

    }

    private MoveRowDown() {
        if (this.IsAbleSort) {
            this.Trigger(EVENTS.MOVE_ROW_DOWN);
        }
    }

    private HoverSort(){
        if (this.IsAbleSort) {
            this.Trigger(EVENTS.HOVER_SORT);
        }
    }

    private UnHoverSort(){
        if (this.IsAbleSort) {
            this.Trigger(EVENTS.UN_HOVER_SORT);
        }
    }


    GetAttributes(index: number) {
        return {
            'data-value': this.ViewValue && this._valueExists,
            'data-new-value': this._valueExists,
            'data-priority': this.Model.DisplayPriority,
            'data-index': index
        }
    }

    GetStyle() {
        return {
            maxHeight: this._maxRowHeight + 'px',
            width: this.Model.width
        }
    }

    private TooltipContent(tooltipValue: string, typeName: string, formatName: string) {
		return (tooltipValue && tooltipValue.length > 98 &&
			(typeName === FIELD_TYPES.Memo || typeName === FIELD_TYPES.Text) &&
			formatName !== 'SQL' && formatName !== 'URL')
			? tooltipValue
            : undefined;
    }

    ShowOriginalMemo() {
		BlockUI.Block();
		if (this._isNewRecord) {
			let memoValueModal = new MemoValueModal(this._model.Value);
			memoValueModal.Show();
			BlockUI.Unblock();
		} else {
			GridStore.GetMemoValue(
				{
					EntityId: this.Model.EntityMetadata.Id,
					FieldId: this.Model.FieldMetadata.Id,
					RecordKeys: this.Model.RecordKeys
				})
                .always(() => {
                    BlockUI.Unblock();
                })
                .then((data) => {
                    if (this.IsTranslatable()) {
					    const translation = TranslationManager.Instance.GetTranslation(data.DisplayValue, data.Translations);
					    data.Value = translation.TranslatedValue || translation.Value;
                    }

                    let memoValueModal = new MemoValueModal(data.Value);
                    memoValueModal.Show();
                })
                .fail(err => new Notifier().Warning(err.message));
		}
    }

    GetIconName(): string {
        const formatName = this._model.FieldMetadata.FormatName;
        return CONTROL_TYPES_LINK[formatName] ? CONTROL_TYPES_LINK[formatName].icon : null;
    }

    ShowOriginalImage(){
        this.Trigger(EVENTS.SHOW_ORIGINAL_IMAGE, { dataCell: this });
    }

    GetGrid(context: KnockoutBindingContext){
        let baseGridModule = require('Core/Controls/Grid/BaseGrid/BaseGrid').BaseGrid;
        let baseGrid = null;
        _.each(context.$parents, (parent) => {
            if(parent instanceof baseGridModule){
                baseGrid = parent;
            }
        });

        return baseGrid;
    }

    IsBarcodeButtonVisible() {
        return FieldFormat.IsBarcode(this._model.FieldMetadata.FormatName) && !this._model.FieldMetadata.IsVirtual;
    }

    PrintBarCode(){
        this.Trigger(EVENTS.PRINT_LABEL);
    }

    get ShowNextRelationTypeButton(): boolean{

        if(this.QueryColumn && this._model.RelationType) {

            let config = _.find(this.QueryColumn.CrossValueSettings, (conf) => conf.RelationTypeValueId === this._model.RelationType.Id);
        
            if(!config){
                return true;
            }
            return config.ShowNexRelationButton
        }

        return true;
    }

    get ShowEditRelationTypeButton(): boolean{

        if(this.QueryColumn && this._model.RelationType) {
            let config = _.find(this.QueryColumn.CrossValueSettings, (conf) => conf.RelationTypeValueId === this._model.RelationType.Id);
        
            if(!config){
                return true;
            }
            return config.ShowEditRelationButton
        }

        return true;
    }

    get HasSortDescription(): boolean{
        return this._model.FieldMetadata.HasSortDescription && this._screenType === ScreenTypes[ScreenTypes.ConsultScreen];
    }

    get DescriptionTooltipContent(): string {
        return this._hasDescription ? LABELS.SORT_REMOVE_DESCRIPTION : LABELS.SORT_ADD_NEW_DESCRIPTION;
    }

    EditDescription(){
        this._isEditMode(true);
    }

    CancelEditDescription(){
        this._isEditMode(false);
    }

    SaveDescription(){
        this.Trigger(EVENTS.SAVE_RECORD);
    }

    CreateOrRemoveDescription(){
        this._isNewRecord = false;
        if(this.IsSortDescription){ 
            if(!!this._model.Value){
                const confirmationDialog = new ConfirmationDialog({
                    Text: CONFIRMATIONS.REMOVE_SORT_DESCRIPTION,
                    Type: ConfirmationTypes.Question,
                    TextConfirm: LABELS.YES,
                    TextDecline: LABELS.CANCEL
                });

                confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                    this._model.Value = null;
                    this._model.DisplayValue = null;
                    this._model.OriginalDisplayValue = null;
                    this._isNewRecord = true;
                    this._isEditMode(true);
                    this.Trigger(EVENTS.SAVE_RECORD);
                });
                confirmationDialog.Show();
            }else{
                this._isEditMode(true);
            }            
        }else{
            this.Trigger(EVENTS.CREATE_OR_DELETE_SORT_DESCRIPTION);
        }
    }
}