import {GridRowModel} from 'Core/Controls/Grid/Models/GridDataModel/GridRowModel';
import {GridTotalRowModel} from 'Core/Controls/Grid/Models/GridDataModel/GridTotalRowModel';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {EntityMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';
import {deserialize, deserializeAs} from 'libs/cerialize';
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {GridAverageRowModel} from 'Core/Controls/Grid/Models/GridDataModel/GridAverageRowModel';
import { ScreenModel } from '../../../../Models/Screens/ScreenModel';

export class GridDataModel {
	@deserialize
	TotalRecords: number;

	@deserializeAs(GridColumnModel)
	Columns: Array<GridColumnModel>;

	@deserializeAs(GridRowModel)
	Rows: Array<GridRowModel>;

	@deserializeAs(GridTotalRowModel)
	TotalRow: GridTotalRowModel;

	@deserializeAs(GridAverageRowModel)
	AverageRow: GridAverageRowModel;

	@deserializeAs(GridTotalRowModel)
	TotalGroupRows: Array<GridTotalRowModel>;

	@deserializeAs(GridAverageRowModel)
	AverageGroupRows: Array<GridAverageRowModel>;

	constructor() {
		this.Columns = [];
		this.Rows = [];
		this.TotalGroupRows = [];
		this.AverageGroupRows = [];
		this.ScreenRows = [];
	}

	@deserializeAs(QueryExpressionModel)
	QueryExpression: QueryExpressionModel;

	@deserialize
	SubjectRecordId: number;

	@deserialize
	SubjectEntityId: number;

	@deserialize
	IsLinkingAllowed: boolean;

	@deserialize
	IsAddAndLinkAllowed: boolean;

	@deserialize
	IsExportDataAllowed: boolean;

	@deserialize
	IsScanAndLinkAllowed: boolean;

	@deserialize
    IsLinkEditorEnabled: boolean;

	@deserialize
    IsLinkJoin: boolean;
	
    @deserialize
    IsDirectionCorrect?: boolean;

    @deserialize
    HasOneParent: boolean;

	@deserialize
	GridSubjectEntityId: number;

	@deserialize
	GridSubjectEntityType: string;

	@deserializeAs(EntityMetadataModel)
	GridSubjectEntityMetadata: EntityMetadataModel;

	GridParentEntityId: number;

	@deserialize
	SubQueryGuid: string;

	@deserializeAs(ScreenModel)
	ScreenRows: Array<ScreenModel>;

} 