import * as _ from 'underscore';
import {GlobalStore} from 'Core/GlobalManager/Stores/GlobalStore';
import {GlobalModel} from 'Core/GlobalManager/Models/GlobalModel';
import {BlockUI} from 'Core/Common/BlockUi';
import {P} from 'Core/Common/Promise';
import {Notifier} from 'Core/Common/Notifier';
import * as $ from "jquery";
import {CULTURE_CODES} from "Core/Constants/CultureCodes";
import {NOTIFICATIONS} from "Core/Components/Translation/Locales";
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import enumerable from '../Common/Decorators/EnumerableDecorator';

export const GLOBALS = {
    POPUP_HEIGHT_IN_PERCENT: 'PopupHeightInPercent',
    POPUP_WIDHT_IN_PERCENT: 'PopupWidthInPercent',
    CONSULT_GRID_PAGE_LINES: 'ConsultGridPageLines',
    LIST_GRID_PAGE_LINES: 'ListGridPageLines',
    ALLOWED_ENTITIES: 'AllowedEntities',
    OTHER_GRID_PAGE_LINES: 'OtherGridPageLines',
    SEARCH_SCREEN_IF_RECORDS_COUNT: 'SearchScreenIf',
    LOCK_TIME: 'LockTime',
    CD_DATABASE_ID: 'CdDbId',
    CYBERDESK: 'CyberDesk',
    SUPERUSER_MAIL: 'Superusermail',
    TAB_HIDING_DAYS: 'TabHidingDays',
    REMEMBER_SELECT_USER: 'RememberSelectUser',
    ACTION_TABLE: 'ActionTable',
    USER_VARS_UPDATE_TIME: 'UserVarsUpdateTime',
    CULTURE_SETTINGS: 'CultureSettings',
    DESKTOP_LANGUAGE: 'DesktopLanguage',
    PATH_RUNNER_PARENTS_AMOUNT: 'PathRunnerParentsAmount',
    APPOINTMENT_DURATION: 'AppointmentDuration',
    NUMBER_OF_PERIODS: 'Number of periods',
    STARTING_TIME: 'Starting time',
    PERIOD_DURATION: 'Period Duration',
    USER_GROUPS: 'Usergroups',
    RESOURCES: 'Resources',
    DELETE_MAIL_AFTER_SAVING: 'DeleteMailAfterSaving',
    AUTO_MAIL_SAVING: 'AutoMailSaving',
    DEFAULT_COUNTRY_CODE: 'DefaultCountryCode',
    SUBJECT: 'Subject',
    DEFAULT_CURRENCY: 'DefaultCurrency',
    PREVIOUS_WEEKS_NUMBER: 'PreviousWeeksNumber',
    SHOW_CONTROL_ICONS: 'ShowControlIcons',
    ARTICLE_STORAGE_ENTITY: 'ArticleStorageEntity',
    HOME_SCREEN: 'HomeScreen',
    THEME: 'Theme',
    SHOW_PROFILE_SELECTOR: 'ShowProfileSelector',
    SHOW_BREADCRUMBS: 'ShowBreadcrumbs',
    MY_SETTINGS: 'MySettings',
    BASIC_MODE: 'BasicMode',
    READONLY_CREDENTIALS: 'ReadonlyCredentials',
    LOCK_SCREEN_HOURS: 'LockScreenHours',
    GOOGLE_MAPS_API_KEY: 'GoogleMapsAPIKey',
    MEMO_TIMESTAMP_FORMAT: 'Memo Timestamp Format'
};

export class GlobalManager {
    private static instance: GlobalManager;
    private static allowInstantiation: boolean;
    private _globals: Array<GlobalModel>;
    private _getAllGlobalPromises: P.Promise<any>[];

    constructor() {
        if (!GlobalManager.allowInstantiation) {
            throw new Error("Use MenuManager.Instance instead");
        }
        this._getAllGlobalPromises = [];
        this._globals = [];
    }

    static get Instance(): GlobalManager {
        if (GlobalManager.instance == null) {
            GlobalManager.allowInstantiation = true;
            GlobalManager.instance = new GlobalManager();
            GlobalManager.allowInstantiation = false;
        }

        return GlobalManager.instance;
    }

    public AddGlobal(global: GlobalModel) {
        const cashedGlobal = _.find(this._globals, (item) => {
            return item.Name === global.Name;
        });

        if (cashedGlobal) {
            cashedGlobal.Value = global.Value;
        } else {
            this._globals.push(global);
        }
    }

    GetGlobal(name: string) {
        const globalDescriptor = this.GetGlobalDescriptor(name);
        return globalDescriptor ? globalDescriptor.Value : null;
    }

    GetGlobalDescriptor(name: string) : GlobalModel {
        const cashedGlobal = _.find(this._globals, (item) => {
            return item.Name === name;
        });
        return cashedGlobal || null;
    }

    public AddCulture(global: GlobalModel) {
        const currentCulture = global.Value;
        let cultureCode = _.map(CULTURE_CODES, (num, key) => {
            return num.CultureCode;
        });
        let code = _.find(cultureCode, item => item === currentCulture);

        if (code) {
            this.AddGlobal(global);
        } else {
            let newGlobal = global,
                localCulture;
            if (window.navigator.languages) {
                localCulture = window.navigator.languages[0];
            } else {
                localCulture = (window.navigator as any).userLanguage || window.navigator.language;
            }
            newGlobal.Value = localCulture;
            this.AddGlobal(newGlobal);
            new Notifier().Warning(NOTIFICATIONS.INVALID_CULTURE.replace('{currentCulture}', currentCulture).replace('{localCulture}', localCulture));
        }
    }

    AddDesktopLanguage(global: GlobalModel) {
        const currentDesktopLanguage = global.Value;
        const language = TranslationManager.Instance.GetLanguageByShortName(currentDesktopLanguage);

        if (!language) {
            const defaultDesktopLanguage = 'EN';
            global.Value = defaultDesktopLanguage;
            new Notifier().Warning(
                NOTIFICATIONS.INVALID_DESKTOP_LANGUAGE
                    .replace('{currentDesktopLanguage}', currentDesktopLanguage)
                    .replace('{defaultDesktopLanguage}', defaultDesktopLanguage)
            );
        }

        this.AddGlobal(global);
    }

    LoadGlobals(addDesktopLanguage: boolean = true): P.Promise<any> {
        BlockUI.Block();
        const deferredResult = P.defer<any>();
        const params = {
            GlobalNames: Object.keys(GLOBALS).map(globalName => GLOBALS[globalName])
        };

        GlobalStore.GetGlobalByNames(params)
            .always(() => BlockUI.Unblock())
            .then(globals => {
                _.each(globals, global => {
                    if (global.Name === GLOBALS.CULTURE_SETTINGS) {
                        this.AddCulture(global);
                    } else if (global.Name === GLOBALS.DESKTOP_LANGUAGE && addDesktopLanguage) {
                        this.AddDesktopLanguage(global);
                    } else {
                        this.AddGlobal(global);
                    }
                });
                deferredResult.resolve(null);
            })
            .fail((err) => {
                const $preloader = $('.preloader');
                $preloader.detach();
                new Notifier().Show({Message: err.message, Type: {Color: 'red', Title: ''}});
            });

        return deferredResult.promise();
    }

    UpdateUserGlobals(userGlobals: GlobalModel[]) {
        return GlobalStore.UpdateUserGlobals(userGlobals)
            .then(() => userGlobals.forEach(global => this.AddGlobal(global)))
            .fail(err => new Notifier().Failed(err.message));
    }

    GetCulture(): string {
        return this.GetGlobal(GLOBALS.CULTURE_SETTINGS) || 'en-GB';
    }
}