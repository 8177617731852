import {PropertyManagers} from "Core/GeneralProperties/Enums/PropertyManagers";
import {BaseProperty, IPropertyDescription} from "Core/GeneralProperties/Managers/BaseProperty";
import {CheckboxProperty} from "Core/GeneralProperties/Managers/CheckboxProperty/CheckboxProperty";
import {InputNumberProperty} from "Core/GeneralProperties/Managers/InputNumberProperty/InputNumberProperty";
import {InputTextProperty} from "Core/GeneralProperties/Managers/InputTextProperty/InputTextProperty";
import {ColorPicker} from "Core/GeneralProperties/Managers/ColorPicker/ColorPicker";
import {SelectProperty} from "Core/GeneralProperties/Managers/SelectProperty/SelectProperty";
import {SelectLockedLinkEntityProperty} from "Core/GeneralProperties/Managers/SelectLockedLinkEntityProperty/SelectLockedLinkEntityProperty";
import {MultiSelectProperty} from "Core/GeneralProperties/Managers/MultiSelectProperty/MultiSelectProperty";
import {WebSizeProperty} from "Core/GeneralProperties/Managers/WebSizeProperty/WebSizeProperty";
import {InputUrlProperty} from "Core/GeneralProperties/Managers/InputUrlProperty/InputUrlProperty";
import {FileUploadProperty} from "Core/GeneralProperties/Managers/FileUploadProperty/FileUploadProperty";
import {QueryConditionProperty} from "Core/GeneralProperties/Managers/QueryConditionProperty/QueryConditionProperty";
import {QueryBuilderProperty} from "Core/GeneralProperties/Managers/QueryBuilderProperty/QueryBuilderProperty";
import {AlternativeEntitiesProperty} from "Core/GeneralProperties/Managers/AlternativeEntitiesProperty/AlternativeEntitiesProperty";
import {TypesProperty} from "Core/GeneralProperties/Managers/TypesProperty/TypesProperty";
import {BackLinkingProperty} from "Core/GeneralProperties/Managers/BackLinkingProperty/BackLinkingProperty";
import {IControl} from 'Core/Controls/IControl';
import {QuerySortProperty} from "Core/GeneralProperties/Managers/QuerySortProperty/QuerySortProperty";
import {QuerySelectorProperty} from "Core/GeneralProperties/Managers/QuerySelectorProperty/QuerySelectorProperty";
import {ConsultScreenLayout} from 'Core/GeneralProperties/Managers/ConsultScreenLayout/ConsultScreenLayout';
import {TabSecurity} from 'Core/GeneralProperties/Managers/TabSecurity/TabSecurity';
import { DWPackageProperty } from 'Core/GeneralProperties/Managers/DWPackageProperty/DWPackageProperty';
import {DimensionalTableProperty} from '../Managers/DimensionalTableProperty/DimensionalTableProperty';
import {CardScreenProperty} from '../Managers/CardScreenProperty/CardScreenProperty';
import { TemplateTypeProperty } from "../Managers/TemplateTypeProperty/TemplateTypeProperty";
import { DocumentTemplateProperty } from "../Managers/DocumentTemplateProperty/DocumentTemplateProperty";

export class PropertyManagersFactory {
	private static _initialized: boolean;
	private static _propertyNameToProperty: { [name: string]: (property: IPropertyDescription, propertyValue: any, control: IControl, getData: boolean) => BaseProperty } = {};

	static GetPropertyManager(property: IPropertyDescription, propertyValue: any, control: IControl, getData: boolean = true): BaseProperty {
		this.Init();
		return this._propertyNameToProperty[property.PropertyManager](property, propertyValue, control, getData);
	}

	private static Init() {
		if (!this._initialized) {
			this._propertyNameToProperty[PropertyManagers.CheckboxProperty] = this.GetCheckBoxProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.InputTextProperty] = this.GetInputTextProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.InputNumberProperty] = this.GetInputNumberProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.ColorPicker] = this.GetColorPicker.bind(this);
			this._propertyNameToProperty[PropertyManagers.SelectProperty] = this.GetSelectProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.SelectLockedLinkEntityProperty] = this.GetSelectLockedLinkEntityProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.MultiSelectProperty] = this.GetMultiSelectProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.WebSizeProperty] = this.GetWebSizeProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.InputUrlProperty] = this.GetInputUrlProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.FileUploadProperty] = this.GetFileUploadProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.QueryConditionProperty] = this.GetQueryConditionProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.QueryBuilderProperty] = this.GetQueryBuilderProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.AlternativeEntitiesProperty] = this.GetAlternativeEntitiesProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.TypesProperty] = this.GetTypesProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.BackLinkingProperty] = this.GetBackLinkingProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.QuerySortProperty] = this.GetQuerySortProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.QuerySelectorProperty] = this.GetQuerySelectorProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.ConsultScreenLayout] = this.GetConsultScreenLayoutProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.TabSecurity] = this.GetTabSecurityProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.DimensionalTable] = this.GetDimensionalTableProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.CardScreenProperty] = this.GetCardScreenProperty.bind(this);
            this._propertyNameToProperty[PropertyManagers.DWPackageProperty] = this.GetDWPackageProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.TemplateTypeProperty] = this.GetTemplateTypeProperty.bind(this);
			this._propertyNameToProperty[PropertyManagers.DocumentTemplateProperty] = this.GetDocumentTemplateProperty.bind(this);
        }
	}

	private static GetCardScreenProperty(property: IPropertyDescription, propertyValue: any, control: IControl, getData: boolean) {
		return new CardScreenProperty(property, propertyValue, control, getData);
	}

	private static GetCheckBoxProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new CheckboxProperty(property, propertyValue);
	}

	private static GetInputTextProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new InputTextProperty(property, propertyValue);
	}

	private static GetInputNumberProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new InputNumberProperty(property, propertyValue);
	}

	private static GetColorPicker(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new ColorPicker(property, propertyValue);
	}

	private static GetSelectProperty(property: IPropertyDescription, propertyValue: any, control: IControl, getData: boolean ) {
		return new SelectProperty(property, propertyValue, getData);
	}

	private static GetSelectLockedLinkEntityProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new SelectLockedLinkEntityProperty(property, propertyValue, control);
	}

	private static GetMultiSelectProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new MultiSelectProperty(property, propertyValue);
	}

	private static GetWebSizeProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new WebSizeProperty(property, propertyValue);
	}

	private static GetInputUrlProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new InputUrlProperty(property, propertyValue);
	}

	private static GetFileUploadProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new FileUploadProperty(property, propertyValue, control);
	}

	private static GetQueryConditionProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new QueryConditionProperty(property, propertyValue, control);
	}

	private static GetQueryBuilderProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new QueryBuilderProperty(property, propertyValue, control);
	}

	private static GetAlternativeEntitiesProperty(property: IPropertyDescription, propertyValue: any, control: IControl, getData: boolean) {
		return new AlternativeEntitiesProperty(property, propertyValue, control, getData);
	}

	private static GetTypesProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new TypesProperty(property, propertyValue, control);
	}

	private static GetBackLinkingProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new BackLinkingProperty(property, propertyValue, control);
	}

	private static GetQuerySortProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new QuerySortProperty(property, propertyValue, control);
	}

	private static GetQuerySelectorProperty(property: IPropertyDescription, propertyValue: any, control: IControl, getData: boolean) {
		return new QuerySelectorProperty(property, propertyValue, getData);
	}

	private static GetConsultScreenLayoutProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new ConsultScreenLayout(property, propertyValue);
	}

	private static GetTabSecurityProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new TabSecurity(property, propertyValue);
	}

	private static GetDimensionalTableProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
		return new DimensionalTableProperty(property, propertyValue, control);
    }

    private static GetDWPackageProperty(property: IPropertyDescription, propertyValue: any, control: IControl, getData: boolean) {
        return new DWPackageProperty(property, propertyValue, getData);
    }

	private static GetTemplateTypeProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
        return new TemplateTypeProperty(property, propertyValue, control);
    }

	private static GetDocumentTemplateProperty(property: IPropertyDescription, propertyValue: any, control: IControl) {
        return new DocumentTemplateProperty(property, propertyValue, control);
    }

}