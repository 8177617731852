import * as ko from 'knockout';

import {IControlParam} from 'Core/Screens/IScreen';
import {ComplexControl} from 'Core/Controls/ComplexControl/ComplexControl';
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {RequiredFieldModel} from 'Core/Controls/ComplexControl/Models/RequiredFieldModel';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {RenderModes, FIELD_TYPES, TABLE_TYPES} from 'Core/Constant';
import {IconModel} from "Core/Controls/BaseControl/Models/IconModel";
import {Icon} from "Core/Icon/Icon";
import {DEFAULT_ICONS} from 'Core/Constant'
import {QueryExpressionModel} from "Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel";
import {ViewModes} from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import {Serialize, Deserialize, GenericDeserialize} from 'libs/cerialize';

import ViewTemplate from 'Core/Controls/QueryBuilder/Templates/View.html';
import ToolBarTemplate from 'Core/Controls/QueryBuilder/Templates/ToolBar.html';

ko.templates['Core/Controls/QueryBuilder/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/QueryBuilder/Templates/Edit'] = ViewTemplate;
ko.templates['Core/Controls/QueryBuilder/Templates/ToolBar'] = ToolBarTemplate;
ko.templates['Core/Controls/QueryBuilder/Templates/Design'] = ComplexControl.designTemplate;

export class QueryBuilder extends ComplexControl {
    private _queryBuilder: KnockoutObservable<any>;
    private _recordTypeField: AttachedFieldModel;
    private _recordNameField: AttachedFieldModel;
    private _subjectField: AttachedFieldModel;
    private _subjectGridField: AttachedFieldModel;
    private _showInMenuField: AttachedFieldModel;
    private _showInPortletField: AttachedFieldModel;

    private _lockToGridField: AttachedFieldModel;

    private _gridIdField: AttachedFieldModel;
    private _queryField: AttachedFieldModel;
    private _oldQueryBuilderData: string;
    private _newQueryBuilderData: Array<any>;
    private _gridId: number;

    constructor(params: IControlParam) {
        super(params);
        this._model().IsComplexControl = true;
        this._queryBuilder = ko.observable(null);
        this.Init();
    }

    ApplyProperties(){}

    private Init(): void {
        if (this.GetRenderMode() !== RenderModes.Design && this.GetRenderMode() !== RenderModes.ToolBar) {
            [
                this._recordNameField,
                this._recordTypeField,
                this._subjectField,
                this._queryField,
                this._subjectGridField,
                this._showInMenuField,
                this._lockToGridField,
                this._gridIdField,
                this._showInPortletField
            ] = this._model().Fields;
        }

        this._requiredFields([
            new RequiredFieldModel('NAME', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('F_TYPE', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('F_SUBJECT', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('QUERY', FIELD_TYPES.Text, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('F_GRIDSUBJECT', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('SHOWINMENU', FIELD_TYPES.YesNo, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('LOCKEDTOGRID', FIELD_TYPES.YesNo, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('F_GRID', FIELD_TYPES.Lookup, TABLE_TYPES.Entity, null),
            new RequiredFieldModel('SHOWINPORTLET', FIELD_TYPES.YesNo, TABLE_TYPES.Entity, null)
        ]);

        this.InitRequiredFields();
        this.SetDefaultIcon(new Icon(DEFAULT_ICONS.QueryBuilder));
    }

    SetValue(value: IControlValue): void {
        const tableType = this._form.GetScreen().GetTableTypeName();

        if (value.Datas) {
            const queryData = _.find(value.Datas, (item) => {
                return item.FieldId === this._queryField.Id;
            });

            if (queryData) {
                let showInMenu = false;
                let showInPortlet = false;

                if (tableType === ViewModes[ViewModes.Query]) {
                    const showInMenuField = _.find(value.Datas, (item) => {
                        return item.FieldId === this._showInMenuField.Id;
                    });

                    const showInPortletField = _.find(value.Datas, (item) => {
                        return item.FieldId === this._showInPortletField.Id;
                    });

                    showInMenu = showInMenuField.Value === 'True' || showInMenuField.Value === true;
                }

                const showInPortletField = _.find(value.Datas, (item) => {
                    return item.FieldId === this._showInPortletField.Id;
                });

                showInPortlet = showInPortletField.Value === 'True' || showInPortletField.Value === true;

                this.EditQuery(queryData.Value, showInMenu, showInPortlet);
            } else {
                this.NewQuery(tableType);
            }
        } else {
            this.NewQuery(tableType);
        }
    }

    NewQuery(queryTypeName: string) {
        this._oldQueryBuilderData = queryTypeName;

        var queryBuilder = require('../../../QueryBuilder/QueryBuilder');
        this._queryBuilder(new queryBuilder.QueryBuilder(false));
        if (queryTypeName === ViewModes[ViewModes.Query] || queryTypeName === ViewModes[ViewModes.Spim]) {
            this._queryBuilder().QueryTypeName = ViewModes[this._form.GetScreen().GetTableTypeName()];
            this._queryBuilder().NewFreeQuery();
        }
    }

    EditQuery(queryModel: string, showInMenu: boolean = false, showInPortlet: boolean = false) {
        this._oldQueryBuilderData = queryModel;

        var queryBuilder = require('../../../QueryBuilder/QueryBuilder');
        this._queryBuilder(new queryBuilder.QueryBuilder(false));
        this._queryBuilder().QueryTypeName = ViewModes[this._form.GetScreen().GetTableTypeName()];
        this._queryBuilder().EditFreeQuery(GenericDeserialize<QueryExpressionModel>(JSON.parse(queryModel), QueryExpressionModel), showInMenu, showInPortlet);
    }

    AfterRender(el: Array<HTMLElement>): void {
        super.AfterRender(el);
    }

    Deserialize() {
        if (this._queryField && this._subjectField && this._queryBuilder()) {
            this._newQueryBuilderData = [];
            this._newQueryBuilderData.push([`${this._queryField.EntityName}.${this._queryField.Name}`, JSON.stringify(Serialize(this._queryBuilder().GetModel()))]);

            const data = [];

            data.push([`${this._queryField.EntityName}.${this._queryField.Name}`, JSON.stringify(Serialize(this._queryBuilder().GetModel()))]);
            data.push([`${this._subjectField.EntityName}.${this._subjectField.Name}`, this._queryBuilder().GetSubjectEntityId()]);

            if (this._subjectGridField) {
                data.push([`${this._subjectGridField.EntityName}.${this._subjectGridField.Name}`, this._queryBuilder().GetGridSubjectEntityId()]);
            }

            if (this._showInMenuField) {
                data.push([`${this._subjectGridField.EntityName}.${this._showInMenuField.Name}`, this._queryBuilder().GetShowInMenu()]);
            }
            
            if (this._showInPortletField) {
                data.push([`${this._subjectGridField.EntityName}.${this._showInPortletField.Name}`, this._queryBuilder().GetShowInPortlet()]);
            }

            if (this._gridIdField) {
                data.push([`${this._gridIdField.EntityName}.${this._gridIdField.Name}`, this._gridId]);
            }

            return data;
        }
        return null;
    }

    IsModified(): boolean {
        this.Deserialize();
        if (this._newQueryBuilderData.length !== 0) {
            let newQueryBuilderData = this._newQueryBuilderData[0][1];

            if (this._oldQueryBuilderData !== newQueryBuilderData.toString()) {
                return true;
            }
        }
        return null;
    }

    IsValid(): boolean {
        if (this._queryField && this._subjectField && this._queryBuilder()) {
            if (this._queryBuilder().GetModel()) {
                this._isValid(true);
            } else {
                this._isValid(false);
                this._errorMessage('Please initialize query');
            }
        } else {
            this._isValid(false);
            this._errorMessage('Please initialize control');
        }
        return this._isValid();
    }

    SetSubjectEntityId(subjectEntityId: number): void {
        this._queryBuilder().SubjectEntityId = subjectEntityId;
    }

    SetSubjectRecordId(subjectRecordId: number): void {
        this._queryBuilder().SubjectRecordId = subjectRecordId;
    }

    SetQueryTypeName(queryTypeName: any): void {
        this._queryBuilder().QueryTypeName = queryTypeName;
    }

    SetGridId(id: number): void {
        this._gridId = id;
    }

    SetFilterByOwners(value: boolean) {
        this._queryBuilder().FilterByOwners = value;
    }

    GetQueryId(): number {
        return this._queryBuilder().QueryId;
    }

    InitQuery(data: QueryExpressionModel): void {
        this._queryBuilder().InitQuery(data);
    }
}