import {deserialize} from 'libs/cerialize';

export class KanbanViewModel {
    constructor(id: number, name: string) {
        this.Id = id;
        this.Name = name;
    }

    @deserialize
    Id: number;

    @deserialize
    Name: string;

    @deserialize
    IsEditingAllowed: boolean;

    @deserialize
    IsDeletingAllowed: boolean;
}