import * as ko from "knockout";
import 'jBox';

let uniqueId = 0;

class otherOptions {// see option in documentation for  jBox
	theme?: string;
	trigger?: string;
	adjustTracker?: boolean;
	adjustPosition?: boolean;
	appendTo?: any;
	outside?: any;
	closeOnClick?: any;
	animation?: string;
	preventDefault?: boolean;
	repositionOnContent?: boolean;
	createOnInit?: boolean;
	zIndex?: number;
	attach?: string | JQuery<HTMLElement>;
	target?: string | JQuery<HTMLElement> |  JQuery<Element>;
	addClass?: string;
	onClose?: any;
	onCloseComplete?: any;
	content?: string;
	pointer?: string;
	position?: {
		x: string;
		y: string;
	};
	adjustDistance?: {
		top?: number;
		left?: number;
		right?: number;
		bottom?: number;
	} | number;
	offset?: {
		x?: number;
		y?: number;
	};
	isolateScroll?: boolean;
	fixed?: boolean;
	width?: string | number;
	height?: string | number;
	minWidth?: string | number;
	minHeight?: string | number;
	maxWidth?: string | number;
	maxHeight?: string | number;
	closeOnMouseleave?: boolean;
	delayOpen?: number;
	delayClose?: number;
	blockScroll?: boolean;
}


class DropDownOptions {
	onOpen?: any;
	onClose?: any;
	onCreated?: any;
	otherOptions?: otherOptions;
	target?: string | Element; // element selector
	bindTarget?: Element; // component that we want bind to for destroying
	bindComponent?: any; // class that will binded to view
	bindOnCreate?: boolean;
}

const observerConfig = { childList: true, characterData: true, subtree : true };

export class JBoxDropDown {
	private _toolTip;
	private _bindComponent;
	private _observer;
	constructor(options: DropDownOptions) {
		const { onOpen, onClose, target, bindTarget, bindComponent, onCreated, otherOptions, bindOnCreate } = options;

		this._init(onOpen, onClose, target, onCreated, otherOptions, bindOnCreate);

		this._bindComponent = bindComponent;

		bindTarget && ko.utils.domNodeDisposal.addDisposeCallback(bindTarget, () => {
			this._toolTip.destroy();
		});
	}

	/**
	 * init jbox tooltip
	 * @param onOpen
	 * @param onClose
	 * @param target
	 * @param onCreated
	 * @param bindOnCreate
	 * @param otherOptions
	 */
	private _init = (onOpen, onClose, target, onCreated, otherOptions, bindOnCreate) => {
		this._toolTip = new jBox("Tooltip", {
			attach: target,
			theme: "TooltipBorder",
			trigger: "click",
			adjustTracker: !0,
			closeOnClick: "body",
			animation: "move",
			preventDefault: true,
			repositionOnContent: true,
			isolateScroll: false,
			position: {
				x: "left",
				y: "top"
			},
			outside: "y",
			pointer: "left:20",
			offset: {
				x: 5
			},
			content: "",
			onOpen: onOpen,
			onClose: onClose,
			onCreated: () => { // check if dropdown in modal 
				const inModal = this._toolTip.target.closest('.jBox-Modal')
				if (inModal.length) {
					inModal.find(".jBox-content").scroll(this._ScrollHandler)
				}

				const pivotTooltip = this._toolTip.container.closest('.pivotDetailsJBox');
				const isPivotTooltip = pivotTooltip.length;

				this._observer = new MutationObserver(() => {
					this.Position();
				});

				this._observer.observe(this._toolTip.content[0], observerConfig);

				if (!isPivotTooltip){
					window.addEventListener("mouseenter", this.RepositionOnHorizontalScroll, true);
					window.addEventListener("mouseup", this.RepositionOnHorizontalScroll, true);
				}

				onCreated && onCreated();
				bindOnCreate && this.BindContent();
			},
			adjustDistance: {
				top: 55,
				right: 5,
				bottom: 5,
				left: 5
			},
			zIndex: 999999,
			...otherOptions
		});
		this._toolTip.target = $(target);
	};

	private RepositionOnHorizontalScroll = (e) => {
		if (!e.target.classList.contains('jBox-content')) { //if I am behind the block "jBox-content"
			this.Position()
		}
	}

	public GetContentElement = () => {
		return this._toolTip.content[0]
	}

	private _ScrollHandler = () => {
		this.Close();
	};

	public Position = () => {
		this._toolTip.position();
	}

	public Destroy = () => {
		this._toolTip.destroy();
		const inModal = this._toolTip.target.closest('.jBox-Modal')
		if (inModal.length) {
			inModal.find(".jBox-content").off("scroll", this._ScrollHandler)
		}
		this._observer && this._observer.disconnect();

		window.removeEventListener("mouseenter", this.RepositionOnHorizontalScroll, true);
		window.removeEventListener("mouseup", this.RepositionOnHorizontalScroll, true);
	}

	public SetContent = (options: { content?: string, isBindNeed?: boolean } = {}) => {
		let { content, isBindNeed = true } = options;
		if (!content) {
			content = this._toolTip.target
				.parent()
				.find(".jBox-content-target")
				.first()
				.data("jbox-content");
		}
		this._toolTip.setContent($(content));

		isBindNeed && this.BindContent();
	}

	public BindContent = () => {
		ko.applyBindings(this._bindComponent, this.GetContentElement());
	}

	public Close = () => {
		this._toolTip.close();
	}

	public Open = (targetElem?: any) => {
		const target: {target: JQuery<any>} = targetElem ? { target: $(targetElem) } : undefined;
		this._toolTip.open(target);
	}

	public Toggle = () => {
		this._toolTip.toggle();
	}

	static GetDropDownId() {
		return "jbox-dropdown-" + uniqueId++;
	}
}