import { Request, IRequestOptions } from "Core/Common/Request";
import { P } from "Core/Common/Promise";
import { SelectUserModel } from "Core/Controls/SelectUser/Models/SelectUserModel";

export class SelectUserStore {
	static GetSelectUserModel(entityId: number): P.Promise<SelectUserModel> {
		const requestOptions: IRequestOptions = {
			proxy: {
				url: "/api/ControlsApi/GetSelectUserList",
				type: "GET",
				dataType: "json"
			},params: {
				id: entityId
			}
		};

		const deffered = P.defer<SelectUserModel>();

		Request.Send(requestOptions)
			.then(response => {
				if (response.IsSuccessfull) {
					const model = response.ResultObject;
					deffered.resolve(model);
				} else {
					deffered.reject({ message: response.ErrorMessage });
				}
			}).fail(() => deffered.reject({ message: "Error getting data" }));
		return deffered.promise();
	}
}