import { messages } from './../Core/Common/Notifier';
import { record, pack } from "rrweb";
import { SessionStore } from "./SessionStore";
import { Guid } from "../Core/Common/Guid";
import { apm } from "Core/Common/ApmAgent";
import { UserManager } from '../User/UserManager';

let events = [];
let sessionId = null;
let interval;
let stopFn = null;

let func = (apm as any).serviceFactory.instances.ErrorLogging.logErrorEvent;
                   
(apm as any).serviceFactory.instances.ErrorLogging.logErrorEvent = (eventError)=>{
  func.call((apm as any).serviceFactory.instances.ErrorLogging, eventError);
  record.addCustomEvent('js-error', {
    message: eventError.error ? eventError.error.message : eventError.message
  });
}

export const startRecording = () =>{
  return;
  stopRecording()
  sessionId = Guid.NewGuid();
  window.onbeforeunload = () => {
    save()
    clearInterval(interval);
  };

  stopFn = record({
    emit(event) {
      events.push(event);
    },
    packFn: pack,
    collectFonts: true,
    recordCanvas: true
  });

  interval = setInterval(save, 10 * 1000);
}

export const stopRecording = () =>{
    if(stopFn){
      stopFn();
    };
    save()
    clearInterval(interval);
}

function save() {
  
  if(events.length == 0 || !sessionId || (UserManager.Instance && UserManager.Instance.CurrentUser && UserManager.Instance.CurrentUser.IsLocked)){
    return;
  }

  let eventsString = JSON.stringify(events);
  events = [];
  SessionStore.Update({ sessionId: sessionId, events: eventsString, timestamp: Date.now() });
}