import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {Deserialize, GenericDeserialize} from 'libs/cerialize';
import {RecordKeyModel} from 'Core/Controls/Document/DocumentDataModel';
import {PreviewDocumentModel} from 'Core/Components/Controls/DocumentManager/Models/PreviewDocumentModel';
import {Notifier} from 'Core/Common/Notifier';
import { DownloadDocumentModel } from 'Core/Components/Controls/DocumentManager/Models/DownloadDocumentModel';
import {DestinationMailModel} from 'Core/Components/Controls/DocumentManager/Models/DestinationMailModel';
import { ParentAssemblyModel } from '../Models/ParentAssemblyModel';

export interface ISavePdfDocumentRequestModel{
	FieldId: number;
	Content: string;
	RecordKeys: Array<RecordKeyModel>;
}

export interface IAttachmenttModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface ICreateEmlMessageRequestModel {
	FromAddress: string;
	ToAddresses: Array<string>;
	Attachment: IAttachmenttModel;
}

export interface IRecordKey {
	FieldId: number;
	RecordId: number;
}

export interface ICheckOutDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface ICheckInDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	ConfirmationPassword?: string;
	Recordkeys: Array<RecordKeyModel>;
};

export interface ILockDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface IUnlockDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface IEditDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface IEnableVersionsDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface IDisableVersionsDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface IPreveiwDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface IDownloadDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	Recordkeys: Array<RecordKeyModel>;
};

export interface IGetMainMailsRequestModel {
	EntityId: number;
	RecordId: number;
}

export interface IGeneratePdfDocumentRequestModel {
	EntityId: number;
	FieldId: number;
	RecordKeys: Array<RecordKeyModel>;
};

export interface IPrintLabelDto {
	Barcode: string;
};

export class DocumentManagerStore {

	static CheckIn(params: ICheckInDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/CheckIn',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject.Data);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetParentAssemblies(params: ICheckOutDocumentRequestModel): P.Promise<Array<ParentAssemblyModel>> {
		let deferredResult = P.defer<Array<ParentAssemblyModel>>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/GetParentAssemblies',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(Deserialize(data, ParentAssemblyModel));
			});

		return deferredResult.promise();
	}

	static CheckOut(params: ICheckOutDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/CheckOut',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Unlock(params: IUnlockDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/Unlock',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject.Data);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Lock(params: ILockDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/Lock',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject.Data);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Edit(params: IEditDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/Edit',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Switch(params: IEditDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/Switch',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static EnableVersions(params: IEnableVersionsDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/EnableVersions',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject.Data);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static DisableVersions(params: IDisableVersionsDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: 'api/DocumentsApi/DisableVersions',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(data.ResultObject.Data);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static SaveDocument(params: any): P.Promise<any> {
		let deferredResult = P.defer();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentsApi/Save',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Preview(params: IPreveiwDocumentRequestModel): P.Promise<PreviewDocumentModel> {
		let deferredResult = P.defer<PreviewDocumentModel>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentsApi/Preview',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					var previewDocumentModel = GenericDeserialize<PreviewDocumentModel>(data.ResultObject, PreviewDocumentModel);
					deferredResult.resolve(previewDocumentModel);

				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static Download(params: IDownloadDocumentRequestModel): P.Promise<any> {
		let deferredResult = P.defer<any>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentsApi/Download',
				type: 'POST',
				xhrFields:{
					responseType: 'blob'
				}
			},
			params: params
		};
		Request.DownloadFile(requestParams)
			.then(data => {
				deferredResult.resolve(data);				
			});

		return deferredResult.promise();
	}

	static CreateEmlMessage(params: ICreateEmlMessageRequestModel): P.Promise<any> {
		var deferredResult = P.defer<any>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/ButtonTemplateApi/CreateEmlMessage',
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}


	static GetMainMails(params: IGetMainMailsRequestModel): P.Promise<Array<DestinationMailModel>> {
		const deferredResult = P.defer<Array<DestinationMailModel>>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: '/api/MailApi/GetMainMails',
				dataType: "json",
				type: "POST"
			}, params: params
		};

		Request.Send(requestParams)
			.then(result => {
				if (result.IsSuccessfull) {
					deferredResult.resolve(<Array<DestinationMailModel>>Deserialize(result.ResultObject, DestinationMailModel));
				} else {
					deferredResult.reject({ message: result.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GeneratePdf(params: IGeneratePdfDocumentRequestModel): P.Promise<null> {
		let deferredResult = P.defer<null>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentsApi/GeneratePdf',
				dataType: 'json',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(null);
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static PrintLabel(params: IPrintLabelDto): P.Promise<null> {
		let deferredResult = P.defer<null>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentsApi/PrintLabel',
				type: 'GET'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(null);
			}).fail(data => { 
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });;

		return deferredResult.promise();
	}

	static SavePdfDocument(params: ISavePdfDocumentRequestModel): P.Promise<null> {
		let deferredResult = P.defer<null>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/DocumentsApi/SavePdf',
				type: 'POST'
			},
			params: params
		};
		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(null);
			})
			.fail(data => {
				deferredResult.reject({ message: data.message });
			})

		return deferredResult.promise();
	}
}