import { IScreen } from 'Core/Screens/IScreen'
import { ConsultScreen } from 'Core/Screens/ConsultScreen/ConsultScreen'
import { ListScreen } from 'Core/Screens/ListScreen/ListScreen'
import { SpecialScreen } from 'Core/Screens/SpecialScreen/SpecialScreen'
import { DesignScreen } from 'Core/Screens/DesignScreen/DesignScreen'
import { EditScreen } from 'Core/Screens/EditScreen/EditScreen'
import { LinkEditor } from 'Core/Screens/Common/LinkEditor/LinkEditor'
import { ScreenModel, DesignScreenModel } from 'Core/Models/Screens/ScreenModel'
import { Portlet } from "Core/Portlets/Models/Runtime/Portlet";
import { QueryScreen } from 'Core/Screens/QueryScreen/QueryScreen'
import { CardScreen } from 'Core/Screens/CardScreen/CardScreen'
import {EmptyScreen} from "Core/Screens/EmptyScreen/EmptyScreen";
import {Dashboard} from './Dashboard/Dashboard';
import { ConcreteEntityModel } from 'QueryBuilder/Models/ConcreteEntityModel'

export class ScreenFactory {
	static GetScreenInstance(model: ScreenModel, isLinkEditor?:boolean): IScreen {
		var screen = null;
		if (model) {
			switch (model.ScreenTypeName) {
				case "ConsultScreen":
					if (model.Data !== null && !model.Data.RecordExists && !isLinkEditor){
						const emptyScreenModel = EmptyScreen.EmptyScreenModel(model);
						screen = new EmptyScreen(emptyScreenModel);
					} else {
						screen = new ConsultScreen(model);
					}
					break;
				case "ListScreen":
					screen = new ListScreen(model);
					break;
				case "Portlet":
					screen = new Portlet(model);
					break;
				case "SpecialScreen":
					screen = new SpecialScreen(model);
					break;
				case "Dashboard":
					screen = new Dashboard(model);
					break;
				case "EditScreen":
					screen = new EditScreen(model);
					break;
				case "LinkEditor":
					screen = new LinkEditor(model);
					break;
				case "QueryScreen":
					screen = new QueryScreen(model);
					break;
				case "CardScreen":
					screen = new CardScreen(model);
					break;
			}
		}

		if (!screen) {
			console.log(`Not implemented screen: ${model.ScreenTypeName}`);
			console.log(model);
		}
		return screen;
	}

	static GetDesignScreenInstance(model: DesignScreenModel, subjectEntity: ConcreteEntityModel): DesignScreen {
		var screen = null;
		if (model) {
			screen = new DesignScreen(model, subjectEntity);
		}
		return screen;
	}

}