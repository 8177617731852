import * as ko from 'knockout';

import {FormatConverter} from "FormatEditor/FormatConverter";
import {CustomFieldControl} from "../CustomFieldControl";

import Template from './Template.html';
import {ProductCustomFieldDescription} from "../../ProductCustomFieldDescription";
import {CustomFieldValue} from '../../../CustomFieldValue';
import {ProductPart} from '../../../ProductPart';

export class CustomDecimalControl extends CustomFieldControl {
	private _displayValue: KnockoutObservable<string>;

	constructor(customField: ProductCustomFieldDescription, productPart: ProductPart) {
		super(customField, productPart);
		
		this.HasContent = ko.observable(true);
		this._displayValue = ko.observable(null);
	}

	GetTemplate() {
		return Template;
	}

	GetConvertedValue() {
		const value = this._displayValue();

		let convertedValue = !value && value !== '0' ? null : +value.replace(',', '.');

		convertedValue = isNaN(convertedValue) ? null : convertedValue;

		return convertedValue;
	}

	GetValueForSave(): any {
		return new CustomFieldValue(this.Id, this.GetConvertedValue(), this.IsValid(this.GetConvertedValue()));
	}

	SetValue(customFieldValue: CustomFieldValue) {
		let formattedValue = null;

		if (customFieldValue && customFieldValue.Value) {
			formattedValue = FormatConverter.ConvertDecimal(customFieldValue.Value.toString());
		}

		this._displayValue(formattedValue);
		this.StartListeningValueChanges();
	}

	SetDefaultValue() {
		const defaultFieldValue = new CustomFieldValue(this.Id, this.DefaultValue(), this.IsValid(true));
		this.SetValue(defaultFieldValue);
	}

	ClearValue() {
		this._displayValue(null);
	}

	private StartListeningValueChanges() {
		if (this._displayValue.getSubscriptionsCount() == 0) {
			this._displayValue.subscribe(() => {
				this.CheckValidControl();
				this.OnValueChanged();
			});
		}
	}

	IsValid(value: any): boolean {
		return this.GetDecimalIntegerValid(value);
	}

	CheckValidControl() {
		this.isValidControl(this.GetDecimalIntegerValidRules(this.GetConvertedValue()));
	}
}