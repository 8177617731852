import {FIELD_TYPES} from "Core/Constant";

import {ISelectedRecord} from 'Core/Screens/SearchScreen/SearchScreen';

import {ProductInfoResponse} from "../Stores/Models/ProductInfoResponse";
import {ProductPartInfoResponse} from "../Stores/Models/ProductPartInfoResponse";

import {BaseProduct} from "../Models/BaseProduct";
import {BaseProductGroup} from "../Models/BaseProductGroup";

import {RootGroup} from "../Models/RootGroup";
import {ProductGroup} from "../Models/ProductGroup";

import {Product} from "../Models/Product";
import {ProductPart} from "../Models/ProductPart";

import {ProductPrice} from "../Models/ProductPrice";

import {ProductPartDto} from "../Stores/Params/ProductPartDto";
import {SelectedProduct} from "../Models/SelectedProduct";
import {ProductPropertyGroupResponse} from "../Stores/Models/ProductPropertyGroupResponse";
import {ProductPropertyResponse} from "../Stores/Models/ProductPropertyResponse";
import {PropertyValueResponse} from "../Stores/Models/PropertyValueResponse";
import {PropertyValue} from "../Models/PropertyValue";
import {PropertyValueDto} from "../Stores/Params/PropertyValueDto";
import {ProductPropertyGroupDescription} from "../Models/Properties/ProductPropertyGroupDescription";
import {ProductPropertyDescription} from "../Models/Properties/ProductPropertyDescription";
import {PropertyEvaluationExpressionResponse} from "../Stores/Models/PropertyEvaluationExpressionResponse";
import {PropertyEvaluationExpression} from "../Models/PropertyEvaluationExpression";
import {ProductCustomFieldResponse} from "../Stores/Models/ProductCustomFieldResponse";
import {ProductCustomFieldDescription} from "../Models/CustomFields/ProductCustomFieldDescription";
import {CustomFieldValueResponse} from "../Stores/Models/CustomFieldValueResponse";
import {CustomFieldValue} from "../Models/CustomFieldValue";
import {CustomFieldValueDto} from "../Stores/Params/CustomFieldValueDto";
import {CachedProduct} from '../Models/CachedProduct';
import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import {PcfGroupResponse} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Stores/Models/PcfGroupResponse";
import {AlternativePriceModel} from "Core/Components/Controls/ProductConfigurator/Pages/ConfigurationPage/Models/AlternativePriceModel";

export type AfterMapRootGroup = (productResponse: string, rootGroup: RootGroup) => void;
export type AfterMapProductPart = (productPartResponse: ProductInfoResponse, productPart: ProductPart) => void;
export type AfterMapProductGroup = (productGroupName: string, productGroup: ProductGroup) => void;

export class ConfigurationPageMappings {
    constructor(private _orderEntityId: number, private _productEntityId: number) {
    }

    MapToProduct(productInfoDto: ProductInfoResponse, alternativePriceModel: AlternativePriceModel, isActiveLevelLocked: boolean) {
        return new Product(productInfoDto.Id, productInfoDto.KSeq, productInfoDto.Name, productInfoDto.NameTranslated, productInfoDto.Image, productInfoDto.Description, productInfoDto.Price, productInfoDto.Quantity, alternativePriceModel, isActiveLevelLocked);
    }

    MapToRootGroups(pcfPartsGroups: PcfGroupResponse[], parentProduct: BaseProduct, afterMap?: AfterMapRootGroup): RootGroup[] {
        let rootGroups = [];

        for (let i = 0; i < pcfPartsGroups.length; i++) {
            rootGroups.push(this.MapToRootGroup(pcfPartsGroups[i], parentProduct, afterMap));
        }
        return rootGroups;
    }

    MapToRootGroup(pcfPartGroup: PcfGroupResponse, parentProduct: BaseProduct, afterMap?: AfterMapRootGroup): RootGroup {
        const rootGroup = new RootGroup(pcfPartGroup.Id, pcfPartGroup.Name, pcfPartGroup.NameTranslation, pcfPartGroup.NameTranslations, pcfPartGroup.AddAsExtra, parentProduct);

        if (afterMap) {
            afterMap(pcfPartGroup.Name, rootGroup);
        }

        return rootGroup;
    }

    MapToProductParts(productPartsDto: ProductInfoResponse[], productGroup: BaseProductGroup,
                      afterMapProductPart?: AfterMapProductPart,
                      afterMapProductGroup?: AfterMapProductGroup, readOnly?: boolean): ProductPart[] {

        return productPartsDto.map(part => this.MapToProductPart(part, productGroup, afterMapProductPart, afterMapProductGroup, readOnly));
    }

    MapToProductPart(productInfoDto: ProductInfoResponse, productGroup: BaseProductGroup,
                     afterMapProductPart?: AfterMapProductPart,
                     afterMapProductGroup?: AfterMapProductGroup, readOnly: boolean = false, isMain: boolean = false): ProductPart {

        const propertyGroups = this.MapToPropertyGroupsDescription(productInfoDto.PropertyGroups);
        const propertyEvaluationExpressions = this.MapToEvaluationExpressions(productInfoDto.PropertyEvaluationExpressions);
        const productCustomFields = this.MapToProductCustomFields(productInfoDto.ProductCustomFields);

        const productPart = new ProductPart(
            this._orderEntityId,
            this._productEntityId,
            productInfoDto.Id,
            productInfoDto.KSeq,
            productInfoDto.Name,
            productInfoDto.NameTranslated,
            productInfoDto.Image,
            productInfoDto.Description,
            productInfoDto.Price,
            null,
            productInfoDto.Level,
            propertyGroups,
            propertyEvaluationExpressions,
            [],
            productGroup,
            productCustomFields,
            productInfoDto.InclusiveProducts,
            productInfoDto.ExclusiveProducts,
            productInfoDto.Defaults,
            productInfoDto.Quantity,
            productInfoDto.QuantityCanBeChanged,
            readOnly
        );

        productPart.IsMain(isMain);

        productPart.IsDisabled = productInfoDto.IsDisabled;

        if (readOnly) {
            productPart.SetPropertyValues(productInfoDto.PropertyValues);
            productPart.SetCustomFieldValues(productInfoDto.CustomFieldValues);
            productPart.Price(productInfoDto.Price);
            productPart.MakeSelected();
        }

        const productGroups = this.MapToProductGroups(productPart, productInfoDto.PcfPartsGroups, afterMapProductGroup);
        productPart.Groups(productGroups);

        if (afterMapProductPart) {
            afterMapProductPart(productInfoDto, productPart);
        }

        return productPart;
    }

    MapToCachedProducts(productParts: ProductPart[], rootGroup: BaseProductGroup): CachedProduct[] {
        return productParts.map(productPart => this.MapToCachedProduct(productPart, rootGroup));
    }

    MapToCachedProduct(productPart: ProductPart, rootGroup: BaseProductGroup): CachedProduct {
        return new CachedProduct(productPart.Id, productPart.ParentGroup.Id, productPart.ParentGroup.Name, rootGroup.Id, rootGroup.Name, productPart.Path, productPart.KSeq, productPart.KSeqGuid);
    }

    MapToPropertyGroupsDescription(productPropertyGroupsDto: ProductPropertyGroupResponse[]): ProductPropertyGroupDescription[] {
        return productPropertyGroupsDto.map(productPropertyGroupDto => this.MapToPropertyGroupDescription(productPropertyGroupDto));
    }

    MapToPropertyGroupDescription(productPropertyGroupDto: ProductPropertyGroupResponse): ProductPropertyGroupDescription {
        const propertiesDescription = this.MapToPropertiesDescription(productPropertyGroupDto.Properties);
        return new ProductPropertyGroupDescription(productPropertyGroupDto.Id, productPropertyGroupDto.Name, productPropertyGroupDto.TranslatedName, productPropertyGroupDto.PropertyMode, propertiesDescription);
    }

    MapToPropertiesDescription(productPropertiesDto: ProductPropertyResponse[]): ProductPropertyDescription[] {
        return productPropertiesDto.map(productPropertiesDto => this.MapToPropertyDescription(productPropertiesDto));
    }

    MapToPropertyDescription(productPropertyResponse: ProductPropertyResponse): ProductPropertyDescription {

        const propertyDescription = new ProductPropertyDescription(productPropertyResponse.Id,
            productPropertyResponse.Name,
            productPropertyResponse.TranslatedName,
            productPropertyResponse.Type,
            productPropertyResponse.Size,
            productPropertyResponse.ValueHolderId,
            productPropertyResponse.PriceInfluence,
            productPropertyResponse.ProductEntityId,
            productPropertyResponse.ProductId,
            productPropertyResponse.IsRequired);

        if (productPropertyResponse.Type == FIELD_TYPES.Lookup) {
            propertyDescription.AvailableOptionsCount = productPropertyResponse.AvailableOptionsCount;
            propertyDescription.RelatedTableId = productPropertyResponse.RelatedTableId;
            propertyDescription.RelatedTableFieldId = productPropertyResponse.RelatedTableFieldId;
            propertyDescription.LookupTableId = productPropertyResponse.LookupTableId;
        }

        return propertyDescription;
    }

    MapToEvaluationExpressions(propertyEvaluationExpressionsDto: PropertyEvaluationExpressionResponse[]): PropertyEvaluationExpression[] {
        return propertyEvaluationExpressionsDto.map(evaluationExpressionDto => this.MapToEvaluationExpression(evaluationExpressionDto));
    }

    MapToEvaluationExpression(propertyEvaluationExpressionDto: PropertyEvaluationExpressionResponse): PropertyEvaluationExpression {
        return new PropertyEvaluationExpression(propertyEvaluationExpressionDto.Id, propertyEvaluationExpressionDto.Destination, propertyEvaluationExpressionDto.Expression);
    }

    MapToProductCustomFields(productCustomFieldsDto: ProductCustomFieldResponse[]): ProductCustomFieldDescription[] {
        return productCustomFieldsDto.map(productCustomFieldDto => this.MapToProductCustomField(productCustomFieldDto));
    }

    MapToProductCustomField(productCustomFieldResponse: ProductCustomFieldResponse): ProductCustomFieldDescription {
        return new ProductCustomFieldDescription(productCustomFieldResponse.Id,
            productCustomFieldResponse.Name,
            productCustomFieldResponse.TranslatedName,
            productCustomFieldResponse.DefaultValue,
            productCustomFieldResponse.Type,
            productCustomFieldResponse.Size,
            productCustomFieldResponse.ValTable,
            productCustomFieldResponse.ValTableType,
            productCustomFieldResponse.ValFieldId,
            productCustomFieldResponse.ValFieldName,
            productCustomFieldResponse.AllowCustomValue,
            productCustomFieldResponse.PriceInfluence,
            productCustomFieldResponse.IsRequired);
    }

    MapToProductGroups(product: ProductPart, pcfPartsGroups: PcfGroupResponse[], afterMap?: AfterMapProductGroup): ProductGroup[] {
        return pcfPartsGroups.map(group => this.MapToProductGroup(product, group.Id, group.Name, group.NameTranslation, group.NameTranslations, group.AddAsExtra, afterMap));
    }

    MapToProductGroup(product: ProductPart, productGroupId: number, productGroupName: string, productTranslatedGroupName: string, productGroupNamesTranslations: TranslationModel[], addAsExtra: boolean, afterMap?: AfterMapProductGroup): ProductGroup {
        const productGroup = new ProductGroup(productGroupId, productGroupName, productTranslatedGroupName, productGroupNamesTranslations, addAsExtra,
            product);

        if (afterMap) {
            afterMap(productGroupName, productGroup);
        }

        return productGroup;
    }

    MapRootGroupProductPartsResponseOnProductPrices(products: ProductPartInfoResponse[]): ProductPrice[] {
        return products.map(product => this.MapProductPartInfoResponseOnProductPrice(product, product.PcfGroup.Id, product.PcfGroup.Name));
    }

    MapProductPartInfoResponseOnProductPrice(product: ProductPartInfoResponse, rootGroupId: number, rootGroupName: string): ProductPrice {
        return new ProductPrice(product.Id, product.Name, product.NameTranslated, product.FactualPrice, product.Quantity, product.PcfGroup.Id, product.PcfGroup.Name, product.PcfGroup.NameTranslation, rootGroupId, rootGroupName, product.Path, product.KSeq, null);
    }

    MapProductPartOnProductPrice(product: ProductPart, rootGroup: BaseProductGroup): ProductPrice {
        return new ProductPrice(product.Id, product.Name, product.NameTranslated, product.Price(), product.Quantity(), product.ParentGroup.Id, product.ParentGroup.Name, product.ParentGroup.TranslatedName, rootGroup.Id, rootGroup.Name, product.Path, product.KSeq, product.KSeqGuid);
    }

    MapToProductPartsDto(productPrices: SelectedProduct[]): ProductPartDto[] {
        return productPrices.map(productPrice => this.MapToProductPartDto(productPrice));
    }

    MapToProductPartDto(productPrice: SelectedProduct): ProductPartDto {
        return new ProductPartDto(productPrice.Id, productPrice.KSeq, productPrice.Path, productPrice.GroupId, productPrice.GroupName);
    }

    MapToPropertyValuesDto(propertyValue: PropertyValue[]): PropertyValueDto[] {
        return propertyValue.map(propertyValue => this.MapToPropertyValueDto(propertyValue));
    }

    MapToPropertyValueDto(propertyValue: PropertyValue): PropertyValueDto {
        return new PropertyValueDto(propertyValue.Id, propertyValue.Value, propertyValue.IsValid);
    }

    MapToPropertyValues(propertyValuesDto: PropertyValueResponse[]) {
        return propertyValuesDto.map(dto => this.MapToPropertyValue(dto));
    }

    MapToPropertyValue(propertyValueDto: PropertyValueResponse) {
        return new PropertyValue(propertyValueDto.Id, propertyValueDto.Value);
    }

    MapToCustomFieldValuesDto(customFieldValues: CustomFieldValue[]): CustomFieldValueDto[] {
        return customFieldValues.map(customFieldValue => this.MapToCustomFieldValueDto(customFieldValue));
    }

    MapToCustomFieldValueDto(customFieldValue: CustomFieldValue): CustomFieldValueDto {
        return new CustomFieldValueDto(
            customFieldValue.Id,
            customFieldValue.IsCustomValue ? customFieldValue.Text : customFieldValue.Value,
            customFieldValue.IsCustomValue,
            customFieldValue.IsValid
        );
    }

    MapToCustomFieldValues(customFieldValueDto: CustomFieldValueResponse[]) {
        return customFieldValueDto.map(dto => this.MapToCustomFieldValue(dto));
    }

    MapToCustomFieldValue(customFieldValueDto: CustomFieldValueResponse) {
        return new CustomFieldValue(
            customFieldValueDto.Id,
            customFieldValueDto.Value,
            customFieldValueDto.IsValid,
            customFieldValueDto.Text,
            customFieldValueDto.IsCustomValue
        );
    }
}