import * as _ from 'underscore';
import * as ko from "knockout";

import { deserialize } from 'libs/cerialize';
import { deserializeAs } from 'libs/cerialize';
import {IControl} from 'Core/Controls/IControl';
import { IconModel } from 'Core/Controls/BaseControl/Models/IconModel';


export class EntityModel {

	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	SubjectAreaId: number;

	@deserialize
	SubjectAreaName: string;

	@deserialize
	Icon: string;

	@deserialize
	IconType: string;

	@deserialize
	IconImage: string;

	@deserialize
	SubjectAreaIcon: string;

	@deserialize
	SubjectAreaIconType: string;

	@deserialize
	SubjectAreaIconId: number;

	@deserialize
	SubjectAreaIconImage: string;

	isActive: KnockoutObservable<boolean>;
	isOpen: KnockoutObservable<boolean>;

	directScreen?: boolean;
}


export class EntityType {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	Description: string;
}

export class EntityScreen {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TypeName: string;
}

export class SelectedEntityModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	Types: Array<EntityType>;

	@deserialize
	Description: string;
}

export class SubFormControl {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	Controls: Array<Control>;

	@deserialize
	ControlComponents: Array<IControl>;

	@deserialize
	Description: string;
}

export class Control {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	TypeName: string;

	@deserialize
	Controls: Array<Control>;

	@deserialize
	ControlComponents: Array<IControl>;

	@deserialize
	Description: string;

	@deserialize
	Properties: any;

	@deserialize
	TranslatedName: string;

	@deserialize
	LabelPosition: string;

	@deserialize
	UseFieldName: boolean;

	@deserialize
	IsFieldAttached: boolean;

	@deserialize
	IsLookupFieldAttached: boolean;

	@deserialize
	IsMultiSelectFieldAttached: boolean;

	@deserialize
	IsComplexControl: boolean;

	AttachedFields: Array<AttachedField>;
}

export class AttachedField {
	@deserialize
	Name: string;

	@deserialize
	TranslatedName: string;

	@deserialize
	TableName: string;

	@deserializeAs(IconModel)
	EntityIcon: IconModel;

	@deserializeAs(IconModel)
	FieldIcon: IconModel;
}

export class SelectedScreenModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
	SubForms: Array<SubFormControl>;

	@deserialize
	Description: string;
}