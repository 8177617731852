import {GenericDeserialize} from 'libs/cerialize';

import {Request} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';

import {IConsultScreenLayoutValue} from 'Core/GeneralProperties/Managers/ConsultScreenLayout/IConsultScreenLayoutValue';

import {PageResponse} from './Models/PageResponse';

import {LABELS} from "Core/Components/Translation/Locales";

export class BookStore {
    constructor(private _entityId: number, private _relatedEntityId: number) {
    }

    GetPage(bookId: number, pageNumber: number, controlId: number, pageLayout: IConsultScreenLayoutValue): P.Promise<PageResponse> {
        const deferred = P.defer<PageResponse>();

        Request.Send({
            proxy: {
                url: '/api/BookControlApi/GetPage',
                type: 'POST',
                dataType: 'json'
            },
            params: {
                BookId: bookId,
                EntityId: this._entityId,
                RelatedEntityId: this._relatedEntityId,
                PageNumber: pageNumber,
                ControlId: controlId,
                PageLayout: pageLayout
            }
        })
            .then(page => deferred.resolve(GenericDeserialize<PageResponse>(page, PageResponse)))
			.fail(error => deferred.reject(this.GetResponseError(error, LABELS.ERROR_GETTING_BOOK_PAGE)));

        return deferred.promise();
    }

    private GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}