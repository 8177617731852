import * as ko from 'knockout';
import { deserialize } from 'libs/cerialize';

export class LifeStatusSimpleModel {
	@deserialize
	Id: number;

	@deserialize
	Name: string;

	@deserialize
    Sort: number;

    @deserialize
    NoActionNode: boolean;

    @deserialize
    MultipleActions: boolean;

	@deserialize
	ApprovalName: string;

	@deserialize
    TranslatedName: string;

    @deserialize
    Memo: string;

    @deserialize
    MemoTranslation: string;

    @deserialize
    NodeTypeId: number;

    @deserialize
    NodeTypeName: string;

    @deserialize
    FollowupModeId: number;

    @deserialize
    FollowupModeName: string;

    @deserialize
    IsFollowUpDisabled: boolean;

    @deserialize
    DisabledReason: string;
}