import {P} from 'Core/Common/Promise';
import {Request, IRequestOptions} from 'Core/Common/Request';
import { EntitiesListModel } from 'Core/Controls/CanvasDesigner/Models/EntitiesListModel';
import { Deserialize, GenericDeserialize } from 'libs/cerialize';
import { CanvasModel, ShapeModel, CanvasItemModel } from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import { MsAccessSchemaDto } from 'Core/Controls/CanvasDesigner//Models/Dto/MsAccessSchemaDto';
import { MsAccessTableDto } from 'Core/Controls/CanvasDesigner//Models/Dto/MsAccessTableDto';
import { DesktopFolderDto } from 'Core/Controls/CanvasDesigner//Models/Dto/DesktopFolderDto';
import { TriggerDto } from 'Core/Controls/CanvasDesigner/Models/Dto/TriggerDto';

export interface IExactOnlineSettingDto{
	EndPointUrl: string;
	CallbackUrl: string;
	ClientId: string;
	ClientSecret: string;
	Login: string;
	Password: string;
	SecretKey: string;
	QueryString: string;
}

export interface ICheckExactOnlineSettingsDto {
	Settings: IExactOnlineSettingDto;
}

export interface IExecuteRequestModel {
	Id: number;
}

export interface IGetEntitiesRequestModel {
	IncludeDeleted: boolean;
}

export interface IGetNodesByFunctionPackageRequestModel {
	Id: number;
	Shape: any;
}

export interface IGetFunctionPackageRequestModel {
	DwPackageGuid: string;
	PackageId: number;
	ParentType: string;
}

export interface IGetTriggerRequestModel {	
	DwPackageGuid: string;
	TriggerId: number;
	ParentType: string;
}

export class CanvasDesignerStore {

	static CheckExactOnlineSettings(params: ICheckExactOnlineSettingsDto): P.Promise<any> {
        let deferredResult = P.defer<any>();

        let requestParams: IRequestOptions = {
            proxy: {
                url: '/api/CanvasApi/CheckExactOnlineSettings',
                type: 'POST'
            },
            params: params
        };
        Request.Send(requestParams)
            .then((result) => {
                deferredResult.resolve(null);
            }).fail(data => { 
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

        return deferredResult.promise();
    }


	static GetCanvasDesignerMetaData(): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetCanvasDesignerMetaData',
				dataType: 'json',
				type: 'GET'
			}
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static DeleteCanvas(canvasId: any): P.Promise<any> {
		const deferredResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/DeleteCanvas',
				type: 'POST',
				dataType: 'json'
			},
			params: canvasId
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(data));

		return deferredResult.promise();
	}

	static SaveCanvas(canvas: CanvasModel): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/Save',
				type: 'POST'
			}, params: canvas
		};

		Request.Send(requestParams)
			.then((result) => {
				deferedResult.resolve(result);
				
			}).fail(data => { 				
				let jsonResult = JSON.parse(data.message);
				deferedResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferedResult.promise();
	}

	static SubscribeExactOnlineWebhook(packageId: number): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/SubscribeExactOnlineWebhook',
				type: 'GET'
			}, params: {dwPackageId: packageId}
		};

		Request.Send(requestParams)
			.then((result) => {
				deferedResult.resolve(result);
				
			}).fail(data => { 				
				let jsonResult = JSON.parse(data.message);
				deferedResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferedResult.promise();
	}

	static GetProductCatalogUrl(packageId: number): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetProductCatalogUrl',
				type: 'GET'
			}, params: {dwPackageId: packageId}
		};

		Request.Send(requestParams)
			.then((result) => {
				deferedResult.resolve(result);
				
			}).fail(data => { 				
				let jsonResult = JSON.parse(data.message);
				deferedResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferedResult.promise();
	}

	static GetEntities(params: IGetEntitiesRequestModel): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetEntities',
				type: 'POST',
				dataType: 'json'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(data.ResultObject);
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetNodesByEntity(dwPackageGuid: string, entityId, parentType): P.Promise<CanvasModel> {
		const deferedResult = P.defer<any>();
		
		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetNodesByEntity',
				type: 'POST',
				dataType: 'json'
			}, params: { dwPackageGuid: dwPackageGuid, Id: entityId, ParentType: parentType }
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel))
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetNodesByQuery(query: string, rootGuid: string, parentType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		
		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetNodesByQuery',
				type: 'POST',
				dataType: 'json'
			}, params: { query: query, rootGuid: rootGuid, parentType: parentType }
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetNodesByExactOnlineEntity(name: string, dwPackageGuid: string, parentType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		
		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetNodesByExactOnlineEntity',
				type: 'POST',
				dataType: 'json'
			}, params: { Name: name,  DWPackageGuid: dwPackageGuid, parentType: parentType }
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetNodesByEWSEntity(name: string, dwPackageGuid: string, parentType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		
		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetNodesByEWSEntity',
				type: 'POST',
				dataType: 'json'
			}, params: { Name: name,  DWPackageGuid: dwPackageGuid, parentType: parentType }
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetNodesByMsAccessEntity(table: MsAccessTableDto, path: string, dwPackageGuid: string, parentType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		
		Request
		.For('api/CanvasApi/GetNodesByMsAccessEntity')
		.Post({ DWPackageGuid: dwPackageGuid, ParentType: parentType, Table: table, Path: path })
		.then(data => deferredResult.resolve(<CanvasModel>Deserialize(data, CanvasModel)))
		.fail(data => deferredResult.reject({ message: JSON.parse(data.message).Message }));

		return deferredResult.promise();
	}

	
	static GetNodesByDesktopFolder( path: string, dwPackageGuid: string, parentType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		
		Request
		.For('api/CanvasApi/GetNodesByDesktopFolder')
		.Post({ DWPackageGuid: dwPackageGuid, ParentType: parentType, Path: path })
		.then(data => deferredResult.resolve(<CanvasModel>Deserialize(data, CanvasModel)))
		.fail(data => deferredResult.reject({ message: JSON.parse(data.message).Message }));

		return deferredResult.promise();
	}

	static GetByOCIPunchOut(dwPackageGuid: string, parentType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		
		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetNodesByOCIPunchOut',
				type: 'POST',
				dataType: 'json'
			}, params: { DWPackageGuid: dwPackageGuid, parentType: parentType }
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetConstantGroup(dwPackageId: number): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetConstantGroup',
				type: 'POST',
				dataType: 'json'
			}, params: { DWPackageId: dwPackageId }
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetFunctionGroup(dwPackageId: number, methodId: number): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetFunctionGroup',
				type: 'POST',
				dataType: 'json'
			}, params: { DWPackageId: dwPackageId, MethodId: methodId }
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel));
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}


	static GetNodesBySpreadsheet(file: string, fileName: string, parentType: string, dwPackageId: number, dwPackageGuid: string): P.Promise<CanvasModel> {
		const deferedResult = P.defer<CanvasModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetNodesBySpreadsheet',
				type: 'POST',
				dataType: 'json'
			}, params: { UploadFile: { Base64Data: file, Name: fileName }, parentType: parentType, dwPackageGuid: dwPackageGuid, dwPackageId: dwPackageId }
		};

		Request.Send(requestParams)
			.then(data => {
					if (data.IsSuccessfull) {
						deferedResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel));
					} else {
						deferedResult.reject({ message: data.ErrorMessage });
					}
			}
		);

		return deferedResult.promise();
	}


	static GetCanvas(canvasId: any): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetCanvas',
				type: 'POST',
				dataType: 'json'
			},
			params: canvasId
		};

		Request.Send(requestParams)
			.then(data => deferredResult.resolve(<CanvasModel>Deserialize(data, CanvasModel)));

		return deferredResult.promise();
	}

	static Execute(params: IExecuteRequestModel): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/Execute',
				type: 'POST',
				dataType: 'json'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(null);
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetFunctionPackages(): P.Promise<Array<CanvasItemModel>> {
		const deferedResult = P.defer<Array<CanvasItemModel>>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetPackages',
				type: 'GET',
				dataType: 'json'
			}
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(<Array<CanvasItemModel>>Deserialize(data.ResultObject, CanvasItemModel));
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetDataWizardPackages(): P.Promise<Array<CanvasItemModel>> {
		const deferedResult = P.defer<Array<CanvasItemModel>>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetDataWizardPackages',
				type: 'GET',
				dataType: 'json'
			}
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(<Array<CanvasItemModel>>Deserialize(data.ResultObject, CanvasItemModel));
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetEWSEntities(): P.Promise<Array<string>> {
		const deferredResult = P.defer<Array<string>>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetEWSEntities',
				type: 'GET',
				dataType: 'json'
			}
		};

        Request.Send(requestParams)
            .then((result) => {
                deferredResult.resolve(Deserialize(result, String));
            }).fail(data => { deferredResult.reject({ message: JSON.parse(data.message).Message }) });


		return deferredResult.promise();
	}

	static GetMsAccessSchema(): P.Promise<MsAccessSchemaDto> {
		const deferredResult = P.defer<MsAccessSchemaDto>();

        Request
		.For('api/CanvasApi/GetMsAccessSchema')
		.Get()
        .then((result) => {			
            deferredResult.resolve(GenericDeserialize(result, MsAccessSchemaDto));
        }).fail(data => {
			 deferredResult.reject({ message: JSON.parse(data.message).Message });
		});

		return deferredResult.promise();
	}


	static GetDesktopFolderPath(): P.Promise<DesktopFolderDto> {
		const deferredResult = P.defer<DesktopFolderDto>();

        Request
		.For('api/CanvasApi/GetDesktopFolderPath')
		.Get()
        .then((result) => {			
			deferredResult.resolve(GenericDeserialize(result, DesktopFolderDto));
        }).fail(data => {
			 deferredResult.reject({ message: JSON.parse(data.message).Message });
		});

		return deferredResult.promise();
	}

	static GetExactOnlineEntities(): P.Promise<Array<string>> {
		const deferredResult = P.defer<Array<string>>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetExactOnlineEntities',
				type: 'GET',
				dataType: 'json'
			}
		};

        Request.Send(requestParams)
            .then((result) => {
                deferredResult.resolve(Deserialize(result, String));
            }).fail(data => { deferredResult.reject({ message: JSON.parse(data.message).Message }) });


		return deferredResult.promise();
	}

	static GetNodesByPackage(params: IGetNodesByFunctionPackageRequestModel): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetNodesByPackage',
				type: 'POST',
				dataType: 'json'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(data.ResultObject)
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetNodesByPackage2(params: any): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetNodesByMethod',
				type: 'POST',
				dataType: 'json'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(data.ResultObject)
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetTriggers(): P.Promise<Array<TriggerDto>> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetTriggers',
				type: 'GET',
				dataType: 'json'
			}
		};

		Request.Send(requestParams)
			.then(data => {
				deferedResult.resolve(<Array<TriggerDto>>Deserialize(data, TriggerDto));
			});

		return deferedResult.promise();
	}

	static GetTrigger(params: IGetTriggerRequestModel): P.Promise<CanvasModel> {
		const deferedResult = P.defer<CanvasModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetTrigger',
				type: 'POST',
				dataType: 'json'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel))
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetFunctionPackage(params: IGetFunctionPackageRequestModel): P.Promise<CanvasModel> {
		const deferedResult = P.defer<CanvasModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetFunctionPackage',
				type: 'POST',
				dataType: 'json'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel))
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static GetDwPackageById(params: IGetFunctionPackageRequestModel): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/BuildByDwPackage',
				type: 'POST',
				dataType: 'json'
			},
			params: params
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferredResult.resolve(<CanvasModel>Deserialize(data.ResultObject, CanvasModel))
				} else {
					deferredResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferredResult.promise();
	}

	static GetMethods(apiPackageId: number): P.Promise<Array<CanvasItemModel>> {
		const deferedResult = P.defer<Array<CanvasItemModel>>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/GetMethods',
				type: 'POST',
				dataType: 'json'
			},
			params: { ApiPackageId: apiPackageId }
		};

		Request.Send(requestParams)
			.then(data => {
				if (data.IsSuccessfull) {
					deferedResult.resolve(<Array<CanvasItemModel>>Deserialize(data.ResultObject, CanvasItemModel));
				} else {
					deferedResult.reject({ message: data.ErrorMessage });
				}
			});

		return deferedResult.promise();
	}

	static ExecuteRollback(packageId: number): P.Promise<any> {
		const deferedResult = P.defer<any>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/ExecuteRollback',
				type: 'POST'
			}, params: { DwPackageId: packageId }
		};

		Request.Send(requestParams)
			.then((result) => {
				deferedResult.resolve(result);
				
			}).fail(data => { 				
				let jsonResult = JSON.parse(data.message);
				deferedResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferedResult.promise();
	}

	static HasRollback(packageId: number): P.Promise<boolean> {
		const deferedResult = P.defer<boolean>();

		const requestParams: IRequestOptions = {
			proxy: {
				url: 'api/CanvasApi/HasRollback',
				type: 'GET',
				dataType: 'json'
			}, params: { DwPackageId: packageId }
		};

		Request.Send(requestParams)
			.then((result) => {
				deferedResult.resolve(result);				
			}).fail(data => { 				
				let jsonResult = JSON.parse(data.message);
				deferedResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });

		return deferedResult.promise();
	}

	static BuildLifeStatusTriggerFlow(lifeStatusName: string, exampleTriggerId?: number): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();

		Request.For('api/CanvasApi/BuildLifeStatusTriggerFlow')
		.Get({ LifeStatusName: lifeStatusName, ExampleStatusId: exampleTriggerId })
		.then(data => deferredResult.resolve(<CanvasModel>Deserialize(data, CanvasModel)));

		return deferredResult.promise();
	}
}