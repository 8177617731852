import * as _ from 'underscore'
import {GenericDeserialize} from 'libs/cerialize'

import {URL} from 'Core/Common/Url'
import {Request, IRequestOptions} from 'Core/Common/Request'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {P} from 'Core/Common/Promise'
import {Deserialize} from 'libs/cerialize'
import {DataModel} from 'Core/Screens/EditScreen/Models/DataModel'
import OperationResult from 'Core/Results/ResultModels/OperationResult'

export class InsertOrUpdateRecordStore {
	static UpdateData(params: DataModel): P.Promise<OperationResult> {
		var deferredResult = P.defer<OperationResult>();

		var requestParams: IRequestOptions = {
			proxy: {
				url: '/api/ScreenFormApi/InsertOrUpdateRecord',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(this.Deserialize(data));
			}).fail(err => {
				deferredResult.reject(err);
			});

		return deferredResult.promise();
	}

	static Deserialize(jsonObject: any): OperationResult {
		var model = GenericDeserialize<OperationResult>(jsonObject, OperationResult);
		return model;
	}
}