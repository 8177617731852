import * as ko from 'knockout';
import * as _ from 'underscore';

import { ISelectedEntity, QueryEntity } from 'QueryBuilder/QueryEntity/QueryEntity';
import { QueryEntityJoin } from 'QueryBuilder/QueryEntityJoin/QueryEntityJoin';
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { EntityRelationshipsMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/Metadata/EntityRelationshipsMetadataModel';
import { QUERY_BUILDER } from 'QueryBuilder/Constants';
import { EVENTS } from 'QueryBuilder/Events';
import { EntityMetadataStore } from 'QueryBuilder/Stores/EntityMetadataStore';
import { QueryEntityJoinModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { CanvasPositionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/CanvasPositionModel';
import { ViewModes } from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import { BlockUI } from 'Core/Common/BlockUi';
import { EntityTypes } from 'Core/Controls/Grid/BaseGrid/Enums/EntityTypes';
import { IQueryExpressionParams } from 'QueryBuilder/QueryExpression/IQueryExpressionParams';
import { IQueryEntityParams } from 'QueryBuilder/QueryEntity/IQueryEntityParams';
import { IQueryEntityJoinParams } from 'QueryBuilder/QueryEntityJoin/IQueryEntityJoinParams';
import { IObjectIndex } from 'QueryBuilder/IObjectIndex';
import { Event } from 'Core/Common/Event';

import QueryExpressionTemplate from 'QueryBuilder/QueryExpression/Templates/QueryExpression.html';
import { ZIndexManager } from "../../Core/Common/ZIndexManager";

export class QueryExpression extends Event{
	private _entity: KnockoutObservable<QueryEntity>;
	private _entityJoins: KnockoutObservableArray<QueryEntityJoin>;
	private _subEntityJoins: KnockoutObservableArray<QueryEntityJoin>;
	private _model: QueryExpressionModel;
	private _jsPlumb: KnockoutObservable<jsPlumbInstance>;
	private _entitiesRelationships: Array<EntityRelationshipsMetadataModel>;
	private _viewMode: ViewModes;
	private _overlayWrapper: HTMLElement;
	private _overlayBody: HTMLElement;
	private _overlayTitle: HTMLElement;
	private _enabledColumnTypes: Array<string>;
	private _objectIndexes: Array<IObjectIndex>;
	private _lookupEntityJoins: KnockoutObservableArray<QueryEntityJoin>;
	private _referenceLookupEntityJoins: KnockoutObservableArray<QueryEntityJoin>;
	private _referenceEntityJoins: KnockoutObservableArray<QueryEntityJoin>;

	constructor(params: IQueryExpressionParams) {
		super();
		this._model = params.Model;
		this._jsPlumb = params.JsPlumb;
		this._entitiesRelationships = params.EntitiesRelationships;
		this._viewMode = params.ViewMode;
		this._overlayWrapper = params.Container;
		this._overlayBody = $(this._overlayWrapper).find('.portlet-body') && $(this._overlayWrapper).find('.portlet-body')[0];
		this._overlayTitle = $(this._overlayWrapper).find('.portlet-title') && $(this._overlayWrapper).find('.portlet-title')[0];
		this._enabledColumnTypes = params.EnabledColumnTypes;
		this._entity = ko.observable(null);
		this._entityJoins = ko.observableArray([]);
		this._subEntityJoins = ko.observableArray([]);
		this._objectIndexes = params.ObjectIndexes;
		this._lookupEntityJoins = ko.observableArray([]);
		this._referenceLookupEntityJoins = ko.observableArray([]);
		this._referenceEntityJoins = ko.observableArray([]);


		this._entityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));
		this._subEntityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));
		this._lookupEntityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));
		this._referenceLookupEntityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));
		this._referenceEntityJoins.subscribe(()=>this.Trigger(EVENTS.UPDATE_SORT));

		this.AddEvent(EVENTS.COLUMN_SELECTED);
		this.AddEvent(EVENTS.UPDATE_SORT);
		this.Init();
	}

	private Init() {
		var ids: Array<ISelectedEntity> = [];
		var gridSubjectId = null;

		if (this._viewMode === ViewModes.TableView) {
			var gridSubjectJoin = _.first(this._model.EntityJoins);
			if (gridSubjectJoin) {
				gridSubjectId = gridSubjectJoin.Entity.Metadata.Id;
			}
		}

		var params: IQueryEntityParams = {
			Model: this._model.Entity,
			JsPlumb: this._jsPlumb,
			EntitiesRelationships: this._entitiesRelationships,
			IsSubject: true,
			GridSubjectEntityId: gridSubjectId,
			IsEnableSubQuery: false,
			IsInSubQuery:  false,
			IsEnableSelectSubEntity: true,
			EnabledColumnTypes: this._enabledColumnTypes,
			ObjectIndexes: this._objectIndexes,
			IsLookupJoin: false,
			IsReferenceJoin: false
		};

		var queryEntity = new QueryEntity(params);

		queryEntity.On(EVENTS.ENTITY_SELECTED, this, (eventArgs: any) => {
			this.AddJoin(eventArgs.data.EntityId);
		});

		queryEntity.On(EVENTS.ENTITY_UNSELECTED, this, (eventArgs: any) => {
			this.RemoveJoin(eventArgs.data.EntityId, eventArgs.data.Type);
		});

		queryEntity.On(EVENTS.LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
			this.AddLookupJoin(eventArgs.data.EntityId, eventArgs.data.LookupFieldId, 'Lookup');
		});

		queryEntity.On(EVENTS.LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			this.RemoveLookupJoin(eventArgs.data.EntityId, eventArgs.data.LookupFieldId);
		});

		queryEntity.On(EVENTS.REFERENCE_LOOKUP_ENTITY_SELECTED, this, (eventArgs: any) => {
			this.AddReferenceLookupJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceLookupFieldId, 'Lookup');
		});

		queryEntity.On(EVENTS.REFERENCE_LOOKUP_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			this.RemoveReferenceLookupJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceLookupFieldId);
		});

		queryEntity.On(EVENTS.REFERENCE_ENTITY_SELECTED, this, (eventArgs: any) => {
			this.AddReferenceJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceFieldId);
		});

		queryEntity.On(EVENTS.REFERENCE_ENTITY_UNSELECTED, this, (eventArgs: any) => {
			this.RemoveReferenceJoin(eventArgs.data.EntityId, eventArgs.data.ReferenceFieldId);
		});

		_.each(this._model.EntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, LookupFieldId: null }));
		_.each(this._model.SubEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, LookupFieldId: null }));
		_.each(this._model.LookupEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, LookupFieldId: item.LookupFieldId }));
		_.each(this._model.ReferenceEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, ReferenceFieldId: item.ReferenceFieldId }));
		_.each(this._model.ReferenceLookupEntityJoins, item => ids.push({ Id: item.Entity.Metadata.Id, ReferenceLookupField: item.LookupFieldId }));

		queryEntity.SetSelectedEntities(ids);

		this.InitGridSubject();

		queryEntity.On(EVENTS.AFTER_RENDER, this, (eventArgs: any) => {
			this._entityJoins([]);
			this._subEntityJoins([]);
			_.each(this._model.EntityJoins, (joinModel, index) => {
				let params: IQueryEntityJoinParams = {
					Model: joinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: this._entitiesRelationships,
					IsSubject: joinModel.Entity.IsGridSubject,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes
				};

				this._entityJoins.push(new QueryEntityJoin(params));
			});

			if(this._entityJoins().length === 1 && this._viewMode === ViewModes.TableView){
				let join =  this._entityJoins()[0];
				join.On(EVENTS.COLUMN_SELECTED, this, (eventArgs) => {
					this.Trigger(EVENTS.COLUMN_SELECTED, { Id: eventArgs.data.Id, Join: join.Model } );
				});
			}

			_.each(this._model.SubEntityJoins, (joinModel) => {

				let params: IQueryEntityJoinParams = {
					Model: joinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: this._entitiesRelationships,
					IsSubject: joinModel.Entity.IsGridSubject,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: 'Lookup'
				};

				this._subEntityJoins.push(new QueryEntityJoin(params));
			});


			_.each(this._model.LookupEntityJoins, (joinModel) => {
				let params: IQueryEntityJoinParams = {
					Model: joinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: this._entitiesRelationships,
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: 'Lookup'
				};

				this._lookupEntityJoins.push(new QueryEntityJoin(params));
			});

			_.each(this._model.ReferenceLookupEntityJoins, (joinModel, index) => {

				let params: IQueryEntityJoinParams = {
					Model: joinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: this._entitiesRelationships,
					IsSubject: joinModel.Entity.IsGridSubject,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: 'Lookup'
				};

				this._referenceLookupEntityJoins.push(new QueryEntityJoin(params));
			});

			_.each(this._model.ReferenceEntityJoins, (joinModel) => {			
				let params: IQueryEntityJoinParams = {
					Model: joinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: this._entitiesRelationships,
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: joinModel.ReferenceFieldId
				};

				this._referenceEntityJoins.push(new QueryEntityJoin(params));
			});

		});
		
		this._entity(queryEntity);
	}

	private BlockOverlayBodyAndTitle() {
		if(!this._overlayTitle && !this._overlayBody) {
			BlockUI.Block({ Target: this._overlayWrapper, ZIndex: ZIndexManager.Instance.NextValue });
			return;
		}

		if(this._overlayBody) {
			BlockUI.Block({ Target: this._overlayBody, ZIndex: ZIndexManager.Instance.NextValue });
		}

		if(this._overlayTitle) {
			BlockUI.Block( { Target: this._overlayTitle, ZIndex: ZIndexManager.Instance.NextValue });
		}
	}

	private UnBlockOverlayBodyAndTitle() {
		if(!this._overlayTitle && !this._overlayBody) {
			BlockUI.Unblock(this._overlayWrapper);
			return;
		}
		if(this._overlayBody) {
			BlockUI.Unblock(this._overlayBody);
		}

		if(this._overlayTitle) {
			BlockUI.Unblock(this._overlayTitle);
		}
	}

	InitGridSubject(){
		if(this._viewMode !== ViewModes.TableView){
			return;
		}

		let hasGridSubject = 
			_.any(this._model.EntityJoins
			.concat(this._model.SubEntityJoins)
			.concat(this._model.LookupEntityJoins)
			.concat(this._model.ReferenceEntityJoins)
			.concat(this._model.ReferenceLookupEntityJoins), (item)=> item.Entity.IsGridSubject);
			if(hasGridSubject) {
				return;
			}

			let firstJoin = _.first(this._model.EntityJoins);
			if(firstJoin){
				firstJoin.Entity.IsGridSubject = true;
				return;
			}

			firstJoin = _.first(this._model.ReferenceLookupEntityJoins);
			if(firstJoin){
				firstJoin.Entity.IsGridSubject = true;
				return;
			}
	}

	RemoveLookupJoin(entityId: number, lookupFieldId: number) {
		let entityJoin = _.find(this._lookupEntityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId && join.Model.LookupFieldId == lookupFieldId });
		entityJoin.Destroy();
		let entityJoinModel = _.find(this._model.LookupEntityJoins, join => { return join.Entity.Metadata.Id === entityId && join.LookupFieldId == lookupFieldId });
		this._model.LookupEntityJoins.splice(this._model.LookupEntityJoins.indexOf(entityJoinModel), 1);
		this._lookupEntityJoins.splice(this._lookupEntityJoins().indexOf(entityJoin), 1);
		this.DecreaseObjectIndex(entityJoinModel.Entity.Metadata.Id, entityJoinModel.Entity.Index);
	}

	AddLookupJoin(entityId: number, lookupFieldId: number, source: string) {
		this.BlockOverlayBodyAndTitle();
		EntityMetadataStore.GetEntityMetadata({ EntityId: entityId })
			.always(() => {
				this.UnBlockOverlayBodyAndTitle();
			})
			.then((data) => {
				let queryEntityModel = new QueryEntityModel();
				queryEntityModel.Metadata = data.EntityMetadata;

				let entityJoinModel = new QueryEntityJoinModel();
				entityJoinModel.Entity = queryEntityModel;
				entityJoinModel.LookupFieldId = lookupFieldId;

				entityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
				this._model.LookupEntityJoins.push(entityJoinModel);

				var entityJoinParams: IQueryEntityJoinParams = {
					Model: entityJoinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: [data],
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: source
				};

				this._lookupEntityJoins.push(new QueryEntityJoin(entityJoinParams));
			});
	}

	AddReferenceLookupJoin(entityId: number, lookupFieldId: number, source: string) {
		this.BlockOverlayBodyAndTitle();
		EntityMetadataStore.GetEntityMetadata({ EntityId: entityId })
			.always(() => {
				this.UnBlockOverlayBodyAndTitle();
			})
			.then((data) => {
				let queryEntityModel = new QueryEntityModel();
				queryEntityModel.Metadata = data.EntityMetadata;

				let entityJoinModel = new QueryEntityJoinModel();
				entityJoinModel.Entity = queryEntityModel;
				entityJoinModel.LookupFieldId = lookupFieldId;

				entityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
				this._model.ReferenceLookupEntityJoins.push(entityJoinModel);

				var entityJoinParams: IQueryEntityJoinParams = {
					Model: entityJoinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: [data],
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: source
				};

				this._referenceLookupEntityJoins.push(new QueryEntityJoin(entityJoinParams));
			});
	}

	RemoveReferenceLookupJoin(entityId: number, lookupFieldId: number) {
		let entityJoin = _.find(this._referenceLookupEntityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId && join.Model.LookupFieldId == lookupFieldId });
		entityJoin.Destroy();
		let entityJoinModel = _.find(this._model.ReferenceLookupEntityJoins, join => { return join.Entity.Metadata.Id === entityId && join.LookupFieldId == lookupFieldId });
		this._model.ReferenceLookupEntityJoins.splice(this._model.ReferenceLookupEntityJoins.indexOf(entityJoinModel), 1);
		this._referenceLookupEntityJoins.splice(this._referenceLookupEntityJoins().indexOf(entityJoin), 1);
	}

	AddReferenceJoin(entityId: number, referenceFieldId: number) {
		this.BlockOverlayBodyAndTitle();
		EntityMetadataStore.GetEntityMetadata({ EntityId: entityId })
			.always(() => {
				this.UnBlockOverlayBodyAndTitle();
			})
			.then((data) => {
				let queryEntityModel = new QueryEntityModel();
				queryEntityModel.Metadata = data.EntityMetadata;

				let entityJoinModel = new QueryEntityJoinModel();
				entityJoinModel.Entity = queryEntityModel;
				entityJoinModel.ReferenceFieldId = referenceFieldId;

				entityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
				this._model.ReferenceEntityJoins.push(entityJoinModel);

				var entityJoinParams: IQueryEntityJoinParams = {
					Model: entityJoinModel,
					ParentEntity: this._entity(),
					JsPlumb: this._jsPlumb,
					EntitiesRelationships: [data],
					IsSubject: false,
					OverlayWrapper: this._overlayWrapper,
					EnabledColumnTypes: this._enabledColumnTypes,
					ObjectIndexes: this._objectIndexes,
					JoinSource: referenceFieldId
				};

				this._referenceEntityJoins.push(new QueryEntityJoin(entityJoinParams));
			});
	}

	RemoveReferenceJoin(entityId: number, referenceFieldId: number) {
		let entityJoin = _.find(this._referenceEntityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId && join.Model.ReferenceFieldId == referenceFieldId });
		entityJoin.Destroy();
		let entityJoinModel = _.find(this._model.ReferenceEntityJoins, join => { return join.Entity.Metadata.Id === entityId && join.ReferenceFieldId == referenceFieldId });
		this._model.ReferenceEntityJoins.splice(this._model.ReferenceEntityJoins.indexOf(entityJoinModel), 1);
		this._referenceEntityJoins.splice(this._referenceEntityJoins().indexOf(entityJoin), 1);
		this.DecreaseObjectIndex(entityJoinModel.Entity.Metadata.Id, entityJoinModel.Entity.Index);
	}


	get Entity(): KnockoutObservable<QueryEntity> {
		return this._entity;
	}

	get EntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._entityJoins;
	}

	get SubEntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._subEntityJoins;
	}

	get ReferenceEntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._referenceEntityJoins;
	}

	get Model() {
		return this._model;
	}

	get LookupEntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._lookupEntityJoins;
	}

	get ReferenceLookupEntityJoins(): KnockoutObservableArray<QueryEntityJoin> {
		return this._referenceLookupEntityJoins;
	}

	AddJoin(entityId: number) {
		this.BlockOverlayBodyAndTitle();
		EntityMetadataStore.GetEntityMetadata({ EntityId: entityId })
			.always(() => {
				this.UnBlockOverlayBodyAndTitle();
			 })
			.then((data) => {
				var queryEntityModel = new QueryEntityModel();
				queryEntityModel.Metadata = data.EntityMetadata;

				if (data.EntityMetadata.Type === EntityTypes[EntityTypes.Entity]) {
					var entityJoinModel = new QueryEntityJoinModel();
					entityJoinModel.Entity = queryEntityModel;

					var relationship = _.find(data.RelatedEntitiesMetadata, item => item.EntityMetadata.Id === this._entity().Model.Metadata.Id);
					if (relationship) {
						var queryLikEntityModel = new QueryEntityModel();
						queryLikEntityModel.Metadata = relationship.LinkEntityMetadata;
						entityJoinModel.LinkEntity = queryLikEntityModel;
					}
					entityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
					this._model.EntityJoins.push(entityJoinModel);

					var entityJoinParams: IQueryEntityJoinParams = {
						Model: entityJoinModel,
						ParentEntity: this._entity(),
						JsPlumb: this._jsPlumb,
						EntitiesRelationships: [data],
						IsSubject: false,
						OverlayWrapper: this._overlayWrapper,
						EnabledColumnTypes: this._enabledColumnTypes,
						ObjectIndexes: this._objectIndexes
					};

					this._entityJoins.push(new QueryEntityJoin(entityJoinParams));
				}

				if (data.EntityMetadata.Type === EntityTypes[EntityTypes.Sub]) {
					var subEntityJoinModel = new QueryEntityJoinModel();
					subEntityJoinModel.Entity = queryEntityModel;
					subEntityJoinModel.Entity.CanvasPosition = this.CalculatePosition();
					var subEntityJoinParams: IQueryEntityJoinParams = {
						Model: subEntityJoinModel,
						ParentEntity: this._entity(),
						JsPlumb: this._jsPlumb,
						EntitiesRelationships: [data],
						IsSubject: false,
						OverlayWrapper: this._overlayWrapper,
						EnabledColumnTypes: this._enabledColumnTypes,
						ObjectIndexes: this._objectIndexes,
						JoinSource: 'Lookup'
					};

					var subEntityJoin = new QueryEntityJoin(subEntityJoinParams);
					this._model.SubEntityJoins.push(subEntityJoinModel);
					this._subEntityJoins.push(subEntityJoin);
				}
		});
	}

	CalculatePosition(): CanvasPositionModel {
		let canvasPosition = new CanvasPositionModel();
		let joins = [];
		joins = joins.concat(this._entityJoins()).concat(this._subEntityJoins()).concat(this._lookupEntityJoins()).concat(this._referenceEntityJoins());
		let maxTopJoin = _.max(joins, (item: QueryEntityJoin) => item.Model.Entity.CanvasPosition.Top);

		if (!_.isEmpty(maxTopJoin)) {
			canvasPosition.Left = maxTopJoin.Model.Entity.CanvasPosition.Left;
			canvasPosition.Top =  +maxTopJoin.Model.Entity.CanvasPosition.Top + QUERY_BUILDER.CELL_SIZE * 3;
		} else {
			canvasPosition.Left = +this._entity().Model.CanvasPosition.Left + QUERY_BUILDER.CELL_SIZE * 14;
			canvasPosition.Top = +this._entity().Model.CanvasPosition.Top + QUERY_BUILDER.CELL_SIZE * 3;
		}

		return canvasPosition;
	}

	RemoveJoin(entityId: number, type: string) {
		if (type === EntityTypes[EntityTypes.Entity]) {
			let entityJoin = _.find(this._entityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId });
			if (entityJoin) {
				entityJoin.Destroy();
				let entityJoinModel = _.find(this._model.EntityJoins, join => { return join.Entity.Metadata.Id === entityId });
				this._model.EntityJoins.splice(this._model.EntityJoins.indexOf(entityJoinModel), 1);
				this._entityJoins.splice(this._entityJoins.indexOf(entityJoin), 1);
				this.DecreaseObjectIndex(entityJoinModel.Entity.Metadata.Id, entityJoinModel.Entity.Index);
			}
		}

		if (type === EntityTypes[EntityTypes.Sub]) {
			let entityJoin = _.find(this._subEntityJoins(), join => { return join.Model.Entity.Metadata.Id === entityId });
			if (entityJoin) {
				entityJoin.Destroy();
				let entityJoinModel = _.find(this._model.SubEntityJoins, join => { return join.Entity.Metadata.Id === entityId });
				this._model.SubEntityJoins.splice(this._model.SubEntityJoins.indexOf(entityJoinModel), 1);
				this._subEntityJoins.splice(this._subEntityJoins.indexOf(entityJoin), 1);
				this.DecreaseObjectIndex(entityJoinModel.Entity.Metadata.Id, entityJoinModel.Entity.Index);
			}
		}
	}

	DecreaseObjectIndex(entityId: number, index: number) {
		let objIndex = _.find(this._objectIndexes, (indx) => {
			return indx.Id === entityId;
		});
		if(objIndex && objIndex.Index === index){
			objIndex.Index = +objIndex.Index - 1;
		}
	}

	GetTemplateHtml() {
		return QueryExpressionTemplate;
	}

	AfterRender() {}
}