export class ProductPropertyDescription {
    LookupTableId: number;
    RelatedTableId: number;
    RelatedTableFieldId: number;
    AvailableOptionsCount: number;
    constructor(public Id: number,
                public Name: string,
                public TranslatedName: string,
                public Type: string,
                public Size: number,
                public ValueHolderId: number,
                public PriceInfluence: boolean,
                public ProductEntityId: number,
                public ProductId: number,
                public IsRequired: boolean) {
    }
}