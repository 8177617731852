import * as ko from "knockout";

import 'lockr';

import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';
import {Notifier} from "Core/Common/Notifier";
import {FileDownloader} from "Core/Components/FileDownloader/FileDownloader";
import {BaseGrid} from "Core/Controls/Grid/BaseGrid/BaseGrid";
import {Toolbar} from "Core/Controls/Grid/Toolbar/Toolbar";
import {EVENTS as TOOLBAR_EVENTS} from 'Core/Controls/Grid/Toolbar/Constants';
import {Event} from "Core/Common/Event";
import {Modal} from "Core/Common/Modal";
import {GridDataModel} from "Core/Controls/Grid/Models/GridDataModel/GridDataModel";
import {EVENTS} from "Core/Controls/Grid/BaseGrid/QueryResultPage/Events";
import {EVENTS as PAGINATOR_EVENTS} from "Core/Components/Paginator/Constants";
import {EVENTS as BASE_GRID_EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import {ViewModes} from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import {BlockUI} from 'Core/Common/BlockUi'
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {GridStore, IGetGridDataRequestModel, IExportDataRequestModel} from "Core/Controls/Grid/Stores/GridStore";
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {LABELS, CONFIRMATIONS} from 'Core/Components/Translation/Locales';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';

import QueryResultPageTemplate
    from 'Core/Controls/Grid/BaseGrid/QueryResultPage/Templates/QueryResultPage.html';

import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";

import {PUB_SUB_EVENTS} from "MenuManager/PubSubEvents";
import {SortModel} from "../../Models/SortModel";
import {IBaseGridOptions} from "../../Models/GridDataModel/BaseGridModel";
import { QueryExpressionModel } from "../../Models/GridDataModel/QueryExpression/QueryExpressionModel";

ko.templates['Core/Controls/Grid/BaseGrid/QueryResultPage/Templates/QueryResultPage'] = QueryResultPageTemplate;

export class QueryResultPage extends Event {
    private _grid: KnockoutObservable<BaseGrid>;
    private _modal: Modal;
    private _toolbar: Toolbar;
    private _query: QueryExpressionModel;
    private _isRendered: boolean;
    private _exportDataAllowed: KnockoutObservable<boolean>;
    private _isEditable: KnockoutObservable<boolean>;
    private _isReady: KnockoutObservable<boolean>;
    private _screenType: ScreenTypes;
    private _sorting: Array<SortModel>;
    private _showButtons: boolean;
    private _updateFromFastFilters: boolean;
    private _selectOnRowClick: boolean;
    private _queryId: number;
    private _prevPageNumber = 1;

    RecordId = 0;

    constructor(
        isEditable: boolean = false,
        screenType: ScreenTypes = null,
        showFunctionLookup = true,
        showButtons = true,
        selectOnRowClick = false
    ) {
        super();
        this._showButtons = showButtons;
        this._isEditable = ko.observable(isEditable);
        this._isReady = ko.observable(false);
        this._screenType = screenType;
        this._isRendered = false;
        this._exportDataAllowed = ko.observable(false);
        this._selectOnRowClick = selectOnRowClick;

		this._updateFromFastFilters = false;
		this._toolbar = new Toolbar(
			false,
			showFunctionLookup,
			false,
			ko.observable(false),
            ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(false),
			ko.observable(true),
			null,
			null,
			this._exportDataAllowed,
			ko.observable(true),
			null,
			false,
			false,
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            true,
            false,
            []
        );

        this._grid = ko.observable(null);
        this.AddEvent(EVENTS.RECORDS_SELECTED);
        this.AddEvent(EVENTS.RECORD_SELECTED);
        this.AddEvent(BASE_GRID_EVENTS.OPEN_HYPERLINK);

        this.Init();
    }

    Init() {
        this._toolbar.Paginator().On(PAGINATOR_EVENTS.CHANGE, this, this.OnPaginationChange);
        this._toolbar.On(TOOLBAR_EVENTS.EXPORT_DATA, this, eventArgs => this.ExportData(eventArgs.data.Destination));
        this._toolbar.On(TOOLBAR_EVENTS.SEARCH_BY_PHRASE, this, (eventArgs: any) => {
            this.SearchByPhrase(eventArgs.data.SearchPhrase);
        });
    }

    GetTemplateName() {
        return "Core/Controls/Grid/BaseGrid/QueryResultPage/Templates/QueryResultPage";
    }

    Render(target: HTMLElement) {
        ko.cleanNode(target);
        ko.applyBindings(this, target);
    }

    SetData(
        data: GridDataModel,
        gridOptions: IBaseGridOptions = {enabledFavorite: true, isEnableSelectRecord: true},
        request: IGetGridDataRequestModel
    ) {
        let otherGridPageLines = GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES);
        let recordsPerPage = parseInt(otherGridPageLines);
        this._toolbar.Paginator().RecordsPerPage = isNaN(recordsPerPage) ? 10 : recordsPerPage;

        this._exportDataAllowed(data.IsExportDataAllowed);
        this._toolbar.Paginator().TotalRecords = data.TotalRecords;

        const grid = new BaseGrid({
            isPrioritiesDisabled: true,
            isQueryResultGrid: true,
            isEditable: this._isEditable,
            screenType: ScreenTypes[this._screenType],
            enabledFavorite: gridOptions.enabledFavorite,
            isEnableSelectRecord: gridOptions.isEnableSelectRecord || false,
            isMultiselect: gridOptions.isMultiselect
        });
        grid.IsFastFilterEnabled = true;

        grid.On(BASE_GRID_EVENTS.OPEN_HYPERLINK, this, eventArgs => this.OnHyperLink(eventArgs.data));
        grid.On(BASE_GRID_EVENTS.SORT, this, this.OnSortColumns);
        grid.On(BASE_GRID_EVENTS.SELECT_RECORD, this, (eventArgs: any) => {
            if (this._selectOnRowClick) {
                this.Trigger(EVENTS.RECORD_SELECTED, {SelectedRecord: eventArgs.data.Row});
                this._modal.Close();
            }
        });

        grid.On(BASE_GRID_EVENTS.UPDATE_GRID, this, eventArgs => this.OnUpdateGrid(eventArgs.data.updateFromFastFilters));

        grid.On(BASE_GRID_EVENTS.LOAD_SUB_GRID, this, (eventArgs)=>{
            this.OnUpdateGrid(false, eventArgs.data.SubGrid, eventArgs.data.ParentRowId);
        });

        data.SubjectEntityId = data.QueryExpression.Entity.Metadata.Id;
        data.SubjectRecordId = 0;

        this._grid(grid);
        this._grid().SetGridDataModel(request);
        this._grid().SetData(data, undefined, true);
        this._toolbar.Enable();
    }

    Close() {
        this._modal.Close();
    }

    Save() {
        let records = this._grid().GetSelectRecords();
        this.Trigger(EVENTS.RECORDS_SELECTED, {SelectedRecords: records});
        if (this._modal) {
            this._modal.Close();
        }
    }

    Show(options = {addClass: 'showScrollModal jBox-padding-15px'}, targetId = '') {
        if (targetId != '') {
            let target = document.getElementById(targetId);
            ko.cleanNode(target);
            ko.applyBindings(this, target);
        } else {
            this._modal = new Modal(options);
            ko.cleanNode(this._modal.Wrapper);
            ko.applyBindings(this, this._modal.Wrapper);
        }

        this._isRendered = true;
        this._isReady(true);
    }

    AfterRender() {
        if (this._modal) {
            this._modal.Show();
        }
    }

    ShowAddQueryResult(queryId: number) {
        let otherGridPageLines = GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES);
        let recordsPerPage = parseInt(otherGridPageLines);
        this._toolbar.Paginator().RecordsPerPage = isNaN(recordsPerPage) ? 10 : recordsPerPage;
        this._queryId = queryId;
        const getGridDataRequestModel: IGetGridDataRequestModel = {
            TableViewId: queryId,
            SubjectRecordId: 0,
            SubjectKindId: 0,
            SubjectTypeId: 0,
            SubjectStatusId: 0,
            ViewMode: ViewModes.Query,
            PageNumber: 1,
            RecordsPerPage: recordsPerPage,
            FilterByOwners: false,
            ControlId: 0,
            SubjectEntityId: 0,
            Sort: [],
            SearchPhrase: null,
            ShowPlanned: false,
            RecordOwners: []
        };

        BlockUI.Block();

        GridStore.GetData(getGridDataRequestModel)
            .always(() => {
                BlockUI.Unblock()
            })
            .then(data => {
                this.SetData(data, {isEnableSelectRecord: true}, getGridDataRequestModel);
                this.Show();
            });
    }

    ShowQueryResult(
        query?: QueryExpressionModel,
        targetId = '',
        enableSelectRecord: boolean = false,
        multiSelect: boolean = true
    ) {
        let requestModel: IGetGridDataRequestModel = this.GetQueryData(query, undefined, undefined, {enabledFavorite: true, isEnableSelectRecord: true});

        this.LoadData(requestModel).then((result) => {
            this.SetData(result, {isEnableSelectRecord: enableSelectRecord, isMultiselect: multiSelect}, requestModel);
            if (!this._isRendered) {
                this.Show({addClass: 'showScrollModal jBox-padding-15px'}, targetId);
            }
        });

    }

    LoadData(requestModel: IGetGridDataRequestModel) {
        BlockUI.Block();

        return GridStore
            .GetData(requestModel)
            .always(() => {
                BlockUI.Unblock();
            })
            .fail((error) => {
                const notifier = new Notifier(null);
                notifier.Failed(error.message);
            });
    }

    private GetQueryData(query: QueryExpressionModel = this._query, subGrid?: BaseGrid, parentRowId?: string, gridOptions?: IBaseGridOptions): IGetGridDataRequestModel {
        this._query = query;
        const otherGridPageLines = GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES);
        const recordsPerPage = parseInt(otherGridPageLines);
        this._toolbar.Paginator().RecordsPerPage = isNaN(recordsPerPage) ? 10 : recordsPerPage;

        let pageNumber = subGrid ? subGrid.Paginator.PageNumber : this._toolbar.Paginator().PageNumber;

        if (this._grid() === null){
            this._grid(new BaseGrid({
                isPrioritiesDisabled: true,
                isQueryResultGrid: true,
                isEditable: this._isEditable,
                screenType: ScreenTypes[this._screenType],
                enabledFavorite: gridOptions.enabledFavorite,
                isEnableSelectRecord: gridOptions.isEnableSelectRecord || false,
                isMultiselect: gridOptions.isMultiselect
            }));
        }

        let fastFilters = this._grid().FilterSaveModels;

        UserVarsManager.Instance.SetGridColumnFilters(this.RecordId, fastFilters);

        const preselectedFilters = UserVarsManager.Instance.GetGridColumnFilters(this.RecordId);

        if (preselectedFilters && preselectedFilters.length) {
            preselectedFilters.map((preselectedFilter) => {
                if (fastFilters.findIndex((el) => el.FieldAlias === preselectedFilter.FieldAlias) < 0) {
                    fastFilters.push(preselectedFilter);
                }
            });
        }

        return {
            TableViewId: this._queryId,
            SubjectRecordId: this.RecordId,
            SubjectTypeId: 0,
            SubjectKindId: 0,
            SubjectStatusId: 0,
            ViewMode: ViewModes.Query,
            PageNumber: pageNumber,
            RecordsPerPage: this._toolbar.Paginator().RecordsPerPage,
            Sort: this._sorting,
            Query: this._query,
            FilterByOwners: false,
            ControlId: 0,
            SubjectEntityId: 0,
            SearchPhrase: null,
            ShowPlanned: false,
            RecordOwners: [],
            ParentRowId: parentRowId,
            SubQueryGuid: subGrid ? subGrid.Model.SubQueryGuid : null,
            FastFilters: fastFilters.filter((filter) => filter.Values.length)
                .map((filter) => {
                    filter.Values.forEach(value => {
                        if (value.DisplayValue === LABELS.EMPTY_VALUE) {
                            value.DisplayValue = null;
                        }
                    });
                    return {
                        FieldAlias: filter.FieldAlias,
                        Values: filter.Values,
                        TimeZone: (new Date().getTimezoneOffset()) / 60
                    };
                })
        };
    }

    OnSortColumns = (eventArgs: any) => {
        this._sorting = eventArgs.data.SortColumns;

        let requestModel: IGetGridDataRequestModel = this.GetQueryData();

        this.LoadData(requestModel).then((result) => {
            this._grid().SetData(result, true, true);
        });
    }

    OnUpdateGrid = (updateFromFastFilters: boolean, subGrid?: BaseGrid, parentRowId?: string) => {
        this._updateFromFastFilters = updateFromFastFilters;

        let requestModel: IGetGridDataRequestModel = this.GetQueryData(this._query, subGrid, parentRowId, {enabledFavorite: true, isEnableSelectRecord: true});
        let contextGrid = subGrid || this._grid();

        this.LoadData(requestModel).then((result) => {
            this._toolbar.Paginator().TotalRecords = result.TotalRecords;
            contextGrid.SetRecordsPerPage(this._toolbar.Paginator().RecordsPerPage);
            contextGrid.SetData(result, true, true);
        });
    }

    SearchByPhrase(searchPrhase: string) {
        let requestModel: IGetGridDataRequestModel = this.GetQueryData();
        requestModel.SearchPhrase = searchPrhase;

        this.LoadData(requestModel).then((result) => {
            this._toolbar.Paginator().TotalRecords = result.TotalRecords;
            this._grid().SetData(result, true, true);
        });
    }

    OnPaginationChange = (eventArgs: { data: { pageNumber: number } }) => {
        if (eventArgs.data.pageNumber === this._prevPageNumber) {
            return;
        }

        const loadData = () => {
            let requestModel: IGetGridDataRequestModel = this.GetQueryData();

            this.LoadData(requestModel).then((result) => {
                result.SubjectEntityId = result.QueryExpression.Entity.Metadata.Id;
                result.SubjectRecordId = 0;
                this._grid().SetData(result, true, true);
            });
        };

        if (this._grid().GetEditRows().length > 0 || this._grid().GetNewAndLinkRows().length > 0) {
            const confirmationDialog = new ConfirmationDialog({
                Text: CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST,
                Type: ConfirmationTypes.Warning
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                loadData();

                this._prevPageNumber = eventArgs.data.pageNumber;
            });

            confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
                this._toolbar.Paginator().PageNumber = this._prevPageNumber;
            });

            confirmationDialog.Show();
        } else {
            loadData();

            this._prevPageNumber = eventArgs.data.pageNumber;
        }
    }

    private ExportData(destination: string) {
        const exportParams: IExportDataRequestModel = {
            Query: this._grid().Model.QueryExpression,
            Destination: destination,
            FilterByOwners: false,
            RecordOwners: [],
            ShowPlanned: true,
            ViewMode: ViewModes.Query,
            ShowRetired: false
        };

        BlockUI.Block();

        GridStore.ExportData(exportParams)
            .always(() => BlockUI.Unblock())
            .then((fileName: string) => FileDownloader.DownloadFile(fileName))
            .fail(error => new Notifier().Failed(error.message));
    }

    private GoToScreen(linkInfo) {
        UserVarsManager.Instance.AddRecent(linkInfo.EntityId, linkInfo.RecordId, linkInfo.RecordTypeId);
        Lockr.set(LOCAL_STORAGE.HYPERLINK_SOURCE, linkInfo.EntityId);

        linkInfo.IsOpenInModal = true;
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, linkInfo);
    }

    private OnHyperLink(linkInfo) {
        if (!linkInfo.ShowInModal) {
            this.Close();
        }

        this.GoToScreen(linkInfo);
    }
}