import { ILinkedRecord, ILinkedRecords, INewLinkedRecord} from "Core/Components/RecordLinker/RecordLinker";
import {P} from "Core/Common/Promise";
import {Request, IRequestOptions} from "Core/Common/Request";
import {URL} from 'Core/Common/Url'

export class RecordLinkerStore {
    static IsLinkWithRecordExist(params: ILinkedRecord) {
        const deferredResult = P.defer<any>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.COMPONENTS.RECORDLINKER.IS_LINK_WITH_RECORD_EXIST,
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => deferredResult.resolve(result))
            .fail(err => deferredResult.reject(err));

        return deferredResult.promise();
    }

    static GetExistingLinksWithRecords(params: ILinkedRecords) {
        const deferredResult = P.defer<any>();

        const requestParams: IRequestOptions = {
            proxy: {
                url: URL.COMPONENTS.RECORDLINKER.GET_EXISTING_LINKS_WITH_RECORDS,
                dataType: 'json',
                type: 'POST'
            }, params: params
        };

        Request.Send(requestParams)
            .then(result => deferredResult.resolve(result))
            .fail(err => deferredResult.reject(err));

        return deferredResult.promise();
    }

    static LinkRecord(record: ILinkedRecord): P.Promise<any> {
        let deferredResult = P.defer<any>();

        Request.Send({
            proxy: {
                url: URL.COMPONENTS.RECORDLINKER.LINK_WITH_RECORD,
                dataType: 'json',
                type: 'POST'
            },
            params: record
        }).then(result => deferredResult.resolve(result));

        return deferredResult.promise();
    }

    static LinkRecords(record: ILinkedRecords): P.Promise<any> {
        let deferredResult = P.defer<any>();

        Request.Send({
            proxy: {
                url: URL.COMPONENTS.RECORDLINKER.LINK_WITH_RECORDS,
                dataType: 'json',
                type: 'POST'
            },
            params: record
        }).then(result => deferredResult.resolve(result));

        return deferredResult.promise();
    }

    static AddAndLinkRecord(record: INewLinkedRecord) {
        let deferredResult = P.defer<any>();

        Request.Send({
            proxy: {
                url: URL.COMPONENTS.RECORDLINKER.ADD_AND_LINK_RECORD,
                dataType: 'json',
                type: 'POST'
            },
            params: record
        }).then(result => deferredResult.resolve(result));

        return deferredResult.promise();
    }
}