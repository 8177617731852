export enum ScreenTypes {
	ConsultScreen,
	ListScreen,
	EditScreen,
	None,
	FormDesigner,
	Portlet,
	SpecialScreen,
	Dashboard,
    LinkEditor,
	LookupEditorNewRecord,
	QueryScreen,
	CardScreen,
	EmptyScreen
}