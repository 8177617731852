import clone from 'clone';

import {deserialize, deserializeAs, Deserialize} from 'libs/cerialize';
import {GridCellValueModel, IRecordModel, RecordKey} from 'Core/Controls/Grid/Models/GridDataModel/GridCellValueModel';
import {RightsModel} from 'Core/ScreenManager/Models/RightsModel';

export class GridRowModel {

    constructor() {
        this.Data = [];
        this.NestedData = [];
        this.Rights = new RightsModel();
        this.Rights.IsEditingAllowed = true;
        this.RecordKeys = [];
        this.IsUnlinkCheckboxChecked = false;
    }

    NestedData: any;

    TypeId?: number;

    @deserialize
    KindId?: number;

    @deserializeAs(GridCellValueModel)
    Data: Array<GridCellValueModel>;

    @deserialize
    EntityId: number;

    @deserialize
    RecordId: number;

    @deserialize
    IsGroup: boolean;

    @deserialize
    RecordTypeId: number;

    @deserialize
    LifestatusId: number;

    @deserialize
    IsStartGroup: boolean;

    @deserialize
    IsEndGroup: boolean;

    @deserialize
    HideToggleDisableButton: boolean;

    @deserialize
    KSeq: number;

    @deserialize
    IsRetired: boolean;

    @deserialize
    KSeqs: Array<number>;

    @deserialize
    GroupGuid: string;

    @deserialize
    IsUnlinkCheckboxChecked: boolean;

    static OnDeserialized(instance: GridRowModel, json: any): void {
        const gridDataModel = require('Core/Controls/Grid/Models/GridDataModel/GridDataModel').GridDataModel;
        instance.NestedData = Deserialize(json.NestedData, gridDataModel);
    }

    GetRecordModelIds(): Array<IRecordModel> {
        return this.Data.map(model => model.GetRecordIds());
    }

    Clone() {
        return clone(this);
    }

    @deserializeAs(RightsModel)
    Rights: RightsModel;

    @deserialize
    RelationshipType: number;

    @deserializeAs(RecordKey)
    RecordKeys: Array<RecordKey>;

    @deserialize
    RowId: string;

    @deserialize
    ParentRowId: string;
}