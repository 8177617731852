import * as ko from 'knockout';
import * as $ from 'jquery';

import 'jqueryInputmask';
import 'inputmaskExtensions';
import 'inputmaskRegEx';

import {FormatConverter} from "FormatEditor/FormatConverter";

interface IDecimalInputMaskOptions {
    CanBeNegative: boolean;
    CanBeZero: boolean;
    Size: number;
}

export class DecimalInputMaskExtension {
    static Init() {
        ko.bindingHandlers.decimalInputMask = {
            init: (element, valueAccessor: KnockoutObservable<IDecimalInputMaskOptions>) => {
                const options = this.ExtendOptions(valueAccessor());

                const decimalSeparator = this.GetSeparator();
                if (options.CanBeNegative){
                    $(element).inputmask('Regex', {regex: '-?[0-9]{0,' + (29 - options.Size) + '}[' + decimalSeparator + ']{1}[0-9]{0,' + options.Size + '}'});
                } else {
                    $(element).inputmask('Regex', {regex: '[0-9]{0,' + (29 - options.Size) + '}[' + decimalSeparator + ']{1}[0-9]{0,' + options.Size + '}'});
                }
            }
        }
    }

    private static ExtendOptions(options: IDecimalInputMaskOptions): IDecimalInputMaskOptions {
        return {
            CanBeNegative: options.CanBeNegative !== undefined ? options.CanBeNegative : true,
            CanBeZero: options.CanBeZero !== undefined ? options.CanBeZero : true,
            Size: options.Size
        };
    }

    private static GetSeparator(): string {
        const culture = FormatConverter.GetLocale();
        const n = 1.1;
        return n.toLocaleString(culture).substring(1, 2);
    }
}