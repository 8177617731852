import * as $ from 'jquery';
import * as _ from 'underscore';
import * as ko from 'knockout';

import {BaseScreen} from 'Core/Screens/BaseScreen';
import {SubForm} from 'Core/Screens/Forms/SubForm/SubForm';
import {BlockUI} from 'Core/Common/BlockUi';
import {Search} from 'Core/Controls/Search/Search';
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {IControl} from 'Core/Controls/IControl';
import {RenderModes, ControlTypes, SYSTEM_TABLE_NAMES, LIFE_STATUS_GROUPS} from 'Core/Constant';
import { FollowupModes } from 'Core/Constants/FollowupModes';
import {DataModel} from 'Core/Screens/EditScreen/Models/DataModel';
import {InsertOrUpdateRecordStore} from 'Core/Screens/EditScreen/Stores/InsertOrUpdateRecordStore';
import {Notifier} from 'Core/Common/Notifier';

import {ScreenDataModel} from 'Core/ScreenManager/Models/ScreenDataModel';
import {RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel';
import {RecordPersonalizationModel} from 'Core/ScreenManager/Models/RecordPersonalizationModel';
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel';
import {LifeStatusInfo} from 'Core/ScreenManager/Models/LifeStatusInfo';
import {RecordExampleModel} from 'Core/ScreenManager/Models/RecordExampleModel';

import {MenuManager} from 'MenuManager/MenuManager';
import {MakePersonal} from 'Core/Components/Controls/MakePersonal/MakePersonal';
import {MakeExample} from 'Core/Components/Controls/MakeExample/MakeExample';
import {RecordStore} from 'Core/Screens/Stores/RecordStore';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {
	DeletionModeDialog,
	EVENTS as DELETION_MODE_DIALOG_EVENTS
} from 'Core/Components/Dialogs/DeletionModeDialog/DeletionModeDialog';
import {DeletionModeModel} from "Core/Screens/Models/DeletionModeModel";
import {Password} from 'Core/Controls/Password/Password';
import {CONTROL_TYPES, SIMPLE_CONTROLS, SECURITY_CONTROLS} from 'Core/Constant';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';
import {ScreenManager} from 'Core/ScreenManager/ScreenManager';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {DataModes} from 'Core/Enums/DataModes';
import {P} from 'Core/Common/Promise';
import {NewRelationModel} from 'Core/Controls/LinkList/Models/NewRelationModel';
import {NOTIFICATIONS, LABELS, CONFIRMATIONS} from 'Core/Components/Translation/Locales';
import {ButtonFollowUp} from 'Core/Controls/ButtonFollowUp/ButtonFollowUp';
import {FollowUpRecordModel} from 'Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel';
import {SecurityEditor} from 'Core/Components/SecurityEditor/SecurityEditor';
import {ProgressBar} from 'Core/Components/ProgressBar/ProgressBar';
import {Grid} from 'Core/Controls/Grid/Grid';

import {MobileChecker} from "Core/Common/MobileChecker";
import {Serialize} from 'libs/cerialize';
import {LockManager} from "Core/Components/Locker/LockManager";
import {SecurityEditorModel} from 'Core/Components/SecurityEditor/Models/SecurityEditorModel';
import {LinkList} from 'Core/Controls/LinkList/LinkList';
import {UserModel} from 'Core/Controls/LinkList/Models/UserModel';
import {EVENTS as MAKE_EXAMPLE_EVENTS} from "Core/Components/Controls/MakeExample/Events";
import {GetServerRequestError} from "Core/Common/Enums/ServerRequestErrors";
import {UpdateDataModel} from 'Core/Controls/LinkList/Models/UpdateDataModel';
import {ILoadScreenRelationModel, LinkEditor} from 'Core/Screens/Common/LinkEditor/LinkEditor';
import {ResizeObserver} from 'Core/Common/ResizeObserver';
import {BREADCRUMBS_UPDATE_RECORD_NAME} from "MenuManager/Breadcrumbs/Breadcrumbs";

// Templates
import Template from 'Core/Screens/Templates/AdditionalConsultScreen/EditScreen/EditScreen.html';
ko.templates['Core/Screens/Templates/AdditionalConsultScreen/EditScreen/EditScreen'] = Template;

const ResizeService = new ResizeObserver();

export class EditScreen extends BaseScreen {
    private _isDataFromExample: boolean;
    private _isFollowUp: boolean = false;
    private _makePersonal: MakePersonal;
    private _makeExample: MakeExample;
    private _securityEditor: SecurityEditor;
    private _progressBar: ProgressBar;
    private _sourceSearchTerm: KnockoutObservable<string>;
    private _followUpRecord: FollowUpRecordModel;
    private _requiredControls: Array<IControl>;
    private _statusDescription: string;
    private _isHamburgerActive: KnockoutObservable<boolean>;
    private _areWidthsEqual: KnockoutObservable<boolean>;

    ParentRecordId: number = 0;
    ParentRecordTypeId: number = 0;
    UseLinking: boolean = false;
    SaveImmediately: boolean = true;
    NewlyCreatedRecord: boolean = false;

    constructor(screenModel: ScreenModel) {
        super(screenModel, RenderModes.Edit);
        let self = this;
        this._sourceSearchTerm = ko.observable('');

        MobileChecker.IsMobile();
        let mobileOptions = MobileChecker.IsMobile() ? {
            minWidth: '100%',
            width: '100%',
            height: '100%',
        } : {};

        this.SetModalOptions({
            minHeight: MobileChecker.IsMobile() ? '100%' : 450,
            closeOnEsc: false,
            addClass: 'edit-screen-modal showScrollModal',
            blockScroll: true,
            onOpen: function () {
                $(this.closeButton[0]).off('click');
                this.closeButton[0].addEventListener('click', self.Close.bind(self));
            },
            ...mobileOptions
        });

        this._renderMode = RenderModes.Edit;

        this._makePersonal = null;
        this._makeExample = null;
        this._securityEditor = null;

        this.AddEvents();

        this.Init(screenModel);

        this._requiredControls = _.filter(this.GetAllControls(), (control) => {
            return control.GetIsRequired()
        });

        this.SetData(screenModel.Data);
        this.InitProgressBar(screenModel.Data);

        if (!screenModel.Data) {
            screenModel.Data = new ScreenDataModel();
            screenModel.Data.RecordId = 0;
        }

        const changingSecurityAllowed = screenModel.Data.Rights &&
            screenModel.Data.Rights.ChangeSecurityAllowance &&
            screenModel.Data.RecordSpecs &&
            !screenModel.Data.RecordSpecs.IsNewRecord;

        const editingSecurityAllowed = screenModel.Data.Rights && screenModel.Data.Rights.IsChangingSecurityAllowed;
        this._securityEditor && this._securityEditor.Setup(changingSecurityAllowed, !editingSecurityAllowed);

        this.On('CANCEL', this, () => {
            this.Close();
        });
        this._isHamburgerActive = ko.observable(false);
        this._areWidthsEqual = ko.observable(false);
    }

    AddEvents() {
        this.AddEvent('CLOSE');
        this.AddEvent('CANCEL');
        this.AddEvent('ON_CHANGED_PAGE_LEAVING');
        this.AddEvent('SAVE');
        this.AddEvent('DELETE_RECORD');
        this.AddEvent('SEARCH_RECORD');
        this.AddEvent('FOLLOWUP');
        this.AddEvent('FOLLOWUP_DONE');
        this.AddEvent('COPY_RECORD');
        this.AddEvent('COPY_RECORD_WITH_RELATIONS');
        this.AddEvent('MODAL_CLOSE');
        this.AddEvent('RECORD_SAVED');
        this.AddEvent('RECORD_REMOVED');
        this.AddEvent('FOLLOWUP_RECORD');
        this.AddEvent('COPY');
        this.AddEvent('RECORD_DELETED');
        this.AddEvent('DATA_CHANGED');
        this.AddEvent('LINK_LIST_DATA_LOADED');
        this.AddEvent('READY_TO_SAVE');
        this.AddEvent('RECORD_SELECTED');
    }

    PreventWindowConfirmation() {
        const eventWindowConfirmation = (event) => {
            let isModifiedControl = _.find(this.GetAllControls(), (control) => {
                return control.IsModified();
            });
            if (isModifiedControl && this.IsInModal()) {
                // Cancel the event as stated by the standard.
                event.preventDefault();
                // Chrome requires returnValue to be set.
                event.returnValue = 'Would you like to leave the page?';
                return 'Would you like to leave the page?'
            } else {
                this.Trigger('ON_CHANGED_PAGE_LEAVING');
            }
        };

        window.addEventListener('beforeunload', eventWindowConfirmation);

        this.On('ON_CHANGED_PAGE_LEAVING', this, () => {
            window.removeEventListener('beforeunload', eventWindowConfirmation);
        });
    }

    ToggleActionBar(el){
        const actionBarWrapper = $(el).find('.action-bar-wrapper');
        this._areWidthsEqual(false);

        if (actionBarWrapper.length) {
            const actionBar_barLeft = $('.bar-left', actionBarWrapper).width();
            const actionBar_controls = $('.actionBar-Controls', actionBarWrapper).width();

            let isLeftAndControlsEqual: boolean = actionBar_barLeft === actionBar_controls
            if (actionBar_barLeft === 0 && actionBar_controls == 0) {
                isLeftAndControlsEqual = false
            }

            this._areWidthsEqual(isLeftAndControlsEqual);
        }
    }

    ToggleHamburger(data, event){
        $(event.target).find('.ham').toggleClass('active');
        this._isHamburgerActive(!this._isHamburgerActive());
    }

    ToggleLifeStatusBarClass(data, event){
        if ($(window).width() <= 991){
            $(event.target).parents('.lifestatus-bar-wrapper').toggleClass('showLifeStatus');
        }
    }

    IsSubjectScreen() {
        return true;
    }

    SetData(dataModel: ScreenDataModel) {
        if (!dataModel) {
            return;
        }

        if (!dataModel.Rights.IsRecordSecurityOk) {
            new Notifier().Warning(`Record security is not set for ${this.GetEntityName()} table`);
            return;
        }

        if (dataModel.RecordSpecs.LifeStatusInfo.Name === 'Example' && dataModel.RecordSpecs.RecordExampleInfo.IsExample) {
            this.AllowEmptyRequiredControls(true);
        }

        super.SetData(dataModel);
    }

    private Init(screenModel: ScreenModel) {
        this.PreventWindowConfirmation();
        this.On('SAVE', this, (eventArgs: any) => {
            this.SaveGrids();
            this.Save();
        });
        this.On('DELETE_RECORD', this, (eventArgs: any) => {
            this.DeleteRecord();
        });
        this.On('SEARCH_RECORD', this, (eventArgs: any) => {
            this.SearchRecord();
        });
        this.On('FOLLOWUP', this, (eventArgs: any) => {
            this.FollowUp();
        });
        this.On('FOLLOWUP_DONE', this, (eventArgs: any) => {
            this.FollowUp(true);
        });
        this.On('COPY_RECORD', this, (eventArgs: any) => this.OnCopy(DataModes.Copy));
        this.On('COPY_RECORD_WITH_RELATIONS', this, (eventArgs: any) => this.OnCopy(DataModes.CopyWithRelations));

        var bottomBar = this._bottomBar;
        if (bottomBar) {
            _.each(this.SubForms, subForm => {
                subForm.On('EXPANDED', this, (eventArgs: any) => {
                    bottomBar.IsExpanded = true;
                });
                subForm.On('COLLAPSED', this, (eventArgs: any) => {
                    bottomBar.IsExpanded = false;
                });
            });
        }

        if (screenModel.Data) {
            var recordSpec = screenModel.RecordSpecs || screenModel.Data.RecordSpecs;
            if (recordSpec) {
                this._makePersonal = new MakePersonal({
                    IsPersonal: recordSpec.RecordPersonalization.IsPersonalized,
                    PossibleToPersonalize: recordSpec.RecordPersonalization.PossibleToPersonalize
                });

                this._makeExample = new MakeExample({
                    IsExample: recordSpec.RecordExampleInfo.IsExample,
                    PossibleToExample: recordSpec.RecordExampleInfo.PossibleToExample
                });
                this._makeExample.On(MAKE_EXAMPLE_EVENTS.MAKE_EXAMPLE, this, (evtArgs) => {
                    this.AllowEmptyRequiredControls(evtArgs.data.IsExample);
                });
            }

            this._securityEditor = new SecurityEditor(screenModel.EntityId, screenModel.Data.RecordId);
            this._securityEditor.On('SAVING_CHANGES', this, eventArgs => this.ApplySecurityEditorChanges(eventArgs.data));
        }
    }

    private AllowEmptyRequiredControls(isAllowed: boolean) {
        _.each(this._requiredControls, (control) => {
            control.SetIsRequired(!isAllowed);
        });
    }

    private OnCopy(dataMode: DataModes) {
        const self = this;
        this._dataMode = dataMode;

        if (this.ValidateData()) {
            this.IsTypeAvailable(self._model.TableTypeId, self._model.EntityId)
                .then(() => {
                    this.DeserializeData().then(result => {
                        UserVarsManager.Instance.RemoveFromRecent(self.GetRecordId(), self._model.EntityId, self._model.TableTypeId);
                        this.Trigger('COPY', {recordId: result.recordId, dataMode: dataMode});
                    });
                });
        }
    }

    private ApplySecurityEditorChanges(model: SecurityEditorModel) {
        const linkList = this.GetControl(CONTROL_TYPES.LinkList) as LinkList;
        if (!linkList) return;

        linkList.UpdateRecordOwner(new UserModel(model.Owner.Id, model.Owner.Name, model.Owner.SecurityData.UserAllowance));
    }

    private InitProgressBar(data: ScreenDataModel) {
        if (data && data.RecordSpecs && data.RecordSpecs.LifeStatusInfo) {
            this._progressBar = new ProgressBar({
                EntityId: this.GetEntityId(),
                RecordId: this.GetRecordId(),
                TypeId: this.GetTableTypeId(),
                RenderMode: this._renderMode,
                Screen: this,
                RecordSpecs: data.RecordSpecs,
                MigratedRequired: data.IsTypeTransformationRequired
            });
            this._statusDescription = data.RecordSpecs.LifeStatusInfo.MemoTranslation || data.RecordSpecs.LifeStatusInfo.Memo;
        }
    }

    SetFocusOnFirstControl() {
        const control = this.GetControlBy(c => c.IsFocusable());
        if (control) {
            control.SetFocus(true);
        }
    }

    GetTemplateName(): string {
        return 'Core/Screens/Templates/AdditionalConsultScreen/EditScreen/EditScreen';
    }

    AfterRender(el) {
        super.AfterRender(el);
        let isModalMode: boolean = !this.TargetId || false;
        this.SetScreenStyle(isModalMode);

        if (this._dataModel) {
            this.SetFocusOnFirstControl();
        }

        ko.utils.domNodeDisposal.addDisposeCallback(el[0], ()=>{
            $(document).off('keydown', this.CtrlS);
        });

        $(document).keydown(this.CtrlS);

        const unbindResize = ResizeService.SubscribeWidth(this.OnResize.bind(this, el), el[0]); // subscribe on resize

        ko.utils.domNodeDisposal.addDisposeCallback(el, () => {
            unbindResize();
        });
        this.ToggleActionBar(el);
    }

    OnResize = (el) => {
        this.ToggleActionBar(el);
    };

    CtrlS = (event) => {
        if(event.keyCode == 83 && event.ctrlKey && MenuManager.Instance.GetTopScreen() === this) {
            this.Trigger('SAVE');
            return false;
        }
    }

    FollowUp(isDone: boolean = false) {
        const self = this;
        if (this.ValidateData()) {
            this._isFollowUp = true;

            this.DeserializeData().then(result => {
                UserVarsManager.Instance.RemoveFromRecent(self.GetRecordId(), self._model.EntityId, self._model.TableTypeId);

                let followUpControl = this.GetControlByType(CONTROL_TYPES.ButtonFollowUp) as ButtonFollowUp;
                if (!isDone) {
                    this.Trigger('FOLLOWUP_RECORD', {recordId: result.recordId, followUpRecordModel: followUpControl.GetRecord(), followUpRecordId: result.followUpRecordId});
                }
            });
        }
    }

    SaveGrids() {
        let grids = this.GetControlsByType(CONTROL_TYPES.Grid);
        _.each(grids,
            item => {
                let grid = item as Grid;
                grid.SaveEditRows();
                grid.ReleaseLockRows();
            });
    }

    ReleaseLockGrids() {
        let grids = this.GetControlsByType(CONTROL_TYPES.Grid);
        _.each(grids,
            item => {
                let grid = item as Grid;
                grid.ReleaseLockRows();
            });
    }

    Save(close: boolean = true) {
        let deferredResult = P.defer<any>();
        if (this._dataModel.Rights.IsRecordSecurityOk) {
            if (this.ValidateData()) {
                this.DeserializeData(close).then((data) => {
                    deferredResult.resolve(data);
                });
            }
        } else {
            new Notifier().Warning(`Record security is not set for ${this.GetEntityName()} table`);
        }
        return deferredResult.promise();
    }

    Close() {
        this.Trigger('ON_CHANGED_PAGE_LEAVING');
        if (!this._dataModel.RecordSpecs || !this._dataModel.RecordSpecs.IsNewRecord) {
            LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId());
        }

        this.ReleaseLockGrids();
        this.ControlPopupsClose();
        super.Close();
    }

    AfterSave() {
        if (!this.IsInModal()) {
            if (this._isFollowUp) {FollowupModes
                if (!this._followUpRecord ||
                    (this._followUpRecord.LifeStatusSort !== null
                        && this._followUpRecord.LifeStatusSort !== LIFE_STATUS_GROUPS.RETIRED
                        && !this._followUpRecord.LifeStatusNoActionNode
                        && this._actionSubjectRecord
                        && (this._followUpRecord.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_CURRENT_AND_NEW
                            || this._followUpRecord.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_NEW))) {
                    this.NewRecord(this.GetTableTypeId(), this.GetKindId(), this.GetRecordId(), null, DataModes.FollowUp);
                }
            } else if (this._dataMode === DataModes.Copy || this._dataMode === DataModes.CopyWithRelations) {
                this.IsTypeAvailable(this.GetTableTypeId(), this.GetEntityId())
                    .then(() => this.NewRecord(this.GetTableTypeId(), this.GetKindId(), this.GetRecordId(), null, this._dataMode));
            } else {
                this.LoadScreenFor(this.GetTableTypeId(), this.GetRecordId());
            }
        } else {
            super.Close();
        }
    }


    ControlPopupsClose() {
        let followUpControl = this.GetControlByType(CONTROL_TYPES.ButtonFollowUp);

        if (followUpControl) {
            (followUpControl as ButtonFollowUp).Close();
        }

        if (this._securityEditor) {
            this._securityEditor.Close();
        }

        this._progressBar.CloseActionCheckList();
    }

    GetTextSearchTermControl(): IControl {
        return _.first(_.filter(this.GetAllControls(), (control: IControl) => control.GetType() === 'Search'));
    }

    SearchRecord(): void {
        const SearchControl = this.GetTextSearchTermControl();
        const searchTerm = SearchControl ? SearchControl.GetValue() : '';
        const searchScreen = new SearchScreen({EntityId: this.GetEntityId(), SearchTerm: searchTerm});

        searchScreen.On('RECORD_SELECTED', this, (eventArgs) => {
            this._sourceSearchTerm(eventArgs.data.SearchTerm);

			const recordId = eventArgs.data.RecordId;
			const typeId = eventArgs.data.TypeId;
            UserVarsManager.Instance.AddRecent(this.GetEntityId(), recordId, typeId);

            this.Trigger('RECORD_SELECTED', {
				RecordId: recordId,
				TableTypeId: typeId,
                SearchTerm: eventArgs.data.SearchTerm
            });
        });

        searchScreen.On('NEW_RECORD', this, (eventArgs) => {
            const typeScreen = new TypeScreen(this.GetEntityId(), this.GetTableTypeId(), true);

            typeScreen.On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND));

            typeScreen.On('TYPES_FOUND', this, (eventArgs) => searchScreen.Cancel());

            typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {
                const exampleRecordId = eventArgs.data.ExampleRecordId;
                const typeId = eventArgs.data.TypeId;
                const kindId = eventArgs.data.KindId;

                this.NewRecord(typeId, kindId, exampleRecordId, searchScreen.SearchTerm());
            });

            typeScreen.Show();
        });

        searchScreen.Show();
    }

    LoadScreenFor(tableTypeId: number, recordId: number) {
        BlockUI.Block();
        ScreenManager.GetScreenByScreenType(this.GetEntityId(), ScreenTypes.EditScreen, recordId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(screen => {
                screen.SetIsReady(true);
                MenuManager.Instance.GoToScreen(screen, this.IsInModal());
                var baseScreen = screen as BaseScreen;
                var textSearchTerm = baseScreen.GetControl<Search>('Search');
                if (textSearchTerm) {
                    textSearchTerm.SetSearchTerm(this._sourceSearchTerm());
                }
            }).fail(err => new Notifier().Warning(err.message));
    }

    NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number, searchTerm: string, dataMode: DataModes = DataModes.Default) {
        BlockUI.Block();

        ScreenManager.GetEditScreen({
            EntityId: this.GetEntityId(),
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0,
            DataMode: dataMode,
            ParentRecordId: this.GetRecordId(),
            SubjectLifestatusId: this._followUpRecord ? this._followUpRecord.LifeStatusId : null
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                const editScreen = screen;
                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.ParentRecordId = this.GetRecordId();
                editScreen.ParentRecordTypeId = this.GetTableTypeId();

                editScreen.UseLinking = true;

                if (searchTerm && searchTerm !== '') {
                    this.NameControlSetValue(searchTerm);
                }

                screen.On('RECORD_SAVED', this, (eventArgs) => {
                    const notifier = new Notifier($(this._el));
                    notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                    LockManager.Instance.ReleaseLock(screen.GetEntityId(), screen.GetRecordId());
                    if (this.IsInModal() && !this.IsDashMain) {
                        this.Trigger('RECORD_SAVED');
                    } else {
                        this.LoadScreenFor(tableTypeId, eventArgs.data.RecordId);
                    }

                    UserVarsManager.Instance.AddRecent(this.GetEntityId(), eventArgs.data.RecordId, tableTypeId);
                });

                screen.On('RECORD_REMOVED', this, (eventArgs) => {
                    const notifier = new Notifier($(this._el));
                    notifier.Success(screen.DeleteRecordSuccessNotification);
                    this.LoadScreenFor(null, null);
                });

                if (this.IsDashMain) {
                    screen.On('COPY',
                        this,
                        (eventArgs: any) => {
                            this.IsTypeAvailable(this.GetTableTypeId(), this.GetEntityId())
                                .then(() => this.NewRecord(this.GetTableTypeId(), this.GetKindId(), eventArgs.data.recordId, null, eventArgs.data.dataMode));
                        });
                }

                screen.ShowInModal();

            })
            .fail(error => {
                new Notifier($(this._el)).Warning(error.message);
            });
    }

    DeleteRecord(): void {
        const notifier = new Notifier($(this._el));
        let checkRecordId = this._isDataFromExample ? 0 : this.GetRecordId();

        if (!checkRecordId || checkRecordId === 0) {
            notifier.Warning(NOTIFICATIONS.ERROR_DELETING_RECORD);
            return;
        }

		if (!this.IsEntitySysUsersOrCd()) {
			RecordStore.GetDeletionMode({ EntityId: this.GetEntityId(), RecordId: this.GetRecordId() })
				.always(() => {
					BlockUI.Unblock();
				})
				.then(result => {
					if (!result.IsSuccessfull) {
						notifier.Failed(result.ErrorMessage || NOTIFICATIONS.ERROR_DELETING_RECORD);
						return;
					}

					const deletionMode = result.ResultObject;

					if (deletionMode.OnDelete || deletionMode.OnDelete === 0) {
						this.ShowDeletionModeDialog(deletionMode);
					} else {
						this.ShowConfirmationDialog();
					}
				});
		} else {
			this.ShowConfirmationDialog();
		}
        
    }

	private ShowDeletionModeDialog(deletionModeModel: DeletionModeModel) {
	    const deletionModeDialog = new DeletionModeDialog({
		    DeletionMode: deletionModeModel.OnDelete,
			HasCustomLifeStatus: deletionModeModel.HasCustomLifeStatus
	    });

	    deletionModeDialog.On(DELETION_MODE_DIALOG_EVENTS.DELETE_SELECTED, this, () => {
		    this.UseDeleteDeletionMode();
		});

	    deletionModeDialog.On(DELETION_MODE_DIALOG_EVENTS.DISABLE_SELECTED, this, () => {
		    this.UseDisableDeletionMode();
	    });

	    deletionModeDialog.Show();
    }

	private ShowConfirmationDialog() {
		const confirmationDialog = new ConfirmationDialog({
			Text: this.DeleteRecordConfirmation,
			Type: ConfirmationTypes.Question
		});

		confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
			this.UseDeleteDeletionMode();
		});
		confirmationDialog.Show();
	}

	private UseDeleteDeletionMode() {
		BlockUI.Block();

		const notifier = new Notifier($(this._el));
		RecordStore.DeleteRecord({ EntityId: this.GetEntityId(), RecordId: this.GetRecordId() })
			.always(() => {
				BlockUI.Unblock();
			})
			.then(result => {
				if (!result.IsSuccessfull) {
					notifier.Failed(result.ErrorMessage || NOTIFICATIONS.ERROR_DELETING_RECORD);
					return;
				}

				if (result.Warnings && result.Warnings.length > 0) {
					_.each(result.Warnings, (warning) => notifier.Warning(warning));
					this.Trigger('RECORD_SAVED', {
						EntityId: this.GetEntityId(),
						RecordId: this.GetRecordId()
					});
					this.Close();
				} else {
					this.Trigger('RECORD_DELETED', { RecordId: this.GetRecordId() });
					this.DeleteFromRecent();
				}
			})
			.fail(error => {
				notifier.Warning(error.message);
			});
	}

	private UseDisableDeletionMode() {
		BlockUI.Block();

		const notifier = new Notifier($(this._el));
		RecordStore.DeleteRecord({ EntityId: this.GetEntityId(), RecordId: this.GetRecordId(), Disable: true })
			.always(() => {
				BlockUI.Unblock();
			})
			.then(result => {
				if (!result.IsSuccessfull) {
					notifier.Failed(result.ErrorMessage);
					return;
				}

				this.Trigger('RECORD_SAVED', {
					EntityId: this.GetEntityId(),
					RecordId: this.GetRecordId()
				});
				this.Close();
			})
			.fail(error => {
				notifier.Warning(error.message);
			});
	}

    private DeleteFromRecent() {
        UserVarsManager.Instance.RemoveFromRecent(this.GetRecordId(), this._model.EntityId, this._model.TableTypeId);
        this.Trigger('RECORD_REMOVED');
        this.Close();
    }

    set IsDataFromExample(isFromExample: boolean) {
        this._isDataFromExample = isFromExample;
    }

    get IsDataFromExample(): boolean {
        return this._isDataFromExample;
    }

    ValidateData() {
        var result = true;
        _.each(this._controls, control => {
            var isControlValid = control.IsValid();
            if (!isControlValid) {
                result = false;
            }
        });

        return result;
    }

    DeserializeData(close: boolean = true): P.Promise<any> {
        let deferredResult = P.defer<any>();

        const dataModel = new DataModel();
        dataModel.EntityId = this.GetEntityId();
        dataModel.RecordId = this._isDataFromExample ? 0 : this.GetRecordId();
        dataModel.ParentRecordId = this.ParentRecordId;
        dataModel.ParentRecordTypeId = this.ParentRecordTypeId;
        dataModel.ScreenTypeId = this.GetTypeId();
        dataModel.UseLinking = this.UseLinking;
        dataModel.FillFromExample = this._isDataFromExample && (this.GetDataMode() === DataModes.Default
            || this.GetDataMode() === DataModes.FollowUp || this.GetDataMode() === DataModes.CopyWithRelations);
        dataModel.ExampleRecordId = this._isDataFromExample && (this.GetDataMode() === DataModes.Default
            || this.GetDataMode() === DataModes.FollowUp || this.GetDataMode() === DataModes.CopyWithRelations) ? this.GetRecordId() : 0;
        dataModel.EntityTypeId = this.GetTableTypeId();
        dataModel.KindId = this.GetKindId();
        dataModel.ActionSubjectRecord = this._actionSubjectRecord;

        let passwordControl = this.GetControl<Password>(CONTROL_TYPES.Password);

        if (passwordControl) {
            dataModel.PasswordAction = passwordControl.SelectedAction;
        }

        let personalizationInfo = this._makePersonal.GetPersonalizationInfo();
        let exampleInfo = this._makeExample.GetExampleInfo();

        dataModel.RecordSpecs = new RecordSpecsModel({
            RecordPersonalization: new RecordPersonalizationModel({
                PossibleToPersonalize: personalizationInfo.PossibleToPersonalize(),
                IsPersonalized: personalizationInfo.PersonalizationSelected()
            }),
            RecordExampleInfo: new RecordExampleModel({
                PossibleToExample: exampleInfo.PossibleToExample(),
                IsExample: exampleInfo.ExampleSelected()
            }),
            LifeStatusInfo: new LifeStatusInfo()
        });

        if (this._securityEditor) {
            dataModel.RecordSettings = this._securityEditor.GetData();
        }

        if (this._progressBar) {
            const lifeStatusInfo = this._progressBar.GetLifeStatus();
            dataModel.RecordSpecs.LifeStatusInfo = lifeStatusInfo;
        }

        if (this._isFollowUp) {
            dataModel.FollowUpRecord = new FollowUpRecordModel();
        }

        _.each(this._controls, control => {
            let controlType = control.GetType();

            if (SIMPLE_CONTROLS.indexOf(controlType) >= 0) {
                const hasChanges = control.IsModified() || control.GetFieldModel().HasDefaultValue
                    || this._isDataFromExample || this._actionSubjectRecord;
                const data = control.Deserialize();
                if (data && hasChanges) {
                    dataModel.FieldValueList.push(data);
                }
            }

            if (SECURITY_CONTROLS.indexOf(controlType) >= 0) {
                const data = control.Deserialize();

                if (data) {
                    if (controlType === CONTROL_TYPES.TableSecurity) {
                        dataModel.TableSecurityChanges = data;
                    }

                    if (controlType === CONTROL_TYPES.TypeSecurity) {
                        dataModel.TypeSecurityChanges = data;
                    }

                    if (controlType === CONTROL_TYPES.RecordSecurity) {
                        dataModel.RecordSecurityChanges = data;
                    }

                    if (controlType === CONTROL_TYPES.RecordSharing) {
                        dataModel.RecordSharingChanges = data;
                    }

                    if (controlType === CONTROL_TYPES.UserSecurity) {
                        dataModel.UserSecurityChanges = data;
                    }

                    if (controlType === CONTROL_TYPES.UserAllowance) {
                        dataModel.UserAllowanceChanges = data;
                    }

                    if (controlType === CONTROL_TYPES.Password) {
                        dataModel.PasswordFieldList.push(data);
                    }

                    if (controlType === CONTROL_TYPES.FieldSecurity) {
                        dataModel.FieldSecurityControlChanges = data;
                    }
                }
            }

            if (control.GetType() === CONTROL_TYPES.Signature) {
                const controlAttachments = control.Deserialize();
                dataModel.Attachments = dataModel.Attachments.concat(controlAttachments['attachments']);
                dataModel.RemoveAttachments = dataModel.RemoveAttachments.concat(controlAttachments['removeAttachments']);
            }

            if (control.GetType() === CONTROL_TYPES.Image) {
                const controlAttachments = control.Deserialize();
                dataModel.Attachments = dataModel.Attachments.concat(controlAttachments['attachments']);
                dataModel.RemoveAttachments = dataModel.RemoveAttachments.concat(controlAttachments['removeAttachments']);
                dataModel.Annotations = dataModel.Annotations.concat(controlAttachments['annotations']);
            }

            if (control.GetType() === CONTROL_TYPES.QueryBuilder) {
                const data = control.Deserialize();
                dataModel.FieldValueList = dataModel.FieldValueList.concat(data);
            }

            if (control.GetType() === CONTROL_TYPES.Drop) {
                const controlAttachments = control.Deserialize();
                dataModel.DropAttachments.push(controlAttachments);
            }

            if (control.GetType() === CONTROL_TYPES.LinkList) {
                let linkListData = control.Deserialize();
                dataModel.LinklistChanges.ChangedRelations.push(...linkListData.ChangedRelations);
                dataModel.LinklistChanges.ChangedSecurity.push(...linkListData.ChangedSecurity);
                dataModel.LinklistChanges.DeletedRelations.push(...linkListData.DeletedRelations);
                dataModel.LinklistChanges.LinkEditorChanges.push(...linkListData.LinkEditorChanges);
                dataModel.LinklistChanges.NewRelations.push(...linkListData.NewRelations);
                dataModel.LinklistChanges.ActionSubjectRecord = linkListData.ActionSubjectRecord;
            }

            if (control.GetType() === CONTROL_TYPES.Grid) {
                let gridData = (control as Grid).Serialize();
                let linkEditorChanges = (control as Grid).SerializeLinkEditorData();

                _.each(linkEditorChanges, (item) => {
                    dataModel.LinklistChanges.LinkEditorChanges.push(...item.LinkEditorChanges);
                    dataModel.LinklistChanges.DeletedRelations.push(...item.DeletedRelations);
                });

                if (gridData.length > 0) {
                    dataModel.GridsData.push(gridData);
                }
            }

            if (control.GetType() === CONTROL_TYPES.Tag) {
                let tagChanges = control.Deserialize();
                if (!dataModel.TagsChanges) {
                    dataModel.TagsChanges = [];
                }
                dataModel.TagsChanges.push(tagChanges);
            }

            if (control.GetType() === CONTROL_TYPES.Spreadsheet) {
                const spreadsheetRewrite = control.Deserialize();
                if (spreadsheetRewrite) {
                    dataModel.Spreadsheets.push(spreadsheetRewrite);
                }
            }

            if (this._isFollowUp && control.GetType() === CONTROL_TYPES.ButtonFollowUp) {
                let followUpControl = control as ButtonFollowUp;
                this._followUpRecord = followUpControl.GetRecord();
                if(this._followUpRecord){
                    dataModel.FollowUpRecord = this._followUpRecord;
                }
            }
        });

        if (this.SaveImmediately) {
            BlockUI.Block();
            InsertOrUpdateRecordStore.UpdateData(dataModel)
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(result => {
                    if (result.Warnings && result.Warnings.length > 0) {
                        const notifier = new Notifier();
                        _.each(result.Warnings, (warning) => notifier.Warning(warning));
                    }

                    if (result.IsSuccessfull) {
                        if (dataModel.TableSecurityChanges || dataModel.UserSecurityChanges) {
                            MenuManager.Instance.RefreshMenuAreas(false);
                        }
                        const control = this.GetControlByFieldName('NAME', CONTROL_TYPES.Text);
                        const name = control ? control.GetValue() : null;
                        this.Trigger('RECORD_SAVED', {
                            EntityId: this.GetEntityId(),
                            RecordId: result.Id,
                            Name: name,
                            IsFollowUp: this._isFollowUp
                        });

                        if (!this.IsQueryBuilderScreen) {
                            PubSub.publish(BREADCRUMBS_UPDATE_RECORD_NAME, { Name: name });
                        }

                        deferredResult.resolve({ recordId: result.Id, followUpRecordId: result.FollowUpRecordId });
                        if (close) {
                            this.Close();
                        }
                        this.AfterSave();

                        if (dataModel.ParentRecordId && dataModel.EntityTypeId != 0) {
                            const relation: ILoadScreenRelationModel = {
                                MainEntityId: dataModel.EntityId,
                                MainRecordId: dataModel.ParentRecordId,
                                MainRecordTypeId: dataModel.ParentRecordTypeId,
                                RelatedEntityId: dataModel.EntityId,
                                RelatedRecordId: result.Id,
                                RelatedRecordTypeId: dataModel.EntityTypeId,
                                KSeq: result.Sequence
                            };

                            this.UpdateLinkRecord(relation);
                        }
                    } else {
                        const notifier = new Notifier($(this._el));
                        notifier.Failed(result.ErrorMessage);
                    }
                }).fail(error => {
                const requestError = GetServerRequestError(error.status);
                const notifier = new Notifier($(this._el));
                notifier.Failed(NOTIFICATIONS.DATA_IS_NOT_SAVED);
                deferredResult.reject({message: null, status: error.status, requestError: requestError});
            });

            return deferredResult.promise();
        }

        this.Trigger('READY_TO_SAVE', dataModel);
        if (close) {
            this.Close();
        }

        return deferredResult.promise();
    }

    UpdateLinkRecord(relation: ILoadScreenRelationModel, isNew: boolean = false): P.Promise<any> {
        let data = null;
        let deferredResult = P.defer<any>();

        LinkEditor.LoadScreen(relation, isNew, data, false)
            .then(screen => {
                if (screen !== null) {
                    const linkEditor = screen as LinkEditor;

                    linkEditor.On('SAVE_DATA', this, eventArgs => {
                        const dataModel = new DataModel();

                        dataModel.EntityId = relation.MainEntityId;
                        dataModel.RecordId = relation.MainRecordId;
                        const updateDataModel = new UpdateDataModel();

                        updateDataModel.LinkEditorChanges.push(eventArgs.data);
                        dataModel.LinklistChanges = Serialize(updateDataModel);

                        InsertOrUpdateRecordStore.UpdateData(dataModel)
                            .always(() => {
                                BlockUI.Unblock();
                            })
                            .then(result => {
                                if (result.IsSuccessfull) {
                                    new Notifier($(this._el)).Success(NOTIFICATIONS.RECORD_UPDATED);
                                    linkEditor.Close();
                                    deferredResult.resolve(null);
                                } else {
                                    linkEditor.GetNotifier().Failed(result.ErrorMessage);
                                }
                            });
                    });
                }
            }).fail(err => {
            new Notifier($(this._el)).Warning(err.message);
        });
        return deferredResult.promise();
    }

    public NameControlSetValue(value: string) {
        const control = this.GetControlByFieldName('NAME', CONTROL_TYPES.Text);

        if (control) {
            const controlData = new ControlDataModel();

            controlData.FieldId = control.FieldModel.Id;
            controlData.Value = value;
            controlData.DisplayValue = value;

            const controlValue: IControlValue = {
                Data: controlData,
                SubjectEntityId: this._model.EntityId,
                SubjectRecordId: this._dataMode === DataModes.Default ? this._recordId : 0,
                RecordSpecsModel: new RecordSpecsModel()
            };

            control.SetDefaultValue(controlValue);
        }
    }

    public AddInnerRelation(relatedEntityId: number, relatedRecordId: number) {
        const linkList = this.GetControl(CONTROL_TYPES.LinkList) as LinkList;
        if (!linkList) return;

        const entityRelation = linkList.DataModel().Entities().find(e => e.EntityId === relatedEntityId);
        if (!entityRelation) return;

        BlockUI.Block();

        RecordStore.GetRecord({
            TableId: relatedEntityId,
            TableTypeId: 0,
            RecordId: relatedRecordId,
            ReadLookups: false
        })
            .always(() => BlockUI.Unblock())
            .then(recordDataModel => {
                const nameField = recordDataModel.Fields.find(f => f.FieldName === 'NAME');
                const linkedRecordName = nameField && nameField.FieldValue || 'N/A;'

                const newRelationModel = new NewRelationModel();
                newRelationModel.Id = relatedRecordId;
                newRelationModel.Name = linkedRecordName;

                linkList.AddRecord(relatedRecordId, entityRelation, newRelationModel);
            }).fail(error => {
            if (error.status === 404) {
                new Notifier().Failed('Link cannot be added. Record not found')
            } else {
                new Notifier().Failed('Error getting record for link');
            }
        });
    }
}