export class ProductCustomFieldDescription {
    LookupTableId: number;
    RelatedTableId: number;
    RelatedTableFieldId: number;
    AvailableOptionsCount: number;

    constructor(public Id: number,
                public Name: string,
                public TranslatedName: string,
                public DefaultValue: any,
                public Type: string,
                public Size: number,
                public ValTable: string,
                public ValTableType: string,
                public ValFieldId: number,
                public ValFieldName: string,
                public AllowCustomValue: boolean,
                public PriceInfluence: boolean,
                public IsRequired: boolean) {
    }
}