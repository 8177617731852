import * as ko from 'knockout'

import {BaseScreen} from 'Core/Screens/BaseScreen'
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen'
import {Search} from 'Core/Controls/Search/Search'
import {IControlParam, ISearchScreenParam} from 'Core/Screens/IScreen'
import {CONTROL_TYPES, EVENTS} from 'Core/Constant'
import {BlockUI} from 'Core/Common/BlockUi'
import {Request, IRequestOptions} from 'Core/Common/Request'
import {URL} from 'Core/Common/Url'
import {ScreenModel} from 'Core/Models/Screens/ScreenModel'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl'
import {IControl} from 'Core/Controls/IControl'
import {RenderModes} from 'Core/Constant'
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen'
import {ScreenManager} from 'Core/ScreenManager/ScreenManager'
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen'
import {Notifier} from 'Core/Common/Notifier'
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager'
import {ScreenDataStore} from 'Core/Screens/ConsultScreen/Stores/ScreenDataStore'
import {MenuManager} from 'MenuManager/MenuManager'
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes'
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel'
import {ScreenDataModel} from 'Core/ScreenManager/Models/ScreenDataModel'
import {DataModes} from 'Core/Enums/DataModes'

import SpecialScreenTemplate
    from 'Core/Screens/Templates/AdditionalConsultScreen/SpecialScreen/SpecialScreen.html';
import {PUB_SUB_EVENTS} from "../../../MenuManager/PubSubEvents";
import {NOTIFICATIONS, LABELS} from "Core/Components/Translation/Locales";
import {LOCK_EVENTS, LockManager} from "../../Components/Locker/LockManager";
import {ProfileSelectorScreen} from '../ProfileSelectorScreen/ProfileSelectorScreen';

ko.templates["Core/Screens/Templates/AdditionalConsultScreen/SpecialScreen/SpecialScreen"] = SpecialScreenTemplate;

export class SpecialScreen extends BaseScreen {
    private _Search: IControl;

    constructor(screenModel: ScreenModel) {
        super(screenModel, RenderModes.View);
        this._renderMode = RenderModes.View;
        this.Init();
    }

    private Init() {
        if (!this._model.Data) {
            return;
        }

        this._dataModel = this._model.Data;

        var controlData = new ControlDataModel();
        controlData.Rights = this._model.Data.Rights;

        var controlValue: IControlValue = {
            Data: controlData,
            SubjectEntityId: this._model.EntityId,
            SubjectRecordId: -1,
            RecordSpecsModel: null
        };

        _.each(this._controls, (control: IControl) => {
            control.SetValue(controlValue);
        });

        this.On('SEARCH_RECORD', this, (eventArgs: any) => {
            this.SearchRecord();
        });
        this.On('EDIT_RECORD', this, (eventArgs: any) => {
            if (eventArgs.data && eventArgs.data.TableTypeId && eventArgs.data.RecordId) {
                this.EditRecord(eventArgs.data.RecordId, eventArgs.data.TableTypeId);
            } else {
                this.EditRecord();
            }
        });

        this.On('NEW_RECORD', this, (eventArgs: any) => {
            var screenData = null;
            if (eventArgs.data && eventArgs.data.ScreenData instanceof ScreenDataModel) {
                screenData = eventArgs.data.ScreenData;
            }

            if (eventArgs.data && eventArgs.data.TableTypeId) {
                this.NewRecord(eventArgs.data.TableTypeId, 0, screenData);
            } else {
                this.ShowTypeScreen(screenData);
            }
        });

        this.On('ADD_RECORD', this, (eventArgs: any) => {
            this.ShowProfileSelector();
        });
    }

    Refresh() {
        this.LoadData(this.GetRecordId());
    }

    SearchRecord(): void {
        const SearchControl = this.GetTextSearchTermControl();
        const searchControlId = SearchControl != null ? SearchControl.GetControlId() : 0;
        const searchTerm = SearchControl ? SearchControl.GetValue() : "";

        const searchParams: ISearchScreenParam = {
            EntityId: this.GetEntityId(),
            ControlId: searchControlId,
            SearchTerm: searchTerm
        };

        const searchScreen = new SearchScreen(searchParams);

        searchScreen.On("RECORD_SELECTED", this, (eventArgs: any) => {
            searchScreen.Close();
            UserVarsManager.Instance.AddRecent(this.GetEntityId(), eventArgs.data.RecordId, eventArgs.data.TypeId);
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
                EntityId: this.GetEntityId(),
                RecordId: eventArgs.data.RecordId,
                RecordTypeId: eventArgs.data.TypeId,
                Owner: this
            });
        });

        searchScreen.On("ALT_ENTITY_RECORD_SELECTED", this, (eventArgs) => {
            const data = eventArgs.data;
            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);


            data.IsOpenInModal = false;
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });

        searchScreen.On("NEW_RECORD", this, (eventArgs) => {
            this.ShowProfileSelector(null, searchScreen);
        });

        searchScreen.Show();
    }

    LoadData(recordId: number) {
        BlockUI.Block();
        ScreenManager.GetScreenByScreenType(this.GetEntityId(), ScreenTypes.SpecialScreen, recordId)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(screen => {
                screen.SetIsReady(true);
                MenuManager.Instance.GoToScreen(screen);
            }).fail(err => new Notifier().Warning(err.message));
    }

    ShowProfileSelector(screenData?: ScreenDataModel, searchScreen: SearchScreen = null) {
        if (this.IsInModal()) {
            this.ShowTypeScreen(screenData, searchScreen);
            return;
        }

		const profileSelectorScreen = new ProfileSelectorScreen(this.GetEntityId(), this.GetEntityName());

        profileSelectorScreen
            .On('PROFILES_NOT_FOUND', this, () => {
                new Notifier().Warning('Profiles with create possibilities not found');
            })
            .On('PROFILE_SELECTION_CANCELLED', this, () => {
                profileSelectorScreen.Close();
            })
            .On('PROFILE_SELECTED', this, () => {
                profileSelectorScreen.Close();
                this.ShowTypeScreen(screenData, searchScreen);
            })
            .On('USED_CURRENT_PROFILE', this, () => {
                profileSelectorScreen.Close();
                this.ShowTypeScreen(screenData, searchScreen);
            });

        profileSelectorScreen.ShowIfNeeded();
    }

    ShowTypeScreen(screenData?: ScreenDataModel, searchScreen: SearchScreen = null) {
        var typeScreen = new TypeScreen(this.GetEntityId(), 0, true);
        typeScreen.On("TYPE_SELECTED", this, (eventArgs) => {
            const typeId = eventArgs.data.TypeId;
            const kindId = eventArgs.data.KindId;
            const exampleRecordId = eventArgs.data.ExampleRecordId;
            if (searchScreen) {
                searchScreen.Cancel();
            }
            this.NewRecord(typeId, kindId, exampleRecordId, screenData);
        });

        typeScreen.On("TYPES_NOT_FOUND", this, (eventArgs) => {
            new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
        });
        typeScreen.Show();
    }

    EditRecord(recordId?: number, tableTypeId?: number) {
        if (!this.GetRecordId() && !recordId) {
            const notifier = new Notifier($(this._el));
            notifier.Warning(NOTIFICATIONS.PLEASE_SELECT_ANY_RECORD);
            return;
        }

        BlockUI.Block();

        const lockedRecordId = recordId || this.GetRecordId();
        LockManager.Instance.TryLock(this.GetEntityId(), lockedRecordId)
            .then(result => {

                ScreenManager.GetEditScreen({
                    EntityId: this.GetEntityId(),
                    TableTypeId: tableTypeId || this.GetTableTypeId(),
                    RecordId: recordId || this.GetRecordId()
                }).always(() => {
                    BlockUI.Unblock();
                }).then((screen: EditScreen) => {
                    LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs: any) => {
                        if (eventArgs.data.EntityId === this.GetEntityId() && eventArgs.data.RecordId === lockedRecordId) {
                            screen.Close();
                        }
                    });

                    screen.On('MODAL_CLOSE', this, () => {
                        LockManager.Instance.ReleaseLock(this.GetEntityId(), lockedRecordId);
                    });

                    screen.On("RECORD_SAVED", this, (eventArgs) => {
                        var notifier = new Notifier($(this._el));
                        notifier.Success(NOTIFICATIONS.RECORD_UPDATED);
                        this.LoadData(this.GetRecordId());
                    });

                    screen.On("RECORD_REMOVED", this, () => {
                        LockManager.Instance.ReleaseLock(this.GetEntityId(), lockedRecordId);
                        this.LoadData(this.GetRecordId());
                    });

                    screen.On('COPY', this, (eventArgs) => {
                        this.NewRecord(this.GetTableTypeId(), this.GetKindId(), eventArgs.data.recordId, null, eventArgs.data.dataMode);
                    });

                    screen.ShowInModal();
                }).fail(err => {
                    LockManager.Instance.ReleaseLock(this.GetEntityId(), lockedRecordId);
                    let notifier = new Notifier($(this._el));
                    notifier.Warning(err.message);
                });
            })
            .fail(() => {
                BlockUI.Unblock();
            });
    }

    NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number, screenData?: ScreenDataModel, dataMode: DataModes = DataModes.Default) {
        const SearchControl = this.GetTextSearchTermControl();
        const searchTerm = SearchControl ? SearchControl.GetValue() : '';

        BlockUI.Block();
        ScreenManager.GetEditScreen({
            EntityId: this.GetEntityId(),
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            DataMode: dataMode,
            LoadAsExample: exampleRecordId > 0
        }).always(() => {
            BlockUI.Unblock();
        }).fail((err) => {
            var notifier = new Notifier();
            notifier.Failed(err.message);
        }).then(screen => {
            var editScreen = <EditScreen>screen;
            editScreen.IsDataFromExample = exampleRecordId > 0;

            if (searchTerm) {
                editScreen.NameControlSetValue(searchTerm);
            }

            if (screenData) {
                editScreen.SetDefaultData(screenData);
            }

            screen.On("RECORD_SAVED", this, (eventArgs) => {
                var notifier = new Notifier($(this._el));
                notifier.Success(NOTIFICATIONS.RECORD_CREATED);
                this.LoadData(eventArgs.data.RecordId);
                UserVarsManager.Instance.AddRecent(this.GetEntityId(), eventArgs.data.RecordId, tableTypeId);
            });

            screen.On('COPY', this, (eventArgs) => {
                this.NewRecord(this.GetTableTypeId(), this.GetKindId(), eventArgs.data.recordId, null, eventArgs.data.dataMode);
            });

            screen.ShowInModal();
        });
    }

    GetTextSearchTermControl(): IControl {
        return _.first(_.filter(this.GetAllControls(), (control: IControl) => control.GetType() === "Search"));
    }

    AfterRender(el) {
        super.AfterRender(el);
        this.SetScreenStyle();
    }

    GetTemplateName(): string {
        return 'Core/Screens/Templates/AdditionalConsultScreen/SpecialScreen/SpecialScreen';
    }
}