import * as ko from "knockout";
import * as $ from "jquery";
import * as _ from "underscore";

import {UserManager, UserTypes} from "User/UserManager";

import {MenuAreasStore} from "MenuManager/MenuAreas/Stores/MenuAreasStore";
import {MenuAreaModel} from "MenuManager/MenuAreas/Models/MenuAreaModel";

import {Event} from "Core/Common/Event";

import {RecentAndFavorites} from 'Core/Components/RecentAndFavorites/RecentAndFavorites';
import {MenuQueries} from 'Core/Components/MenuQueries/MenuQueries';

import {DesignedScreenModel} from "./Models/DesignedScreenModel";
import {TableModel} from './Models/TableModel';
import {BlockUI} from "Core/Common/BlockUi";
import {LABELS} from 'Core/Components/Translation/Locales';
import {PUB_SUB_EVENTS} from "MenuManager/PubSubEvents";
import {UserVarsManager} from "Core/UserVarsManager/UserVarsManager";
import {MobileChecker} from "Core/Common/MobileChecker";

import MenuAreaTemplate from "MenuManager/MenuAreas/Templates/MenuAreas.html";
import IconTemplate from "MenuManager/MenuAreas/Templates/IconTemplate.html";
import { Notifier } from "../../Core/Common/Notifier";

ko.templates["MenuManager/MenuAreas/Templates/MenuAreas"] = MenuAreaTemplate;
ko.templates["MenuManager/MenuAreas/Templates/IconTemplate"] = IconTemplate;

export class MenuAreas extends Event {
    private _showSearchButtons: KnockoutObservable<boolean>;
    private _areas: KnockoutObservableArray<MenuAreaModel>;
    private _selectedArea: MenuAreaModel;
    private _menuQueries: KnockoutObservable<MenuQueries>;
    private _menuQueriesTableId: KnockoutObservable<number>;
    private _recentAndFavorites: KnockoutObservable<RecentAndFavorites>;
    private _recentAndFavoritesTableId: KnockoutObservable<number>;
    private _rootScreen: KnockoutObservable<MenuAreaModel>;
    private _openedTableTypeFlowFolder: boolean;
    private _labels: LABELS;
    private _isMobile: KnockoutObservable<boolean>;

    constructor() {
        super();

        this._showSearchButtons = ko.observable(false);
        this._areas = ko.observableArray([]);
        this._menuQueries = ko.observable(null);
        this._menuQueriesTableId = ko.observable(null);
        this._recentAndFavorites = ko.observable(null);
        this._recentAndFavoritesTableId = ko.observable(null);
        this._labels = LABELS;
        this._rootScreen = ko.observable(null);
        this._isMobile = ko.observable(MobileChecker.IsMobile());
    }

    DeleteAreas() {
        this._areas([]);
    }

    Init() {
        this.AddEvent("UPDATED");
    }

    Refresh(reloadScreen: boolean = true) {
        BlockUI.Block({ Target: $('#mainMenu')[0] });
        MenuAreasStore.Get()
            .always(()=> {
                BlockUI.Unblock($('#mainMenu')[0]);
                this.Trigger("UPDATED", { ReloadScreen: reloadScreen });
            })
            .fail((err)=>Notifier.Failed(err.message))
            .then(areasList => {
                this._areas([]);
                _.forEach(areasList, (area) => {
                    if (area.Name !== 'Root') {
                        this._areas.push(area);
                    } else {
                        this._rootScreen(area);
                    }
                });              
            });
    }

    CollapseAll() {
        this._areas().forEach(area => {
            area.IsActive(false);
            area.Screens.forEach(screen => screen.IsSelected(false));
        });
    }

    GetTemplateName(): string {
        return "MenuManager/MenuAreas/Templates/MenuAreas";
    }

    DestroyRecentAndFavoritesDropdown() {
        if (!this._isMobile()) {
            this._recentAndFavorites().DestroyRecentAndFavoritesDropdown();
        }
    }

    DestroyMenuQueriesDropdown() {
        if (!this._isMobile()) {
            this._menuQueries().DestroyMenuQueriesDropdown();
        }
    }

    AfterRender() {
    }

    GetAreaByEntityId(entityId: number) {
        var value = null;
        _.each(this._areas(), area => {
            _.each(area.Screens, screen => {
                if (screen.Table.Id === entityId) {
                    value = area;
                }
            });
        });

        if (!value && this._rootScreen()) {
            _.each(this._rootScreen().Screens, screen => {
                if (screen.Table.Id === entityId) {
                    value = this._rootScreen();
                }
            });
        }

        return value;
    }

    GetScreenByEntityId(entityId: number): DesignedScreenModel {
        let value: DesignedScreenModel = null;
        _.each(this._areas(), area => {
            _.each(area.Screens, screen => {
                if (screen.Table.Id === entityId) {
                    value = screen;
                }
            })
        });

        if (!value && this._rootScreen()) {
            _.each(this._rootScreen().Screens, screen => {
                if (screen.Table.Id === entityId) {
                    value = screen;
                }
            });
        }
        return value;
    }

    get OpenedTypeFlowFolder() {
        return this._openedTableTypeFlowFolder;
    }

    set OpenedTypeFlowFolder(value: boolean) {
        this._openedTableTypeFlowFolder = value;
    }

    OpenSubMenu(menuAreaModel: MenuAreaModel) {
        if (!this._selectedArea) {
            this._selectedArea = menuAreaModel;
            this._selectedArea.IsSelected(true);
        } else if (this._selectedArea !== menuAreaModel) {
            this._selectedArea.IsSelected(false);
            this._selectedArea = menuAreaModel;
            this._selectedArea.IsSelected(true);
        }
        if (this._menuQueries()) {
            this.DestroyMenuQueriesDropdown();
            this._menuQueries(null);
        }
        if (this._recentAndFavorites()) {
            this.DestroyRecentAndFavoritesDropdown();
            this._recentAndFavorites(null);
        }
    }

    SelectUser(entity, user) {
        UserVarsManager.Instance.AddRecent(entity.Id, user.Id, user.TypeId);

        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, {
            EntityId: entity.Id,
            RecordId: user.Id,
            RecordTypeId: user.TypeId
        });

        this.Trigger('RECORD_SELECTED');
    }

    ShowQueriesDropdown(entity : TableModel, screenModel: DesignedScreenModel, event) {

        let target: HTMLElement,
            isRecentAndFavoritesDropdownBox = $(event.target).parents('.recent-and-favorites-attach-to').length > 0;

        target = isRecentAndFavoritesDropdownBox ? $(event.target).parents('.recent-and-favorites-attach-to')[0] : event.currentTarget;

        const newTarget = $(target).closest(".subject-area").length ? $(target).closest(".subject-area") : target;
        const sidebar = $('.page-sidebar-menu');
        const sidebarClosed = sidebar.hasClass("page-sidebar-menu-closed");

        if (sidebarClosed) {
            $(".recent-and-favorites-active").removeClass("recent-and-favorites-active");
        }

        if(this._recentAndFavorites()) {
            this.DestroyRecentAndFavoritesDropdown();
            this._recentAndFavorites(null);
        }

        if (this._menuQueries() && this._menuQueriesTableId() === entity.Id) {
            this.DestroyMenuQueriesDropdown();
            this._menuQueries(null);

            return;
        }

        if (this._menuQueries() && this._menuQueriesTableId() !== entity.Id) {
            this.DestroyMenuQueriesDropdown();
            this._menuQueries(null);
        }

        const menuQueries = new MenuQueries(entity.Id, target);

        menuQueries.On('QUERY_SELECTED', this, eventArgs => {
            this.DestroyMenuQueriesDropdown();
            this._menuQueries(null);

            if (sidebarClosed) {
                $(newTarget).removeClass("recent-and-favorites-active");
            }
        });

        this._menuQueries(menuQueries);
        this._menuQueriesTableId(entity.Id);

        $(window).resize(() => {
            if (this._menuQueries()) {
                this.DestroyMenuQueriesDropdown();
                this._menuQueries(null);
            }
        });

        $(document).click((event) => {
            if (!$(event.target).closest('#menu-queries-panel').length) {
                if (this._menuQueries()) {
                    this.DestroyMenuQueriesDropdown();
                    this._menuQueries(null);

                    if (sidebarClosed) {
                        $(target).removeClass("recent-and-favorites-active");
                    }
                }
            }
        });

        $('.page-sidebar-menu').scroll((event) => {
            if (this._menuQueries()) {
                this.DestroyMenuQueriesDropdown();
                this._menuQueries(null);
            }
        });

        if (sidebarClosed) {
            $(newTarget).addClass("recent-and-favorites-active");

            $('.queries-dropdown').on('mouseleave.jBoxMenuQueries', (event) => {
                if (this._menuQueries()) {
                    this.DestroyMenuQueriesDropdown();
                    this._menuQueries(null);
                    $(newTarget).removeClass("recent-and-favorites-active");
                    $('.queries-dropdown').off('mouseleave.jBoxMenuQueries');
                }
            });
        }
    }

    ShowRecentAndFavoritesDropdown(entity, data, event) {
        this._openedTableTypeFlowFolder = this.OpenedTypeFlowFolder;

        let target: HTMLElement,
            isRecentAndFavoritesDropdownBox = $(event.target).parents('.recent-and-favorites-attach-to').length > 0;

        target = isRecentAndFavoritesDropdownBox ? $(event.target).parents('.recent-and-favorites-attach-to')[0] : event.currentTarget;

        const newTarget = $(target).closest(".subject-area").length ? $(target).closest(".subject-area") : target;
        const sidebar = $('.page-sidebar-menu');
        const sidebarClosed = sidebar.hasClass("page-sidebar-menu-closed");

        if (sidebarClosed) {
            $(".recent-and-favorites-active").removeClass("recent-and-favorites-active");
        }

        if (this._menuQueries()) {
            this.DestroyMenuQueriesDropdown();
            this._menuQueries(null);
        }

        if (this._recentAndFavorites() && this._recentAndFavoritesTableId() === entity.Id) {
            this.DestroyRecentAndFavoritesDropdown();
            this._recentAndFavorites(null);

            return;
        }

        if (this._recentAndFavorites() && this._recentAndFavoritesTableId() !== entity.Id) {
            this.DestroyRecentAndFavoritesDropdown();
            this._recentAndFavorites(null);
        }

        const recentAndFavorites = new RecentAndFavorites(entity, target, this._openedTableTypeFlowFolder);

        recentAndFavorites.On('RECORD_SELECTED', this, () => {
            this.DestroyRecentAndFavoritesDropdown();
            this._recentAndFavorites(null);
            if (sidebarClosed) {
                $(newTarget).removeClass("recent-and-favorites-active");
            }
        });

        this._recentAndFavorites(recentAndFavorites);
        this._recentAndFavoritesTableId(entity.Id);

        $(window).resize(() => {
            if (this._recentAndFavorites()) {
                this.DestroyRecentAndFavoritesDropdown();
                this._recentAndFavorites(null);
                // $(document).off('click');
            }
        });

        $(document).click((event) => {
            if (!$(event.target).closest('#recent-and-favorites-panel').length) {
                if (this._recentAndFavorites()) {
                    this.DestroyRecentAndFavoritesDropdown();
                    this._recentAndFavorites(null);
                    // $(document).off('click');
                    if (sidebarClosed) {
                        $(newTarget).removeClass("recent-and-favorites-active");
                    }
                }
            }
        });

        $('.page-sidebar-menu').scroll((event) => {
            if (this._recentAndFavorites()) {
                this.DestroyRecentAndFavoritesDropdown();
                this._recentAndFavorites(null);
            }
        });

        if (sidebarClosed) {
            $(newTarget).addClass("recent-and-favorites-active");

            $('.recent-and-favorites-dropdown').on('mouseleave.jBoxRecentAndFavorites', (event) => {
                if (this._recentAndFavorites()) {
                    this.DestroyRecentAndFavoritesDropdown();
                    this._recentAndFavorites(null);
                    $(newTarget).removeClass("recent-and-favorites-active");
                    $('.recent-and-favorites-dropdown').off('mouseleave.jBoxRecentAndFavorites');
                }
            });
        }
    }

    ShowHideSearchButtons() {
        const userManager = UserManager.Instance;
        const addButtons = userManager.IsAuthenticated && userManager.CurrentUser.UserType !== UserTypes.Gdpr;
        this._showSearchButtons(addButtons);
    }
}