export enum SearchTypes {
	None,
	FastSearch,
	PhoneticSearch,
	FieldSearch,
	TotalSearch
}

export enum RecordTypes {
	Dash,
	Root,
	All
}

export enum SearchByMainRelationTypes
{
	Off,
    ByRelations,
	ByMainRelations,
	ByConditions
}