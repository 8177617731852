import * as ko from 'knockout';

import {GridTotalCellValueModel} from 'Core/Controls/Grid/Models/GridDataModel/GridTotalCellValueModel';
import {GridColumnModel} from 'Core/Controls/Grid/Models/GridDataModel/GridColumnModel';
import {FieldFormat} from 'Core/Common/FieldFormat';
import {FormatConverter} from 'FormatEditor/FormatConverter';
import { GridAverageCellValueModel } from 'Core/Controls/Grid/Models/GridDataModel/GridAverageCellValueModel';

import TotalCellTemplate from 'Core/Controls/Grid/BaseGrid/GridCell/Templates/TotalCell.html';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';
ko.templates['Core/Controls/Grid/BaseGrid/GridCell/Templates/TotalCell'] = TotalCellTemplate;

export class TotalCell {
	constructor(
		private _model: GridTotalCellValueModel | GridAverageCellValueModel,
		 private _columnModel: GridColumnModel
	) {}

	GetTemplateName(): string {
		return 'Core/Controls/Grid/BaseGrid/GridCell/Templates/TotalCell';
	}

	@enumerable 
	get IsWrapped(): boolean {
		return this._model.IsWrapped;
	}

	@enumerable
	get Value(): string {
		if (!this._model.Value) {
			return this._model.Value;
		}

		return FormatConverter.LocalizeDecimalOrInteger(
			this._columnModel.FieldMetadata.FormatName === FieldFormat.PERCENTAGE
				? (Number(this._model.Value) * 100).toFixed(
					  this._columnModel.FieldMetadata.Size < 2 ? 0 : this._columnModel.FieldMetadata.Size - 2
				  )
				: this._model.Value
		);
	}

	@enumerable
	get FormatName(){
        return !!this.Value && this._columnModel.FieldMetadata.FormatName;
    }

	@enumerable
	get DisplayPriorityClass(): string {
		return '';
	}
}