import * as ko from 'knockout';
import {LABELS} from 'Core/Components/Translation/Locales';

export class PortletSpaceEditorModel {
	PortletSpaceName: KnockoutObservable<string>;

	constructor() {
		this.PortletSpaceName = ko.observable('').extend({
			required: {
				message: LABELS.PLEASE_ENTER_PORTLET_SPACE_NAME
			}
		});
	}
}