import {LIFE_STATUS_GROUPS} from "Core/Constant";

export const TOOL_MARKUP = [
    '<g class="link-tool">',
    '<g class="tool-remove" event="link:remove">',
    '<circle r="11" />',
    '<path transform="scale(.8) translate(-16, -16)" d="M24.778,21.419 19.276,15.917 24.777,10.415 21.949,7.585 16.447,13.087 10.945,7.585 8.117,10.415 13.618,15.917 8.116,21.419 10.946,24.248 16.447,18.746 21.948,24.248z"/>',
    '</g>',
    '</g>'
].join('');

export const LABEL_MARKUP = [
    '<g class="label">',
    '<rect/>',
    '<text/>',
    '<image width="15" height="15" xlink:href="img/Rappid/font-awesome-black/exclamation.png" y="5" data-type="30000" visibility="hidden"></image>',
    '<image width="15" height="15" xlink:href="img/Rappid/font-awesome-black/ban.png" y="5" data-type="20000" visibility="hidden"></image>',
    '<image width="15" height="15" xlink:href="img/Rappid/font-awesome-black/check-square.png" y="5" data-type="10000" visibility="hidden"></image>',
    '<image width="15" height="15" xlink:href="img/Rappid/font-awesome-black/check.png" y="-25" data-type="3000" visibility="hidden"></image>',
    '<image width="15" height="15" xlink:href="img/Rappid/font-awesome-black/key.png" y="-25" data-type="2000" visibility="hidden"></image>',
    '</g>'
].join('');

export const START_CIRCLE_MODEL = {
    fill: '#000',
    stroke: '#000',
    'stroke-width': 1,
    style: {
        'pointer-events': 'non‌​e'
    },
};

export const CELL_OPTIONS = {
    markup: '',
        position: {
            x: 0,
            y: 0
        },
        size: {
            width: 80,
            height: 60
        },
        attrs: {
            'image[data-name="OnLifestatusSetTrigger"]': {
                'xlink:href': 'img/Rappid/font-awesome-black/bolt.png',
                    width: 20,
                    height: 20,
                    x: 80,
                    y: 5,
                    visibility: 'hidden'
            },
            'image[data-name="retired"]': {
                'xlink:href': 'img/Rappid/font-awesome-black/archive.png',
                    width: 20,
                    height: 20,
                    x: 5,
                    y: 35,
                    visibility: 'hidden'
            },
            'image[data-name="NoActionNode"]': {
                'xlink:href': 'img/Rappid/font-awesome-black/bell-slash-o.png',
                width: 20,
                height: 20,
                x: 5,
                y: 5,
                visibility: 'hidden'
            },
            'image[data-name="MultipleActions"]': {
                'xlink:href': 'img/Rappid/font-awesome-black/clone.png',
                width: 20,
                height: 20,
                x: 5,
                y: 5,
                visibility: 'hidden'
            },
            text: {
                text: '',
                fill: 'White',
                'font-weight': 'normal',
                'font-size': 14
            },
            rect: {
                fill: '#4a83b6',
                stroke: '#2d5f8b',
                'stroke-width': 2,
            },
            sort: LIFE_STATUS_GROUPS.PLANNED.toString(),
            isStart: false,
            ActionItems: [],
            translations: []
    },
    ports: {items: []}
};

export const CELL_OPTIONS_START = {
    markup: '',
    position: {
        x: 30,
        y: 30
    },
    size: {
        width: 40,
        height: 40
    },
    attrs: {
        'image[data-name="OnLifestatusSetTrigger"]': {
            'xlink:href': 'img/Rappid/font-awesome-black/bolt.png',
            width: 20,
            height: 20,
            x: 80,
            y: 5,
            visibility: 'hidden'
        },
        'image[data-name="retired"]': {
            'xlink:href': 'img/Rappid/font-awesome-black/archive.png',
            width: 20,
            height: 20,
            x: 5,
            y: 35,
            visibility: 'hidden'
        },
        'image[data-name="NoActionNode"]': {
            'xlink:href': 'img/Rappid/font-awesome-black/bell-slash-o.png',
            width: 20,
            height: 20,
            x: 5,
            y: 5,
            visibility: 'hidden'
        },
        'image[data-name="MultipleActions"]': {
            'xlink:href': 'img/Rappid/font-awesome-black/clone.png',
            width: 20,
            height: 20,
            x: 5,
            y: 5,
            visibility: 'hidden'
        },
        text: {
            text: 'Start',
            fill: '#fff',
            'font-weight': 'normal',
            'font-size': 14
        },
        sort: LIFE_STATUS_GROUPS.PLANNED.toString(),
        isStart: true,
        circle: START_CIRCLE_MODEL,
        ActionItems: []
    },
    ports: {items: []}
};

export const LINK_OPTIONS = {
    source: {id: 0},
    target: {id: 0},
    labels: [{
        position: .5,
        attrs: {
            text: {
                text: '',
                'font-weight': 'bold',
            },
            'image[data-type="2000"]': {
                visibility: 'hidden'
            },
            'image[data-type="3000"]': {
                visibility: 'hidden'
            }
        }
    }],
    vertices: [],
    attrs: {
    '.marker-target': {d: 'M 0 -5 L -10 0 L 0 5 Z' },
    '.connection': {
        strokeWidth: 2,
            stroke: '#000000'
    }
},
    router: {
        name: 'normal'
    },
    connector: {
        name: 'smooth'
    }
};

export enum COLORS  {
    BLACK = '#000000',
    WHITE = '#ffffff',
    GREEN = 'rgb(49, 208, 198)'
};

export enum LIFECYCLE_EVENTS {
	INSPECT_CHECK_LIST_CHANGE = 'change:attrs/checklist',
    INSPECT_TRANSITION_CHANGE_COLOR = 'change:attrs/.connection/stroke',
    INSPECT_TRANSITION_CHANGE_APPROVAL = 'change:attrs/approvalId',
    INSPECT_TRANSITION_CHANGE_NEXTALLOWED = 'change:attrs/nextAllowed',
    INSPECT_TRANSITION_CHANGE_TEST_CONDITION = 'change:attrs/testCondition',
    INSPECT_NODE_CHANGE_TRIGGER = 'change:attrs/OnLifestatusSetTrigger',
    INSPECT_NODE_CHANGE_DURATION = 'change:attrs/DateElapsed',
    INSPECT_NODE_CHANGE_AUTOCOMPLETE = 'change:attrs/IsAutocomplete',
    INSPECT_NODE_CHANGE_DEADLINE = 'change:attrs/DeadlineElapsed',
    INSPECT_NODE_CHANGE_SORT = 'change:attrs/sort',
    INSPECT_NODE_CHANGE_NO_ACTION = 'change:attrs/NoActionNode',
    INSPECT_NODE_CHANGE_MULTIPLE_ACTIONS = 'change:attrs/MultipleActions',
    TOOLBAR_RESET = 'reset:pointerclick',
    TOOLBAR_PRINT = 'print:pointerclick',
    TOOLBAR_AUTOLAYOUT = 'layout:pointerclick',
    TOOLBAR_FULLSCREEN = 'fullscreen:pointerclick',
    TOOLBAR_ZOOM = 'zoomSlider:change',
    ADVANCED_MODE_TOGGLE = 'toggleAdvancedMode:change',
    PAPER_CLICK = 'cell:pointerdown',
    LINK_REMOVE = 'link:remove',
    BLANK_CLICK = 'blank:pointerdown',
    CELL_POINTER_CLICK = 'cell:pointerclick',
    HALO_ENABLE = 'action:enable:pointerdown',
    HALO_DISABLE = 'action:disable:pointerdown',
    DIALOG_DELETELINK = 'action:deleteLink',
    DIALOG_CANCELDELETE = 'action:cancelDeleteLink',
    DIALOG_DISABLENODE = 'action:disableNode',
    DIALOG_CANCELDISABLENODE = 'action:cancelDisableNode',
    FULLSCREEN_WEBKIT = 'webkitfullscreenchange',
    FULLSCREEN_MOZ = 'mozfullscreenchange',
    FULLSCREEN = 'fullscreenchange',
    WINDOW_RESIZE = 'resize',
    LINK_HIGHTLIGHT = 'hightlight',
    LINK_UNHIGHTLIGHT = 'unHighlight',
    REMOVE_LINKS = 'removeLinks',
    MOUSEWHEEL = 'mousewheel DOMMouseScroll',
    ADD_BUTTON_CLICK = 'cell:addbutton:pointerdown',
    DEFAULT_RETIRED = 'change:attrs/DefaultRetired',
    INSPECT_NODE_CHANGE_SUB_FLOW_LINKED_ENTITY = 'change:attrs/SubFlowLinkedEntity',
    INSPECT_NODE_CHANGE_SUB_FLOW_TYPE = 'change:attrs/SubFlowType',
    INSPECT_NODE_CHANGE_SUB_FLOW_STATUS = 'change:attrs/SubFlowStatus',
}

export enum HALO_HANDLES {
    RESIZE = 'resize',
    FORK = 'fork',
    LINK = 'link',
    CLONE = 'clone',
    UNLINK = 'unlink',
    REMOVE = 'remove',
    ROTATE = 'rotate',
    DISABLE = 'disable',
    ENABLE = 'enable'
}

export enum LINK_CUSTOM_ATTRS {
    PASSWORD = 'image[data-type="2000"]',
    YESNO = 'image[data-type="3000"]',
    APPROVALID = 'approvalId'
}

export enum TEST_CONDITION_CUSTOM_ATTRS {
    VALID = 'image[data-type="10000"]',
    NOT_VALID = 'image[data-type="20000"]',
    ERROR = 'image[data-type="30000"]',
    TESTCONDITION = 'testCondition'
}

export enum CELL_CUSTOM_ATTRS {
    TRIGGER = 'image[data-name="OnLifestatusSetTrigger"]',
    RETIRED = 'image[data-name="retired"]',
    NO_ACTION = 'image[data-name="NoActionNode"]',
    MULTIPLE_ACTIONS = 'image[data-name="MultipleActions"]',
    COPY_BUTTON = 'foreignObject[class="rect-with-image-copy-button-container"]',
    DELETE_BUTTON = 'foreignObject[class="rect-with-image-delete-button-container"]',
    DEFAULT_RETIRED = 'image[data-name="DefaultRetired"]',
}

export enum INSPECTOR_HTML_ATTRS {
    SUB_FLOW_LINKED_ITEMS = '[data-attribute="attrs/SubFlowLinkedEntity"]',
    SUB_FLOW_TYPE = '[data-attribute="attrs/SubFlowType"]',
    SUB_FLOW_STATUS = '[data-attribute="attrs/SubFlowStatus"]',
}

export enum NODE_TYPES {
    CONVENTIONAL = 'Conventional',
    STATUS = 'Status',
    ERROR = 'Error',
    APPROVE = 'Approve',
    MESSAGE = 'Message',
    FUNCTION = 'Function',
    TEST = 'Test',
    PARSE = 'Parse',
    JOIN = 'Join',
    SECURITY = 'Security',
    START_ACTION = 'StartAction',
    SUB_FLOW = 'Subflow'
}

export enum NODE_TYPES_ID {
    START,
    CONVENTIONA,
    STATUS,
    ERROR,
    APPROVE,
    MESSAGE,
    FUNCTION,
    TEST,
    PARSE,
    JOIN,
    SECURITY,
    START_ACTION,
    SUB_FLOW
}

export const DEFAULT_LINK_ATTRS = {
    '.connection': {stroke: '#000000', strokeWidth: 2},
    '.marker-target': {d: 'M 0 -5 L -10 0 L 0 5 Z' }
};


