/// <reference types="webrtc" />

import * as ko from "knockout";
import { Modal } from 'Core/Common/Modal';
import { Event } from 'Core/Common/Event';
import { Notifier } from 'Core/Common/Notifier';
import { NOTIFICATIONS, LABELS } from "Core/Components/Translation/Locales";

import CameraTemplate from 'Core/Controls/Image/Templates/CameraTemplate.html'
ko.templates['Core/Controls/Image/Templates/CameraTemplate'] = CameraTemplate;

export class ImageFromCamera extends Event  {
	private _canvas: any;
	private _video: any;
	private _context: any;
	private _modal: Modal;
	private _cansavStatus: KnockoutObservable<boolean>;
	private _showStream: KnockoutObservable<boolean>;
	private _stream: MediaStream;
	private _el: any;
	private _labels: LABELS;
	private _width: KnockoutObservable<number>;
	private _height: KnockoutObservable<number>;

	constructor() {
		super();
		this._canvas = ko.observable(null);
		this._video = ko.observable(null);
		this._cansavStatus = ko.observable(false);
		this.AddEvent('SAVE');
		this.AddEvent('Close');
		this._showStream = ko.observable(true);
		this._labels = LABELS;
		this._width = ko.observable(null);
		this._height = ko.observable(null);

		this.GetCameraStream()
			.then((stream) => {
				this._stream = stream;
				let videoTrack = stream.getVideoTracks()[0];
				let settings = videoTrack.getSettings();

				this._width(settings.width);
				this._height(settings.height);

				this.ShowInModal();
			})
			.catch((error) => {
				const message = NOTIFICATIONS.ERROR_CAMERA_INITIALIZATION.replace('{ErrorMessage}', error.message);
				new Notifier(null).Failed(message);

				console.log(error);
			});
	}

	AfterRender(el) {
		this._el = el;

		this.InitPhotoPreview();
		this._modal.Show();
	}

	InitPhotoPreview() {
		this._video = $(this._el).find('#video')[0];
		this._canvas = $(this._el).find('#canvas')[0];
		this._context = this._canvas.getContext('2d');

        if ('srcObject' in this._video) {
            this._video.srcObject = this._stream;
        } else {
			const url = window.URL || window['webkitURL'];
            // Avoid using this in new browsers, as it is going away.
            this._video.src = url.createObjectURL(this._stream as any);
        }
		this._video.play();
	}

	GetCameraStream(): Promise<MediaStream> {
		return new Promise((resolve, reject) => {
            const constraints = {
				video: {
					width: {
						min: 640,
						ideal: 1920,
						max: 2560,
					},
					height: {
						min: 480,
						ideal: 1080,
						max: 1440
					},
					video: true
				}
			};

            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia(constraints)
                    .then(resolve)
                    .catch(reject);

            } else {
                // for browsers that doesn't support mediaDevices.getUserMedia method
                navigator.getUserMedia =
                    navigator.getUserMedia ||
                    navigator['webkitGetUserMedia'] ||
                    navigator['mozGetUserMedia'] ||
                    navigator['msGetUserMedia'];

                if (navigator.getUserMedia) {
                    navigator.getUserMedia(constraints, resolve, reject);
                } else {
                    const notifier = new Notifier(null);

                    notifier.Failed(NOTIFICATIONS.GET_USER_MEDIA_IS_NOT_SUPPORTED);
                    this.CloseModal();
                    return reject({ message: NOTIFICATIONS.GET_USER_MEDIA_IS_NOT_SUPPORTED});
                }
			}
		});
	}

	TakePhoto() {
		this._context.drawImage(this._video, 0, 0, 1920, 1080, 0, 0, 1920, 1080);
		this._cansavStatus(true);
		this._showStream(false);
		this.StopStream();
	}

	BackToStream() {
		this.GetCameraStream()
			.then((stream) => {
				this._stream = stream;

				this.InitPhotoPreview();
			})
			.catch((error) => {
				this.CloseModal();

				console.log(error);
			});


		this._showStream(true);
	}

    StopStream() {
        if (this._stream) {
            this._stream.getTracks().forEach((stream) => stream.stop());
        }
	}

	Save() {
		if (this._cansavStatus()) {
			const base64Image = this._canvas.toDataURL('image/jpeg');

			this._modal.Close();
			this.Trigger('SAVE', { Image: base64Image });
		} else {
			new Notifier().Warning(NOTIFICATIONS.PLEASE_TAKE_PHOTO);
		}
	}

	ShowInModal() {
		this._modal = new Modal({
			widthInPercent: 100,
			heightInPercent: 100,
			addClass: 'camera-modal'
		}, false);

		this._modal.On('CLOSE', this, this.StopStream);

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);
	}

	CloseModal() {
        this.StopStream();
        if (this._modal) {
        	this._modal.Close();
        }
	}

	GetTemplateName() {
		return 'Core/Controls/Image/Templates/CameraTemplate';
	}

	CloseComponentInModal() {
		this.CloseModal();
	}
}
