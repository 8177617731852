///<reference path="../typings/jsPlumb.d.ts" />

import * as ko from 'knockout';
import * as _ from 'underscore';
import 'jsPlumb';
import 'pubsub';

import { BlockUI } from 'Core/Common/BlockUi';
import {MobileChecker} from "Core/Common/MobileChecker";
import { QueryExpressionModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import { ICreateNewQueryRequestModel, NewQueryStore } from 'QueryBuilder/Stores/NewQueryStore';
import { GetQueryStore } from 'QueryBuilder/Stores/GetQueryStore';
import { IUpdateQueryRequestModel, UpdateQueryStore } from 'QueryBuilder/Stores/UpdateQueryStore';
import { ConditionBuilder } from 'QueryBuilder/QueryCondition/ConditionBuilder/ConditionBuilder';
import { Guid } from 'Core/Common/Guid';
import { TableViewConfig } from 'QueryBuilder/TableViewConfig/TableViewConfig';
import { Notifier } from 'Core/Common/Notifier';
import { Event } from 'Core/Common/Event';
import { QUERY_BUILDER } from 'QueryBuilder/Constants';
import { EVENTS, EVENTS as QUERY_BUILDER_EVENTS } from 'QueryBuilder/Events';
import { QueryExpression } from 'QueryBuilder/QueryExpression/QueryExpression';
import { QuerySorting } from 'QueryBuilder/QuerySorting/QuerySorting';
import { EntityMetadataStore } from 'QueryBuilder/Stores/EntityMetadataStore';
import { EntityRelationshipsMetadataModel } from 'Core/Controls/Grid/Models/GridDataModel/Metadata/EntityRelationshipsMetadataModel';
import { QueryEntityModel } from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityModel';
import { ViewModes } from 'Core/Controls/Grid/BaseGrid/Enums/ViewModes';
import { QueryResultGrid } from 'QueryBuilder/QueryResultGrid/QueryResultGrid';
import { QueryBootstrap } from 'QueryBuilder/QueryBootstrap/QueryBootstrap';
import { LABELS, NOTIFICATIONS } from "Core/Components/Translation/Locales";
import { CrossTableConfig } from 'QueryBuilder/CrossTableConfig/CrossTableConfig';
import { Util } from 'QueryBuilder/Util';
import { EVENTS as BOOTSTRAP_EVENTS } from 'QueryBuilder/QueryBootstrap/Events';
import { Serialize } from 'libs/cerialize';
import { IQueryExpressionParams } from 'QueryBuilder/QueryExpression/IQueryExpressionParams';
import { IObjectIndex } from 'QueryBuilder/IObjectIndex';
import { QueryScreen } from './QueryScreen/QueryScreen';
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import { TranslationModel } from 'Core/ScreenManager/Models/TranslationModel';

import { QuerySortModel } from "../Core/Controls/Grid/Models/GridDataModel/QueryExpression/QuerySortModel";
import { SortOrder } from "./Enums";
import { QueryEntityJoinModel } from "../Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryEntityJoinModel";
import { FIELD_TYPES } from "../Core/Constant";
import { SortItem } from "./QuerySorting/SortItem/SortItem";
import { AttachedFieldModel } from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {TYPES_COMPATIBILITY} from "QueryBuilder/QueryCondition/ConditionItem/ConditionItemConfig";
import { TemplateScreen } from './TemplateScreen/TemplateScreen';
import {TranslationFieldEditor} from 'Core/Components/TranslationFieldEditor/TranslationFieldEditor';
import {ITranslationValue} from 'Core/Controls/BaseControl/BaseControl';
import { GlobalManager, GLOBALS } from "Core/GlobalManager/GlobalManager";

import QueryBuilderTemplate from 'QueryBuilder/Templates/QueryBuilder.html';
import { IControlAttachedField } from 'Core/Screens/BaseScreen';
ko.templates['QueryBuilder/Templates/QueryBuilder'] = QueryBuilderTemplate;

const DELETE_STATUS = 'Delete';

export class QueryBuilder extends Event {
	private _container: HTMLElement;
	private _expression: KnockoutObservable<QueryExpression>;
	private _id: string;
	private _el: HTMLElement;
	private _jsPlumb: KnockoutObservable<jsPlumbInstance>;
	private _queryType: ViewModes;
	private _subjectEntityId: KnockoutObservable<number>;
	private _gridSubjectEntityId: KnockoutObservable<number>;
	private _queryName: KnockoutObservable<string>;
	private _hasData: KnockoutObservable<boolean>;
	private _hasPendingChanges: KnockoutObservable<boolean>;
	private _isEmbedded: KnockoutObservable<boolean>;
	private _queryResultGrid: KnockoutObservable<QueryResultGrid>;
	private _subjectRecordId: number;
	private _expressionModel: QueryExpressionModel;
	private _entitiesRelationships: Array<EntityRelationshipsMetadataModel>;
	private _defaultQueryName = 'New Query';
	private _recordsPerPage = 0;
	private _loadInProgres: KnockoutObservable<boolean>;
	private _queryBootstrap: KnockoutObservable<QueryBootstrap>;
	private _isDesktop: KnockoutObservable<boolean>;
	private _labels = LABELS;
	private _saveToQueries: boolean;
	private _objectIndexes: Array<IObjectIndex>;
	private _filterByOwners: boolean;
	private _isNew: boolean;
	private _screenFields: Array<IControlAttachedField>;
	private _showInMenu: boolean;
	private _showInPortlet: boolean;
	private _translationFieldEditor: KnockoutObservable<TranslationFieldEditor>;
	private _basicMode: boolean;
	private _isMobile: MobileChecker;

	QueryId: number;
	ShowQueryName: boolean;
	SingleColumn: boolean;
	SingleColumnType: string;

	TableViewConfig: KnockoutObservable<TableViewConfig>;
	ConditionBuilder: KnockoutObservable<ConditionBuilder>;
	QuerySorting: KnockoutObservable<QuerySorting>;
	CrossTableConfig: KnockoutObservable<CrossTableConfig>;
	QueryScreen: KnockoutObservable<QueryScreen>;
	TemplateScreenTab: KnockoutObservable<TemplateScreen>;

	CrossTableTabId: string;
	FilterTabId: string;
	TableViewConfigTabId: string;
	QueryResultTabId: string;
	QueryScreenTabId: string;
	TemplateScreenTabId: string;

	constructor(
		isEmbedded: boolean = true,
		isDesktop: boolean = false,
		saveToQueries: boolean = false,
		canvasQuery: boolean = false,
		screenFields: Array<IControlAttachedField> = []
	) {
		super();
		this._id = Guid.NewGuid();
		this._expression = ko.observable(null);
		this._jsPlumb = ko.observable(jsPlumb.getInstance());
		this._hasData = ko.observable(false);
		this._hasPendingChanges = ko.observable(false);
		this._queryName = ko.observable(this._defaultQueryName);
		this._isEmbedded = ko.observable(isEmbedded);
		this._queryResultGrid = ko.observable(null);
		this._isDesktop = ko.observable(isDesktop);
		this._saveToQueries = saveToQueries;
		this._subjectRecordId = 0;
		this._entitiesRelationships = [];
		this._loadInProgres = ko.observable(false);
		this._queryBootstrap = ko.observable(null);
		this._subjectEntityId = ko.observable(null);
		this.ConditionBuilder = ko.observable(null);
		this.QuerySorting = ko.observable(new QuerySorting());
		this.TableViewConfig = ko.observable(new TableViewConfig());
		this.CrossTableConfig = ko.observable(new CrossTableConfig());
		this.QueryScreen = ko.observable(null);
		this.TemplateScreenTab = ko.observable(null);
		this.CrossTableTabId = Guid.NewGuid();
		this.FilterTabId = Guid.NewGuid();
		this.TableViewConfigTabId = Guid.NewGuid();
		this.QueryResultTabId = Guid.NewGuid();
		this.QueryScreenTabId = Guid.NewGuid();
		this.TemplateScreenTabId = Guid.NewGuid();
		this.ShowQueryName = !canvasQuery;
		this.SingleColumn = false;
		this._objectIndexes = [];
		this._filterByOwners = false;
		this._isNew = false;
		this._screenFields = screenFields;
		this._translationFieldEditor = ko.observable(null);
		this._basicMode = GlobalManager.Instance.GetGlobal(GLOBALS.BASIC_MODE) === '1';
		this._recordsPerPage = Number(GlobalManager.Instance.GetGlobal(GLOBALS.OTHER_GRID_PAGE_LINES));
		this._isMobile = MobileChecker.IsMobile();

		this._queryName.subscribe(newValue => {
			if(this._translationFieldEditor()){
				this._translationFieldEditor().SetValue(newValue);
			}			
		});

		this.Init();
	}

	ChangeTranslation(translation: ITranslationValue) {
        this._queryName(translation.Value);
    }

	private Init(){
		this.AddEvent(QUERY_BUILDER_EVENTS.CLOSE);
		this.AddEvent(QUERY_BUILDER_EVENTS.DATA_SAVED);
		this.AddEvent(QUERY_BUILDER_EVENTS.NO_ACCESS);
		this.RemoveAllConnections();
	}

	set FilterByOwners(value: boolean) {
		this._filterByOwners = value;
		if(this._queryResultGrid()){
			this._queryResultGrid().FilterByOwners = this._filterByOwners;
		}
	}

	set SubjectRecordId(recordId: number) {
		this._subjectRecordId = recordId;
		if(this._queryResultGrid()){
			this._queryResultGrid().RecordId = this._subjectRecordId;
		}
	}

	set SubjectEntityId(entityId: number) {
		this._subjectEntityId(entityId);
	}

	Render(target: string) {
		this._container = document.getElementById(target);
		ko.cleanNode(document.getElementById(target));
		ko.applyBindings(this, document.getElementById(target));
	}

	GetTemplateHtml() {
		return QueryBuilderTemplate;
	}

	GetTemplateName() {
		return 'QueryBuilder/Templates/QueryBuilder';
	}

	EditQuery(queryId: number, recordsPerPage: number) {
		this._recordsPerPage = recordsPerPage;
		BlockUI.Block({Target: this._el});
		GetQueryStore.Get({Id: queryId})
			.always(() => {
				BlockUI.Unblock(this._el);
			})
			.then((data) => {
				this.InitQueryName(data.Name, data.NameTranslations);
				this.QueryId = queryId;
				this._entitiesRelationships = data.EntitiesRelationships;
				this.InitQuery(data.Query, false);
				this._hasData(true);
			}).fail(err=>{
				new Notifier().Failed(err.message);
				this.Trigger(QUERY_BUILDER_EVENTS.CLOSE);
				this.Trigger(QUERY_BUILDER_EVENTS.NO_ACCESS, { Message: err.message });
			});;
	}

	ToggleTranslations() {
        this._translationFieldEditor().Toggle();
    }

	GetTranslation(name: string, translations: Array<TranslationModel>): ITranslationValue {
        return TranslationManager.Instance.GetTranslation(name, translations);
    }

	private SaveQuery() {
		if (this.IsValid()) {
			if (this.QueryId > 0) {
				this.UpdateQuery(false);
			} else {
				this.CreateQuery(false);
			}
		}
	}

	private SaveAndCloseQuery() {
		if (this.IsValid()) {
			if (this.QueryId > 0) {
				this.UpdateQuery(true);
			} else {
				this.CreateQuery(true);
			}
		}
	}

	GetModel(): QueryExpressionModel {
		if (this._expression()) {
			this.TableViewConfig().Update();
			return this._expression().Model;
		}
		return null;
	}

	GetSubjectEntityId(): number {
		return this._subjectEntityId();
	}

	GetGridSubjectEntityId(): number {
		let joins = this._expressionModel.EntityJoins
			.concat(this._expressionModel.ReferenceLookupEntityJoins)
			.concat(this._expressionModel.ReferenceEntityJoins)
			.concat(this._expressionModel.SubEntityJoins)
			.concat(this._expressionModel.LookupEntityJoins);

		let gridSubjectEntity: QueryEntityJoinModel;

		if (this.QueryTypeName === ViewModes.TableView) {
			gridSubjectEntity = _.find(joins, (item)=>item.Entity.IsGridSubject) || _.first(this._expressionModel.EntityJoins);;
		}
		
		if (gridSubjectEntity && this.QueryTypeName === ViewModes.TableView) {
			return gridSubjectEntity.Entity.Metadata.Id;
		}
		return 0;
	}

	GetShowInMenu() {
		return this.QueryScreen() && this.QueryScreen().ShowInMenu;
	}

	GetShowInPortlet() {
		return this.TemplateScreenTab() && this.TemplateScreenTab().ShowInPotlet;
	}

	private UpdateQuery(close: boolean) {
		let queryName = this._translationFieldEditor() ? this._translationFieldEditor().GetDefaultTranslation().Value : this._queryName();

		var updateQueryRequestModel: IUpdateQueryRequestModel = {
			Id: this.QueryId,
			Name: queryName,
			QueryText: JSON.stringify(Serialize(this._expression().Model)),
			NameTranslations: this._translationFieldEditor() ? this._translationFieldEditor().GetTranslations(false) : []
		};

		BlockUI.Block();
		UpdateQueryStore.Update(updateQueryRequestModel)
		.always(()=>{
			BlockUI.Unblock();
		})
		.then((data) => {
			this.Trigger(QUERY_BUILDER_EVENTS.DATA_SAVED, {
				RecordId: this.QueryId,
				Close: close,
				QueryText: updateQueryRequestModel.QueryText
			});
		});
	}

	private CreateQuery(close: boolean) {
		var gridSubjectEntity;
		if (this.QueryTypeName === ViewModes.TableView) {
			gridSubjectEntity = _.first(this._expressionModel.EntityJoins);
		} else {
			gridSubjectEntity = this._expressionModel;
		}
		if (gridSubjectEntity) {
			var newQueryRequestModel: ICreateNewQueryRequestModel = {
				TypeName: this.QueryTypeName,
				SubjectEntityId: this._expressionModel.Entity.Metadata.Id,
				GridSubjectEntityId: this.QueryTypeName === ViewModes.TableView ? gridSubjectEntity.Entity.Metadata.Id : 0,
				Name: this._queryName(),
				QueryText: JSON.stringify(Serialize(this._expression().Model))
			};

			if (this._saveToQueries) {
				NewQueryStore.Create(newQueryRequestModel).then((data) => {
					this.QueryId = data.Id;
					this.Trigger(QUERY_BUILDER_EVENTS.DATA_SAVED, {
						RecordId: data.Id,
						Close: close,
						QueryText: newQueryRequestModel.QueryText
					});
				});
			} else {
				this.Trigger(QUERY_BUILDER_EVENTS.DATA_SAVED, {
					Close: close,
					QueryText: newQueryRequestModel.QueryText
				});
			}
		}
	}

	AfterRender(el) {
		this._el = el[0];
		var exp = this._expression();
		this._expression(null);
		this._jsPlumb(jsPlumb.getInstance());
		this._expression(exp);
	}

	set QueryTypeName(value: ViewModes) {
		this._queryType = value;
	}

	get QueryTypeName(): ViewModes {
		return this._queryType;
	}

	get Expression(): KnockoutObservable<QueryExpression> {
		return this._expression;
	}

	InitQuery(expression: QueryExpressionModel, isNew: boolean = true) {
		this._isNew = isNew;
		this._queryBootstrap(null);
		this._expressionModel = expression;

		this._queryResultGrid(new QueryResultGrid(
			this._expressionModel,
			this._subjectRecordId,
			this._recordsPerPage,
			this._queryType,
			this._isDesktop(),
			this._filterByOwners,
			this.GetSubjectEntityId(),
			this.GetGridSubjectEntityId()
		));

		this._expressionModel.Entity.CanvasPosition.Top = QUERY_BUILDER.CELL_SIZE;
		this._expressionModel.Entity.CanvasPosition.Left = QUERY_BUILDER.CELL_SIZE;

		if (isNew) {

			_.each(this._expressionModel.ReferenceLookupEntityJoins,
				(entityJoin, index) => {
					entityJoin.Entity.CanvasPosition.Left = QUERY_BUILDER.CELL_SIZE * 15;
					entityJoin.Entity.CanvasPosition.Top = QUERY_BUILDER.CELL_SIZE * (index + 1) * 4;
				});

			_.each(this._expressionModel.EntityJoins,
				(entityJoin, index) => {
					entityJoin.Entity.CanvasPosition.Left = QUERY_BUILDER.CELL_SIZE * 15;
					entityJoin.Entity.CanvasPosition.Top = QUERY_BUILDER.CELL_SIZE * (index + 1) * 4;
				});

			_.each(this._expressionModel.SubEntityJoins,
				(entityJoin, index) => {
					entityJoin.Entity.CanvasPosition.Left = QUERY_BUILDER.CELL_SIZE;
					entityJoin.Entity.CanvasPosition.Top = QUERY_BUILDER.CELL_SIZE * (index + 4) * 2;
				});
		}

		let entities = Util.GetAllQueryEntities(this._expressionModel);
		let ids = [];
		_.each(entities, entity => {
			ids.push(entity.Metadata.Id)
		});
		ids = _.uniq(ids);

		var isFullScreen = $(this._el).hasClass('portlet-fullscreen');
		if(isFullScreen) {
			BlockUI.Block({Target: $(this._el).find('.portlet-fullscreen')[0]});
		}
		else {
			this._loadInProgres(true);
		}
		EntityMetadataStore.GetEntitiesMetadata({EntityIds: ids})
			.always(() => {
				if(isFullScreen) {
					BlockUI.Unblock($(this._el).find('.portlet-fullscreen')[0]);
				}
				else {
					this._loadInProgres(false);
				}
			})
			.then((data) => {
				this._entitiesRelationships = data;
				this.InitQueryExpression();
			});

		if(this._queryType === ViewModes.Query){
			this.QueryScreen(new QueryScreen());
		}

		if(this._queryType == ViewModes.TableView || this._queryType == ViewModes.ListView){
			this.TemplateScreenTab(new TemplateScreen(this.QueryTypeName));
		}
	}

	InitTemplateQuery(recordsPerPage: number, name: string, translations: Array<TranslationModel>) {		
		this._recordsPerPage = recordsPerPage;
		this._queryType = ViewModes.Template;
		NewQueryStore.New(name).then((data) => {
			if(data.NameTranslations.length == 0){
				translations = [];
			}

			this.InitQueryName(name, translations);
			this._queryBootstrap(new QueryBootstrap(data.Entities));
			this._queryBootstrap().On(BOOTSTRAP_EVENTS.ENTITY_SELECTED,
				this,
				(eventArgs) => {
					this.InitQueryByEntityId(eventArgs.data.EntityId);
					this._queryBootstrap(null);
				});
		});
	}

	private InitQueryName(name: string, translations: Array<TranslationModel>){
		if(translations.length >= 0){
			let translation = this.GetTranslation(name, translations);
			let translationEditor = new TranslationFieldEditor();
			this._translationFieldEditor(translationEditor);                    
			translationEditor.On('TranslationSelected', this, eventArgs => this.ChangeTranslation(eventArgs.data));
			translationEditor.LoadTranslationItems();
			translationEditor.SetTranslations(translations, name);
			translationEditor.SetActiveTranslation(translation.Language.Id);    
			let currentValue = (translation.TranslatedValue || translation.Value) ? translation.TranslatedValue || translation.Value : this._defaultQueryName;
			this._queryName(currentValue);
		}else{
			this._queryName(name);
		}
	}

	set QueryType(type: ViewModes) {
		this._queryType = type; 
	}

	InitQueryByEntityId(entityId: number) {
		var isFullScreen = $(this._el).hasClass('portlet-fullscreen');
		if(isFullScreen) {
			BlockUI.Block({Target: $(this._el).find('.portlet-fullscreen')[0]});
		}
		else {
			this._loadInProgres(true);
		}
		EntityMetadataStore.GetEntityMetadata({EntityId: entityId})
			.always(() => {
				if(isFullScreen) {
					BlockUI.Unblock($(this._el).find('.portlet-fullscreen')[0]);
				}
				else {
					this._loadInProgres(false);
				}
			})
			.then((data) => {
				this._entitiesRelationships = [data];
				this._expressionModel = new QueryExpressionModel();
				this._expressionModel.Entity = new QueryEntityModel();
				this._expressionModel.Entity.Metadata = data.EntityMetadata;
				this._expressionModel.Entity.CanvasPosition.Top = QUERY_BUILDER.CELL_SIZE;
				this._expressionModel.Entity.CanvasPosition.Left = QUERY_BUILDER.CELL_SIZE;
				this.InitQueryExpression();
				this._queryResultGrid(new QueryResultGrid(
					this._expressionModel,
					this._subjectRecordId,
					this._recordsPerPage,
					this._queryType,
					this._isDesktop(),
					this._filterByOwners,
					this.GetSubjectEntityId(),
					this.GetGridSubjectEntityId()
				));
			});

		this._subjectEntityId(entityId);
	}

	NewFreeQuery() {

		if (!this._queryType && this._queryType != 0) {
			this._queryType = ViewModes.Query;
		}

		if(this._queryType === ViewModes.Query){
			this.QueryScreen(new QueryScreen());
		}

		if(this._queryType === ViewModes.TableView || this._queryType === ViewModes.ListView){
			this.TemplateScreenTab(new TemplateScreen(this.QueryTypeName));
		}

		NewQueryStore.New('').then((data) => {
			this._queryBootstrap(new QueryBootstrap(data.Entities));
			this._queryBootstrap().On(BOOTSTRAP_EVENTS.ENTITY_SELECTED,
				this,
				(eventArgs) => {
					this.InitQueryByEntityId(eventArgs.data.EntityId);
					this._queryBootstrap(null);
				});
		});
	}

	EditFreeQuery(queryModel: QueryExpressionModel, showInMenu: boolean = false, showInPortlet: boolean = false) {
		if (queryModel) {
			if (!this._queryType && this._queryType != 0) {
				this._queryType = ViewModes.Query;
			}
			this._subjectEntityId(queryModel.Entity.Metadata.Id);
			this._showInMenu = showInMenu;
			this._showInPortlet = showInPortlet;

			this.InitQuery(queryModel, false);
		}
	}

	InitQueryExpression() {
		let params: IQueryExpressionParams = {
			Model: this._expressionModel,
			JsPlumb: this._jsPlumb,
			EntitiesRelationships: this._entitiesRelationships,
			ViewMode: this._queryType,
			Container: this._el,
			EnabledColumnTypes: this.SingleColumnType ? TYPES_COMPATIBILITY[this.SingleColumnType].Types : [],
			ObjectIndexes: this._objectIndexes
		};

		this._expression(new QueryExpression(params));

		if(this._queryType === ViewModes.Template){
			if(this._expressionModel.Entity.Metadata.Lifestatus === DELETE_STATUS){
				this._expression(null);

				if (this._jsPlumb()) {
					_.each(this._jsPlumb().getConnections(), (connection) => {
						this._jsPlumb().deleteConnection(connection);
					});
				}

				this.NewFreeQuery();
			};
		}

		if(this._queryType === ViewModes.TableView && this._isNew){
			if(this._expressionModel.EntityJoins.length === 1){
				let sortColumn = _.find(this._expressionModel.EntityJoins[0].LinkEntity.Columns, (item) => { return item.Metadata.Type ===  FIELD_TYPES.Sort });
				if(sortColumn != null){
					let sort = new QuerySortModel();
					sort.Column = sortColumn;
					sort.Column.QueryEntityGuid = this._expressionModel.EntityJoins[0].LinkEntity.Guid;
					sort.SortOrder = SortOrder.Ascending;
					this._expressionModel.Sorts.push(sort);
				}
			}
		}

		this._expression().On(QUERY_BUILDER_EVENTS.COLUMN_SELECTED, this, (eventArgs)=>{
			let columnId = eventArgs.data.Id;
			let join = eventArgs.data.Join as QueryEntityJoinModel;
			let column = _.find(join.LinkEntity.Columns, (item)=>{ return item.Metadata.Type === FIELD_TYPES.Sort && item.Metadata.Id === columnId});
			if(column){
				let columnInSorting = _.find(this.QuerySorting().Items(), (item)=> { return item.Model.Column.Metadata.Id === columnId});
				if(!columnInSorting){
					let sort = new QuerySortModel();
					sort.Column = column;
					sort.Column.QueryEntityGuid = join.LinkEntity.Guid;
					sort.SortOrder = SortOrder.Ascending;
					let sortItem = new SortItem(sort, join.LinkEntity);
					this.QuerySorting().Items.push(sortItem);
				}
			}
		});

		this._expression().On(EVENTS.UPDATE_SORT,this, ()=>this.QuerySorting().Update());

		this.TableViewConfig().ExpressionModel = this._expressionModel;
		this.CrossTableConfig().ExpressionModel = this._expressionModel;
		this.QuerySorting().ExpressionModel = this._expressionModel;
		this.ConditionBuilder(new ConditionBuilder(this._queryType, this._screenFields));
		this.ConditionBuilder().InitByQueryExpression(this._expression().Model);
		if(this.QueryScreen()){
			this.QueryScreen().ExpressionModel = this._expressionModel;
			this.QueryScreen().SubjectEntityId = this.GetGridSubjectEntityId() || this.GetSubjectEntityId(); 
			this.QueryScreen().ShowInMenu = this._showInMenu;			
		}

		if(this.TemplateScreenTab()){
			this.TemplateScreenTab().ExpressionModel = this._expressionModel;
			this.TemplateScreenTab().SubjectEntityId = this.GetGridSubjectEntityId() || this.GetSubjectEntityId();
			this.TemplateScreenTab().ShowInPotlet = this._showInPortlet;
		}
		this._hasData(true);
	}

	BuilderTab() {
		this.ShowConnections(true);
	}

	FilteringPage(context, evt) {
		if (this._hasData()) {
			this.ShowConnections(false);
			this.ConditionBuilder().RefreshEntityList();
		} else {
			evt.stopImmediatePropagation();
			return false;
		}
		return true;
	}

	CrossTablePage(context, evt) {
		if (this._hasData()) {
			this.ShowConnections(false);
			this.CrossTableConfig().Update();
		} else {
			evt.stopImmediatePropagation();
			return false;
		}
		return true;
	}

	ColumnOrderPage(context, evt) {
		if (this._hasData()) {
			this.ShowConnections(false);
			this.TableViewConfig().Update();
		} else {
			evt.stopImmediatePropagation();
			return false;
		}
		return true;
	}

	QueryResultPage(context, evt) {
		if (this._hasData()) {
			this.ShowConnections(false);
			this.RefreshQueryData();
		} else {
			evt.stopImmediatePropagation();
			return false;
		}
		return true;
	}

	RefreshQueryData() {
		if (this._queryResultGrid()) {
			this._queryResultGrid().Refresh();
		}
	}

	ShowConnections(isVisible: boolean) {
		_.each(this._jsPlumb().getConnections(), (connection) => {
			connection.setVisible(isVisible);
		});
	}

	RemoveAllConnections() {
		_.each(this._jsPlumb().getConnections(), (connection) => {
			this._jsPlumb.deleteConnection(connection);
		});
	}

	get Id(): string {
		return this._id;
	}

	Scroll() {
		this._jsPlumb().repaintEverything();
	}

	Cancel() {
		this._jsPlumb().repaintEverything();
		this.Trigger(QUERY_BUILDER_EVENTS.CLOSE);
	}

	Dispose() {
		this.RemoveAllConnections();
	}

	IsValid(): boolean {
		var columns = [];

		var entities = Util.GetAllQueryEntities(this._expression().Model);

		_.each(entities, entity => { columns = columns.concat(entity.Columns); });

		if (columns.length === 0) {
			var notifier = new Notifier();
			notifier.Failed(NOTIFICATIONS.PLEASE_SELECT_ANY_FIELD);
			return false;
		}

		if (columns.length > 1 && this.SingleColumn) {
			var notifier = new Notifier();
			notifier.Failed('Please select only one column');
			return false;
		}

		return true;
	}

	QueryScreenPage(context, evt) {
		if (this._hasData()) {
			this.ShowConnections(false);
			this.QueryScreen().Update();
		} else {
			evt.stopImmediatePropagation();
			return false;
		}
		return true;
	}

	TemplateScreenPage(context, evt) {
		if (this._hasData()) {
			this.ShowConnections(false);
			this.TemplateScreenTab().Update();
		} else {
			evt.stopImmediatePropagation();
			return false;
		}
		return true;
	}
}