import * as ko from 'knockout';
import * as _ from 'underscore';

import {
	DocumentManagerStore,
	IAttachmenttModel,
	ICheckInDocumentRequestModel,
	ICheckOutDocumentRequestModel,
	ICreateEmlMessageRequestModel,
	IDownloadDocumentRequestModel,
	IEditDocumentRequestModel,
	IEnableVersionsDocumentRequestModel,
	IGeneratePdfDocumentRequestModel,
	IPreveiwDocumentRequestModel
} from 'Core/Components/Controls/DocumentManager/Stores/DocumentManagerStore';

import {BlockUI as BlockUi, BlockUI} from 'Core/Common/BlockUi';
import {Event} from 'Core/Common/Event';
import {IScreen} from "Core/Screens/IScreen";
import {RecordKey} from 'Core/Controls/Grid/Models/GridDataModel/GridCellValueModel';
import {Notifier} from 'Core/Common/Notifier';
import {UserManager} from 'User/UserManager';
import {EVENTS} from 'Core/Components/Controls/DocumentManager/Events';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {DocumentDataModel} from 'Core/Controls/Document/DocumentDataModel';
import {PreviewDocumentModel} from 'Core/Components/Controls/DocumentManager/Models/PreviewDocumentModel';
import {DocumentViewer} from 'Core/Components/Controls/DocumentManager/DocumentViewer/DocumentViewer';
import {FileDownloader} from 'Core/Components/FileDownloader/FileDownloader';
import {ComposeMail} from 'Core/Controls/Mail/ComposeMail';
import {MailStore} from 'Core/Controls/Mail/Stores/MailStore';
import {MailTabModel} from 'Core/Controls/Mail/Models/MailTabModel';
import {MailAttachmentModel} from 'Core/Controls/Mail/Models/MailAttachmentModel';
import {FollowUpStore} from "Core/Controls/ButtonFollowUp/Stores/FollowUpStore";
import {IsTrue} from 'Core/Common/Utils';

import DocumentTemplate from 'Core/Components/Controls/DocumentManager/Templates/DocumentManager.html';
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "Core/Components/Translation/Locales";

import {PromptDialog, Types} from 'Core/Components/Dialogs/PromptDialog/PromptDialog';

import {
	ConfirmationDialog,
	EVENTS as CONFIRMATION_DIALOG_EVENTS,
	Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog'
import {JBoxDropDown} from 'Core/Components/JBoxDropdown/DropDown';
import {DestinationMailModel} from "./Models/DestinationMailModel";
	import { Guid } from 'Core/Common/Guid';
import {TABLE_TYPES} from "Core/Constant";

let SOLIDWORKS_EXTENSIONS = {
	SLDASM: '.SLDASM',
	SLDPRT: '.SLDPRT'
}

var EXTENTION_TO_ICON = {
	'.doc': 'fa-file-word-o',
	'.docx': 'fa-file-word-o',
	'.xlsx': 'fa-file-excel-o',
	'.xls': 'fa-file-excel-o',
	'.txt': 'fa-file-text',
	'.eml': 'fa-envelope'
}

let HAS_PDF = ['.doc', '.docx', '.xlsx', '.xls', '.ppt', '.pptx', '.html'];

export enum DocumentStatus {
	NoVersion = 0,
	CheckIn = 1,
	CheckOut = 2,
	Locked = 3,
	OwnerLock = 4,
	SoftCheckIn = 5
};

var MAX_VERSION_FIELD_NAME_POSTFIX = '_MAX_VERSION';
var VERSION_FIELD_NAME_POSTFIX = '_VERSION';
var STATUS_FIELD_NAME_POSTFIX = '_STATUS';
var FILE_TYPE_FIELD_NAME_POSTFIX = '_FILE_TYPE';
var FILE_NAME_FIELD_NAME_POSTFIX = '_FILE_NAME';
var CHANGED_BY_LABEL = '_CHANGEDBY_LABEL';
var CHANGEDBY = '_CHANGEDBY';
var CREATEDBY = '_CREATEDBY';

export class DocumentManager extends Event {
	private _version: KnockoutObservable<string>;
	private _status: KnockoutObservable<DocumentStatus>;
	private _extention: KnockoutObservable<string>;
	private _recordkeys: Array<RecordKey>;
	private _entityId: number;
	private _entityTypeName: string;
	private _fieldId: number;
	private _labels = LABELS;
	private _approvalName: string;
	private _fieldName: string;
	private _subjectEntityId: number;
	private _subjectRecordId: number;
	private _createdBy: KnockoutObservable<number>;
	private _isReady: KnockoutObservable<boolean>;
	private _ownerId: KnockoutObservable<number>;
	private _notifier: Notifier;
	private _enableButtons: boolean;
	private _enableEditButton: boolean;
	private _changedByName: KnockoutObservable<string>;
	private _changedBy: KnockoutObservable<number>;
	private _screenType: string;
	private _screen: IScreen;
	private _model: DocumentDataModel;
	private _hasData: KnockoutObservable<boolean>;
	private _mails: KnockoutObservableArray<MailTabModel>;
	private _showMailList: KnockoutObservable<boolean>;
	private _isEnableMailButton: KnockoutObservable<boolean>;
	private _fileName: string;
	private _dropDown: JBoxDropDown;
	private _id: string;
	private _hasPdfData: KnockoutObservable<boolean>;
	private _isEnableSwitch: boolean;

	ExtentionClassIcon: KnockoutComputed<string>;

	StatusTitle: KnockoutComputed<string>;
	IsEnableCheckIn: KnockoutComputed<boolean>;
	IsEnableCheckOut: KnockoutComputed<boolean>;
	IsEnableEdit: KnockoutComputed<boolean>;
	IsEnableOwnerLock: KnockoutComputed<boolean>;
	IsEnableOwnerUnlock: KnockoutComputed<boolean>;
	IsEnableVersions: KnockoutComputed<boolean>;
	IsEnableNoVersions: KnockoutComputed<boolean>;
	IsEnablePreview: KnockoutComputed<boolean>;
	IsEnableChangedByName: KnockoutComputed<boolean>;
	IsEnableGeneratePdfButton: KnockoutComputed<boolean>;

	constructor(
		screenType: string,
		screen: IScreen,
		model: DocumentDataModel,
		entityId: number,
		entityTypeName: string,
		fieldId: number,
		fieldName: string,
		isEnableMail: boolean = false,
		isEnableSwitchVersion: boolean = false
	) {
		super();
		this._screenType = screenType;
		this._screen = screen;
		this.AddEvent(EVENTS.UPDATED);

		this._fieldId = fieldId;
		this._fieldName = fieldName;
		this._entityId = entityId;
		this._entityTypeName = entityTypeName;
		this._approvalName = model && model.ApprovalName || model && model.Data && model.Data.DOCUMENT_APPROVAL || null;
		this._model = model;
		this._isEnableMailButton = ko.observable(isEnableMail);
		this._subjectEntityId = this._screen ? this._screen.GetEntityId() : null;
		this._subjectRecordId = this._screen ? this._screen.GetRecordId() : null;
		this._isEnableSwitch = isEnableSwitchVersion;

		this.Init();

		if (model && !_.isEmpty(model.Data) && IsTrue(model.Data['HAS_DATA'])) {
			this.SetValue(model.Data);
			this._hasData(true);
			this._hasPdfData(model.Data['HAS_PDF_DATA'] === 'True');
		}
		this._id = JBoxDropDown.GetDropDownId();
	}

	private Init() {
		this._notifier = new Notifier();
		this._version = ko.observable(null);
		this._status = ko.observable(null);
		this._extention = ko.observable(null);
		this._ownerId = ko.observable(null);
		this._changedBy = ko.observable(null);
		this._changedByName = ko.observable('');
		this.StatusTitle = ko.computed(() => { return DocumentStatus[this._status()]; });
		this._createdBy = ko.observable(null);
		this._hasData = ko.observable(false);
		this._mails = ko.observableArray([]);
		this._showMailList = ko.observable(false);
		this._hasPdfData = ko.observable(false);

		this.ExtentionClassIcon = ko.computed(() => {
			return EXTENTION_TO_ICON[this._extention()];
		});

		var userManager = UserManager.Instance;

		this.IsEnableCheckIn = ko.computed(() => {
			return (this._status() === DocumentStatus.CheckOut || this._status() === DocumentStatus.SoftCheckIn) && this._screenType === ScreenTypes[ScreenTypes.EditScreen] && (this._changedBy() === userManager.CurrentUser.Id || this._ownerId() === userManager.CurrentUser.Id) && this._entityTypeName === TABLE_TYPES.Sub;
		});

		this.IsEnableCheckOut = ko.computed(() => {
			return (this._status() === DocumentStatus.CheckIn && this._screenType === ScreenTypes[ScreenTypes.EditScreen]) && this._entityTypeName === TABLE_TYPES.Sub;
		});

		this.IsEnableEdit = ko.computed(() => {
			return (this._status() === DocumentStatus.CheckOut
				&& this._screenType === ScreenTypes[ScreenTypes.EditScreen]
				&& this._changedBy() === userManager.CurrentUser.Id)
				|| (this._status() === DocumentStatus.SoftCheckIn
					&& this._screenType === ScreenTypes[ScreenTypes.EditScreen]);
		});

		this.IsEnableOwnerLock = ko.computed(() => {
			return this._status() === DocumentStatus.CheckOut && this._ownerId() === userManager.CurrentUser.Id && this._screenType === ScreenTypes[ScreenTypes.EditScreen];
		});

		this.IsEnableOwnerUnlock = ko.computed(() => {
			return this._status() === DocumentStatus.OwnerLock && this._ownerId() === userManager.CurrentUser.Id && this._screenType === ScreenTypes[ScreenTypes.EditScreen];
		});

		this.IsEnableVersions = ko.computed(() => {
			//TODO check if version extension is in GLOBAL
			return this._status() === DocumentStatus.NoVersion && this._ownerId() === userManager.CurrentUser.Id && this._screenType === ScreenTypes[ScreenTypes.EditScreen] && this._entityTypeName === TABLE_TYPES.Sub;
		});

		this.IsEnableNoVersions = ko.computed(() => {
			return this._status() === DocumentStatus.CheckIn && this._ownerId() === userManager.CurrentUser.Id && this._screenType === ScreenTypes[ScreenTypes.EditScreen] && this._entityTypeName === TABLE_TYPES.Sub;
		});

		this.IsEnablePreview = ko.computed(() => {
			if (_.contains([ScreenTypes[ScreenTypes.ConsultScreen], ScreenTypes[ScreenTypes.Portlet]], this._screenType)) {
				return true;
			}
			return this._status() === DocumentStatus.Locked || this._status() === DocumentStatus.CheckIn || this._status() === DocumentStatus.NoVersion;
		});

		this.IsEnableChangedByName = ko.computed(() => {
			return this._status() === DocumentStatus.CheckOut || this._status() === DocumentStatus.OwnerLock;
		});

		this.IsEnableGeneratePdfButton = ko.computed(() => {
			return (_.contains(HAS_PDF, this._extention()) && _.contains([null, DocumentStatus.CheckIn, DocumentStatus.NoVersion], this._status())) && !this._hasPdfData() && !this.IsSolidworks;
		});
	}

	GetTemplate() {
		return DocumentTemplate;
	}

	SetValue(data: any) {

		var versionFieldName = `${this._fieldName}${VERSION_FIELD_NAME_POSTFIX}`;
		if (data[versionFieldName]) {
			this._version(data[versionFieldName]);
		}

		var statusFieldName = `${this._fieldName}${STATUS_FIELD_NAME_POSTFIX}`;
		if (data[statusFieldName]) {
			this._status(DocumentStatus[DocumentStatus[Number(data[statusFieldName])]]);
		}

		var fileTypeFieldName = `${this._fieldName}${FILE_TYPE_FIELD_NAME_POSTFIX}`;
		if (data[fileTypeFieldName]) {
			this._extention(data[fileTypeFieldName]);
		}

		var changedByLabel = `${this._fieldName}${CHANGED_BY_LABEL}`;
		if (data[changedByLabel]) {
			this._changedByName(data[changedByLabel]);
		}

		var changedBy = `${this._fieldName}${CHANGEDBY}`;
		if (data[changedBy]) {
			this._changedBy(Number(data[changedBy]));
		}

		var createdBy = `${this._fieldName}${CREATEDBY}`;
		if (data[createdBy]) {
			this._ownerId(Number(data[createdBy]));
		}

		var fileName = `${this._fieldName}${FILE_NAME_FIELD_NAME_POSTFIX}`;
		this._fileName = data[fileName] || Guid.NewGuid();
	}

	Edit() {
		BlockUi.Block();
		var requestModel: IEditDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.Edit(requestModel)
			.fail((err) => {
				this._notifier.Failed(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then((data) => { });
	}

	get IsSolidworks(): boolean{
		return this._extention() === SOLIDWORKS_EXTENSIONS.SLDASM || this._extention() === SOLIDWORKS_EXTENSIONS.SLDPRT;
	}

	CheckIn(password?: string) {
		
		if(this.IsSolidworks){
			this._notifier.Failed(NOTIFICATIONS.CHECKIN_IS_NOT_ALLOWED);
			return;
		}

		BlockUi.Block();
		var requestModel: ICheckInDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		if (password) requestModel.ConfirmationPassword = password;

		DocumentManagerStore
			.CheckIn(requestModel)
			.fail((err) => {
				this._notifier.Failed(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then((data) => {
				this.Trigger(EVENTS.UPDATED);
			});
	}


	CheckAprovalStatus(data) {

		const confirmationDialog = new ConfirmationDialog({
			Text: CONFIRMATIONS.ARE_SURE_TO_CHECKIN_DOCUMENT,
			Type: ConfirmationTypes.Question
		});

		confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
			this,
			() => {
				this.CheckIn();
			});

		const approvalName = this._approvalName || 'Off';
		switch (approvalName) {
			case 'Off':
				this.CheckIn();
				break;
			case 'Password':
				const passwordPrompt = new PromptDialog(
					{
						Type: Types.Password,
						Label: CONFIRMATIONS.PLEASE_TYPE_YOUR_PASSWORD_TO_CONFIRM,
						Value: '',
						MinHeight: 200,
						Required: true,
						RequiredErrorMessage: LABELS.PASSWORD + ' ' + NOTIFICATIONS.IS_REQUIRED,
						ShowNotification: false,
						CloseOnSave: false
					});
				passwordPrompt.Show();

				passwordPrompt.On('Save', this,
					(eventargs) => {
						const password = eventargs.data.value;
						if (password) {
							BlockUI.Block();

							FollowUpStore.ConfirmPassword({ password: password })
								.always(() => {
									BlockUI.Unblock();
								})
								.then((response) => {
									if (response) {
										passwordPrompt.Hide();
										this.CheckIn(password);
									} else {
										passwordPrompt.ExternalInvalidActionHandling(NOTIFICATIONS.INCORRECT_PASSWORD);
									}
								})
								.fail(error => {
									new Notifier().Failed(error);
								});
						}
					}
				);
				break;

			case 'Yes/No':
				confirmationDialog.Show();
				break;
		}

	}

	CheckOut(){
		if(this.IsSolidworks){
			BlockUi.Block();
			const requestModel: ICheckOutDocumentRequestModel = {
				EntityId: this._entityId,
				Recordkeys: this._model.RecordKeys,
				FieldId: this._fieldId
			};
	
			DocumentManagerStore
				.GetParentAssemblies(requestModel)
				.fail((err) => {
					this._notifier.Warning(err.message);
				})
				.always(() => {
					BlockUi.Unblock();
				})
				.then((assemblies) => {
						
					if(assemblies && assemblies.length > 0){

						let assemblyList = '';
						_.each(assemblies, (assembly) => {
							assemblyList += `<br><b>${assembly.Name}</b>`;
						});

						const confirmationDialog = new ConfirmationDialog({
							Text: `${CONFIRMATIONS.CONFIRM_ASSEMBLY_CHECKOUT} ${assemblyList}`,
							Type: ConfirmationTypes.Question
						});

						confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED,
							this,
							() => {
								this.DoCheckOut();
							});

						confirmationDialog.Show();
					}else{
						this.DoCheckOut();
					}
				});				
		}else{
			this.DoCheckOut();
		}

	}

	DoCheckOut() {
		BlockUi.Block();
		const requestModel: ICheckOutDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.CheckOut(requestModel)
			.fail((err) => {
				this._notifier.Warning(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then((data) => {
				this.Trigger(EVENTS.UPDATED);
			});
	}

	Lock() {
		BlockUi.Block();
		var requestModel: ICheckOutDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.Lock(requestModel)
			.fail((err) => {
				this._notifier.Warning(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then((data) => {
				this.Trigger(EVENTS.UPDATED);
			});
	}

	Unlock() {
		BlockUi.Block();
		var requestModel: ICheckOutDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.Unlock(requestModel)
			.fail((err) => {
				this._notifier.Warning(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then((data) => {
				this.Trigger(EVENTS.UPDATED);
			});
	}

	EnableVersions() {
		BlockUi.Block();
		var requestModel: IEnableVersionsDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.EnableVersions(requestModel)
			.fail((err) => {
				this._notifier.Failed(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then((data) => {
				this.Trigger(EVENTS.UPDATED);
			});
	}

	DisableVersions() {
		BlockUi.Block();
		var requestModel: IEnableVersionsDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.DisableVersions(requestModel)
			.fail((err) => {
				this._notifier.Failed(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then((data) => {
				this.Trigger(EVENTS.UPDATED);
			});
	}

	Preview() {
		BlockUi.Block();
		var requestModel: IPreveiwDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.Preview(requestModel)
			.fail((err) => {
				this._notifier.Failed(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then((data: PreviewDocumentModel) => {
				if (data && data.IsWebDocument) {
					this.ShowDocument(data, requestModel);
				}
				else {
					this._notifier.Success(LABELS.OPEN_DOCUMENT_IN_SPHEERES)
				}
			});
	}

	Download() {
		BlockUi.Block();
		var requestModel: IDownloadDocumentRequestModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.Download(requestModel)
			.fail((err) => {
				this._notifier.Failed(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			}).then((data) => {
				let name = this._fileName.endsWith(this._extention()) ? this._fileName : `${this._fileName}${this._extention()}`;
				FileDownloader.DownloadFileData(data.FileData, name);
			});
	}

	private ShowDocument(previewDocument: PreviewDocumentModel, requestModel: IPreveiwDocumentRequestModel) {
		var documentViewer = new DocumentViewer(previewDocument, requestModel);
		documentViewer.Show();
	}

	get Version(): KnockoutObservable<string> {
		return this._version;
	}

	get ChangedByName(): KnockoutObservable<string> {
		return this._changedByName;
	}

	private OpenDropDown() {
		this._dropDown = new JBoxDropDown({
			target: "." + this._id,
			otherOptions: {
				attach: undefined,
				closeOnClick: true,
				addClass: "mails-dropdown",
				onCloseComplete: () => this._dropDown.Destroy()
			},
			onOpen: () => {
				this._dropDown.SetContent({ content: $("." + this._id).next().data("jbox-content") });
			},
			bindComponent: this,
		});

		this._dropDown.Open();
	}

	GetMails() {
		const mailsCount = this._mails().length;
		if (mailsCount == 0) {
			BlockUI.Block();
			this._mails([]);
			MailStore.GetMailTabs()
				.then((tabs) => {
					_.map(tabs, (tab: any) => {
						let mail = new MailTabModel();
						mail.Login = tab.Login;
						mail.MailConnectionId = tab.MailConnectionId;
						mail.Client = tab.Client;
						this._mails.push(mail);
					});

					if (this._mails().length > 1) {
						this.OpenDropDown();
					}

					if (this._mails().length === 1) {
						this.ShowMailComposer(this._mails()[0]);
					}
				})
				.always(() => {
					BlockUI.Unblock();
				});
		} else if (mailsCount > 1) {
			this.OpenDropDown();
		} else {
			this.ShowMailComposer(this._mails()[0]);
		}
	}

	ShowMailComposer(mail: MailTabModel) {
		if (mail.Client === 'Desktop') {
			this.OpenDesktopClient(mail);
		} else {
			this.OpenInternalClient(mail);
		}
	}

	OpenInternalClient(mail: MailTabModel) {

		DocumentManagerStore.GetMainMails({ EntityId: this._subjectEntityId, RecordId: this._subjectRecordId })
			.then((data) => {
				const mailComposer = new ComposeMail({ ResponseMailItem: null });
				const attachDocumentModel = new MailAttachmentModel();
				attachDocumentModel.DocumentFieldId = this._fieldId;
				attachDocumentModel.RecordKeys = this._model.RecordKeys;
				attachDocumentModel.Name = this._fileName;
				mailComposer.AttachDocument(attachDocumentModel);
				mailComposer.IsEnableAttachment = false;
				mailComposer.IsEnableRemoveAttachment = false;
				mailComposer.AddScreen(this._screen);

				const mailsByRecord = _.groupBy(data, (item) => item.RecordId);

				_.each(mailsByRecord, (value, key) => {
					const firstMail = _.find(value, (item: DestinationMailModel) => { return !!item.Mail });
					if (firstMail) {
						mailComposer.AddMailWithRecord(firstMail.Mail, firstMail.TableId, firstMail.RecordId);
					}
				});

				mailComposer.ShowInModal(mail.MailConnectionId);
			})
	}

	OpenDesktopClient(mail: MailTabModel) {

		var attachmentModel: IAttachmenttModel = {
			EntityId: this._entityId,
			Recordkeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		var requestModel: ICreateEmlMessageRequestModel = {
			FromAddress: mail.Login,
			ToAddresses: [],
			Attachment: attachmentModel
		}

		BlockUI.Block();

		DocumentManagerStore.GetMainMails({ EntityId: this._subjectEntityId, RecordId: this._subjectRecordId })
			.then((data) => {

				const mailsByRecord = _.groupBy(data, (item) => item.RecordId);

				_.each(mailsByRecord, (value, key) => {
					const firstMail = _.find(value, (item: DestinationMailModel) => { return !!item.Mail });
					if (firstMail) {
						requestModel.ToAddresses.push(firstMail.Mail);
					}
				});

				DocumentManagerStore.CreateEmlMessage(requestModel).fail((err) => {
					new Notifier().Failed(err.message);
				}).always(() => {
					BlockUI.Unblock();
				});
			});
	}

	get IsEnableMailButton(): KnockoutObservable<boolean> {
		return this._isEnableMailButton;
	}

	GeneratePdf() {
		BlockUi.Block();
		let requestModel: IGeneratePdfDocumentRequestModel = {
			EntityId: this._entityId,
			RecordKeys: this._model.RecordKeys,
			FieldId: this._fieldId
		};

		DocumentManagerStore
			.GeneratePdf(requestModel)
			.fail((err) => {
				this._notifier.Failed(err.message);
			})
			.always(() => {
				BlockUi.Unblock();
			})
			.then(() => {
				this._hasPdfData(true);
			});
	}

	SwitchToVersion(){
		const switchPrompt = new ConfirmationDialog(
			{
				Type: ConfirmationTypes.Question,
				Text: CONFIRMATIONS.SWITCH_TO_VERSION
			});

		switchPrompt.Show();

		switchPrompt.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, ()=>{
			this.Trigger(EVENTS.UPDATED);
			BlockUi.Block();
			var requestModel: IEditDocumentRequestModel = {
				EntityId: this._entityId,
				Recordkeys: this._model.RecordKeys,
				FieldId: this._fieldId
			};
	
			DocumentManagerStore
				.Switch(requestModel)
				.fail((err) => {
					this._notifier.Failed(err.message);
				})
				.always(() => {
					BlockUi.Unblock();
				})
				.then((data) => {
					this.Trigger(EVENTS.UPDATED);
				 });
		});
	}
}
