import * as ko from 'knockout';

import {FormatConverter} from "FormatEditor/FormatConverter";

import {PropertyControl} from "../PropertyControl";

import Template from './Template.html';
import {ProductPropertyDescription} from "../../ProductPropertyDescription";
import { ProductPart } from "../../../ProductPart";
import { PropertyValue } from '../../../PropertyValue';

export class DecimalPropertyControl extends PropertyControl {
    private _displayValue: KnockoutObservable<string>;

    constructor(property: ProductPropertyDescription, productPart: ProductPart) {
        super(property, productPart);

        this.HasContent = ko.observable(true);
        this._displayValue = ko.observable(null);
    }

    GetTemplate() {
        return Template;
    }

    GetConvertedValue(){
        const value = this._displayValue();

        if (!value && value !== '0') {
            return null;
        }

        let convertedValue = +value.replace(',', '.');

        convertedValue = isNaN(convertedValue) ? null : convertedValue;

        return convertedValue;
    }

    GetValueForSave(): any {
        return this.GetConvertedValue();
    }

    SetValue(propertyValue: PropertyValue) {
        let formattedValue = null;

        if (propertyValue && propertyValue.Value) {
            formattedValue = FormatConverter.ConvertDecimal(propertyValue.Value.toString());
        }

        this._displayValue(formattedValue);
        this.StartListeningValueChanges();
    }

    ClearValue() {
        this._displayValue(null);
    }

    private StartListeningValueChanges() {
        if (this._displayValue.getSubscriptionsCount() == 0) {
            this._displayValue.subscribe(() => {
                this.CheckValidControl();
                this.OnValueChanged();
            });
        }
    }

    IsValid(value?: any): boolean {
        return this.GetDecimalIntegerValid(this.GetConvertedValue());
    }

    CheckValidControl() {
        this.isValidControl(this.GetDecimalIntegerValidRules(this.GetConvertedValue()));
    }
}