import * as $ from "jquery";
import * as moment from 'moment';
import * as _ from 'underscore';
import * as ko from 'knockout';
import * as stringTemplateEngine from "stringTemplateEngine";
import { KnockoutExtenions } from "Core/KnockoutExtentions/Extentions";
import 'jqueryMigrate-3.4.1';

import 'libs/dom-to-image';
import 'pubsub';
import 'bootstrap';
import 'jqueryUi';
import 'touch-punch';
import 'lockr';
import 'history';
import 'lockr';
import 'progressivefilter';
import 'xEditable';
import 'TouchSpinExtension';
import 'TouchSpinDecimalExtension';
import 'PercentageExtantion';
import 'BootstrapDateTimeExtension';
import 'BootstrapTimeExtension';
import 'TinyMceExtension';
import "select2";
import 'Core/Common/ApmAgent';

(()=>{
    ($ as any).UNSAFE_restoreLegacyHtmlPrefilter();
    ($ as any).migrateMute = true;
    ko.setTemplateEngine(stringTemplateEngine.StringTemplateEngine);
    KnockoutExtenions.Init();      
})();

export {
    $,
    _,
    ko,
    moment,
    stringTemplateEngine,
    KnockoutExtenions
}