import * as _ from 'underscore';

import {URL} from 'Core/Common/Url';
import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import {GenericDeserialize} from 'libs/cerialize';
import {AgendaItemModel, AgendaItemPerDay} from 'Core/Controls/Agenda/Models/AgendaItemModel';
import {PlanningItemModel} from 'Core/Controls/Agenda/Models/PlanningItemModel'
import OperationResult from 'Core/Results/ResultModels/OperationResult';
import {IGetTypesRequestModel} from "Core/Screens/TypeScreen/Stores/EntityTypesStore";
import {Notifier} from "Core/Common/Notifier";

export interface IGetAgendaDataRequestModel {
    ControlId: number;
    UsersId: Array<number>;
    StartDate: string;
    EndDate: string;
    GetScheduleRequestModel: IGetScheduleRequestModel;
    GetSpecialDaysRequestModel: IGetSpecialDaysRequestModel;
    GetTableTypesModel: IGetTypesRequestModel;
}

export interface ICreatePlanningAppointmentRequest {
    PlanningId: number;
    StartDate: string;
    UserId?: number;
}

export interface IBookPlanningRequest {
    AgendaTableId: number;
    PlanningId: number;
    StartDate: string;
}

export interface IAcceptAppointmentModel {
    AgendaTableId: number;
    AppointmentId: number;
    ScreenSubjectEntityId?: number;
    ScreenSubjectRecordId?: number;
}

export interface IGetScheduleRequestModel {
    StartDate: string;
    EndDate: string;
    UsersId: number[];
}

export interface IGetSpecialDaysRequestModel {
    StartDate: string;
    EndDate: string;
    UsersId: number[];
}

export interface IGetUserAllowanceRequestModel {
    TableId: number;
    SecurityWord: number;
}

export interface ICopyFieldDataRequestModel {
    Name: string;
    Value: string;
}

export interface ICopyRecordRequestModel {
    TableId: number;
    TableTypeId?: number;
    RecordId: number;
	Starting: string;
	AppointmentAttendeesId: number[];
}

export interface ICreateAppointmentFromTodoRequestModel {
    AgendaEntityId: number;
    TodoItemId: number;
    Starting: string;
    Duration: string;
	RemoveTodo: boolean;
	AppointmentAttendeesId: number[];
}

export class AgendaDataStore {
    static GetData(params: IGetAgendaDataRequestModel): P.Promise<any> {
        var deferredResult = P.defer<any>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "/api/AgendaApi/GetAgenda",
                dataType: "json",
                type: "POST"
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(data.ResultObject);
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            });

        return deferredResult.promise();
    }

    static GetUnreportedTime(): P.Promise<any> {
        var deferredResult = P.defer<any>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "/api/AgendaApi/GetUnreportedTime",
                type: "POST"
            }
        };

        Request.Send(requestParams)
            .then(data => {
                deferredResult.resolve(data);
            }).fail(data => { 				
				let jsonResult = JSON.parse(data.message);
				deferredResult.reject({ message: jsonResult.ExceptionMessage || jsonResult.Message });
			 });
;

        return deferredResult.promise();
    }

    static UpdateAgenda(params: AgendaItemModel): P.Promise<Array<OperationResult>> {
        var deferredResult = P.defer<Array<OperationResult>>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "/api/AgendaApi/UpdateAgenda",
                dataType: "json",
                type: "POST"
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(null);
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            });

        return deferredResult.promise();
    }

    static CreateAppointmentFromTodo(params: ICreateAppointmentFromTodoRequestModel): P.Promise<OperationResult> {
        var deferredResult = P.defer<OperationResult>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "/api/AgendaApi/CreateAppointmentFromTodo",
                dataType: "json",
                type: "POST"
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(data);
                } else {
                    deferredResult.reject({ message: data.ErrorMessage });
                }
            });

        return deferredResult.promise();
    }

    static CreatePlanningAppointment(params: ICreatePlanningAppointmentRequest): P.Promise<OperationResult> {
        var deferredResult = P.defer<OperationResult>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "/api/AgendaApi/CreatePlanningAppointment",
                dataType: "json",
                type: "POST"
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(null);
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            }).fail(() => {
            new Notifier().Failed('Error creating appointment');
        });

        return deferredResult.promise();
    }

    static CreatePlanningAppointments(planningId: number, userId?: number): P.Promise<OperationResult> {
        const deferredResult = P.defer<OperationResult>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "/api/AgendaApi/CreatePlanningAppointments",
                dataType: "json",
                type: "POST"
            }, params: { PlanningId: planningId, UserId: userId}
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(null);
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            }).fail(() => {
            new Notifier().Failed('Error creating appointments');
        });

        return deferredResult.promise();
    }

    static BookPlanning(params: IBookPlanningRequest): P.Promise<Array<OperationResult>> {
        var deferredResult = P.defer<Array<OperationResult>>();

        var requestParams: IRequestOptions = {
            proxy: {
                url: "/api/AgendaApi/BookPlanning",
                dataType: "json",
                type: "POST"
            }, params: params
        };

        Request.Send(requestParams)
            .then(data => {
                if (data.IsSuccessfull) {
                    deferredResult.resolve(null);
                } else {
                    deferredResult.reject({message: data.ErrorMessage});
                }
            }).fail(() => {
            new Notifier().Failed('Error booking planning');
        });

        return deferredResult.promise();
    }

    static AcceptAppointment(params: IAcceptAppointmentModel) {
        return Request.Send({
            proxy: {
                url: '/api/AgendaApi/AcceptAppointment',
                type: 'POST',
                dataType: 'json'
            },
            params: params
        });
    }

    static GetSchedule(params: IGetScheduleRequestModel) {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: '/api/AgendaApi/GetSchedule',
                type: 'POST',
                dataType: 'json'
            }, params: params
        }).then(result => {
            if (result.IsSuccessfull) {
                deferred.resolve(result.ResultObject);
            } else {
                deferred.reject({message: result.ErrorMessage});
            }
        });

        return deferred.promise();
    }

    static GetSpecialDays(params: IGetSpecialDaysRequestModel) {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: '/api/AgendaApi/GetSpecialDays',
                type: 'POST',
                dataType: 'json'
            }, params: params
        }).then(result => {
            if (result.IsSuccessfull) {
                deferred.resolve(result.ResultObject);
            } else {
                deferred.reject({message: result.ErrorMessage});
            }
        });

        return deferred.promise();
    }

    static GetUserAllowance(params: IGetUserAllowanceRequestModel) {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: 'api/TableSecurityApi/IsUserAllowedTo',
                type: 'POST',
                dataType: 'json'
            }, params: params
        }).then(result => {
            if (result.IsSuccessfull) {
                deferred.resolve(result.ResultObject);
            } else {
                deferred.reject({message: result.ErrorMessage});
            }
        });

        return deferred.promise();
    }

    static CopyRecord(params: ICopyRecordRequestModel): any {
        const deferred = P.defer();

        Request.Send({
            proxy: {
                url: 'api/AgendaApi/CopyAppointment',
                type: 'POST',
                dataType: 'json'
            }, params: params
        })
			.then(result => {
				if (result.IsSuccessfull) {
					deferred.resolve(result.ResultObject);
				} else {
					deferred.reject({ message: result.ErrorMessage });
				}
			})
            .fail(error => deferred.reject(this.GetResponseError(error, 'Error copying appointment')));

        return deferred.promise();
    }

    private static GetResponseError(err: P.Rejection, generalError: string): P.Rejection {
        if (err.status === 400) {
            return {message: JSON.parse(err.message).Message};
        }

        return {message: generalError};
    }
}