import * as ko from 'knockout';
import * as _ from 'underscore';

import {Event} from 'Core/Common/Event';
import {Modal} from "Core/Common/Modal";
import {BlockUI} from "Core/Common/BlockUi";
import {Notifier} from "Core/Common/Notifier";

import {PUB_SUB_EVENTS} from "MenuManager/PubSubEvents";

import {LABELS} from "Core/Components/Translation/Locales";

import {IReadyForInvoicingDataParams} from "./Params/IReadyForInvoicingDataParams";

import {BaseGrid} from "Core/Controls/Grid/BaseGrid/BaseGrid";
import {Toolbar} from "Core/Controls/Grid/Toolbar/Toolbar";
import {SortModel} from "Core/Controls/Grid/Models/SortModel";
import { EditScreen } from 'Core/Screens/EditScreen/EditScreen';
import { IScreen } from 'Core/Screens/IScreen';
import { UserVarsManager } from 'Core/UserVarsManager/UserVarsManager';
import { MobileChecker } from "Core/Common/MobileChecker";

import {EVENTS as BASE_GRID_EVENTS} from "Core/Controls/Grid/BaseGrid/Events";
import {EVENTS as PAGINATOR_EVENTS} from "Core/Components/Paginator/Constants";

import {ReadyForInvoicingDataDtoModel} from "../../Models/Dto/Response/ReadyForInvoicingDataDtoModel";
import {FinancialDashboardMappingProfile} from "../../Mappings/FinancialDashboardMappings";
import { QueryDropdowns } from 'Core/Components/QueryDropdowns/QueryDropdowns';
import {ViewGridModel} from 'Core/Controls/Grid/Models/ViewGridModel';
import {GridStore} from "Core/Controls/Grid/Stores/GridStore";
import { TableStore } from 'Core/Common/Stores/TableStore';
import { DeleteQueryStore } from 'QueryBuilder/Stores/DeleteQueryStore';
import {ViewModes} from "Core/Controls/Grid/BaseGrid/Enums/ViewModes";
import {QueryBuilder as QueryBuilderControl} from "Core/Controls/QueryBuilder/QueryBuilder";
import { CONTROL_TYPES } from 'Core/Constant';
import { NOTIFICATIONS } from 'Core/Components/Translation/Locales';
import { ViewGridListStore } from 'Core/Controls/Grid/Stores/ViewGridListStore';
import {LOCK_EVENTS, LockManager} from "Core/Components/Locker/LockManager";
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from "Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import Template
    from 'Core/Components/Dashboards/FinancialDashboard/Components/ReadyForInvoicingData/Templates/Template.html';

ko.templates['Core/Components/Dashboards/FinancialDashboard/Components/ReadyForInvoicingData/Templates/Template'] = Template;

export class ReadyForInvoicingData extends Event {
    private _labels = LABELS;

    private _modal: Modal;

    private _searchPhrase: KnockoutObservable<string>;

    private _grid: BaseGrid;
    private _sort: SortModel[];
    private _paginationToolbar: Toolbar;
    private _queryDropdown: QueryDropdowns;
    private _gridViews: KnockoutObservableArray<ViewGridModel>;
    private _gridView: KnockoutObservable<ViewGridModel>;

    private _isEditingAllowed: KnockoutObservable<boolean>;
    private _isDeletingAllowed: KnockoutObservable<boolean>;
    private _isGridPrioritiesDisabled: KnockoutObservable<boolean>;
    private _canHide: KnockoutObservable<boolean>;
    private _canShow: KnockoutObservable<boolean>;


    constructor(private _params: IReadyForInvoicingDataParams) {
        super();

        this._modal = new Modal();

        this._searchPhrase = ko.observable(null);
        this._gridViews = ko.observableArray([]);
        this._gridView = ko.observable(null);
        this._isEditingAllowed = ko.observable(false);
        this._isDeletingAllowed = ko.observable(false);
        this._isGridPrioritiesDisabled = ko.observable(false);
        this._canHide = ko.observable(false);
        this._canShow = ko.observable(false);

        this._grid = new BaseGrid({
            isPrioritiesDisabled: this.GetGridPriorityToggle(),
            isQueryResultGrid: true,
            isEnableSelectRecord: true,
            enabledFavorite: false,
            ToggleAllShownColumns: this.ToggleAllShownColumns.bind(this)
        });

        this._queryDropdown = new QueryDropdowns({
            showElement: ko.observable(true),
            showViewLookup: true,
            showFunctionLookup: true,
            view: this._gridView,
            isEnabled: ko.observable(true),
            viewList: this._gridViews,
            allowViewListUnset: true,
            isAddingRecordAllowed: ko.observable(true),
            enableEditView: this._isEditingAllowed,
            enableDeleteView: this._isDeletingAllowed,
            enableLockItem: ko.observable(false),
            withExportFeatures: false,
            showLockItem: false,
            queryLocked: ko.observable(true),
            exportDataAllowed: ko.observable(false),
            newView: () => this.NewTableView(),
            editView: () => this.EditTableView(),
            copyView: () => this.CopyTableView(),
            deleteView: () => this.DeleteTableView(),
            toggleLock: () => {},
            exportToCSV: () => {},
            exportToExcel: () => {},
        });

        this._sort = [];
        this._paginationToolbar = this.BuildPaginationToolbar();

        this._grid.On(BASE_GRID_EVENTS.SORT, this, eventArgs => {
            this._sort = eventArgs.data.SortColumns;
            this.LoadGridData();
        });

        this._grid.On(BASE_GRID_EVENTS.OPEN_HYPERLINK, this, eventArgs => {
            const data = _.clone(eventArgs.data);
            data.IsOpenInModal = true;

            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });
        
        this.LoadTableViewList();

        this._gridView.subscribe((newValue)=>{ 
            this.GridViewId = newValue.Id;
            this.LoadGridData()
            this._isEditingAllowed(newValue.IsEditingAllowed);
            this._isDeletingAllowed(newValue.IsDeletingAllowed);
        });

        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
    }

    private async ShowQueryEditScreen(screen: IScreen){
        screen.ShowInModal();

        let defaultView = await GridStore.GetDefaultTableView({
            ControlId: 0,
            ViewMode: ViewModes.InvoicingQuery,
            SubjectEntityId: this._params.TableId
        });

        const queryBuilderControl = screen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

        if (queryBuilderControl != null) {
            queryBuilderControl.SetSubjectEntityId(this._params.TableId);
            queryBuilderControl.SetQueryTypeName( ViewModes.InvoicingQuery);
            queryBuilderControl.InitQuery(defaultView);

            screen.On('RECORD_SAVED', this, async (eventArgs) => {
                this.GridViewId = eventArgs.data.RecordId;
                await this.LoadTableViewList();
            });
        }       
    }

    async LoadTableViewList(){
        let viewList = await ViewGridListStore.GetInvoicingGridViewList(this._params.TableId);
        let defaultView = new ViewGridModel(0, "Default");
        defaultView.IsEditingAllowed = false;
        defaultView.IsDeletingAllowed = false;
        let selectedView = _.find(viewList.GridViews, (view)=>view.Id === this.GridViewId) || defaultView;
        this._gridViews([defaultView, ...viewList.GridViews]);
        this._gridView(selectedView);
    }

    async NewTableView(){
        BlockUI.Block();
        let queryTable = await this.GetQueryTable();

        let screenManager = (await import ('Core/ScreenManager/ScreenManager')).ScreenManager;
        screenManager.GetEditScreen({ EntityId: queryTable.Id, TableTypeName: 'InvoicingQuery' })
            .always(()=> BlockUI.Unblock())
            .then((editScreen) => this.ShowQueryEditScreen(editScreen))
            .fail((err)=>Notifier.Failed(err.message));
    }

    async GetQueryTable(){
        return await TableStore.Get({ TableName: 'QUERIES' }).fail((err)=>Notifier.Failed(err.message));
    }

    async EditTableView(){
        let queryTable = await this.GetQueryTable();
        let viewId = this._gridView()?.Id;
        if(!viewId){
            return;
        }

        await LockManager.Instance.TryLock(queryTable.Id, viewId);
        let screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        
        BlockUI.Block();
        let editScreen = await screenManager.GetEditScreen({ EntityId: queryTable.Id, RecordId: viewId }).fail((err)=>Notifier.Failed(err.message));
        BlockUI.Unblock();

        LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs) => {
            if (eventArgs.data.TableId === queryTable.Id && eventArgs.data.RecordId === viewId) {
                editScreen.Close();
            }
        });

        editScreen.On('MODAL_CLOSE', this, () => {
            LockManager.Instance.ReleaseLock(queryTable.id, viewId);
        });

        editScreen.ShowInModal();

        editScreen.On('RECORD_DELETED', this, () => {
            this._gridView(null);
            this.LoadTableViewList();
        });

        const queryBuilderControl = editScreen.GetControl<QueryBuilderControl>(CONTROL_TYPES.QueryBuilder);

        if (queryBuilderControl != null) {
            queryBuilderControl.SetQueryTypeName(ViewModes.InvoicingQuery);

            editScreen.On('RECORD_SAVED', this, () => {
                this.LoadGridData();
            });
        }
    }

    async CopyTableView(){
        let viewId = this._gridView()?.Id;
        if(!viewId){
            return;
        }

        let queryTable = await this.GetQueryTable();
        let screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
        let editScreen = (await screenManager.GetEditScreen({ EntityId: queryTable.Id, RecordId: viewId, LoadAsExample: true })) as EditScreen;
        editScreen.IsDataFromExample = true;
        editScreen.ShowInModal();

        const queryBuilderControl = editScreen.GetControl(CONTROL_TYPES.QueryBuilder) as QueryBuilderControl;

        if (queryBuilderControl != null) {
            queryBuilderControl.SetQueryTypeName(ViewModes.InvoicingQuery);
            queryBuilderControl.SetFilterByOwners(true);

            editScreen.On('RECORD_SAVED', this, (eventArgs) => {
                this.GridViewId = eventArgs.data.RecordId;
                this.LoadTableViewList();
            });
        }
    }

    async DeleteTableView(){
        let viewId = this._gridView()?.Id;
        if(!viewId){
            return;
        }

        const confirmationDialog = new ConfirmationDialog({
            Text: 'Are you sure?',
            Type: ConfirmationTypes.Question,
            TextConfirm: 'Yes',
            TextDecline: 'Cancel'
        });

        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, async () => {
            await DeleteQueryStore.Delete({Id: viewId});
            this.LoadTableViewList();
        });
        
        confirmationDialog.Show();
    }

    GetTemplateName() {
        return 'Core/Components/Dashboards/FinancialDashboard/Components/ReadyForInvoicingData/Templates/Template';
    }

    AfterRender() {

    }

    Show() {
        this.LoadGridData()
            .then(() => this._modal.Show())
            .fail(error => new Notifier().Failed(JSON.parse(error.message).Message))
    }

    GetGridPriorityToggle(): boolean {
        if (MobileChecker.IsMobile()) {
            return this._isGridPrioritiesDisabled();
        }

        const subjectEntityId = this._params.TableId;
        const gridSubjectEntityId = 0;
        this._isGridPrioritiesDisabled(UserVarsManager.Instance.GetGridPriority(subjectEntityId, gridSubjectEntityId, this._isGridPrioritiesDisabled()));
        return UserVarsManager.Instance.GetGridPriority(subjectEntityId, gridSubjectEntityId, this._isGridPrioritiesDisabled());
    }

    TogglePriorities() {
        this._isGridPrioritiesDisabled(!this._isGridPrioritiesDisabled());

        const subjectEntityId = this._params.TableId;
        const gridSubjectEntityId = this._grid.SubjectEntityId;
        !MobileChecker.IsMobile() && UserVarsManager.Instance.SetGridPriority(subjectEntityId, gridSubjectEntityId, this._isGridPrioritiesDisabled());
        this.LoadGridData();
    }

    ToggleAllShownColumns(canHide: boolean, canShow: boolean) {
        this._canHide(canHide);
        this._canShow(canShow);
    }

    private BuildPaginationToolbar() {
        const toolbar = new Toolbar(
            false,
            false,
			false,
			ko.observable(false),
			ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            null,
            null,
            ko.observable(false),
            ko.observable(true),
            null,
            false,
            false,
            ko.observable(false),
            ko.observable(false),
            ko.observable(false),
            true,
            false,
            []
        );

        toolbar.Paginator().On(PAGINATOR_EVENTS.CHANGE, this, () => this.LoadGridData());

        return toolbar;
    }

    private LoadGridData() {
        BlockUI.Block();

        return this._params.Store.GetReadyForInvoicingData({
            ViewId: this._gridView()?.Id,
            ControlId: this._params.ControlId,
            PageNumber: this._paginationToolbar.Paginator().PageNumber,
            RecordsPerPage: this._params.RecordsPerPage,
            SearchPhrase: this._searchPhrase(),
            Sort: this._sort
        })
            .then(data => this.SetGridData(this._paginationToolbar.Paginator().PageNumber, data))
            .always(() => BlockUI.Unblock());
    }

    private SetGridData(pageNumber: number, data: ReadyForInvoicingDataDtoModel) {
        this._grid.SetData(FinancialDashboardMappingProfile.MapToReadyForInvoicingData(data), true, this.GetGridPriorityToggle());

        this._paginationToolbar.Paginator().PageNumber = pageNumber;
        this._paginationToolbar.Paginator().RecordsPerPage = this._params.RecordsPerPage;
        this._paginationToolbar.Paginator().TotalRecords = data.TotalRecords;
        this._paginationToolbar.Enable();
    }

    private OnStartProcessing() {
        const selectedRecords = this._grid.GetSelectRecords().map(row => row.RecordId);

        const validationError = this.ValidateSelectedRecords(selectedRecords);
        if (validationError) {
            new Notifier().Warning(validationError);
            return;
        }

        this.Invoice(selectedRecords);
    }

    private Invoice(selectedRecords: number[]) {
        BlockUI.Block();

        this._params.Store.Invoice({
            ControlId: this._params.ControlId,
            Records: selectedRecords
        })
            .then(invoicingResults => {
                this.Trigger('INVOICING_DONE', invoicingResults);
                this._modal.Close();
            })
            .fail(error => this.Trigger('INVOICING_FAILED', {error: error}))
            .always(() => BlockUI.Unblock())
    }

    private ValidateSelectedRecords(selectedRecords: number[]) {
        if (selectedRecords.length === 0) {
            return 'Please select records to process';
        }
    }

    get GridViewId() {
        return UserVarsManager.Instance.GetFinancialDashboardGridView(this._params.ControlId);
    }

    set GridViewId(value: number) {
        UserVarsManager.Instance.SetFinancialDashboardGridView(this._params.ControlId, value);
    }
}