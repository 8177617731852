import * as ko from 'knockout';
import * as _ from 'underscore';
import * as moment from 'moment';

import 'lockr';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';
import enumerable from 'Core/Common/Decorators/EnumerableDecorator';
import {P} from "Core/Common/Promise";
import {TableStore} from "Core/Common/Stores/TableStore";
import {RecordStore} from "Core/Common/Stores/RecordStore";
import {
	MailStore,
	ISendMailRequestModel,
	IDestinationMail,
	ICcRecipientModel,
	IBccRecipientModel
} from 'Core/Controls/Mail/Stores/MailStore';
import {IScreen} from "Core/Screens/IScreen";
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {SearchByMainRelationTypes} from 'Core/Screens/SearchScreen/Enums/Enums';
import {Guid} from 'Core/Common/Guid';
import {MailAttachmentModel} from 'Core/Controls/Mail/Models/MailAttachmentModel';
import {CookieManager} from 'Core/Common/CookieManager';
import {AddEmailAddress} from 'Core/Controls/Mail/AddEmailAddress';
import {MailItemExtendedModel} from 'Core/Controls/Mail//Models/MailItemExtendedModel';
import {ZIndexManager} from 'Core/Common/ZIndexManager';
import {Modal} from 'Core/Common/Modal';
import {COMPOSER_EVENTS, ADD_EMAIL_EVENTS} from 'Core/Controls/Mail/Events';
import {Event} from 'Core/Common/Event';
import {
	ConfirmationDialog,
	EVENTS as ConfirmationDialogEvents,
	Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {ScreenshotAttachmentModel} from 'Core/Controls/Mail/Models/ScreenshotAttachmentModel';
import {IComposerOptionsModel} from 'Core/Controls/Mail/Models/ComposerOptionsModel';
import {DocumentStore} from 'Core/Controls/Document/Stores/DocumentStore';
import {NOTIFICATIONS, LABELS, CONFIRMATIONS} from 'Core/Components/Translation/Locales';
import * as $ from "jquery";

import ComposeMailTemplate from 'Core/Controls/Mail/Templates/ComposeMailTemplate.html';
import SelectedRecepientTemplate from 'Core/Controls/Mail/Templates/SelectedRecepientTemplate.html';
import SelectOptionRecipientTemplate from 'Core/Controls/Mail/Templates/SelectOptionRecepientTemplate.html';

ko.templates['Core/Controls/Mail/Templates/ComposeMailTemplate'] = ComposeMailTemplate;

export class ComposeMail extends Event {
	private _labels: LABELS;
	private _confirmations: CONFIRMATIONS;

	private _isCcVisible: KnockoutObservable<boolean>;
	private _isBccVisible: KnockoutObservable<boolean>;
	private _sendScreenShot: KnockoutObservable<boolean>;
	private _newSubject: KnockoutObservable<string>;
	private _newBody: KnockoutObservable<string>;
	private _attachments: KnockoutObservableArray<MailAttachmentModel>;
	private _screenshots: KnockoutObservableArray<ScreenshotAttachmentModel>;
	private _guid: string;
	private _modal: Modal;
	private _isInModal: boolean;
	private _mailConnectionId: number;
	private _newReciver: KnockoutObservable<string>;
	private _newCc: KnockoutObservable<string>;
	private _newBcc: KnockoutObservable<string>;
	private _responseMailItem: MailItemExtendedModel;

	private _recipients: KnockoutObservableArray<IDestinationMail>;
	private _ccRecepients: KnockoutObservableArray<ICcRecipientModel>;
	private _bccRecepients: KnockoutObservableArray<IBccRecipientModel>;
	private _isEnableAttachment: KnockoutObservable<boolean>;
	private _isEnableRemoveAttachment: KnockoutObservable<boolean>;

	private IsReply: boolean;
	private IsForward: boolean;
	private IsReplyAll: boolean;
	private _currentAction: string;
	private _mailInfo: string;
	private _datetimeCreated: string;
	private _documentsEntityNameTranslation: KnockoutObservable<string>;
	private _showAddFromDocuments: KnockoutObservable<string>;
	private _screen: KnockoutObservable<IScreen>;
	private _subjectEntityId: number;
	private _subjectRecordId: number;
	private _subjectTypeId: number;

	constructor(options?: IComposerOptionsModel) {
		super();

		this._responseMailItem = options.ResponseMailItem;
		this._guid = Guid.NewGuid();
		this._isCcVisible = ko.observable(false);
		this._isBccVisible = ko.observable(false);
		this._sendScreenShot = ko.observable(options.SendScreenShot);
		this._screenshots = ko.observableArray([]);
		this._subjectEntityId = options.SubjectEntityId;
		this._subjectRecordId = options.SubjectRecordId;
		this._subjectTypeId = options.SubjectTypeId;

		this.IsReply = options.IsReply;
		this.IsForward = options.IsForward;
		this.IsReplyAll = options.IsReplyAll;

		this._newBody = ko.observable('');
		this._newSubject = ko.observable('');
		this._newReciver = ko.observable('');
		this._newCc = ko.observable('');
		this._newBcc = ko.observable('');
		this._attachments = ko.observableArray([]);

		this._recipients = ko.observableArray([]);
		this._ccRecepients = ko.observableArray([]);
		this._bccRecepients = ko.observableArray([]);
		this._documentsEntityNameTranslation = ko.observable('');
		this._screen = ko.observable(null);

		this._isInModal = false;
		this._mailConnectionId = options.MailConnectionId;
		this._datetimeCreated = options.DateTimeCreated;
		this._showAddFromDocuments = ko.computed(() => { return `${LABELS.ADD_FROM} ${this._documentsEntityNameTranslation()}` });

		this._labels = LABELS;
		this._confirmations = CONFIRMATIONS;

		this.AddEvents();
		this.Init();
		this._isEnableAttachment = ko.observable(true);
		this._isEnableRemoveAttachment = ko.observable(true);
		this._isCcVisible.subscribe(newValue=>{
			if(!newValue){
				this._ccRecepients([]);
			}
		});

		this._isBccVisible.subscribe(newValue=>{
			if(!newValue){
				this._bccRecepients([]);
			}
		});
	}

	get CurrentActionText() {
		return `${this._currentAction} ${LABELS.E_MAIL}`;
	}

	AddEvents() {
		this.AddEvent(COMPOSER_EVENTS.EMAIL_SENT);
		this.AddEvent(COMPOSER_EVENTS.DISCARD);
		this.AddEvent('ON_CHANGED_PAGE_LEAVING');
	}

	PreventWindowConfirmation() {
		const eventWindowConfirmation = (event) => {
			// Cancel the event as stated by the standard.
			event.preventDefault();
			// Chrome requires returnValue to be set.
			event.returnValue = 'Would you like to leave the page?';
			return 'Would you like to leave the page?'
		};

		window.addEventListener('beforeunload', eventWindowConfirmation);

		this.On('ON_CHANGED_PAGE_LEAVING', this, () => {
			window.removeEventListener('beforeunload', eventWindowConfirmation);
		});
	}

	AddMailTo(firstName: string, mailTo: string) {
		this._recipients.push({
			Mail: mailTo,
			RecordId: -1,
			TableId: -1
		});
	}

	AddMailCC(firstName: string, mailTo: string) {
		this._ccRecepients.push({
			Name: '',
			Mail: mailTo,
			RecordId: -1,
			TableId: -1
		});

		this._isCcVisible(true);
	}

	AddMailBCC(firstName: string, mailTo: string) {
		this._bccRecepients.push({
			Name: '',
			Mail: mailTo,
			RecordId: -1,
			TableId: -1
		});

		this._isBccVisible(true);
	}

	AddMailWithRecord(mailTo: string, tableId: number, recordId: number) {
		this._recipients.push({
			Mail: mailTo,
			TableId: tableId,
			RecordId: recordId
		});
	}

	AddScreen(screen: IScreen) {
		this._screen(screen);
	}

	AddBody(body: string){
		this._newBody(body);
	}

	AddSubject(subject: string) {
		this._newSubject(subject);
	}

	Discard() {
		if (this._isInModal && this._modal) {
			let confirmationDialog = new ConfirmationDialog({
				Text: CONFIRMATIONS.DISCARD,
				Type: ConfirmationTypes.Question,
				TextConfirm: LABELS.YES,
				TextDecline: LABELS.NO
			});

			confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, (eventArgs) => {
				this._modal.Close();
				this.Trigger('ON_CHANGED_PAGE_LEAVING');
			});
			confirmationDialog.Show();
		}

		this.Trigger(COMPOSER_EVENTS.DISCARD);
	}

	Init() {
		if (this.IsReply) {
			this._currentAction = LABELS.REPLY;
			this.ComposeBody();
			this.FillInitialDataReply();
		} else if (this.IsReplyAll) {
			this._currentAction = LABELS.REPLY_TO_ALL;
			this.ComposeBody();
			this.FillInitialDataReplyAll();
		} else if (this.IsForward) {
			this._currentAction = LABELS.FORWARD;
			this.ComposeBody();
			this.FillInitialDataForward();
		} else {
			this.PreventWindowConfirmation();
			this._currentAction = LABELS.NEW;
		}
	}

	ComposeBody() {
		this._mailInfo =
			`${this._datetimeCreated} ${this._responseMailItem.FromName} ${this._responseMailItem.FromAddress} wrote:`;

		const newBodyWithSignature =
			'<p></p><p></p><p></p>' + '<blockquote style="margin: 0 0 0 .8ex; border-left: 1px #ccc solid; padding-left: 1ex;">' + this._mailInfo + '<p></p>' + this._responseMailItem.Body + '</blockquote>';

		this._newBody(newBodyWithSignature);
	}

	FillInitialDataReply() {
		this._newSubject('Re: ' + this._responseMailItem.Subject);

		const fromAddress = this._responseMailItem.FromAddress;

		this._recipients.push({
			Name: this._responseMailItem.FromName,
			Mail: fromAddress,
			RecordId: -1,
			TableId: -1
		});
	}

	FillInitialDataReplyAll() {
		this._newSubject('Re: ' + this._responseMailItem.Subject);

		this._responseMailItem.Cc.forEach((el) => {
			this._ccRecepients.push({
				Mail: el,
				Name: '',
				RecordId: -1,
				TableId: -1
			});
		});

		if (this._responseMailItem.DestinationMails.length) {
			this._responseMailItem.DestinationMails.map((el) => {
				if (!(el.DestinationMails && el.DestinationMails.length)) {
					this._recipients.push({
						Name: '',
						Mail: el.Address,
						RecordId: -1,
						TableId: -1
					});

					return;
				}

				el.DestinationMails.map((destMail) => {
					this._recipients.push({
						Name: destMail.Name,
						Mail: el.Address,
						RecordId: destMail.RecordId,
						TableId: destMail.TableId
					});
				});
			});
		}

		this._recipients.push({
			Name: this._responseMailItem.FromName,
			Mail: this._responseMailItem.FromAddress,
			RecordId: -1,
			TableId: -1
		});

		let recipients = this._recipients().concat(this._ccRecepients());

		this._recipients(recipients);

		this.DeleteDuplicatedRecipients();
	}

	FillInitialDataForward() {
		this._newSubject('Re: ' + this._responseMailItem.Subject);
	}

	DeleteDuplicatedRecipients() {
		this._recipients(_.uniq(this._recipients(), (recipient)=>recipient.Mail));
	}

	GetTemplate() {
		return ComposeMailTemplate;
	}

	GetTemplateName() {
		return 'Core/Controls/Mail/Templates/ComposeMailTemplate';
	}

	ShowHideCc() {
		this._isCcVisible(!this._isCcVisible());
	}

	ShowHideBcc() {
		this._isBccVisible(!this._isBccVisible());
	}

	RemoveAttachment(attachment: MailAttachmentModel) {
		this._attachments.splice(this._attachments.indexOf(attachment), 1);
	}

	Send() {
		if (this.IsReply || this.IsForward) {
			this.SendReplyMail();
		} else if (this.IsReplyAll) {
			this.SendReplyAllMail();
		} else {
			this.SendMail();
		}
	}

	ShowInModal(mailConnectionId) {
		this._mailConnectionId = mailConnectionId;
		this._isInModal = true;
		const self = this;

		this._modal = new Modal({
			addClass: 'compose-email',
			closeOnEsc: false,
			onOpen: function () {
				$(this.closeButton[0]).off('click');
				this.closeButton[0].addEventListener('click', self.PreventCloseModal.bind(self));
			}
		});

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this._modal.Show();
	}

	PreventCloseModal(event) {
		event.stopImmediatePropagation();

		let confirmationDialog = new ConfirmationDialog({
			Text: CONFIRMATIONS.CLOSE,
			Type: ConfirmationTypes.Question,
			TextConfirm: LABELS.YES,
			TextDecline: LABELS.NO
		});

		confirmationDialog.On(ConfirmationDialogEvents.CONFIRM_SELECTED, this, (eventArgs) => {
			this._modal.Close();
			this.Trigger('ON_CHANGED_PAGE_LEAVING');
		});
		confirmationDialog.Show();
	}

	SendMail() {
		const requestModel: ISendMailRequestModel = this.SerializeData();

		if (!requestModel.Recipients.length) {
			new Notifier().Warning(NOTIFICATIONS.PLEASE_ADD_RECIPIENT);

			return;
		}

		BlockUI.Block();

		MailStore.SendMail(requestModel)
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {
				new Notifier().Success(NOTIFICATIONS.EMAIL_WAS_SENT);
				this.Trigger(COMPOSER_EVENTS.EMAIL_SENT, { Mail: requestModel, Id: result.Id });

				if (this._modal) {
					this._modal.Close();
				}

				this._screen().Refresh();
				this.Trigger('ON_CHANGED_PAGE_LEAVING');
			})
			.fail((err) => {
				new Notifier().Warning(err.message);
			});
	}

	SendReplyMail() {
		const requestModel: ISendMailRequestModel = this.SerializeData();

		if (!requestModel.Recipients.length) {
			new Notifier().Warning(NOTIFICATIONS.PLEASE_ADD_RECIPIENT);

			return;
		}

		BlockUI.Block({ZIndex: ZIndexManager.Instance.NextValue});

		MailStore.SendMail(requestModel, 'ReplyMail')
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {
				new Notifier().Success(NOTIFICATIONS.EMAIL_WAS_SENT);
				this.Trigger(COMPOSER_EVENTS.EMAIL_SENT, { Mail: requestModel, Id: result.Id });

			}).fail((err) => {
			new Notifier().Warning(err.message);
		});
	}

	SendReplyAllMail() {
		const requestModel: ISendMailRequestModel = this.SerializeData();

		if (!requestModel.Recipients.length) {
			new Notifier().Warning(NOTIFICATIONS.PLEASE_ADD_RECIPIENT);

			return;
		}

		BlockUI.Block({ZIndex: ZIndexManager.Instance.NextValue});

		MailStore.SendMail(requestModel, 'ReplyMail')
			.always(() => {
				BlockUI.Unblock();
			})
			.then((result) => {
				new Notifier().Success('Email was sent');

				this.Trigger(COMPOSER_EVENTS.EMAIL_SENT, { Mail: requestModel, Id: result.Id});
			})
			.fail((err) => {
				new Notifier().Warning(err.message);
			});
	}

	GetSelect2Settings(type: string = null) {
		const token = CookieManager.GetAuthToken();
		let isRequestInProgress = false;

		return {
			ajax: {
				url: 'api/MailApi/GetDestinationMails',
				method: 'POST',
				dataType: 'json',
				delay: 500,
				headers: {'Authorization': `Bearer ${token}`},

				data: function (params) {
					isRequestInProgress = true;

					$(".select2-search__field").on("keydown", function(event) {
						if (event.keyCode === 13 && isRequestInProgress) {
							event.preventDefault();
							event.stopPropagation();
						}
					});

					return {
						SearchPart: params.term,
						page: params.page
					};
				},

				transport: function (params, success, failure) {
					var $request = $.ajax(params);

					BlockUI.Block({Target: $(".select2-container--default").get(0)});
					$request.then(success);
					$request.fail(failure);

					return $request;
				},

				processResults: function (data, params) {
					if (!data.IsSuccessfull) {
						new Notifier().Failed(data.ErrorMessage);

						return {results: []};
					}

					params.page = params.page || 1;

					_.each(data.ResultObject, (item: any) => item.id = item.TableId + '_' + item.RecordId);

					$(".select2-search__field").off("keydown");
					isRequestInProgress = false;
					BlockUI.Unblock($(".select2-container--default").get(0));

					return {
						results: data.ResultObject,
						pagination: {
							more: (params.page * 30) < data.total_count
						}
					};
				},

				cache: false
			},

			multiple: true,

			escapeMarkup: (markup) => markup,
            placeholder: "",
			allowClear: true,
			minimumInputLength: 3,
			tags: true,
			initialData: this.GenerateInitialData(type),
			initSelection: this.SetDefaultValue.bind(this, type),
			templateResult: this.Select2FormatData.bind(this, type),
			templateSelection: this.Select2FormatDataSelection.bind(this),
			validate: this.validateEmail,
			validateType: 'email',
			selectOnClose: true
		};
	}

	GenerateInitialData(type: string) {
		let preselctedRecipients;
		let recipients;

		if (type === 'cc') {
			recipients = this._ccRecepients();
		} else if (type === 'bcc') {
			recipients = this._bccRecepients();
		} else {
			recipients = this._recipients();
		}

		if (!recipients.length) {
			return;
		}

		preselctedRecipients = recipients;

		return preselctedRecipients.map((item, i) => {
			return _.extend(item, {
					initialDataId: 'initialData_' + i,
					id: i,
					text: ''
				}
			);
		});
	}

	SetDefaultValue(type: string, element, callback) {
		callback(this.GetRecipients(this, type) || []);
	}

	GetRecipients(content: ComposeMail, type: string){
		if (type === 'cc') {
			return this._ccRecepients();
		} else if (type === 'bcc') {
			return this._bccRecepients();
		} else {
			return this._recipients();
		}
	}

	Select2FormatData(type: string, data) {

		if (data.RecordId == null) {
			return undefined;
		}

		const recipientName = data.Name;

		const model = {
			RecipientType: type,
			ParentContext: this,
			RecipientName: recipientName,
			Email: data.Mail,
			AddEmail: this.AddEmail,
			Id: data.RecordId,
			EntityId: data.TableId
		};

		return (ko as any).renderTemplateX(SelectOptionRecipientTemplate, model);
	};

	validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return re.test(email);
	}

	Select2FormatDataSelection(data) {
		let recipientName = `${data.Name || ''} ${data.Mail || ''}`;

		recipientName = recipientName.trim() || data.text;

		return (ko as any).renderTemplateX(SelectedRecepientTemplate, {RecipientName: recipientName});
	}

	AddEmail(model: any) {

		const addEmail = new AddEmailAddress(model.Id, model.EntityId);

		addEmail.On(ADD_EMAIL_EVENTS.EMAIL_SAVED, this, (eventArgs: any) => {
			
			const recipient = _.find(model.ParentContext.GetRecipients(model.ParentContext, model.RecipientType), (recipient: IDestinationMail) => {
				return recipient.RecordId === model.Id;
			});

			if (recipient) {
				recipient.Mail = eventArgs.data.EmailAddress;
			}
		});

		addEmail.ShowInModal();
	}

	AfterTinyInit(editor) {
		editor.setContent(this._newBody());
	}

	AfterTinyChange(content) {
		this._newBody(content);
	}

	AddScreenShot(value: string) {
		const newScreenshot = new ScreenshotAttachmentModel();

		newScreenshot.Name = 'Screenshot ' + moment().format('DMMMYY-h:mm') + '.jpg';
		newScreenshot.Content = value;

		this.Screenshots().push(newScreenshot);
	}

	SerializeData() {
		const ccList = this.SerializeCc();
		const bccList = this.SerializeBcc();
		let recipients = this.SerializeRecepients();
		let attachmentsRequest = [];

		if (this.IsReplyAll && ccList) {
			recipients = this.SerializeRecepients().concat(ccList);
		}

		if (this._responseMailItem) {
			attachmentsRequest = this._responseMailItem.Attachments.map((el) => {
				return {
					Guid: this._responseMailItem.Guid,
					Name: el.Name,
					MailConnectionId: this._mailConnectionId
				};
			});
		}

		const screen = this._screen();
		const subjectEntityId = this._subjectEntityId || screen?.GetEntityId();
		const subjectRecordId = this._subjectRecordId || screen?.GetRecordId();

		const sendMailRequestModel: ISendMailRequestModel = {
			MailConnectionId: this._mailConnectionId,
			Subject: this._newSubject(),
			Body: this.SerializeBody(),
			Recipients: recipients,
			Attachements: this.Attachments(),
			MessageId: null,
			Screenshots: this.Screenshots(),
			AttachmentsRequest: attachmentsRequest,
			SubjectEntityId: subjectEntityId,
			SubjectRecordId: subjectRecordId
		};

		if (ccList) {
			sendMailRequestModel.CcList = ccList;
		}

		if (bccList) {
			sendMailRequestModel.BccList = bccList;
		}

		if (this.IsReply || this.IsReplyAll || this.IsForward) {
			sendMailRequestModel.MessageId = this._responseMailItem.Guid;
		}

		return sendMailRequestModel;
	}

	SerializeBody() {
		return this._newBody();
	}

	SerializeRecepients(): any {
		const recipients: IDestinationMail[] = [];

		const recipientsFilteredData = this._recipients().filter((recipient: any) => {
			return !!recipient.RecordId || this.validateEmail(recipient.text);
		});

		_.each(recipientsFilteredData, (recipientData: IDestinationMail | any) => {
			const recipient: IDestinationMail = {
				TableId: recipientData.TableId || -1,
				RecordId: recipientData.RecordId || -1,
				Mail: recipientData.Mail
			};

			if (recipient.RecordId === -1) {
				recipient.Mail = recipientData.text || recipientData.Mail;
			}

			recipients.push(recipient);
		});

		return recipients;
	}

	SerializeCc(): IDestinationMail[] {
		const ccList = _.map(this._ccRecepients(), (ccRecepient: any): IDestinationMail => {
			return {
				TableId: ccRecepient.TableId || -1,
				RecordId: ccRecepient.RecordId || -1,
				Mail: ccRecepient.Mail || ccRecepient.text
			};
		});

		if (ccList.length) {
			return ccList;
		}

		return null;
	}

	SerializeBcc(): IDestinationMail[] {
		const bccList = _.map(this._bccRecepients(), (bccRecepient: any): IDestinationMail => {
			return {
				TableId: bccRecepient.TableId || -1,
				RecordId: bccRecepient.RecordId || -1,
				Mail: bccRecepient.Mail || bccRecepient.text
			};
		});

		if (bccList.length) {
			return bccList;
		}

		return null;
	}

	get Attachments(): KnockoutObservableArray<MailAttachmentModel> {
		return this._attachments;
	}

	get Screenshots(): KnockoutObservableArray<ScreenshotAttachmentModel> {
		return this._screenshots;
	}

	SearchAttachments() {
		DocumentStore.GetDocumentEntityId().then(documentsEntityId => {
			let firstSearchByMainRelations = true;
			let firstSearchByRelations = true;

			const searchScreen = new SearchScreen({
				EntityId: documentsEntityId,
				SearchTerm: '',
				MultiSelectMode: true,
				ButtonAdd: false,
				SubjectEntityId: this._subjectEntityId,
				SubjectRecordId: this._subjectRecordId,
				SubjectTypeId: this._subjectTypeId
			});

			searchScreen.On('RECORD_SELECTED', this, (eventArgs) => this.OnDocumentSelected(eventArgs.data));

			searchScreen.On('RECORDS_SELECTED', this, (eventArgs) => {
				this.OnDocumentsSelected(eventArgs.data.TableId, eventArgs.data.Ids);
			});

			searchScreen.On("RECORDS_NOT_FOUND", this, (eventArgs) => {
				if (firstSearchByMainRelations && searchScreen.GetFilterByRelationType() === SearchByMainRelationTypes.ByMainRelations) {
					Notifier.Warning(NOTIFICATIONS.NO_DATA_FOUND_WITH_APPLIED_FILTER);
					searchScreen.SetFilterByRelationType(SearchByMainRelationTypes.Off);
					firstSearchByMainRelations = false;
				} else if (firstSearchByRelations && searchScreen.GetFilterByRelationType() === SearchByMainRelationTypes.ByRelations) {
					Notifier.Warning(NOTIFICATIONS.NO_DATA_FOUND_WITH_APPLIED_FILTER);
					searchScreen.SetFilterByRelationType(SearchByMainRelationTypes.Off);
					firstSearchByRelations = false;
				}
			});

			searchScreen.Show();
		});
	}

	AfterRender(el: HTMLElement) {
		BlockUI.Block({Target: el});
		TableStore.Get({TableName: "DOCUMENTS"})
			.then((table: any) => {
				this._documentsEntityNameTranslation(table.TranslatedName || table.Name);
			})
			.always(() => BlockUI.Unblock(el));
	}

	private OnDocumentSelected(record) {
		if (record && record.RecordId && record.Name) {
			const attachment = new MailAttachmentModel();

			attachment.DocumentId = record.RecordId;
			attachment.Name = record.Name;

			this._attachments.push(attachment);
		}
	}

	private OnDocumentsSelected(entityId: number, recordIds: number[]) {
		let getRecordsPromises = [];

		BlockUI.Block();
		_.each(recordIds, (recordId) => {
			getRecordsPromises.push(RecordStore.GetRecord({TableId: entityId, RecordId: recordId}));
		});

		P.when(...getRecordsPromises)
			.always(() => BlockUI.Unblock())
			.then(() => {
				_.each(getRecordsPromises, (promise) => {
					const nameField = promise.result && _.find(promise.result.Fields, (field: any)=> field.FieldName === 'NAME');
					if (promise.result && nameField && promise.result.RecordId && nameField.FieldValue) {
						const attachment = new MailAttachmentModel();

						attachment.DocumentId = promise.result.RecordId;
						attachment.Name = nameField.FieldValue;

						this._attachments.push(attachment);
					}
				});
			});
	}

	public AttachDocument(document: MailAttachmentModel) {
		this._attachments.push(document);
	}

	set IsEnableAttachment(value: boolean) {
		this._isEnableAttachment(value);
	}

	set IsEnableRemoveAttachment(value: boolean) {
		this._isEnableRemoveAttachment(value);
	}
}