import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';
import {serialize, serializeAs} from 'libs/cerialize';
import {SubFormModel} from '../../../Models/Screens/SubFormModel';

export class FieldModel {
	@serialize
	Id: number;

	@serialize
	Sort: number;

	@serialize
	LinkRequired: boolean;
}


export class ControlModel {
	constructor() {
		this.Fields = [];
		this.NameTranslations = [];
		this.DescriptionTranslations = [];
	}

	@serialize
	Id: number;

	@serialize
	Name: string;

	@serialize
	TypeName: string;

	@serialize
	RecordLifeStatusId: number;

	@serialize
	DestinationTypeId: number;

	@serialize
	LabelPosition: number;

	@serialize
	Guid: String;

	@serialize
	FormId: number;

	@serialize
	FormGuid: string;

	@serialize
	ParentControlId: number;

	@serialize
	ParentControlGuid: string;

	@serialize
	Properties: string;

	@serialize
	Sort: number;

	@serialize
	Description: string;

	@serializeAs(FieldModel)
	Fields: Array<FieldModel>;

	@serializeAs(TranslationModel)
	NameTranslations: Array<TranslationModel>;

    @serializeAs(TranslationModel)
    DescriptionTranslations: Array<TranslationModel>;

    @serialize
    IconId: number;

    @serialize
    UseFieldName: boolean;

    @serialize
	TitleFieldId: number;

	@serialize
	KanbanSubjectId: number;

	@serialize
	TodoEntityId: number;

	@serialize
	TodoTypeId: number;

	@serialize
	TodoFieldId: number;

	AddField(fieldId: number, sort: number, linkRequired?: boolean) {
		var fieldModel = new FieldModel();
		fieldModel.Id = fieldId;
		fieldModel.Sort = sort;
		fieldModel.LinkRequired = linkRequired;
		this.Fields.push(fieldModel);
	}
}

export interface IMainFormUpdateModel {
	EntityId: number;
	IsMain: boolean;
	FormId: number;
}

export class MainFormUpdateModel {
	constructor(params: IMainFormUpdateModel) {
		this.EntityId = params.EntityId;
		this.FormId = params.FormId;
		this.IsMain = params.IsMain;
	}

	@serialize
	EntityId: number;

	@serialize
	IsMain: boolean;

	@serialize
	FormId: number;
}

export class UpdateScreenModel {

	constructor() {
		this.DeletedFormIds = [];
		this.DeletedControlIds = [];
		this.SubForms = [];
		this.Controls = [];
	}

	
	@serialize
	IsEnablePathRunner: boolean;

	@serialize
	ScreenId: number;

	@serialize
	DeletedFormIds: Array<number>;

	@serialize
	DeletedControlIds: Array<number>;

	@serializeAs(SubFormModel)
	SubForms: SubFormModel[];

	@serializeAs(ControlModel)
	Controls: Array<ControlModel>;

	@serializeAs(MainFormUpdateModel)
	TableFormLinks: Array<MainFormUpdateModel>;

	@serialize
	FormProperties: string;
}