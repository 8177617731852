import {P} from "Core/Common/Promise";
import {RecordLinkerStore} from "Core/Components/RecordLinker/Stores/RecordLinkerStore";
import {DataModel} from "Core/Screens/EditScreen/Models/DataModel";

export interface ILinkedRecord {
	MainTableId: number;
	MainRecordId?: number;
	LinkedTableId: number;
	LinkedRecordId?: number;
}

export interface ILinkedRecords {
    MainTableId: number;
    MainRecordId: number;
    LinkedTableId: number;
    LinkedRecordIds: number[];
}

export interface INewLinkedRecord {
	InsertOrUpdateRecordModel: DataModel,
	LinkRecordModel: ILinkedRecord;
}

export class RecordLinker {
    static IsLinkRecordExist(record: ILinkedRecord): P.Promise<any> {
        return RecordLinkerStore.IsLinkWithRecordExist(record);
    }

    static GetExistingLinksWithRecords(record: ILinkedRecords): P.Promise<any> {
        return RecordLinkerStore.GetExistingLinksWithRecords(record);
    }

	static LinkRecord(record: ILinkedRecord): P.Promise<any> {
		return RecordLinkerStore.LinkRecord(record);
    }

    static LinkRecords(record: ILinkedRecords): P.Promise<any> {
        return RecordLinkerStore.LinkRecords(record);
    }

	static AddAndLinkRecord(record: INewLinkedRecord): P.Promise<any> {
		return RecordLinkerStore.AddAndLinkRecord(record);
	}

}