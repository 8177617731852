export const DEFAULT_RECORDS_PER_PAGE = 10;

export const PROPERTIES = {
    SHOW_ALL_COLUMNS: 'ShowAllColumns',
    READONLY: 'ReadOnly',
    SEARCH: 'Search',
    HIDE_LIFESTATUS_TOGGLE: "HideLifeStatusToggle",
    HIDE_UNLINK: "HideUnlink",
    HIDE_EDIT: "HideEdit",
    ENABLE_BASKET: 'EnableBasket',
	ENABLE_LINK: 'EnableLinkButton',
    ENABLE_UNLINK_MULTIPLE: 'EnableUnlinkMultipleButton',
    ENABLE_LINK_PARENT: 'EnableLinkParentButton',
	ENABLE_SUBGRID_LINK: 'EnableSubGridLinkButton',
    ENABLE_UNLINK_MULTIPLE_RECORDS_SUBGRID: 'EnableUnlinkMultipleRecordsSubGridLinkButton',
    ENABLE_SUBGRID_LINK_PARENT: 'EnableSubGridLinkParentButton',
    ENABLE_NEW_LINK: 'EnableNewButton',
    ENABLE_ADD_AND_LINK: 'EnableAdd&LinkButton',
    ENABLE_SCAN_AND_LINK: 'EnableScan&LinkButton',
    ENABLE_SUBGID_ADD_AND_LINK: 'EnableSubGridAdd&LinkButton',
    ENABLE_SUBGRID_SCAN_AND_LINK: 'EnableSubGridScan&LinkButton',
    ENABLE_ADD_QUERY: 'EnableAddQueryButton',
    BACK_LINKING: 'BackLinking',
    SECURITY_FILTER: 'SecurityFilter',
    QUERY_CONDITION: 'QueryCondition',
    MAX_ROW_HEIGHT: 'MaxRowHeight',
    FAST_FILTER: 'FastFilter',
    SHOW_TABLE_VIEW: 'ShowTableView',
    ROW_COLOR: 'RowColor',
    BULK_EDIT: 'BulkEdit',
    EDIT_ALL: 'EditAll',
    LINKING_CONDITION: 'LinkingCondition',
    CARD_SCREEN: 'CardScreen',
    POSITION: 'Position'
};

export const SECURITY_FILTERS = {
    SHARING_SECURITY: 'Sharing',
    RECORD_SECURITY: 'Record',
};