import * as joint from 'libs/rappid/build/rappid';
import 'Core/Controls/CanvasDesigner/Shapes/Shapes';
import { CanvasModelState } from 'Core/Controls/CanvasDesigner/Enums/CanvasModelState';
import { CanvasViewModel } from 'Core/Controls/CanvasDesigner/Models/CanvasViewModel';
import { CANVAS_TYPES } from 'Core/Controls/CanvasDesigner/Constants/CanvasTypes';
import { DataTypes, DATA_TYPES } from 'Core/Controls/CanvasDesigner/Constants/DataTypes';
import { EVENTS as DATA_SELECTOR_EVENTS } from 'Core/Controls/CanvasDesigner/Shapes/Views/DataSelectorView/Events';
import { BlockUI } from 'Core/Common/BlockUi';
import { CanvasDesignerStore, IExactOnlineSettingDto } from 'Core/Controls/CanvasDesigner/Stores/CanvasDesignerStore';
import {
	CanvasModel,
	CanvasLinkModel,
	CanvasItemModel
} from 'Core/Controls/CanvasDesigner/Models/CanvasModel';
import { P } from 'Core/Common/Promise';
import { ShapeFactory } from 'Core/Controls/CanvasDesigner/Views/ShapeFactory';
import {GridLayout} from "../GridLayout";
import { EVENTS } from "Core/Controls/CanvasDesigner/Shapes/Views/ParamView/Events";
import {dia as Dia, shapes, dia} from 'libs/rappid/build/rappid';
import { Notifier } from '../../../Common/Notifier';
import { Tooltip } from '../../../Common/Tooltip';
import { ModelState } from '../../../Common/Enums/ModelState';
import { FileDownloader } from 'Core/Components/FileDownloader/FileDownloader';
import { MsAccessTableDto } from '../Models/Dto/MsAccessTableDto';
import { InspectorFactory } from '../Inspector/InspectorFactory';

export class CanvasManager {
	private _graph: joint.dia.Graph;
	private _paper: joint.dia.Paper;
	private _paperScroller: joint.ui.PaperScroller;
	private _viewModel: CanvasViewModel;
	private _dataModel: CanvasModel;
	private _dwPackage: CanvasItemModel;
	private _toogleExpanding: boolean;
	private _shapeFactory: ShapeFactory;
	private _inspector: joint.ui.Inspector;
	private _selectedCellView: any;
	private _arrowTooltip: Tooltip;

	private GetCellType(cell: joint.dia.Cell): string {
		return cell.get('type').split('.')[1];
	}

	constructor(dataModel: CanvasModel) {
		this._viewModel = new CanvasViewModel(dataModel);
		this._dataModel = dataModel;
		this._toogleExpanding = false;
		this.Init();
	}

	GetShapeFactory() {
		return this._shapeFactory;
    }

	get PackageName(){
		let dwPackageItem = _.find(this._dataModel.CanvasItems, (item) => {
			return item.TypeName === CANVAS_TYPES.DW_PACKAGE;
		});
		if(dwPackageItem){
			return dwPackageItem.Name;
		}
		return '';
	}

	get DestinationDataType(){
		let dwPackageItem = _.find(this._dataModel.CanvasItems, (item) => {
			return item.TypeName === CANVAS_TYPES.DESTINATION;
		});
		if(dwPackageItem){
			let properties = JSON.parse(dwPackageItem.Properties);
			if(properties){
				return properties.DataType;
			}
		}
		return '';
	}

	Init() {
		this.InitializePaper();
		this.PopulateGraph();
	}

	PopulateGraph() {
		this._graph.clear();
		this._shapeFactory.InitRoot(this._dataModel);
		this._dwPackage = this._shapeFactory.Root;
		this._shapeFactory.BuildShapes(this._dataModel)
			.then(()=>{
				BlockUI.Unblock();
		});

		if (!this._viewModel.Source) {
			this.AddDataSelector(CANVAS_TYPES.SOURCE);
		}

		if (!this._viewModel.Destination) {
			this.AddDataSelector(CANVAS_TYPES.DESTINATION);
		}
	}

	InitializePaper() {
		const graph = this._graph = new joint.dia.Graph;

		graph.on('change:parent', (element: joint.dia.Cell, newParent, opt) => {});

		graph.on('change', (element: joint.dia.Cell, newParent, opt) => {
			element.set('state', CanvasModelState.Changed);
		});

		graph.on('remove', (element: joint.dia.Cell, collection, opt) => {
			let source = element.get('source');
			let target = element.get('target');

			if (source && target && !this._toogleExpanding) {
				let link = _.find(this._dataModel.CanvasLinks, (item) => {
					return ((item.CanvasItem1Id === source.id || item.CanvasItem1Guid === source.id) && (item.CanvasItem2Id === target.id || item.CanvasItem2Guid === target.id));
				});

				if (link && link.State === CanvasModelState.NoChanges) {
					link.State = CanvasModelState.Deleted;
				}

				if (link && link.State === CanvasModelState.New) {
					this._dataModel.CanvasLinks.splice(this._dataModel.CanvasLinks.indexOf(link), 1);
				}
			}
		});

		const paper = this._paper = new joint.dia.Paper({
			async: { batchSize: 50 },
			width: 1271,
			height: 700,
			gridSize: 5,
			drawGrid: false,
			model: graph,
			embeddingMode: true,
			perpendicularLinks: false,
			clickThreshold: 1,
			defaultLink: new joint.shapes.cyberThing.Arrow(),
			multiLinks: false,

			interactive: (cellView, evt) => {
				let allowMovement = false;

				if(cellView instanceof joint.shapes.cyberThing.GroupView){
					allowMovement = true;
				}

				if (cellView.model.isLink()) {
					allowMovement = true;
				}

				return {
					elementMove: allowMovement,
					addLinkFromMagnet: true
				};
			},
			validateEmbedding: (childview: any, parentview: any) => {
				if (this.GetCellType(childview.model) === 'Node' && this.GetCellType(parentview.model) === 'Zone') {
					childview.model.set({ 'embeddingInvalid': false, 'embedded': true });
					return true;
				} else if (this.GetCellType(childview.model) === 'Param' && this.GetCellType(parentview.model) === 'Node') {
					childview.model.set({ 'embeddingInvalid': false, 'embedded': true });
					return true;
				} else if (this.GetCellType(childview.model) === this.GetCellType(parentview.model)) {
					childview.model.set({ 'embeddingInvalid': true, 'embedded': true });
					return false;
				} else {
					childview.model.set({ 'embeddingInvalid': true, 'embedded': true });
					return false;
				}
			},
			validateConnection: (cellViewS: joint.dia.CellView, magnetS, cellViewT: joint.dia.CellView, magnetT, end, linkView) => {
				// Prevent linking from input ports;
				if (magnetS && magnetS.getAttribute('port-group') === 'in') return false;
				// Prevent linking from output ports to input ports within one element;
				if (cellViewS === cellViewT) return false;
				// Prevent linking to input ports;
				return magnetT && magnetT.getAttribute('port-group') === 'in';
			},
			validateMagnet: (cellView: joint.dia.CellView, magnet) => {
				// Note that this is the default behaviour. Just showing it here for reference.
				// Disable linking interaction for magnets marked as passive (see below `.inPorts circle`).
				return magnet.getAttribute('magnet') !== 'passive';
			}
		});

		paper.on('link:pointermove', (link) => {});

		paper.on('blank:pointerclick', () => {
			this.HideInpector();

			if(this._inspector){
				this._inspector.remove();				
			}

			if(this._selectedCellView){
				this._selectedCellView.unhighlight();
			}
		});

		paper.on('cell:mouseover', (cellView: any, evt)=>{

			if(cellView.model instanceof joint.shapes.cyberThing.Arrow){

				var scrollX = document.body.scrollLeft + document.documentElement.scrollLeft;
				var scrollY = document.body.scrollTop + document.documentElement.scrollTop;
				$('#tooltip-container').offset({top: evt.clientY + scrollY + 10, left: evt.clientX + scrollX + 10 });

				if (cellView.sourceView instanceof joint.shapes.cyberThing.ParamView && cellView.targetView instanceof joint.shapes.cyberThing.ParamView) {
					let sourceParent = graph.get('cells').get(cellView.sourceView.model.get('parent'));
					let targetParent = graph.get('cells').get(cellView.targetView.model.get('parent'));
					let content = `${sourceParent.get('originalName')}.${cellView.sourceView.model.get('originalName')} > ${targetParent.get('originalName')}.${cellView.targetView.model.get('originalName')}`;
					this._arrowTooltip.SetContent(content);
				}

				$('#tooltip-container').trigger('mouseenter');
			}

			cellView.highlight(null, { highlighter: {
					name: 'addClass',
					options: {
						className: 'highlighted'
					}
				}});
		});

		paper.on('cell:mouseout', (cellView: any)=>{

			if(cellView.model instanceof joint.shapes.cyberThing.Arrow){
				$('#tooltip-container').trigger('mouseout');
			}

			cellView.unhighlight(null, { highlighter: {
					name: 'addClass',
					options: {
						className: 'highlighted'
					}
				}});
		});

		paper.on('link:pointerup', (link: any) => {
			if (link.sourceView instanceof joint.shapes.cyberThing.ParamView && link.targetView instanceof joint.shapes.cyberThing.ParamView) {
				let linkModel = new CanvasLinkModel();
				linkModel.CanvasItem1Id = link.sourceView.model.get('recordId');
				linkModel.CanvasItem1Guid = link.sourceView.model.get('guid');
				linkModel.CanvasItem2Id = link.targetView.model.get('recordId');
				linkModel.CanvasItem2Guid = link.targetView.model.get('guid');
				linkModel.State = CanvasModelState.New;
				linkModel.RootRecordId = this._dwPackage.Id;
				linkModel.RootRecordGuid = this._dwPackage.Guid;

				let rightItemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Id === linkModel.CanvasItem2Id && item.Guid === linkModel.CanvasItem2Guid;
				});

				let leftItemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Id === linkModel.CanvasItem1Id && item.Guid === linkModel.CanvasItem1Guid;
				});

				let parent = this._shapeFactory.GetParentByType(this._dataModel, rightItemModel, CANVAS_TYPES.GROUP);

				if(!parent){
					parent = this._shapeFactory.GetParentByType(this._dataModel, leftItemModel, CANVAS_TYPES.GROUP);
				}

				if(parent){
					linkModel.RootRecordId = parent.Id;
					linkModel.RootRecordGuid = parent.Guid;
				}else{
					linkModel.RootRecordId = this._dwPackage.Id;
					linkModel.RootRecordGuid = this._dwPackage.Guid;
				}

				let existsLink = _.find(this._dataModel.CanvasLinks, (item) => {
					return item.CanvasItem1Guid === linkModel.CanvasItem1Guid
						&& item.CanvasItem1Id === linkModel.CanvasItem1Id
						&& item.CanvasItem2Guid === linkModel.CanvasItem2Guid
						&& item.CanvasItem2Id === linkModel.CanvasItem2Id
						&& item.State != ModelState.Deleted
				});

				if(!existsLink){
					this._dataModel.CanvasLinks.push(linkModel);
					this.UpdateLinkProperties(link, linkModel);
				}else{
					this.UpdateLinkProperties(link, existsLink);
				}
			} else {
				graph.attributes.cells.remove(link.model.cid);
				link.remove();
			}
		});

		paper.on('cell:pointerdown', (cellView: any, evt: any) => {
			let targetClass = evt.target.parentNode.getAttribute('class');
			if( targetClass=== 'fa fa-arrow-left' || targetClass === 'stateButton'){
				return;
			}

			let itemModel = this.GetItemByGuid(cellView.model.get('guid'));

			if(this._inspector){
				this._inspector.remove();	
				this._inspector = null;			
			}

			if(this._selectedCellView){
				this._selectedCellView.unhighlight();
			}

			if(cellView instanceof joint.shapes.cyberThing.ParamView) {
				this.RenderInspector(cellView);
				cellView.highlight();
				this._selectedCellView = cellView;
			}

			if(cellView instanceof joint.shapes.cyberThing.GroupView) {
				let className = evt.target.parentNode.getAttribute('class');
				switch (className) {
					case 'element-tool-remove':
						this._shapeFactory.DeleteItem(this._dataModel, itemModel, cellView.model.getEmbeddedCells());
						this._graph.removeCells(cellView.model.getEmbeddedCells());
						cellView.remove();
						break;
					default:
				}
			}

			if(
				(
					(cellView instanceof joint.shapes.cyberThing.SourceView || cellView instanceof joint.shapes.cyberThing.DestinationView)
					&&
					(itemModel.JsonProperties.DataType === DataTypes.ExactOnline || itemModel.JsonProperties.DataType === DataTypes.EWS || itemModel.JsonProperties.DataType === DataTypes.OCIPunchOut)
				)
				|| cellView instanceof joint.shapes.cyberThing.TableView
				|| cellView instanceof joint.shapes.cyberThing.CSharpFunctionView
				|| cellView instanceof joint.shapes.cyberThing.TriggerView
				|| cellView instanceof joint.shapes.cyberThing.JSFunctionView
				|| cellView instanceof joint.shapes.cyberThing.APIMethodView){
				this._toogleExpanding = true;

				if(evt.target.parentNode.getAttribute('class') === 'toggle-icon fa') {
					this.ToggleCollapse(cellView, itemModel, graph, paper);					
				}else{
					this.RenderInspector(cellView);
					cellView.highlight();
					this._selectedCellView = cellView;
				}
			}

			if(!this._inspector){
				this.HideInpector();
			}

		});

		this._paperScroller = new joint.ui.PaperScroller({
			padding: 10,
			paper: paper,
			autoResizePaper: true
		});

		this._shapeFactory = new ShapeFactory(this._graph, this._paper, this._paperScroller);
		this._shapeFactory.On(EVENTS.BACK_TO_DATA_SELECTOR, this, (evtArgs)=>{
			this.AddDataSelector(evtArgs.data.Type);
		});

		$('#paper-container').append(this._paperScroller.el);		
		$('#tooltip-container').remove();
		$('body').append('<div id="tooltip-container" style="position: absolute; top: 0px; left: 0px;"></div>');

		this._arrowTooltip = new Tooltip('#tooltip-container', "test", {
			position: {x: 'right', y: 'center'},
			outside: 'x'
		});

		this._paperScroller.render();
		this._paperScroller.scroll(0,0);
	}

	HideInpector(){
		if(this._inspector){
			let inputs = this._inspector.$el.find('input');
			inputs.trigger('change');
		}

		$("#paper-container").animate({right: 0}, ()=>{
			$("#inspector-container").hide();
		});
	}

	ToggleCollapse(cellView: any, itemModel: CanvasItemModel, graph: joint.dia.Graph, paper: joint.dia.Paper){
		this.UpdateItemProperties(cellView, itemModel);
		if (cellView.model.attributes.attrs.IsCollapsed) {
			this._shapeFactory.Expand(cellView, this._dataModel, graph, paper);
			cellView.model.attributes.attrs.IsCollapsed = false;
			this._toogleExpanding = false;
		} else {
			cellView.model.on('transition:end', () => {
				let embeds = cellView.model.getEmbeddedCells();
				this._graph.removeCells(embeds);
				this._toogleExpanding = false;

				let parent = ShapeFactory.GetRoot(cellView.model, this._graph);
				if (parent) {
					setTimeout(() => {
						let position = parent.get('position');

						GridLayout.layout(parent.getEmbeddedCells(), {
							parentRelative: true,
							deep: true,
							dy: 5,
							resizeToFit: true
						});

						parent.fitEmbeds({
							padding: {
								top: 25,
								left: 10,
								right: 20,
								bottom: 10
							}, deep: true
						});

						parent.position(position.x, position.y, {deep: true});
					}, 0);
				}
				cellView.off('transition:end');
			});

			_.each(cellView.model.getEmbeddedCells(), (cell: any) => {
				cell.attr('./visibility', 'hidden');
			});

			cellView.model.transition(
				'size/height',
				cellView.model.attributes.defaultSize.height,
				{
					duration: 250,
					delay: 10,
					timingFunction: joint.util.timing.linear,
					valueFunction: joint.util.interpolate.number
				});

			cellView.model.attributes.attrs.IsCollapsed = true;
		}
		this.ReRender(cellView);
	}

	BuildExactOnlineInspector(cellView): joint.ui.Inspector {
		let inspector = joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'ButtonTest': {
						type: 'buttonTest',
						label: 'Test',
						group: 'ExactOnline',
						index: 1
					},
					'EndPointUrl': {
						type: 'text',
						label: 'EndPointUrl',
						group: 'ExactOnline',
						index: 2
					},
					'CallbackUrl': {
						type: 'text',
						label: 'CallbackUrl',
						group: 'ExactOnline',
						index: 3
					},
					'ClientId': {
						type: 'text',
						label: 'ClientId',
						group: 'ExactOnline',
						index: 4
					},
					'ClientSecret': {
						type: 'text',
						label: 'ClientSecret',
						group: 'ExactOnline',
						index: 5
					},
					'SecretKey': {
						type: 'text',
						label: 'SecretKey',
						group: 'ExactOnline',
						index: 6
					},
					'Login': {
						type: 'text',
						label: 'Login',
						group: 'ExactOnline',
						index: 6
					},
					'Password': {
						type: 'text',
						label: 'Password',
						group: 'ExactOnline',
						index: 7
					},
					'Division': {
						type: 'text',
						label: 'Division',
						group: 'ExactOnline',
						index: 8
					}
				}
			},
			groups: {
				'ExactOnline': {
					label: 'ExactOnline options',
					index: 1
				}
			},
			getFieldValue: function(attribute, type) {
				if (type === 'buttonTest') {
					return { value: $(attribute).data('result') };
				}
			},
			renderFieldContent: function(options, path, value) {
				if(path === 'attrs/ButtonTest'){
					var $buttonSet = $('<div>');
					var $testBtn = $('<a title="Test credentials"><i class="fa fa-stack fa-2x fa-exclamation"></i></a>');
					var $createWebhookBtn = $('<a class="webhook-btn" title="Create webhook"><img src="img/Custom/webhook.png"></i></a>');
					var $exportBtn = $('<a title="Export settings"><i class="fa fa-stack fa-2x fa-upload"></i></a>');
					var $importBtn = $('<a title="Import settings"><i class="fa fa-stack fa-2x fa-download"></i></a>');
					var $uploadSettings = $('<input type="file" style="display:none"/>');

					$buttonSet.append([$exportBtn, $importBtn, $testBtn, $createWebhookBtn]);

					$testBtn.on('click', function() {
						inspector.trigger('change:attrs/ButtonTest', 'test');
					});

					$createWebhookBtn.on('click', function() {
						inspector.trigger('change:attrs/ButtonTest', 'webhook');
					});

					$exportBtn.on('click', function() {
						inspector.trigger('change:attrs/ButtonTest', 'export');
					});

					$uploadSettings.on('change',()=>{
						var input = $uploadSettings[0] as HTMLInputElement;
						if ( input.files && input.files[0] ) {
							let file = input.files[0];
							let fr = new FileReader();
							fr.onload = function () {
								inspector.trigger('change:attrs/ButtonTest', 'import', fr.result);
							};
							
							fr.readAsText(file);
						}	
					});

					$importBtn.on('click', function() {
						$uploadSettings.click();
					});

					return $buttonSet;
				}

				return null;
			}
		} );
		return inspector;
	}

	BuildOCIInspector(cellView): joint.ui.Inspector {
		let inspector = joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'ButtonTest': {
						type: 'buttonTest',
						label: 'Test',
						group: 'OCI',
						index: 1
					},
					'EndPointUrl': {
						type: 'text',
						label: 'EndPointUrl',
						group: 'OCI',
						index: 2
					},
					'CallbackUrl': {
						type: 'text',
						label: 'CallbackUrl',
						group: 'OCI',
						index: 3
					},
					'QueryString': {
						type: 'textarea',
						label: 'QueryString',
						group: 'OCI',
						index: 4
					},
					'Login': {
						type: 'text',
						label: 'Login',
						group: 'OCI',
						index: 5
					},
					'Password': {
						type: 'text',
						label: 'Password',
						group: 'OCI',
						index: 6
					}
				}
			},
			groups: {
				'OCI': {
					label: 'Options',
					index: 1
				}
			},
			getFieldValue: function(attribute, type) {
				if (type === 'buttonTest') {
					return { value: $(attribute).data('result') };
				}
			},
			renderFieldContent: function(options, path, value) {
				if(path === 'attrs/ButtonTest'){
					var $buttonSet = $('<div>');
					var $createWebhookBtn = $('<a><i class="fa fa-stack fa-2x fa-shopping-cart"></i></a>');
					var $exportBtn = $('<a title="Export settings"><i class="fa fa-stack fa-2x fa-upload"></i></a>');
					var $importBtn = $('<a title="Import settings"><i class="fa fa-stack fa-2x fa-download"></i></a>');
					var $uploadSettings = $('<input type="file" style="display:none"/>');

					$buttonSet.append([$exportBtn, $importBtn, $createWebhookBtn]);

					$createWebhookBtn.on('click', function() {
						inspector.trigger('change:attrs/ButtonTest', 'createOCIWebhook');
					});

					$exportBtn.on('click', function() {
						inspector.trigger('change:attrs/ButtonTest', 'export');
					});

					$uploadSettings.on('change',()=>{
						var input = $uploadSettings[0] as HTMLInputElement;
						if ( input.files && input.files[0] ) {
							let file = input.files[0];
							let fr = new FileReader();
							fr.onload = function () {
								inspector.trigger('change:attrs/ButtonTest', 'import', fr.result);
							};
							
							fr.readAsText(file);
						}	
					});

					$importBtn.on('click', function() {
						$uploadSettings.click();
					});

					return $buttonSet;
				}

				return null;
			}
		} );
		return inspector;
	}

	BuildEWSInspector(cellView): joint.ui.Inspector {
		let inspector = joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'ButtonTest': {
						type: 'buttonTest',
						group: 'EWS',
						index: 1
					},
					'Login': {
						type: 'text',
						label: 'Login',
						group: 'EWS',
						index: 2
					},
					'Password': {
						type: 'text',
						label: 'Password',
						group: 'EWS',
						index: 3
					}
				}
			},
			groups: {
				'EWS': {
					label: 'EWS options',
					index: 1
				}
			},
			renderFieldContent: function(options, path, value) {
				if(path === 'attrs/ButtonTest'){
					var $buttonSet = $('<div>');
					var $exportBtn = $('<a title="Export settings"><i class="fa fa-stack fa-2x fa-upload"></i></a>');
					var $importBtn = $('<a title="Import settings"><i class="fa fa-stack fa-2x fa-download"></i></a>');
					var $uploadSettings = $('<input type="file" style="display:none"/>');

					$buttonSet.append([$exportBtn, $importBtn]);

					$exportBtn.on('click', function() {
						inspector.trigger('change:attrs/ButtonTest', 'export');
					});

					$uploadSettings.on('change',()=>{
						var input = $uploadSettings[0] as HTMLInputElement;
						if ( input.files && input.files[0] ) {
							let file = input.files[0];
							let fr = new FileReader();
							fr.onload = function () {
								inspector.trigger('change:attrs/ButtonTest', 'import', fr.result);
							};
							
							fr.readAsText(file);
						}	
					});

					$importBtn.on('click', function() {
						$uploadSettings.click();
					});

					return $buttonSet;
				}

				return null;
			}
		} );
		return inspector;
	}

	GetItemByGuid(guid: string){	
		return _.find(this._dataModel.CanvasItems, (item) => {
			return item.Guid === guid;
		});	
	}

	BuildFunctionInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'IsBulkSet': {
						type: 'toggle',
						label: 'Bulk set',
						group: 'Options',
						index: 1
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			}
		} );
	}

	BuildTableInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'IsSearchExisting': {
						type: 'toggle',
						label: 'Search Existing',
						group: 'Options',
						index: 2
					},
					'IsCheckOwnerProfile': {
						type: 'toggle',
						label: 'Check owner profile',
						group: 'Options',
						index: 3
					},
					'UseMatching': {
						type: 'toggle',
						label: 'Enable matching',
						group: 'Options',
						index: 1
					},
					'SkipCondition': {
						type: 'text',
						label: 'Skip condition',
						group: 'Options',
						index: 4
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			}
		} );
	}

	BuildEWSTableInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'AddDaysToStart': {
						type: 'number',
						label: 'Add days to start',
						group: 'Filters',
						index: 0
					},
					'AddDaysToEnd': {
						type: 'number',
						label: 'Add days to end',
						group: 'Filters',
						index: 1
					},
					'ExcludeCancelled': {
						type: 'toggle',
						label: 'Filter on cancelled appointments',
						group: 'Filters',
						index: 2
					}
				}
			},
			groups: {
				'Filters': {
					label: 'Filters',
					index: 1
				}
			}
		} );
	}

	BuildExactOnlineTableInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'ODataFilter': {
						type: 'text',
						label: '$filter',
						group: 'OData',
						index: 1
					}
				}
			},
			groups: {
				'OData': {
					label: 'OData',
					index: 1
				}
			}
		} );
	}

	BuildSourcePrimaryKeyInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'UseSubKey': {
						type: 'toggle',
						label: 'Use SubKey',
						group: 'Options',
						index: 1
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			}
		} );
	}

	BuildDestinationPrimaryKeyInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'UsePrimaryKey': {
						type: 'toggle',
						label: 'Use Primary key',
						group: 'Options',
						index: 1
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			}
		} );
	}

	BuildLookupInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'IsSearchBySubKey': {
						type: 'toggle',
						label: 'Search by SubKey',
						group: 'Options',
						index: 1
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			}
		} );
	}

	BuildParamInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'IsRecordIdentifier': {
						type: 'toggle',
						label: 'Record identifier',
						group: 'Options',
						index: 1
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			}
		} );
	}

	BuildMemoParamInspector(cellView): joint.ui.Inspector{
		let inspector = joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'ImageFileLocation': {
						type: 'fileLocation',
						label: 'File type',
						options: ['Base64', 'Curl', 'Desktop'],
						group: 'Options',
						index: 1
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			},
			renderFieldContent: function(options, path, value) {
				if(path === 'attrs/ImageFileLocation'){

					let $buttonSet = $('<div/>');
					
					let $imageCheckBox = $(`
					<div class="field select-field">
						<label>Image</label>
						<input type="checkbox">
					</div>`);

					let $fileLocationSelect = $(`
						<div class="field select-field">
							<label>File type</label>
							<select class="select" data-type="select">
								<option value="Base64">Base64</option>
								<option value="Curl">Curl</option>
								<option value="Desktop">Desktop</option>
							</select>
						</div>`);

					if(!!value && value != ''){
						$imageCheckBox.find('input').prop('checked', true);
						$fileLocationSelect.find('select').val(value);						
					}else{
						$fileLocationSelect.hide();
					}

					$buttonSet.append([$imageCheckBox, $fileLocationSelect]);

					$imageCheckBox.find('input').on('change', (evt)=>{
						if((evt.target as HTMLInputElement).checked){
							$fileLocationSelect.show();
							inspector.trigger('change:attrs/ImageFileLocation', $fileLocationSelect.find('select').val());
						}else{
							$fileLocationSelect.hide();
							$fileLocationSelect.find('select').val('Base64');
							inspector.trigger('change:attrs/ImageFileLocation', null);
						}
					});

					$fileLocationSelect.find('select').on('change', (evt)=>{
						inspector.trigger('change:attrs/ImageFileLocation', (evt.target as HTMLInputElement).value);						
					});

					return $buttonSet;
				}

				return null;
			}
		} );
		return inspector;
	}


	BuildBinaryParamInspector(cellView): joint.ui.Inspector{
		return joint.ui.Inspector.create('#inspector-container', {
			cell: cellView.model,
			theme: 'default',
			inputs: {
				attrs: {
					'FileLocation': {
						type: 'select',
						label: 'File type',
						options: ['Base64', 'Curl', 'Desktop'],
						group: 'Options',
						index: 1
					}
				}
			},
			groups: {
				'Options': {
					label: 'Options',
					index: 1
				}
			}
		} );
	}

	RenderInspector(cellView){
		this._inspector = null;
		let root = ShapeFactory.GetRoot(cellView.model, this._graph);

		if(cellView instanceof joint.shapes.cyberThing.SourceView || cellView instanceof joint.shapes.cyberThing.DestinationView){
			let canvasItemGuid = cellView.model.get('guid');
			let itemModel = this.GetItemByGuid(canvasItemGuid);

			if(itemModel.JsonProperties.DataType === DataTypes.ExactOnline){
				this._inspector = this.BuildExactOnlineInspector(cellView);
			}

			if(itemModel.JsonProperties.DataType === DataTypes.OCIPunchOut){
				this._inspector = this.BuildOCIInspector(cellView);
			}

			if(itemModel.JsonProperties.DataType === DataTypes.EWS){
				this._inspector = this.BuildEWSInspector(cellView);
			}			
		}else if(cellView instanceof joint.shapes.cyberThing.CSharpFunctionView){
			if(root instanceof shapes.cyberThing.Destination){
				this._inspector = this.BuildFunctionInspector(cellView);
			}
		}else if(cellView instanceof joint.shapes.cyberThing.TableView){
			if(root instanceof shapes.cyberThing.Destination){
				let parentModel = this.GetItemByGuid(root.get('guid'));
				if(parentModel.JsonProperties.DataType === DataTypes.Cyberbase){
					this._inspector = this.BuildTableInspector(cellView);			
				}
			}

			if(root instanceof shapes.cyberThing.Source){
				let parentModel = this.GetItemByGuid(root.get('guid'));
				if(parentModel.JsonProperties.DataType === DataTypes.EWS){

					if(cellView.model.attributes.name === 'Appointment'){
						this._inspector = this.BuildEWSTableInspector(cellView);			
					}
				}

				if(parentModel.JsonProperties.DataType === DataTypes.ExactOnline){
					this._inspector = this.BuildExactOnlineTableInspector(cellView);			
				}
			}

		} else if(cellView.model.attributes.protocolName === 'PKey') {
			
			if(root instanceof shapes.cyberThing.Source){
				let parentModel = this.GetItemByGuid(root.get('guid'));
				if(parentModel.JsonProperties.DataType === DataTypes.Cyberbase || parentModel.JsonProperties.DataType === DataTypes.Query){
					this._inspector = this.BuildSourcePrimaryKeyInspector(cellView);
				}
			}

			if(root instanceof shapes.cyberThing.Destination){
				let parentModel = this.GetItemByGuid(root.get('guid'));
				if(parentModel.JsonProperties.DataType === DataTypes.Cyberbase){
					this._inspector = this.BuildDestinationPrimaryKeyInspector(cellView);
				}
			}

		}else if(cellView.model.attributes.protocolName === 'Lookup' || cellView.model.attributes.protocolName === 'MultiSelect') {
			this._inspector = this.BuildLookupInspector(cellView);
		}else if(cellView instanceof joint.shapes.cyberThing.ParamView){

			if(root instanceof shapes.cyberThing.Destination){
				let parentModel = this.GetItemByGuid(root.get('guid'));
				if(parentModel.JsonProperties.DataType === DataTypes.Cyberbase){

					if(cellView.model.attributes.protocolName === 'Binary'){
						this._inspector = this.BuildBinaryParamInspector(cellView);
					}else if(cellView.model.attributes.protocolName === 'Memo'){
						this._inspector = this.BuildMemoParamInspector(cellView);
					}else{
						this._inspector = this.BuildParamInspector(cellView);
					}					
				}
			}	

			if(root instanceof shapes.cyberThing.Destination && !this._inspector){
				this._inspector = InspectorFactory.BuildInspector(cellView, this._graph);
			}
		}

		if(this._inspector) {
			this._inspector.on('change:attrs/IsSearchExisting', () => {				
				this.UpdateProperty(cellView, 'IsSearchExisting');
				this.ReRender(cellView);
			});

			this._inspector.on('change:attrs/IsRecordIdentifier', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'IsRecordIdentifier');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/IsCheckOwnerProfile', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'IsCheckOwnerProfile');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/IsSearchBySubKey', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'IsSearchBySubKey');
					this.ReRender(cellView);
				}
			});


			this._inspector.on('change:attrs/UseMatching', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'UseMatching');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/UseSubKey', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'UseSubKey');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/UsePrimaryKey', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'UsePrimaryKey');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/IsBulkSet', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'IsBulkSet');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/ImageFileLocation', (val) => {		
				cellView.model.attributes.attrs['ImageFileLocation'] = val;
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'ImageFileLocation');
					this.ReRender(cellView);
				}
			});


			this._inspector.on('change:attrs/ExcludeCancelled', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'ExcludeCancelled');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/SkipCondition', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'SkipCondition');
					this.ReRender(cellView);
				}
			});


			this._inspector.on('change:attrs/EndPointUrl', () => {
				let itemModel = this.GetItemByGuid(cellView.model.get('guid'));
				if (itemModel) {
					this.UpdateProperty(cellView, 'EndPointUrl');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/CallbackUrl', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'CallbackUrl');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/ClientId', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'ClientId');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/ClientSecret', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'ClientSecret');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/SecretKey', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'SecretKey');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/Login', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'Login');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/Password', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'Password');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/Division', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'Division');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/QueryString', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'QueryString');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/AddDaysToStart', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'AddDaysToStart');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/AddDaysToEnd', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'AddDaysToEnd');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/ODataFilter', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'ODataFilter');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/FileLocation', () => {
				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'FileLocation');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/FilledFrom', (val) => {				
				let guid = cellView.model.get('guid');
				if(!cellView.model.attributes.attrs['FilledFrom']){
					cellView.model.attributes.attrs['FilledFrom'] = {};
				}

				let root = ShapeFactory.GetRoot(cellView.model, this._graph);				
				cellView.model.attributes.attrs['FilledFrom'][root.get('replicationId')] = val;
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});
				if (itemModel) {
					this.UpdateProperty(cellView, 'FilledFrom');
					this.ReRender(cellView);
				}
			});

			this._inspector.on('change:attrs/ButtonTest', (value, data) => {

				let guid = cellView.model.get('guid');
				let itemModel = _.find(this._dataModel.CanvasItems, (item) => {
					return item.Guid === guid;
				});

				if(value === 'test'){
					if (itemModel) {
						this.CheckExactOnlineSettings(itemModel);					
					}
				}

				if(value === 'webhook'){
					this.SubscribeExactOnlineWebhook();
				}

				if(value === 'createOCIWebhook'){
					this.GetProductCatalogUrl();					
				}

				if(value === 'export'){
					this.ExportSettings(itemModel);					
				}
				if(value === 'import'){
					this.ImportSettings(itemModel, data, cellView);					
				}

			});

			this._inspector.render();

			$("#paper-container").animate({right: 320}, ()=>{
				$("#inspector-container").show();
			});
		}
	}

	private ImportSettings(item: CanvasItemModel, data: string, cell: any){
		try{
			let jsonData = JSON.parse(data);
			cell.model.attributes.attrs['EndPointUrl'] = jsonData.EndPointUrl;
			cell.model.attributes.attrs['CallbackUrl'] = jsonData.CallbackUrl;
			cell.model.attributes.attrs['ClientId'] = jsonData.ClientId;
			cell.model.attributes.attrs['ClientSecret'] = jsonData.ClientSecret;
			cell.model.attributes.attrs['Login'] = jsonData.Login;
			cell.model.attributes.attrs['Password'] = jsonData.Password;
			cell.model.attributes.attrs['SecretKey'] = jsonData.SecretKey;
			cell.model.attributes.attrs['QueryString'] = jsonData.QueryString;

			this.UpdateProperty(cell, 'EndPointUrl');
			this.UpdateProperty(cell, 'CallbackUrl');
			this.UpdateProperty(cell, 'ClientId');
			this.UpdateProperty(cell, 'ClientSecret');
			this.UpdateProperty(cell, 'Login');
			this.UpdateProperty(cell, 'Password');
			this.UpdateProperty(cell, 'SecretKey');
			this.UpdateProperty(cell, 'QueryString');

			this._inspector.render();
		}catch{}
	}

	private ExportSettings(item: CanvasItemModel){
		let properties = JSON.parse(item.Properties);
		let settings: IExactOnlineSettingDto = {
			EndPointUrl: properties.EndPointUrl,
			CallbackUrl: properties.CallbackUrl,
			ClientId: properties.ClientId,
			ClientSecret: properties.ClientSecret,
			Login: properties.Login,
			Password: properties.Password,
			SecretKey: properties.SecretKey,
			QueryString: properties.QueryString
		};

		FileDownloader.DownloadBase64(btoa(JSON.stringify(settings, (key, value) => { if (value !== null) return value})), "settings.json");
	}

	private SubscribeExactOnlineWebhook(){
		BlockUI.Block();
		CanvasDesignerStore.SubscribeExactOnlineWebhook(this._dwPackage.Id)
		.always(()=>{
			BlockUI.Unblock();
		})
		.then(()=>{
			new Notifier().Success('OK');
		})
		.fail((err)=>{
			new Notifier().Failed(err.message);
		});
	}

	private GetProductCatalogUrl(){
		BlockUI.Block();
		CanvasDesignerStore.GetProductCatalogUrl(this._dwPackage.Id)
		.always(()=>{
			BlockUI.Unblock();
		})
		.then((url)=>{
			window.open(url);
		})
		.fail((err)=>{
			new Notifier().Failed(err.message);
		});
	}

	private CheckExactOnlineSettings(item: CanvasItemModel){
		let properties = JSON.parse(item.Properties);
		let settings: IExactOnlineSettingDto = {
			EndPointUrl: properties.EndPointUrl,
			CallbackUrl: properties.CallbackUrl,
			ClientId: properties.ClientId,
			ClientSecret: properties.ClientSecret,
			Login: properties.Login,
			Password: properties.Password,
			SecretKey: properties.SecretKey,
			QueryString: properties.QueryString
		};

		BlockUI.Block();
		CanvasDesignerStore.CheckExactOnlineSettings({Settings: settings})
		.always(()=>{
			BlockUI.Unblock();
		})
		.then(()=>{
			new Notifier().Success('OK');
		})
		.fail((err)=>{
			new Notifier().Failed(err.message);
		});
	}

	private ReRender(cellView: any){
		cellView.initIcons();
	}

	private UpdateProperty(cell: any, propertyName: string) {
		let item = this.GetItemByGuid(cell.model.get('guid'));

		let value = cell.model.attributes.attrs[propertyName];
		try {
			let properties = JSON.parse(item.Properties) || {};
			properties[propertyName] = value;
			item.Properties = JSON.stringify(properties);
		} catch (e) {
			let properties = {};
			properties[propertyName] = value;
			item.Properties = JSON.stringify(properties);
		}

		if (item.State != CanvasModelState.New && item.State != CanvasModelState.Deleted) {
			item.State = CanvasModelState.Changed;
		}
	}

	UpdateLinkProperties(link: any, linkModel: CanvasLinkModel){
		let vertices = link.model.get('vertices');
		try{
			let properties = JSON.parse(link.Properties);
			if(!properties.Rappid){
				properties.Rappid = {};
			}
			properties.Rappid.vertices = vertices;
			linkModel.Properties = JSON.stringify(properties);
		}catch (e) {
			linkModel.Properties = JSON.stringify( { Rappid: { vertices:  vertices } });
		}

		if(linkModel.State != CanvasModelState.New && linkModel.State != CanvasModelState.Deleted){
			linkModel.State = CanvasModelState.Changed;
		}
	}

	UpdateItemProperties(cell: any, itemModel: CanvasItemModel){
		let isCollapsed = !cell.model.attributes.attrs.IsCollapsed;

		try{
			let properties = JSON.parse(itemModel.Properties);
			if(!properties.Rappid){
				properties.Rappid = {};
			}
			properties.Rappid.isCollapsed = isCollapsed;
			itemModel.Properties = JSON.stringify(properties);
		}catch (e) {
			itemModel.Properties = JSON.stringify( { Rappid: { isCollapsed:  isCollapsed } });
		}

		if(itemModel.State != CanvasModelState.New && itemModel.State != CanvasModelState.Deleted){
			itemModel.State = CanvasModelState.Changed;
		}
	}

	AddDataSelector(canvasType: string) {

		let dataSelector = null;
		if (canvasType === CANVAS_TYPES.SOURCE) {
			dataSelector = _.extend(new joint.shapes.cyberThing.DataSelector({
				position: { x: 100, y: 100 }
			}, {}),
				{
					dwType: canvasType,
					title: canvasType,
					dataTypes: [
						{
							Name: DATA_TYPES.DATABASE.Name,
							Icon: DATA_TYPES.DATABASE.Icon
						},
						{
							Name: DATA_TYPES.SPREADSHEET.Name,
							Icon: DATA_TYPES.SPREADSHEET.Icon
						},
						{
							Name: DATA_TYPES.QUERY.Name,
							Icon: DATA_TYPES.QUERY.Icon
						},
						{
							Name: DATA_TYPES.API.Name,
							Icon: DATA_TYPES.API.Icon
						},
						{
							Name: DATA_TYPES.TRIGGER.Name,
							Icon: DATA_TYPES.TRIGGER.Icon
						},
						{
							Name: DATA_TYPES.EXACT_ONLINE.Name,
							Icon: DATA_TYPES.EXACT_ONLINE.Icon
						},
						{
							Name: DATA_TYPES.EWS.Name,
							Icon: DATA_TYPES.EWS.Icon
						},
						{
							Name: DATA_TYPES.OCI_PUNCH_OUT.Name,
							Icon: DATA_TYPES.OCI_PUNCH_OUT.Icon
						},
						{
							Name: DATA_TYPES.MS_ACCESS.Name,
							Icon: DATA_TYPES.MS_ACCESS.Icon
						},
						{
							Name: DATA_TYPES.DESKTOP_FOLDER.Name,
							Icon: DATA_TYPES.DESKTOP_FOLDER.Icon
						}
					]
				});
		}

		if (canvasType === CANVAS_TYPES.DESTINATION) {
			dataSelector = _.extend(new joint.shapes.cyberThing.DataSelector({
				position: { x: 1050, y: 100 }
			}, {}),
				{
					dwType: canvasType,
					title: canvasType,
					dataTypes: [
						{
							Name: DATA_TYPES.DATABASE.Name,
							Icon: DATA_TYPES.DATABASE.Icon
						},
						{
							Name: DATA_TYPES.SPREADSHEET.Name,
							Icon: DATA_TYPES.SPREADSHEET.Icon
						},
						{
							Name: DATA_TYPES.QUERY.Name,
							Icon: DATA_TYPES.QUERY.Icon
						},
						{
							Name: DATA_TYPES.API.Name,
							Icon: DATA_TYPES.API.Icon
						},
						{
							Name: DATA_TYPES.DW_PACKAGE.Name,
							Icon: DATA_TYPES.DW_PACKAGE.Icon
						},
						{
							Name: DATA_TYPES.EXACT_ONLINE.Name,
							Icon: DATA_TYPES.EXACT_ONLINE.Icon
						},
						{
							Name: DATA_TYPES.EWS.Name,
							Icon: DATA_TYPES.EWS.Icon
						},
						{
							Name: DATA_TYPES.DESKTOP_FOLDER.Name,
							Icon: DATA_TYPES.DESKTOP_FOLDER.Icon
						}
					]
				});
		}

		if (dataSelector) {

			dataSelector.on(DATA_SELECTOR_EVENTS.OCI_PUNCH_OUT_SELECTED, () => {
				this.GetByOCIPunchOut(canvasType).then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.SPREADSHEET_SELECTED, (file: File) => {
				this.GetBySpreadsheet(file, canvasType).then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.TRIGGER_SELECTED, (Id: number) => {
				this.GetByTrigger(Id, canvasType).then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.API_PACKAGE_SELECTED, (Id: number) => {
				this.GetFunctionsByPackage(Id, canvasType).then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.DW_PACKAGE_SELECTED, (Id: number) => {
				this.GetDwPackageById(Id, canvasType).then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.ENTITY_SELECTED, (Id: number) => {
				BlockUI.Block();
				this.GetByEntity(Id, canvasType)
					.fail(()=>{
						BlockUI.Unblock();
					})
					.then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.QUERY_SELECTED, (Query: string) => {
				BlockUI.Block();
				this.GetByQuery(Query, canvasType)
					.fail(()=>{
						BlockUI.Unblock();
					})
					.then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.EXACT_ONLINE_ENTITY_SELECTED, (name: string) => {
				this.GetByExactOnlineEntity(name, canvasType)
					.then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.EWS_ENTITY_SELECTED, (name: string) => {
				this.GetByEWSEntity(name, canvasType)
					.then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.MS_ACCESS_ENTITY_SELECTED, (table: MsAccessTableDto, path: string) => {				
				this.GetByMsAccessEntity(table, path, canvasType)
					.then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			dataSelector.on(DATA_SELECTOR_EVENTS.DESKTOP_FOLDER_SELECTED, (path: string) => {				
				this.GetByDesktopFolder(path, canvasType)
					.then((result) => {
					this._graph.removeCells(dataSelector);
					this.BuildShapes(result);
				});
			});

			this._graph.addCell(dataSelector);
		}
	}

	BuildShapes(model: CanvasModel) {
		this._dataModel.CanvasItems = this._dataModel.CanvasItems.concat(model.CanvasItems);
		this._dataModel.CanvasLinks = this._dataModel.CanvasLinks.concat(model.CanvasLinks);

		this._shapeFactory.BuildShapes(this._dataModel)
			.fail(()=>{
				BlockUI.Unblock();
			})
			.then(()=>{
				BlockUI.Unblock();
			});
	}

	GetBySpreadsheet(file: File, parentType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		var convertedResult = null;
		const reader = new FileReader();
		reader.onload = () => {
			convertedResult = btoa(reader.result as string);
			BlockUI.Block();
			CanvasDesignerStore
				.GetNodesBySpreadsheet(convertedResult, file.name, parentType, this._dwPackage.Id, this._dwPackage.Guid)
				.always(() => {
					BlockUI.Unblock();
				}).then((result) => {
					deferredResult.resolve(result);
				}).fail((err)=> Notifier.Failed(err.message));
		};
		reader.readAsBinaryString(file);

		return deferredResult.promise();
	}

	GetByTrigger(id: number, parentType: string): P.Promise<CanvasModel> {
		const deferedResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetTrigger({ DwPackageGuid: this._dwPackage.Guid, TriggerId: id, ParentType: parentType})
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferedResult.resolve(result);
			});
		return deferedResult.promise();
	}

	GetFunctionsByPackage(id: number, parentType: string): P.Promise<CanvasModel> {
		const deferedResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetFunctionPackage({ DwPackageGuid: this._dwPackage.Guid, PackageId: id, ParentType: parentType })
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferedResult.resolve(result);
			});
		return deferedResult.promise();
	}

	GetDwPackageById(id: number, parentType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetDwPackageById({ DwPackageGuid: this._dwPackage.Guid, PackageId: id, ParentType: parentType })
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
			deferredResult.resolve(result);
		}).fail(err=>{
			new Notifier().Failed(err.message);
		});
		return deferredResult.promise();
	}

	GetByEntity(id: number, canvasType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetNodesByEntity(this._dwPackage.Guid, id, canvasType)
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferredResult.resolve(result);
			});
		return deferredResult.promise();
	}

	GetByQuery(query: string, canvasType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetNodesByQuery(query, this._dwPackage.Guid, canvasType)
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferredResult.resolve(result);
			});
		return deferredResult.promise();
	}

	GetByExactOnlineEntity(name: string, canvasType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetNodesByExactOnlineEntity(name, this._dwPackage.Guid, canvasType)
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferredResult.resolve(result);
			});
		return deferredResult.promise();
	}

	GetByMsAccessEntity(table: MsAccessTableDto, path: string, canvasType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetNodesByMsAccessEntity(table, path, this._dwPackage.Guid, canvasType)
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferredResult.resolve(result);
			}).fail(err=>{
				Notifier.Failed(err.message);
			});
		return deferredResult.promise();
	}

	
	GetByDesktopFolder(path: string, canvasType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetNodesByDesktopFolder(path, this._dwPackage.Guid, canvasType)
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferredResult.resolve(result);
			}).fail(err=>{
				Notifier.Failed(err.message);
			});
		return deferredResult.promise();
	}

	GetByEWSEntity(name: string, canvasType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetNodesByEWSEntity(name, this._dwPackage.Guid, canvasType)
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferredResult.resolve(result);
			});
		return deferredResult.promise();
	}

	GetByOCIPunchOut(canvasType: string): P.Promise<CanvasModel> {
		const deferredResult = P.defer<CanvasModel>();
		BlockUI.Block();
		CanvasDesignerStore
			.GetByOCIPunchOut(this._dwPackage.Guid, canvasType)
			.always(() => {
				BlockUI.Unblock();
			}).then((result) => {
				deferredResult.resolve(result);
			});
		return deferredResult.promise();
	}	
}