import 'pubsub';

import {IScreen} from 'Core/Screens/IScreen';
import {ScreenStore} from 'Core/ScreenManager/Stores/ScreenStore';
import {ScreenFactory} from 'Core/Screens/ScreenFactory';
import {P} from 'Core/Common/Promise';
import {BlockUI} from 'Core/Common/BlockUi';
import {DesignScreen} from 'Core/Screens/DesignScreen/DesignScreen';
import {DataModes} from "Core/Enums/DataModes";
import {ScreenTypes} from "Core/Common/Enums/ScreenTypes";
import {Notifier} from "Core/Common/Notifier";
import {ActionSubjectRecordModel} from "Core/ScreenManager/Models/ActionSubjectRecordModel";
import { ConcreteEntityModel } from '../../QueryBuilder/Models/ConcreteEntityModel';

export interface IEditScreenOptions {
    EntityId: number;
    TableTypeId?: number;
    TableTypeName?: string;
    KindId?: number;
    RecordId?: number;
    LoadAsExample?: boolean;
    DataMode?: DataModes;
    SubjectLifestatusId?: number;
    ActionSubjectRecord?: ActionSubjectRecordModel;
    ParentRecordId?: number;
}

export interface IGetScreenByIdParams {
    ScreenId: number;
    RecordId?: number;
    RaiseNotImplemented?: boolean;
}

let getScreenByIdDefaultParams: IGetScreenByIdParams = {
    ScreenId: 0,
    RecordId: 0,
    RaiseNotImplemented: false
}

export class ScreenManager {
    static GetScreenById(params: IGetScreenByIdParams): P.Promise<IScreen> {
        var deferredResult = P.defer<IScreen>();

        params = Object.assign({}, getScreenByIdDefaultParams, params);
        ScreenStore.GetScreenById({ScreenId: params.ScreenId, RecordId: params.RecordId})
            .then(screenModel => {
                if (screenModel.Id === 0) {
                    if (params.RaiseNotImplemented) {
                        PubSub.publish('SCREEN_NOT_IMPLEMENTED', null);
                    }
                    deferredResult.reject({message: 'Screen not implemented'});
                    return;
                }

                var screen = ScreenFactory.GetScreenInstance(screenModel);
                if (screen) {
                    deferredResult.resolve(screen);
                } else {
                    deferredResult.reject({message: "Error loading screen"});
                }
            })
            .fail(error => deferredResult.reject(error));

        return deferredResult.promise();
    }

    static GetEditScreen(options: IEditScreenOptions): P.Promise<IScreen> {
        const dataMode = options.DataMode || DataModes.Default;
        const entityId = options.EntityId;

        let tableTypeId = options.TableTypeId;

        if (!tableTypeId) {
            tableTypeId = !options.TableTypeName ? 0 : null;
        }

        const recordId = options.RecordId || 0;

        var deferredResult = P.defer<IScreen>();

        ScreenStore.GetEditScreen({
            EntityId: entityId,
            TableTypeId: tableTypeId,
            TableTypeName: options.TableTypeName,
            KindId: options.KindId,
            RecordId: recordId,
            LoadAsExample: options.LoadAsExample,
            ParentRecordId: options.ParentRecordId,
            DataMode: dataMode,
            SubjectLifestatusId: options.SubjectLifestatusId
        }).then(screenModel => {
            if (!screenModel.Id) {
                deferredResult.resolve(null);
                return;
            }

            if (dataMode === DataModes.Copy || dataMode === DataModes.CopyWithRelations) {
                screenModel.Data.RecordSpecs.IsNewRecord = true;
            }

            screenModel.DataMode = dataMode;
            screenModel.SubjectLifestatusId = options.SubjectLifestatusId;
            screenModel.ActionSubjectRecord = options.ActionSubjectRecord;

            var screen = ScreenFactory.GetScreenInstance(screenModel);
            if (screen) {
                deferredResult.resolve(screen);
            } else {
                deferredResult.reject({message: "This type not found in screen factory"});
            }
        }).fail(err => {
            deferredResult.reject(err);
        });

        return deferredResult.promise();
    }

    static GetDesignScreen(screenId: number): P.Promise<DesignScreen> {
        var deferredResult = P.defer<DesignScreen>();
        BlockUI.Block();

        let getSubjectEntityPromise = ScreenStore.GetSubjectEntity(screenId);
        let getDesignScreenPromise = ScreenStore.GetDesignScreen({ FormId: screenId });

        P.when(getDesignScreenPromise, getSubjectEntityPromise)
        .always(()=>BlockUI.Unblock())
        .then(result => {
            var screen = ScreenFactory.GetDesignScreenInstance(result[0], result[1])
            deferredResult.resolve(screen)
        }).fail(err => Notifier.Failed(err.message));

        return deferredResult.promise();
    }

    static DeleteScreen(screenId: number): P.Promise<any> {
        var deferredResult = P.defer<DesignScreen>();
        BlockUI.Block();
        ScreenStore.DeleteScreen({FormId: screenId})
            .always(() => BlockUI.Unblock())
            .then(() => deferredResult.resolve(null))
            .fail(error => deferredResult.reject(error));

        return deferredResult.promise();
    }

    static UpdateScreenName(screenId: number, name: string): P.Promise<any> {
        var deferredResult = P.defer<DesignScreen>();
        BlockUI.Block();
        ScreenStore.UpdateScreenName({FormId: screenId, Name: name})
            .always(() => BlockUI.Unblock())
            .then(() => deferredResult.resolve(null))
            .fail(error => deferredResult.reject(error));

        return deferredResult.promise();
    }

    static GetScreenByScreenType(entityId: number,
                                 screenType: ScreenTypes,
                                 recordId: number = 0,
                                 dataMode: DataModes = DataModes.Default,
                                 isLinkEditor: boolean = false) {
        var deferredResult = P.defer<IScreen>();

        ScreenStore.GetScreenByScreenType({
            EntityId: entityId,
            ScreenType: screenType,
            RecordId: recordId,
            DataMode: dataMode
        })
            .then(screenModel => {
                screenModel.DataMode = dataMode;
                var screen = ScreenFactory.GetScreenInstance(screenModel, isLinkEditor);
                if (screen) {
                    deferredResult.resolve(screen);
                }
            })
            .fail(err => {
                if (err.status === 400) {
                    let error = JSON.parse(err.message);
                    deferredResult.reject({
                        message: error.Message,
                        status: err.status
                    });
                } else {
                    deferredResult.reject({
                        message: err.message,
                        status: err.status
                    });
                }
            });

        return deferredResult.promise();
    }

    static IsTypeTransformationRequired(entityId: number, recordId: number): P.Promise<boolean> {
        var deferredResult = P.defer<boolean>();
        ScreenStore.IsTypeTransformationRequired({EntityId: entityId, RecordId: recordId})
	        .then((data) => deferredResult.resolve(data))
            .fail(error => deferredResult.reject(error));

        return deferredResult.promise();
    }
}
