import * as ko from 'knockout';

import { Modal } from "Core/Common/Modal";
import { Event } from "Core/Common/Event";

import {PortletSpaceEditorModel} from "Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModel";

import {LABELS} from "Core/Components/Translation/Locales";
import Template from 'Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModal.html';

ko.templates['Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModal'] = Template;

export const EVENTS = {
	SAVE_PORTLET_SPACE: 'SAVE_PORTLET_SPACE'
};

export interface IEditPortletSpaceParams {
	Name: string;
}

export class PortletSpaceEditorModal extends Event {
	private _model: KnockoutValidatedObservable<PortletSpaceEditorModel>;
	private _modal: Modal;
	private _labels = LABELS;

	constructor(params?: IEditPortletSpaceParams) {
		super();

		this._model = ko.validatedObservable(new PortletSpaceEditorModel());

		if (params) {
			this._model().PortletSpaceName(params.Name);
		}

		this._modal = new Modal({
			addClass: 'jBox-padding-15px',
			width: 350,
			minHeight: 200
		}, false);

		ko.cleanNode(this._modal.Wrapper);
		ko.applyBindings(this, this._modal.Wrapper);

		this.InitValidation();
	}

	InitValidation() {
		ko.validation.init({ insertMessages: false }, true);
	}

	GetTemplateName() {
		return 'Core/Portlets/Modals/PortletSpaceEditorModal/PortletSpaceEditorModal';
	}

	AfterRender() {

	}

	Show() {
		this._modal.Show();
	}

	Close() {
		this._modal.Close();
	}

	IsValid() {
		if (!this._model.isValid()) {
			this._model.errors.showAllMessages();
		}

		return this._model.isValid();
	}

	SavePortletSpace() {
		if (this.IsValid()) {
			const model = this._model();

			const params: IEditPortletSpaceParams = {Name: model.PortletSpaceName()};
			this.Trigger(EVENTS.SAVE_PORTLET_SPACE, params);
		}
	}
}